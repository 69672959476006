import { ComponentType } from 'react';

export default function retryImport<P>(
  fn: () => Promise<{ default: ComponentType<P> }>,
  retriesLeft: number = 3,
  interval: number = 1000
) {
  return new Promise<{ default: ComponentType<P> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          return retryImport(fn, retriesLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
}
