import store, { dispatchThunk } from 'app/store';
import includes from 'lodash-es/includes';
import { change } from 'redux-form';

import get from 'lodash-es/get';

import {
  AvailabilitySetActionPayload,
  clearFieldValue as clearFieldValueThunk,
  ClearHiddenDictionaryEntriesActionPayload,
  DictionaryEntriesVisibilitySetActionPayload,
  DictionaryEntryVisibilitySetActionPayload,
  FormConfigState,
  FormsConfigActions,
  getDictionary,
  getVisibleDictionary,
  IgnoreAnonymizedSetActionPayload,
  InitialFocusedDateSetActionPayload,
  MaxDateSetActionPayload,
  Message,
  MessageSetActionPayload,
  MinDateSetActionPayload,
  PatchableSetActionPayload,
  ReadabilitySetActionPayload,
  RequirabilitySetActionPayload,
  SectionLabelSetActionPayload,
  SectionSubLabelSetActionPayload,
  SectionMessageSetActionPayload,
  SetHiddenDictionaryEntriesActionPayload,
  VisibilitySectionSetActionPayload,
  VisibilitySetActionPayload,
  setSectionVisibility,
  QuestionLabelSetActionPayload,
  QuestionMessageAddActionPayload,
} from 'app/store/data/formsConfig';
import {
  FieldsEnum,
  FormsEnum,
  LeasingVehicleTypeEnum,
  MessageTypeEnum,
  RouteEnum,
  SectionsEnum,
} from 'app/shared/enums';
import { QuotationService } from 'app/services/quotation.service';
import { TravelQuotationService } from 'app/services/travelQuotation.service';
import { CalculationDataByKeysInterface } from 'app/shared/interfaces/CalculationDataByKeys.interface';
import { CheckoutDataByKeysInterface } from 'app/shared/interfaces/CheckoutDataByKeys.interface';
import { TravelCheckoutDataByKeysInterface } from 'app/shared/interfaces/TravelCheckoutDataByKeys.interface';
import { Dictionary } from 'app/services/dict.service';
import { CompleteFormDataByKeysInterface } from 'app/shared/interfaces/CompleteFormDataByKeys.interface';
import { PromotionalCodeState } from 'app/store/data/promotionalCode';
import { getQuestionKeyFromMessageKey } from 'app/services/validation.service';
import { PromotionState } from 'app/store/data/promotion';
import { getParamValues } from 'app/store/data/form';
import { QuestionInterface } from 'app/shared/interfaces/Question.interface';
import { OperatorLeadFormInterface } from 'app/shared/interfaces/OperatorLeadForm.interface';
import { selectIsMotor } from 'app/store/data/quotationConfig';
import { selectIsOperator } from 'app/store/data/user';
import { getOffer } from 'app/store/data/quotationResult';
import { TerminationFormInterface } from 'app/shared/interfaces/TerminationForm.interface';
import { peselFieldsList } from './pesel.service/peselsKeys';
import { AcquisitionActions } from 'app/store/data/acquisition';
import { getIsLeasingLeadConfig } from 'app/shared/helpers/getIsLeasingLeadConfig';
import { selectIsShortCalculationPath } from 'app/store/selectors/calculationForm/selectIsShortCalculationPath';

export function getFormValues<T = CalculationDataByKeysInterface>(
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  return (store.getState().form[formName]?.values || {}) as T;
}

export function getTravelFormValues(): CalculationDataByKeysInterface {
  let formName: string = FormsEnum.TravelForm;
  return (store.getState().form[formName].values ||
    {}) as CalculationDataByKeysInterface;
}

export function getForm<T>(formName: FormsEnum): T {
  return store.getState().form[formName];
}

export function getDictionaryFromSelector(
  formName: FormsEnum = FormsEnum.CalculationForm,
  dictionaryKey: string
): Dictionary {
  return getDictionary(store.getState(), formName, dictionaryKey);
}

export function getPromotionalCode(): PromotionalCodeState {
  return store.getState().promotionalCode;
}

export function getPromotion(): PromotionState {
  return store.getState().promotion;
}

export function getVisibleDictionaryFromSelector(
  dictionaryKey: string,
  formName: FormsEnum = FormsEnum.CalculationForm,
  answersDictionaryKey?: string
) {
  return getVisibleDictionary(
    store.getState(),
    formName,
    dictionaryKey,
    answersDictionaryKey
  );
}

export const getQuestionError = (
  key: string,
  formName: FormsEnum
): Message | undefined => {
  const { messages, questions } = store.getState().formsConfig[formName];
  const question = questions[key];

  if (!question) {
    return;
  }

  const getDangerMessage = (messageKey: string) =>
    messages[messageKey].type === MessageTypeEnum.Danger &&
    (getQuestionKeyFromMessageKey(messageKey) === key ||
      includes(question.messages, messageKey));

  return Object.keys(messages)
    .filter(getDangerMessage)
    .map((messageKey) => messages[messageKey])
    .find((message) => message.visible);
};

export function getUserData() {
  return store.getState().user;
}

export function getCoownersCount() {
  const isCepikModal = window.location.pathname.includes(
    RouteEnum.COMPARE_CEPIK_DATA
  );
  return isCepikModal
    ? getFormValues(FormsEnum.CompareCepikDataForm).car.coownersCount
    : store.getState().quotation.coOwnersCount;
}

export function getActiveScreen(formName: FormsEnum) {
  return store.getState().formsConfig[formName].activeScreen;
}

export function getDictionaryParamValues({
  formName,
  answersDictionaryParams = [],
}: {
  formName: FormsEnum;
  answersDictionaryParams: QuestionInterface['answersDictionaryParams'];
}) {
  return getParamValues(store.getState(), formName, answersDictionaryParams);
}

export function setDictionary({
  dictionary,
  dictionaryKey,
  formName,
}: {
  dictionary: Dictionary;
  dictionaryKey: string;
  formName: FormsEnum;
}) {
  store.dispatch(
    FormsConfigActions.setDictionaryAction({
      dictionary,
      dictionaryKey,
      formName,
    })
  );
}

export function getCheckoutFormValues(): CheckoutDataByKeysInterface {
  let formName: string = FormsEnum.CheckoutForm;
  return (store.getState().form[formName]?.values ||
    {}) as CheckoutDataByKeysInterface;
}

export function getTravelCheckoutFormValues(): TravelCheckoutDataByKeysInterface {
  let formName: string = FormsEnum.TravelCheckoutForm;
  return (store.getState().form[formName]?.values ||
    {}) as TravelCheckoutDataByKeysInterface;
}

export function getCheckoutPaymentFormValues(): CheckoutDataByKeysInterface {
  let formName: string = FormsEnum.CheckoutPaymentForm;
  return (store.getState().form[formName]?.values ||
    {}) as CheckoutDataByKeysInterface;
}

export function getTravelPaymentFormValues(): TravelCheckoutDataByKeysInterface {
  let formName: string = FormsEnum.TravelPaymentForm;
  return (store.getState().form[formName]?.values ||
    {}) as TravelCheckoutDataByKeysInterface;
}

export function getCompleteDataFormValues(): CompleteFormDataByKeysInterface {
  let formName = FormsEnum.CompleteDataForm;
  return store.getState().form[formName].values || {};
}

export function getTerminationGeneratorFormValues(): Partial<TerminationFormInterface> {
  const formName = FormsEnum.TerminationGeneratorForm;

  return store.getState().form[formName].values || {};
}

export function getOperatorLeadInsuranceData(): OperatorLeadFormInterface['insurance'] {
  const formName = FormsEnum.OperatorLeadsForm;
  return getFormValues(formName).insurance || {};
}

export function getFormValue(
  fieldKey: FieldsEnum,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  return get(getFormValues(formName), fieldKey);
}

export function getInsuranceData() {
  const isCepikModal = window.location.pathname.includes(
    RouteEnum.COMPARE_CEPIK_DATA
  );
  const formName = isCepikModal
    ? FormsEnum.CompareCepikDataForm
    : FormsEnum.CalculationForm;

  return getFormValues(formName).insurance || {};
}

export function getCarProductionYear() {
  const carData = getFormValues().car;
  const isLeasingLeadConfig = getIsLeasingLeadConfig();

  if (!isLeasingLeadConfig || !carData?.leasingVehicleType)
    return carData?.productionYear;

  const isTruck = ![
    LeasingVehicleTypeEnum.Car,
    LeasingVehicleTypeEnum.TruckUnder35,
  ].includes(carData.leasingVehicleType);

  if (isTruck) return carData.leasingCustomProductionYear;

  return carData.productionYear;
}

export function getCarData() {
  return (
    { ...getFormValues().car, productionYear: getCarProductionYear() } || {}
  );
}

export function getOwnerData() {
  return getFormValues().owner || {};
}

export function getFirstCoownerData() {
  return getFormValues().coowners[1] || {};
}

export function getSecondCoownerData() {
  return getFormValues().coowners[2] || {};
}

export function getInsurantData() {
  return getFormValues().insurant || {};
}

export function getCheckoutData() {
  return getCheckoutFormValues().checkout || {};
}

export function getCheckoutInsurantData() {
  return getCheckoutData().insurant || {};
}

export function getCheckoutOwnerData() {
  return getCheckoutFormValues().owner || {};
}

export function getCheckoutCarData() {
  return getCheckoutFormValues().car || {};
}

export function getCheckoutCoownersData() {
  return getCheckoutFormValues().coowners || {};
}

export function getQuotation() {
  return store.getState().quotation;
}

export function getTravelQuotation() {
  return store.getState().travelQuotation;
}

export function getTravelCheckoutInsurantData() {
  return getTravelCheckoutFormValues().insurant || {};
}

export function getOfferFromSelector() {
  const { offerId } = store.getState().selectedOffer;

  if (!offerId) {
    return;
  }

  return getOffer(offerId)(store.getState());
}

export function setMessageVisibility(payload: VisibilitySetActionPayload) {
  setTimeout(
    () =>
      store.dispatch(FormsConfigActions.setMessageVisibilityAction(payload)),
    0
  );
}

export function setSectionMessageVisibility(
  payload: VisibilitySetActionPayload
) {
  store.dispatch(FormsConfigActions.setSectionMessageVisibilityAction(payload));
}

export function setQuestionIgnoreAnonymized(
  payload: IgnoreAnonymizedSetActionPayload
) {
  store.dispatch(FormsConfigActions.setQuestionIgnoreAnonymizedAction(payload));
}

export function setMinDate(payload: MinDateSetActionPayload) {
  store.dispatch(FormsConfigActions.setMinDateAction(payload));
}

export function setMaxDate(payload: MaxDateSetActionPayload) {
  store.dispatch(FormsConfigActions.setMaxDateAction(payload));
}

export function setInitialFocusedDate(
  payload: InitialFocusedDateSetActionPayload
) {
  store.dispatch(FormsConfigActions.setInitialFocusedDateAction(payload));
}

export function setQuestionVisibility(payload: VisibilitySetActionPayload) {
  store.dispatch(FormsConfigActions.setQuestionVisibilityAction(payload));
}

export function setQuestionLabel(payload: QuestionLabelSetActionPayload) {
  store.dispatch(FormsConfigActions.setQuestionLabelAction(payload));
}

export function setQuestionPatchable(payload: PatchableSetActionPayload) {
  store.dispatch(FormsConfigActions.setQuestionPatchableAction(payload));
}

export function setMessage(payload: MessageSetActionPayload) {
  store.dispatch(FormsConfigActions.setMessageAction(payload));
}

export function addQuestionMessage(payload: QuestionMessageAddActionPayload) {
  store.dispatch(FormsConfigActions.addQuestionMessage(payload));
}

export function setSectionMessage(payload: SectionMessageSetActionPayload) {
  store.dispatch(FormsConfigActions.setSectionMessageAction(payload));
}

export function setQuestionsVisibility(
  questionKeys: Array<string>,
  visible: boolean,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  questionKeys.forEach((key) => {
    setQuestionVisibility({
      key,
      visible,
      formName,
    });
  });
}

export function setSectionVisibilityHelper(
  payload: VisibilitySectionSetActionPayload
) {
  dispatchThunk(setSectionVisibility(payload));
}

export function setSectionLabel(payload: SectionLabelSetActionPayload) {
  store.dispatch(FormsConfigActions.setSectionLabelAction(payload));
}

export function setSectionSubLabel(payload: SectionSubLabelSetActionPayload) {
  store.dispatch(FormsConfigActions.setSectionSubLabelAction(payload));
}

export function setDictionaryEntryVisibility(
  payload: DictionaryEntryVisibilitySetActionPayload
) {
  store.dispatch(
    FormsConfigActions.setDictionaryEntryVisibilityAction(payload)
  );
}

export function setDictionaryEntriesVisibility(
  payload: DictionaryEntriesVisibilitySetActionPayload
) {
  store.dispatch(
    FormsConfigActions.setDictionaryEntriesVisibilityAction(payload)
  );
}

export function clearHiddenDictionaryEntries(
  payload: ClearHiddenDictionaryEntriesActionPayload
) {
  store.dispatch(
    FormsConfigActions.clearHiddenDictionaryEntriesAction(payload)
  );
}

export function setQuestionAvailability(payload: AvailabilitySetActionPayload) {
  if (!payload.enabled) {
    store.dispatch(
      FormsConfigActions.clearQuestionErrorsAction({
        key: payload.key,
        formName: payload.formName || FormsEnum.CalculationForm,
      })
    );
  }

  store.dispatch(FormsConfigActions.setQuestionAvailabilityAction(payload));
}

export function setQuestionReadability(payload: ReadabilitySetActionPayload) {
  store.dispatch(FormsConfigActions.setQuestionReadabilityAction(payload));
}

export function setHiddenDictionaries(
  payload: SetHiddenDictionaryEntriesActionPayload
) {
  store.dispatch(FormsConfigActions.setHiddenDictionariesEntries(payload));
}

export function setQuestionRequirability(
  payload: RequirabilitySetActionPayload
) {
  store.dispatch(FormsConfigActions.setQuestionRequirabilityAction(payload));
}

export function changeFieldValue(
  field: string,
  value: string | number | boolean | null,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  if (!peselFieldsList.includes(field as FieldsEnum)) {
    store.dispatch(
      FormsConfigActions.clearQuestionErrorsAction({
        key: field,
        formName,
      })
    );
  }
  store.dispatch(change(formName, field, value));
}

export function getFormConfig(formName: FormsEnum) {
  return store.getState().formsConfig[formName];
}

export function changeFieldValueAndUpdate(
  field: FieldsEnum | string,
  value: string | number | boolean | null,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  const state = store.getState();
  const formConfig = state.formsConfig[formName] as FormConfigState;
  const question = formConfig.questions[field];
  const { quotation, travelQuotation } = state;

  if (question) {
    switch (formName) {
      case FormsEnum.TravelForm:
      case FormsEnum.TravelCheckoutForm:
      case FormsEnum.TravelPaymentForm:
        TravelQuotationService.update(
          travelQuotation.id,
          question.modelPath,
          value
        );
        break;
      default:
        QuotationService.update(quotation.id, question.modelPath, value);
    }

    changeFieldValue(field, value, formName);
  }
}

export async function changeFieldValueAndUpdateAsync(
  field: FieldsEnum | string,
  value: string | number | boolean | null,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  const state = store.getState();
  const formConfig = state.formsConfig[formName] as FormConfigState;
  const question = formConfig.questions[field];
  const { quotation, travelQuotation } = state;

  if (question) {
    switch (formName) {
      case FormsEnum.TravelForm:
      case FormsEnum.TravelCheckoutForm:
      case FormsEnum.TravelPaymentForm:
        TravelQuotationService.update(
          travelQuotation.id,
          question.modelPath,
          value
        );
        break;
      default:
        await QuotationService.update(quotation.id, question.modelPath, value);
    }

    changeFieldValue(field, value, formName);
  }
}

export function clearFieldsValue(
  fields: Array<string>,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  fields.forEach((field) => clearFieldValue(field, formName));
}

export function clearFieldValue(
  field: string,
  formName: FormsEnum = FormsEnum.CalculationForm,
  disablePatch?: boolean
) {
  dispatchThunk(
    clearFieldValueThunk({ questionKey: field, formName, disablePatch })
  );
}

export function setSectionsVisibilities(
  sections: Array<VisibilitySetActionPayload>
) {
  sections.forEach(setSectionVisibilityHelper);
}

export function getQuestion(
  key: FieldsEnum,
  formName: FormsEnum = FormsEnum.CalculationForm
) {
  return store.getState().formsConfig[formName].questions[key];
}

export function getSection(key: SectionsEnum, formName: FormsEnum) {
  return store.getState().formsConfig[formName].sections[key];
}

export function getIsMotorFromSelector() {
  return selectIsMotor(store.getState());
}

export function getIsOperatorFromSelector() {
  return selectIsOperator(store.getState());
}

export function getIsShortCalculationPathFromSelector() {
  return selectIsShortCalculationPath(store.getState());
}

export function getQuotationIsFromRenewal() {
  return store.getState().acquisition.isRenewalQuotation;
}

export function getIsDataOwnersChangedRenewal(ownerName: string) {
  return store.getState().acquisition.renewalOwnersDataChange[ownerName];
}

export function setDataOwnersChangedRenewal(payload: {
  ownerName: string;
  isChanged: boolean;
}) {
  store.dispatch(AcquisitionActions.changeRenewalOwnersData(payload));
}

export function getIsDataCarChangedRenewal(field: string) {
  return store.getState().acquisition.renewalCarDataChange[field];
}

export function setDataCarChangedRenewal(payload: {
  field: string;
  isChanged: boolean;
}) {
  store.dispatch(AcquisitionActions.changeRenewalCarData(payload));
}

export function changeQuestionClearOnHide(
  field: FieldsEnum,
  formName: FormsEnum = FormsEnum.CalculationForm,
  clearOnHide: boolean
) {
  store.dispatch(
    FormsConfigActions.setQuestionClearableOnHideAction({
      questionKey: field,
      formName,
      clearOnHide,
    })
  );
}

export function getCepikDataFromSelector() {
  return store.getState().cepikData.data;
}

export function getQuotationWithCepikDataFromSelector() {
  return store.getState().cepikData.quotationWithCepikData;
}
