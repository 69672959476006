export default function getWindowConfig(scale: number = 0.75) {
  const {
    screenLeft,
    screenTop,
    screenX,
    screenY,
    innerWidth,
    innerHeight,
  } = window;
  const dualScreenLeft = screenLeft || screenX;
  const dualScreenTop = Math.max(screenTop || screenY, 0);
  const baseConfig = `toolbar=no, location=no, directories=no, status=no, 
 menubar=no, scrollbars=no, resizable=no, copyhistory=no`;
  if (document.documentElement === null) {
    return baseConfig;
  }
  const { clientWidth, clientHeight } = document.documentElement;

  const width = innerWidth || clientWidth || window.screen.width;
  const height = innerHeight || clientHeight || window.screen.height;

  const [h, w] = [scale * height, scale * width];
  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;

  return `${baseConfig}, height=${h}, width=${w}, top=${top}, left=${left}`;
}
