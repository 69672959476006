import React from 'react';
import CloseCircleOutline from 'mdi-material-ui/CloseCircleOutline';
import { IconButton } from '@material-ui/core/';

import { CloseIconButtonProps } from './CloseIconButton.interfaces';

const CloseIconButton = ({ className, onClick }: CloseIconButtonProps) => {
  return (
    <IconButton onClick={onClick} className={className} data-cy="close-btn">
      <CloseCircleOutline />
    </IconButton>
  );
};

export default CloseIconButton;
