import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isStreetNameValid(streetName: string) {
  const reg = /^(?![0-9,.\-/'"() ]+$)[A-Za-z0-9żźćńłęąóśŻŹĆĄŚĘŁÓŃ,.\-/'"()áäčéëöőüž ]+$/;
  return streetName === '' || reg.test(streetName);
}

export default function validateStreetName(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_street_name',
    valid: isStreetNameValid(data.value.toString()),
  };
}
