import React from 'react';

import {
  ChunkLoadErrorBoundaryProps,
  ChunkLoadErrorBoundaryOwnState,
} from './ChunkLoadErrorBoundary.interfaces';
import { ChunkLoadErrorFallback } from 'app/components/ChunkLoadErrorFallback';

class ChunkLoadErrorBoundary extends React.Component<
  ChunkLoadErrorBoundaryProps,
  ChunkLoadErrorBoundaryOwnState
> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: error.name.includes('ChunkLoadError') };
  }

  constructor(props: ChunkLoadErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    return this.state.hasError ? (
      <ChunkLoadErrorFallback />
    ) : (
      this.props.children
    );
  }
}

export default ChunkLoadErrorBoundary;
