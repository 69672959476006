import { RootState } from 'app/store/rootReducer';
import {
  AbTestEnum,
  InsuranceTypeEnum,
  LeasingVehicleTypeEnum,
  VehicleTypeEnum,
} from 'app/shared/enums';
import { getIsLeasingLeadConfig } from 'app/shared/helpers/getIsLeasingLeadConfig';

export const selectQuotation = (state: RootState) => state.quotation;
export const getQuotationId = (state: RootState) => state.quotation.id;
export const getQuotationStatus = (state: RootState) => state.quotation.status;
export const getQuotationNumber = (state: RootState) => state.quotation.number;
export const getQuotationEmailAddress = (state: RootState) =>
  state.quotation.emailAddress;
export const selectIsMotorcycle = (state: RootState) =>
  state.quotation.vehicleType === VehicleTypeEnum.Motorcycle;

export const selectGtmVehicleType = (state: RootState) =>
  selectIsMotorcycle(state)
    ? 'Ubezpieczenie motocykla'
    : 'Ubezpieczenie samochodu';

export const getSelectedInsuranceScope = (
  state: RootState
): Array<InsuranceTypeEnum> =>
  state.quotation.scope === undefined || state.quotation.scope === null
    ? []
    : state.quotation.scope.components;

export const getCoownersCount = (state: RootState) =>
  state.quotation.coOwnersCount
    ? +state.quotation.coOwnersCount.replace('_', '')
    : 0;

export const getQuotationPhoneNumber = (state: RootState) =>
  state.quotation.phoneNumber;

export const getCarProductionYear = (state: RootState): number | undefined => {
  const car = state.quotation.car;

  if (car) {
    return car.productionYear;
  }
};

export const getCarMake = (state: RootState): string | undefined => {
  const car = state.quotation.car;

  if (car) {
    return car.make;
  }
};

export const getCarModel = (state: RootState): string | undefined => {
  const car = state.quotation.car;

  if (car) {
    const isLeasingLeadConfig = getIsLeasingLeadConfig();
    const leasingVehicleType = state.quotation.car?.leasingVehicleType;
    const isTruck =
      leasingVehicleType &&
      ![
        LeasingVehicleTypeEnum.Car,
        LeasingVehicleTypeEnum.TruckUnder35,
      ].includes(leasingVehicleType);

    return isLeasingLeadConfig && isTruck ? car.leasingCustomModel : car.model;
  }
};

export const selectCarVersionEt = (state: RootState): string | undefined => {
  const car = state.quotation.car;

  if (car) {
    return car.versionEt;
  }
};

export const selectIsPersonalDataPath = (state: RootState) => {
  return state.quotation.personalDataPath;
};

export const selectQuotationPayment = (state: RootState) => {
  return state.quotation.payment;
};

export const selectQuotationActiveAbTests = (state: RootState) => {
  return state.quotation.activeABTests;
};

export const selectIsShortCalculationAbTestActive = (state: RootState) => {
  const activeABTests = state.quotation.activeABTests;

  return (
    activeABTests.length > 0 &&
    activeABTests.includes(AbTestEnum.ShortCalculationPathCepik)
  );
};

export const selectQuotationCepikMergeResult = (state: RootState) => {
  return state.quotation.cepikMergeResult;
};
