import { getMessageKeyWithSuffix } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import {
  getActiveScreen,
  getCoownersCount,
  getQuestion,
  getQuotation,
  getFormValues,
  getFormValue,
  getCheckoutFormValues,
} from 'app/services/businessFunctionHelpers';
import { isPeselValid } from 'app/services/pesel.service';
import store from 'app/store/index';
import { FormsConfigActions } from 'app/store/data/formsConfig';
import {
  FieldsEnum,
  FormsEnum,
  LabelEnum,
  MessageTypeEnum,
  PersonType,
} from 'app/shared/enums';

const getErrorMessage = (userType: string) => {
  switch (userType) {
    case 'COOWNER1':
      return `${LabelEnum.NotUniquePesel} ${LabelEnum.PeselCoowner1}`;
    case 'COOWNER2':
      return `${LabelEnum.NotUniquePesel} ${LabelEnum.PeselCoowner2}`;
    case 'INSURANT':
      return `${LabelEnum.NotUniquePesel} ${LabelEnum.PeselInsurant}`;
    default:
      return `${LabelEnum.NotUniquePesel} ${LabelEnum.PeselOwner}`;
  }
};

const getUsersType = (formName: FormsEnum) => {
  let users = ['OWNER', 'COOWNER1', 'COOWNER2'];
  const coownersCount = parseInt(getCoownersCount() || '0');
  const isInsurantPesel =
    formName === FormsEnum.CheckoutForm &&
    !!getActiveScreen(FormsEnum.CheckoutForm) &&
    getQuestion(FieldsEnum.CheckoutInsurantPesel, FormsEnum.CheckoutForm)
      .visible;

  users = users.slice(0, coownersCount + 1);
  if (isInsurantPesel) {
    users.push('INSURANT');
  }

  return users;
};

const getQuotationPesels = () => {
  const quotation = getQuotation();
  const insurantSelection = quotation.insurantSelection;

  const getQuotationPesel = (person: PersonType) => {
    switch (person) {
      case PersonType.Owner:
        return quotation?.mainOwner?.peselNumber ?? '';
      case PersonType.Coowner1:
        return quotation?.coOwners?.[0]?.peselNumber ?? '';
      case PersonType.Coowner2:
        return quotation?.coOwners?.[1]?.peselNumber ?? '';
      default:
        return quotation?.insurant?.peselNumber ?? '';
    }
  };

  const quotationPesels = {
    OWNER: getQuotationPesel(PersonType.Owner),
    COOWNER1: getQuotationPesel(PersonType.Coowner1),
    COOWNER2: getQuotationPesel(PersonType.Coowner2),
    INSURANT: getQuotationPesel(insurantSelection as PersonType),
  };

  return quotationPesels;
};

const getCheckoutInsurantPesel = () => {
  const signer = getFormValue(
    FieldsEnum.ContractSigner,
    FormsEnum.CheckoutForm
  )?.toUpperCase();
  let formValues = getCheckoutFormValues();

  if (!signer) {
    return null;
  } else if (signer === 'DIFFERENT') {
    return formValues?.checkout?.insurant?.peselNumber?.toString() ?? '';
  } else {
    return {
      OWNER: formValues?.owner?.peselCheckoutForm,
      COOWNER1: formValues?.coowners?.[1]?.peselCheckoutForm,
      COOWNER2: formValues?.coowners?.[2]?.peselCheckoutForm,
    }[signer];
  }
};

const getPesels = (formName?: FormsEnum) => {
  const quotationPesels = getQuotationPesels();
  let formValues, pesels;

  switch (formName) {
    case FormsEnum.CheckoutForm:
      formValues = getCheckoutFormValues();
      pesels = {
        OWNER: formValues?.owner?.peselCheckoutForm ?? quotationPesels.OWNER,
        COOWNER1:
          formValues?.coowners?.[1]?.peselCheckoutForm ??
          quotationPesels.COOWNER1,
        COOWNER2:
          formValues?.coowners?.[2]?.peselCheckoutForm ??
          quotationPesels.COOWNER2,
        INSURANT: getCheckoutInsurantPesel() ?? quotationPesels.INSURANT,
      };
      break;
    case FormsEnum.CalculationForm:
    case FormsEnum.CompareCepikDataForm:
      formValues = getFormValues(formName);
      pesels = {
        OWNER: formValues?.owner?.pesel ?? quotationPesels.OWNER,
        COOWNER1: formValues?.coowners?.[1]?.pesel ?? quotationPesels.COOWNER1,
        COOWNER2: formValues?.coowners?.[2]?.pesel ?? quotationPesels.COOWNER2,
      };
      break;
    case FormsEnum.CompleteDataForm:
      formValues = getFormValues(FormsEnum.CompleteDataForm);
      pesels = {
        OWNER: formValues?.owner?.pesel ?? '',
        COOWNER1: formValues?.coowners?.[1]?.pesel ?? '',
        COOWNER2: formValues?.coowners?.[2]?.pesel ?? '',
      };
      break;
    default:
      pesels = quotationPesels;
  }

  return pesels;
};

/**
 * @return {boolean} is pesel unique.
 */
export default function validateIsUniquePesel({
  value,
  questionKey,
  formName,
}: ValidatorDataInterface): boolean {
  const isTravelCheckoutForm = formName === FormsEnum.TravelCheckoutForm;

  if (isTravelCheckoutForm || value === '') {
    return true;
  }

  const pesels = getPesels(formName);
  const usersType: Array<string> = getUsersType(formName);

  let userType = 'OWNER';

  switch (questionKey) {
    case 'coowners.1.pesel':
    case 'coowners.1.peselCheckoutForm':
      userType = 'COOWNER1';
      break;
    case 'coowners.2.pesel':
    case 'coowners.2.peselCheckoutForm':
      userType = 'COOWNER2';
      break;
    case 'checkout.insurant.peselNumber':
      userType = 'INSURANT';
      break;
  }

  let fieldsToCheck = usersType.filter((user) => user !== userType);

  const signer = getFormValue(
    FieldsEnum.ContractSigner,
    FormsEnum.CheckoutForm
  );
  if (signer?.toUpperCase() === userType) {
    fieldsToCheck = fieldsToCheck.filter((user) => user !== 'INSURANT');
  }

  const notUniqField = fieldsToCheck.find(
    (fieldToCheck) =>
      isPeselValid(value?.toString() ? value.toString() : '') &&
      value === pesels[fieldToCheck]
  );

  store.dispatch(
    FormsConfigActions.setMessageAction({
      message: {
        key: getMessageKeyWithSuffix(questionKey, 'notUnique_pesel'),
        content: notUniqField ? getErrorMessage(notUniqField) : '',
        type: MessageTypeEnum.Danger,
        visible: !!notUniqField,
        questions: [questionKey],
      },
      formName: formName,
    })
  );

  return !notUniqField;
}
