export enum CheckoutUpsaleEnum {
  ExcessValue = 'Udział własny',
  Included = 'w\xa0cenie',
  IncreaseProtection = 'Zwiększ ochronę',
  SelectAddons = 'Wybierz dodatki',
}

export enum InvoiceTypeEnum {
  Aso = 'Serwis autoryzowany',
  Estimate = 'Kosztorys',
  EstimateWithAmortization = 'Kosztorys z amortyzacją',
  Workshop = 'Warsztat',
}
