import {
  FiltersEnums,
  FiltersExcessType,
  MoreFiltersTypeEnum,
  LabelEnum,
  FilterRepairType,
  FilterAmortizationType,
  InsuranceEnum,
} from 'app/shared/enums';
import { InjectedFormProps } from 'redux-form';

export interface MoreResultsFiltersOwnProps {}

export type MoreResultsFiltersProps = InjectedFormProps<
  {},
  MoreResultsFiltersOwnProps
>;

export enum SectionFiltersName {
  AC = 'ac',
  Assistance = 'assistance',
}

export type FilterType =
  | FilterAmortizationType
  | boolean
  | FiltersExcessType
  | FilterRepairType;

export type FilterSectionFieldProps = {
  sectionTitle: FiltersEnums | LabelEnum;
  tooltip?: string;
  name: MoreFiltersTypeEnum;
  options: Array<{
    title: FiltersEnums | LabelEnum;
    value: FilterType;
  }>;
};

export type FilterSectionProps = {
  title: InsuranceEnum;
  sections: Array<FilterSectionFieldProps>;
  sectionName: SectionFiltersName;
};
