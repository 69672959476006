import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import {
  QuotationWithCepikDataSetActionPayload,
  CepikDataSetActionPayload,
} from './actionPayload.interfaces';

export const CepikDataActions = {
  setCepikData: (payload: CepikDataSetActionPayload) =>
    createAction(ActionType.SET_CEPIK_DATA, payload),
  setQuotationWithCepikData: (
    payload: QuotationWithCepikDataSetActionPayload
  ) => createAction(ActionType.SET_QUOTATION_WITH_CEPIK_DATA, payload),
  setCepikMergeData: (payload: CepikDataSetActionPayload) =>
    createAction(ActionType.SET_CEPIK_MERGE_DATA, payload),
  clearCepikData: () => createAction(ActionType.CLEAR_CEPIK_DATA),
  setCepikModalSavingInProgress: (savingInProgress: boolean) =>
    createAction(
      ActionType.SET_CEPIK_MODAL_SAVING_IN_PROGRESS,
      savingInProgress
    ),
};

export type CepikDataActionsType = ActionsUnion<typeof CepikDataActions>;
