import { LabelEnum } from 'app/shared/enums';

export function isValidFileExtension(file: File) {
  const fileExtensions =
    '.bmp,.jpe,.jpg,.tiff,image/jpeg,image/gif,image/png,image/tiff,image/x-panasonic-raw,application/pdf';
  const extension = file?.type.split('/')[1];

  return file ? fileExtensions.includes(extension) : true;
}

const fileExtension = (file: File) => {
  return isValidFileExtension(file)
    ? undefined
    : LabelEnum.InvalidFileExtension;
};

export default fileExtension;
