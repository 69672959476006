import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import CloseCircleOutline from 'mdi-material-ui/CloseCircleOutline';
import CloseCircle from 'mdi-material-ui/CloseCircle';
import CheckCircle from 'mdi-material-ui/CheckCircle';
import classNames from 'clsx';

import { MessageTypeEnum } from 'app/shared/enums';
import { NotificationProps } from './Notification.interfaces';
import { useStyles } from './Notification.styles';

const Notification = ({ open, onClose, type, content }: NotificationProps) => {
  const classes = useStyles();

  const getIcon = (type: NotificationProps['type']) => {
    return (
      {
        [MessageTypeEnum.Success]: CheckCircle,
        [MessageTypeEnum.Warning]: InfoRounded,
        [MessageTypeEnum.Danger]: CloseCircle,
      }[type as string] || InfoRounded
    );
  };

  const getMessage = () => {
    const Icon = getIcon(type);

    return (
      <>
        <div
          className={classNames(classes.iconContainer, classes[type as string])}
        >
          <Icon className={classes.icon} />
        </div>
        {content}
      </>
    );
  };

  const getAction = () => {
    return (
      <div onClick={onClose} className={classes.action}>
        <CloseCircleOutline fontSize="inherit" />
      </div>
    );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      className={classes.root}
    >
      <div>
        <div
          className={classNames(
            classes.leftBorder,
            classes[type + 'borderLeft']
          )}
        />
        <SnackbarContent
          classes={{
            root: classes.snackbarContentRoot,
            message: classes.snackbarContentMessage,
          }}
          message={getMessage()}
          action={getAction()}
        />
      </div>
    </Snackbar>
  );
};

export default Notification;
