export enum CompareCepikDataEnum {
  AcInsuranceHistoryClaimsCount = 'Czy z tych polis AC były likwidowane jakieś szkody?',
  AdditionalDrivers1BirthDateLabel = 'Data urodzenia najmłodszego dodatkowego kierowcy',
  AdditionalDrivers1LicenseYearLabel = 'Kiedy najmłodszy dodatkowy kierowca uzyskał prawo jazdy kat. B?',
  CannotGetCepikData = 'Nie udało się pobrać danych.',
  CarIsImportedInTheLast12Months = 'Czy auto było sprowadzane w ciągu ostatnich 12 miesięcy?',
  CarOwnerPurchaseYear = 'Od którego roku jesteś właścicielem auta?',
  CarWayOfUse = 'Jak korzystasz z samochodu?',
  Cepik = 'CEPiK',
  CompareCepikData = 'Porównaj dane z CEPiK',
  CompleteRemainingData = 'Uzupełnij pozostałe dane',
  Confirmed = 'Potwierdzone',
  Coowner1Data = 'Dane współwłaściciela',
  Coowner1DriverLicenseYearLabel = 'Kiedy współwłaściciel uzyskał prawo jazdy kat. B?',
  Coowner2AcHistorySame = 'Czy historia drugiego współwłaściciela ubezpieczenia AC jest taka sama jak OC?',
  Coowner2AcInsuranceHistoryLength = 'Od ilu lat drugi współwłaściciel kupuje AC dla swoich samochodów?',
  Coowner2Data = 'Dane drugiego współwłaściciela',
  Coowner2DriverLicenseYearLabel = 'Kiedy drugi współwłaściciel uzyskał prawo jazdy kat. B?',
  Coowner2InsuranceHistorySame = 'Czy historia ubezpieczenia drugiego współwłaściciela jest taka sama jak historia właściciela?',
  Coowner2OcInsuranceHistoryLength = 'Od ilu lat drugi współwłaściciel kupuje OC dla swoich samochodów?',
  CoownerAcHistorySame = 'Czy historia współwłaściciela ubezpieczenia AC jest taka sama jak OC?',
  CoownerAcInsuranceHistoryLength = 'Od ilu lat współwłaściciel kupuje AC dla swoich samochodów?',
  CoownerInsuranceHistorySame = 'Czy historia ubezpieczenia współwłaściciela jest taka sama jak historia właściciela?',
  CoownerOcInsuranceHistoryLength = 'Od ilu lat współwłaściciel kupuje OC dla swoich samochodów?',
  Coowners1BirthDateLabel = 'Data urodzenia współwłaściciela',
  Coowners1GenderLabel = 'Płeć współwłaściciela',
  Coowners1LegalAddressCityLabel = 'Miejscowość zameldowania współwłaściciela',
  Coowners1LegalAddressZipCodeLabel = 'Kod pocztowy zameldowania współwłaściciela',
  Coowners2BirthDateLabel = 'Data urodzenia drugiego współwłaściciela',
  Coowners2GenderLabel = 'Płeć drugiego współwłaściciela',
  Coowners2LegalAddressCityLabel = 'Miejscowość zameldowania drugiego współwłaściciela',
  Coowners2LegalAddressZipCodeLabel = 'Kod pocztowy zameldowania drugiego współwłaściciela',
  CoownersCount = 'Ilu jest dodatkowych właścicieli auta?',
  DriverLicenseMonthLabel = 'Miesiąc uzyskania prawa jazdy',
  FamilyName = 'Nazwisko rodowe',
  FirstRegistrationDate = 'Data pierwszej rejestracji',
  Form = 'Formularz',
  FromCepik = 'Z CEPiK',
  FromForm = 'Z FORMULARZA',
  GoBack = 'Cofnij',
  HasAdditionalDriversQuestion = 'Czy są dodatkowi kierowcy poniżej 26 roku życia?',
  InsuranceHistoryClaims1Year = 'Kiedy była pierwsza szkoda?',
  InsuranceHistoryClaims2Year = 'Kiedy była druga szkoda?',
  InsuranceHistoryClaims3Year = 'Kiedy była trzecia szkoda?',
  InsuranceHistoryClaims4Year = 'Kiedy była czwarta szkoda?',
  InsuranceHistoryClaims5Year = 'Kiedy była piąta szkoda?',
  No = 'Nie',
  NoDrivingLicenseLabel = 'Nie posiada prawa jazdy kat. B',
  OcInsuranceHistoryClaimsCount = 'Czy z tych polis OC były likwidowane jakieś szkody?',
  OwnerAcHistorySame = 'Czy historia ubezpieczenia AC właściciela jest taka sama jak OC?',
  OwnerAcInsuranceHistoryLength = 'Od ilu lat właściciel kupuje AC dla swoich samochodów?',
  OwnerData = 'Dane właściciela',
  OwnerDriverLicenseYearLabel = 'Kiedy właściciel uzyskał prawo jazdy kat. B?',
  OwnerOcInsuranceHistoryLength = 'Od ilu lat właściciel kupuje OC dla swoich samochodów?',
  PreviousInsurer = 'Ostatni ubezpieczyciel',
  ProvideAddressDetails = 'Podaj dane osobowe',
  ProvideInsuranceHistory = 'Potwierdź historię ubezpieczenia',
  SaveAndExit = 'Zapisz i wróć',
  SaveAndGoToResults = 'Zapisz i przejdź do ofert',
  SelectAll = 'Wybierz wszystko',
  SelectedDateIsInvalid = 'Wybrana data jest nieprawidłowa',
  StartDate = 'Start ubezpieczenia',
  SteeringWheelLocation = 'Czy kierownica jest po prawej stronie (tzw. anglik)?',
  SteeringWheelOnTheRight = 'Kierownica po\nprawej stronie',
  SubjectOfInsuranceChanged = 'Pojazd wprowadzony na formularzu jest niezgodny z Cepik.',
  VehicleData = 'Szczegóły samochodu',
  Version = 'Wersja',
  Yes = 'Tak',
}
