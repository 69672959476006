export enum PromotionEnum {
  Bonus = 'Bonus',
  BonusInForm = 'bonus w postaci',
  Voucher = 'Voucher',
  Voucher1 = 'vouchera',
  VoucherFerrari = 'voucher na Ferrari',
  VoucherFerrariLong = 'voucher na przejazd Ferrari ',
  VoucherFerrariWhatNext1 = ', a następnie postępuj zgodnie z ',
  VoucherFerrariWhatNext2 = 'wyświetlanymi instrukcjami. Voucher zostanie wysłany w ciągu 40 dni od zgłoszenia.',
}
