export enum ConsentsEnum {
  ACCEPTMARKETINGAUTOMATEDCONTACT = 'ACCEPTMARKETINGAUTOMATEDCONTACT',
  ACCEPTMARKETINGCONTACT = 'ACCEPTMARKETINGCONTACT',
  BIKCHECKACCEPTED = 'BIKCHECKACCEPTED',
  CrifAddressDataSharingConsent = 'CrifAddressDataSharingConsent',
  CrifDataDownloadConsent = 'CrifDataDownloadConsent',
  CrifDataSharingConsent = 'CrifDataSharingConsent',
  DPDLOWERTHANTHRESHOLD = 'DPDLOWERTHANTHRESHOLD',
  ECONOMICBUREAUSACCEPTED = 'ECONOMICBUREAUSACCEPTED',
  ERIFCHECKACCEPTED = 'ERIFCHECKACCEPTED',
  Group0 = 'Group_0',
  Group1 = 'Group_1',
  Group2 = 'Group_2',
  HiProFirstGroup = 'first_group_terms_agreed',
  HiProSecondGroup = 'second_group_terms_agree',
  HiProThirdGroup = 'third_group_terms_agreed',
  MarketingConsent = 'Zgadzam się na otrzymywanie od Internetowego Agenta Ubezpieczeniowego Sp z o.o. oraz Digibits Sp z o.o., wyłącznych partnerów serwisu www.mubi.pl^^, informacji handlowych za pomocą środków komunikacji elektronicznej tj. drogą telefoniczną, sms oraz e-mail w celu przedstawienia dedykowanych dla mnie ofert lub usług, a także na użycie w tym celu automatów wywołujących (telefon, e-mail).',
  RegulationsConsent = 'Akceptuję <a href="https://mubi.pl/polityka-prywatnosci" target="_blank">politykę prywatności</a> i <a href="https://mubi.pl/regulamin" target="_blank">regulamin serwisu</a>.',
  WONGATERMSAGREED = 'WONGATERMSAGREED',
}
