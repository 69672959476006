export enum DataLayerPageType {
  Aztec = 'Aztec',
  AztecLive = 'AztecLive',
  Calculator = 'Kalkulator',
  Checkout = 'Checkout',
  ConvertedToApplication = 'Konwersja kwotacji',
  DocumentsUpload = 'Upload dokumentów',
  EmailVerified = 'Email zweryfikowany',
  FailHiProPayment = 'Błąd płatności HiPro',
  FailPayment = 'Błąd płatności',
  NotificationsDisabling = 'Wyłączanie powiadomień',
  OfferList = 'Lista ofert',
  Payment = 'Płatność',
  SimpleDocumentsUpload = 'Uproszczony upload dokumentów',
  SuccessPayment = 'Pomyślna płatność',
  UserProfile = 'Profil użytkownika',
  WrongEmailVerification = 'Błąd weryfikacji maila',
}
