import React from 'react';
import { Helmet } from 'react-helmet';

import { RouteEnum } from 'app/shared/enums';
import { documentHeadConfig } from './documentHeadConfig';

const getConfig = () => {
  const { pathname } = window.location;

  if (pathname.includes(RouteEnum.TERMINATION_GENERATOR)) {
    return documentHeadConfig.terminationGenerator;
  } else if (pathname.includes(RouteEnum.TRAVEL)) {
    return documentHeadConfig.travel;
  }

  return documentHeadConfig.default;
};

const config = getConfig();

export default function DocumentHead() {
  return (
    <Helmet>
      <title>{config.title}</title>
      <meta name="description" content={config.description} />
      <link rel="canonical" href={config.canonical} />
    </Helmet>
  );
}
