import { AxiosPromise } from 'axios';

import { ApiService } from 'app/services/api.service';
import { OfferFormConfigInterface } from 'app/shared/interfaces/OfferFormConfig.interface';
import { FormConfig } from 'app/shared/interfaces/FormConfig.interface';

export const FormsConfigurationsService = {
  url: 'form-configurations',

  getOfferFormsConfig(
    shouldGetMotorConfig?: boolean
  ): AxiosPromise<OfferFormConfigInterface> {
    const params = {
      ...(shouldGetMotorConfig && { vehicleKind: 'MOTOR' }),
    };

    return ApiService.get(`${this.url}/offer-forms/current`, { params });
  },

  getCheckoutFormConfig(
    insurer: string,
    insuranceType: string,
    resultId: string,
    shouldGetMotorConfig?: boolean
  ): AxiosPromise<FormConfig> {
    const params = {
      insurer,
      insuranceType,
      resultId,
      ...(shouldGetMotorConfig && { vehicleKind: 'MOTOR' }),
    };

    return ApiService.get(`${this.url}/checkout-forms/current`, {
      params,
    });
  },

  getCheckoutPaymentFormConfig(
    insurer: string,
    insuranceType: string,
    resultId: string
  ): AxiosPromise<FormConfig> {
    return ApiService.get(`${this.url}/payment-forms/current`, {
      params: {
        insurer,
        insuranceType,
        resultId,
      },
    });
  },

  getRegisterFormConfig(): AxiosPromise<FormConfig> {
    return ApiService.get(`${this.url}/register-forms/current`);
  },

  getRecoveryRequestFormConfig(): AxiosPromise<FormConfig> {
    return ApiService.get(`${this.url}/recovery-request-forms/current`);
  },

  getCallbackFormConfig(): AxiosPromise<FormConfig> {
    return ApiService.get(`${this.url}/callback-forms/current`);
  },

  getCheckoutPaymentAlertFormConfig(): AxiosPromise<FormConfig> {
    return ApiService.get(`${this.url}/suspended-policy-forms/current`);
  },

  getQuotationForm(shouldGetMotorConfig?: boolean): AxiosPromise<FormConfig> {
    const params = {
      ...(shouldGetMotorConfig && { vehicleKind: 'MOTOR' }),
    };

    return ApiService.get(`${this.url}/quotation-forms/current`, { params });
  },

  getCompleteDataForm(
    shouldGetMotorConfig?: boolean
  ): AxiosPromise<FormConfig> {
    const params = {
      ...(shouldGetMotorConfig && { vehicleKind: 'MOTOR' }),
    };

    return ApiService.get(`${this.url}/complete-data-forms/current`, {
      params,
    });
  },

  getTravelForm(): AxiosPromise {
    return ApiService.get(
      `${this.url}/travel-insurances/quotation-forms/current`
    );
  },

  getTravelOfferFormConfig(): AxiosPromise<OfferFormConfigInterface> {
    return ApiService.get(`${this.url}/travel-insurances/offer-forms/current`);
  },

  getTravelOfferFormConfigForPoland(): AxiosPromise<OfferFormConfigInterface> {
    return ApiService.get(
      `${this.url}/travel-insurances/poland-offer-forms/current`
    );
  },

  getTravelCheckoutFormConfig(
    insurer: string,
    resultId: string
  ): AxiosPromise<FormConfig> {
    const params = {
      insurer,
      resultId,
    };

    return ApiService.get(
      `${this.url}/travel-insurances/checkout-forms/current`,
      {
        params,
      }
    );
  },

  getTravelPaymentFormConfig(
    insurer: string,
    resultId: string,
    offerId: string
  ): AxiosPromise<FormConfig> {
    const params = {
      insurer,
      resultId,
      offerId,
    };

    return ApiService.get(
      `${this.url}/travel-insurances/payment-forms/current`,
      {
        params,
      }
    );
  },
};
