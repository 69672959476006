export enum DictionariesEnum {
  CarAcInsuranceHistoryLengths = 'car_ac_insurance_history_lengths',
  CarAcInsuranceHistorySame = 'car_ac_insurance_history_same',
  CarClaimYears = 'car_claim_years',
  CarClaimsCounts = 'car_claims_counts',
  CarColors = 'car_colors',
  CarCoownersCounts = 'car_coowners_counts',
  CarDamageStatuses = 'car_damage_statuses',
  CarDoors = 'car_doors',
  CarFuelTypes = 'car_fuel_types',
  CarInsuranceBonusMaluses = 'car_insurance_bonus_maluses',
  CarInsuranceBreakYears = 'car_insurance_break_years',
  CarInsuranceBreaksCounts = 'car_insurance_breaks_counts',
  CarInsuranceBreaksYears = 'car_insurance_breaks_years',
  CarInsuranceHistoryLengths = 'car_insurance_history_lengths',
  CarInsuranceScopes = 'car_insurance_scopes',
  CarInsurers = 'car_insurers',
  CarInsurersWithAddress = 'car_insurers_with_address',
  CarMakes = 'car_makes',
  CarModelVersions = 'car_model_versions',
  CarModels = 'car_models',
  CarNightParkingPlaces = 'car_night_parking_places',
  CarOwnersCounts = 'car_owners_counts',
  CarPlannedYearlyMileages = 'car_planned_yearly_mileages',
  CarProductionYears = 'car_production_years',
  CarPurchaseYears = 'car_purchase_years',
  CarRegistrationCountries = 'car_registration_countries',
  CarSecurityProtections = 'car_security_protections',
  CarWaysOfUse = 'car_ways_of_use',
  ChildrenCounts = 'children_counts',
  ChildrenTransportationFrequencies = 'children_transportation_frequencies',
  Cities = 'cities',
  ContractSignerDictionary = 'contract.signer_dictionary',
  DriverLicenseIssueYears = 'driver_license_issue_years',
  EngineCapacities = 'engine_capacities',
  FirstNames = 'first-names',
  Genders = 'genders',
  InfoekspertModelVersions = 'infoekspert_model_versions',
  InfoekspertModels = 'infoekspert_models',
  LeasingVehicleTypes = 'leasing_vehicle_types',
  LegalStatus = 'legal_status',
  Link4PolicyCessionBanks = 'link4/banks',
  MaritalStatuses = 'marital_statuses',
  Months = 'months',
  MotorcycleModelVersions = 'motorcycle_model_versions',
  NoYes = 'no_yes',
  NoticeReason = 'notice_reason',
  Occupations = 'occupations',
  PolicyCessionBanks = 'bank-branches',
  SteeringWheelLocations = 'steering_wheel_locations',
  TravelContractSignerDictionary = 'travel.contract.signer_dictionary',
  VehicleType = 'vehicle_types',
  YesNo = 'yes_no',
}
