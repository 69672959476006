import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { QuotationActions } from 'app/store/data/quotation';
import { ReferralActions } from 'app/store/data/referral';
import { logout as logoutAction } from 'app/store/data/user';
import { ThunkAnyDispatch } from 'app/store/helpers';

export function useLogout(routeAfterLogout: string) {
  const dispatch = useDispatch<ThunkAnyDispatch>();
  const history = useHistory();

  return () => {
    dispatch(QuotationActions.clearQuotation());
    dispatch(ReferralActions.clearReferral());
    dispatch(logoutAction()).then(() => {
      history.push(routeAfterLogout);
    });
  };
}
