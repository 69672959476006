import { LabelEnum } from 'app/shared/enums';

const mod97 = (codeString: string) => {
  let checksum: number | string = codeString.slice(0, 2);
  let fragment = '';

  for (let offset = 2; offset < codeString.length; offset += 7) {
    fragment = checksum + codeString.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum === 1; // 1 - IBAN is valid, any other number - IBAN check digits invalid
};

const isValidIban = (value: string) => {
  const ibanCodeLength = 28;
  const valueToCheck = `PL${value}`;

  const iban = valueToCheck.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  let digits = null;

  if (!code || iban.length !== ibanCodeLength) {
    return false;
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) =>
    String(letter.charCodeAt(0) - 55)
  );

  return mod97(digits);
};

const iban = (value: string) => {
  return isValidIban(value) ? undefined : LabelEnum.InvalidIban;
};

export default iban;
