import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isValidOptionalCarRegistrationNumber(
  carRegistrationNumber: string
) {
  const hasDifferentThanZeros = /[^0]/.test(carRegistrationNumber);

  return (
    /^[a-z0-9]{4,}?$/i.test(carRegistrationNumber) && hasDifferentThanZeros
  );
}

export default function validateOptionalCarRegistrationNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_car_registration_number',
    valid: isValidOptionalCarRegistrationNumber(data.value.toString()),
  };
}
