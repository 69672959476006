import moment from 'moment';

import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import { FieldsEnum } from 'app/shared/enums';

export function isInValidRange(
  minDate: string | undefined,
  maxDate: string | undefined,
  value: string | number | boolean
) {
  const momentDate = moment(value.toString());
  let isValid = momentDate.isValid();
  if (isValid) {
    if (minDate && momentDate.isBefore(minDate)) {
      isValid = false;
    }

    if (maxDate && momentDate.isAfter(maxDate)) {
      isValid = false;
    }
  }
  return isValid;
}

export default function validateDate(
  minDate: string,
  maxDate: string,
  data: ValidatorDataInterface
): ValidationObject {
  const ownersBirthDateQuestionKeys = [
    FieldsEnum.OwnerBirthDate,
    FieldsEnum.Coowners1BirthDate,
    FieldsEnum.Coowners2BirthDate,
  ];
  const value = data.value;
  const valid = isInValidRange(minDate, maxDate, value);
  const isOwnerAfterMaxDate =
    ownersBirthDateQuestionKeys.includes(data.questionKey) &&
    isInValidRange(minDate, undefined, value) &&
    !valid;
  const key = isOwnerAfterMaxDate ? 'too_young_owner' : 'invalid_date';

  return {
    key,
    valid,
  };
}
