// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding

import { OfferInterface } from 'app/shared/interfaces/Offer.interface';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import { InsuranceTypeEnum } from 'app/shared/enums';
import { AcTypeEnum } from 'app/shared/enums/AcType.enum';

export const b64EncodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match: string, p1: string) {
        return String.fromCharCode(parseInt(`0x${p1}`, 16));
      }
    )
  );
};

export const isIE11Browser = () => {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('Trident/') < 0) {
    return false;
  }

  const rv = ua.indexOf('rv:');
  return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) === 11;
};

export const UtilService = {
  hasValue<T>(
    value: string | number | boolean | undefined | null | Array<T>
  ): boolean {
    if (Array.isArray(value) && !value.length) {
      return false;
    }

    return value !== undefined && value !== null && value !== '';
  },

  isTruthyValue(value: string | number | boolean | undefined | null): boolean {
    return !!value;
  },

  htmlSubstring(
    text: string,
    length: number
  ): { text: string; length: number } {
    // https://github.com/bobesa/js-html-substring
    if (length >= text.length) {
      return { text, length: text.length };
    }

    let str = '',
      i = 0,
      tag = '',
      c = '',
      end = 0,
      len = text.length,
      cnt = 0,
      tagTree: Array<string> = [],
      tagStrip = function (content: string) {
        let index = tag.indexOf(' ');

        if (index === -1) {
          return content.slice(1, -1);
        }

        return content.slice(1, index);
      };

    while (cnt < length && i < len) {
      c = text.charAt(i);

      if (c === '<') {
        end = text.slice(i).indexOf('>');
        if (end === -1) {
          return {
            text: text,
            length: cnt,
          };
        }

        end += i + 1;
        tag = text.slice(i, end);
        str += tag;

        if (tag.charAt(1) === '/') {
          tagTree = tagTree.slice(0, -1);
        } else {
          tagTree.push(tagStrip(tag));
        }

        i = end;
      } else {
        str += c;
        i++;
        cnt++;
      }
    }

    if (tagTree.length > 0) {
      for (i = tagTree.length - 1; i >= 0; i--) {
        str += '</' + tagTree[i] + '>';
      }
    }

    return {
      text: str + (cnt >= length ? '...' : ''),
      length: cnt,
    };
  },

  displayIf(show: boolean, element: JSX.Element) {
    return show ? element : null;
  },

  escapeDiacritics(text: string): string {
    return text
      .replace(/ą/g, 'a')
      .replace(/Ą/g, 'A')
      .replace(/ć/g, 'c')
      .replace(/Ć/g, 'C')
      .replace(/ę/g, 'e')
      .replace(/Ę/g, 'E')
      .replace(/ł/g, 'l')
      .replace(/Ł/g, 'L')
      .replace(/ń/g, 'n')
      .replace(/Ń/g, 'N')
      .replace(/ó/g, 'o')
      .replace(/Ó/g, 'O')
      .replace(/ś/g, 's')
      .replace(/Ś/g, 'S')
      .replace(/ż/g, 'z')
      .replace(/Ż/g, 'Z')
      .replace(/ź/g, 'z')
      .replace(/Ź/g, 'Z');
  },

  escapeSpecialCharacters(text: string): string {
    return text.replace(/ /g, '_').replace(/\./g, '').replace(/\?/g, '');
  },

  getVirtualPageView(text: string) {
    const { escapeSpecialCharacters, escapeDiacritics } = UtilService;
    const textWithoutDiacritics = escapeDiacritics(text);
    const textWithoutSpecialCharacters = escapeSpecialCharacters(
      textWithoutDiacritics
    );

    return `/${textWithoutSpecialCharacters.toLowerCase()}`;
  },

  getProducts: (offer: OfferInterface | TravelOfferInterface) => {
    const products = [];

    if ('ac' in offer) {
      if (offer.ac?.acType !== AcTypeEnum.ACMini) {
        products.push(InsuranceTypeEnum.Ac);
      }

      if (offer.ac?.acType === AcTypeEnum.ACMini) {
        products.push(InsuranceTypeEnum.AcMini);
      }
    }

    if (offer.oc) {
      products.push(InsuranceTypeEnum.Oc);
    }

    if (offer.nnw) {
      products.push(InsuranceTypeEnum.Nnw);
    }

    if (offer.assistance) {
      products.push(InsuranceTypeEnum.Assistance);
    }

    return products.join(',');
  },
};
