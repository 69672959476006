import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  menuLink: {
    textTransform: 'none',
    position: 'relative',
    fontWeight: 'normal',
    fontSize: 14,
  },
}));
