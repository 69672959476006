import { useEffect, useRef } from 'react';

import { Milliseconds } from 'app/shared/types/Milliseconds.type';

export const useSetTimeout = (callback: Function, ms: Milliseconds) => {
  const timeoutRef = useRef<number>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => callbackRef.current();

    timeoutRef.current = window.setTimeout(tick, ms);

    return () => window.clearTimeout(timeoutRef.current);
  }, [ms]);
};
