import produce from 'immer';
import merge from 'lodash-es/merge';

import { UserState } from './state.interface';
import { ActionType } from 'app/store/actionTypes';
import { UserActionsType } from './actions';

const initialState: UserState = {
  reachedCheckoutStep: 1,
  reachedUploadStep: 1,
  reachedQuotationStep: 0,
};

const userReducer = (
  state: UserState = initialState,
  action: UserActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_USER_PHONE_NUMBER:
        draft.user!.phoneNumber = action.payload;
        return;
      case ActionType.UPDATE_USER_DATA:
        merge(draft.user, action.payload);
        return;
      case ActionType.LOGIN_REQUEST:
        draft.loggingIn = true;
        draft.error = undefined;
        return;
      case ActionType.LOGIN_SUCCESS:
        draft.loggingIn = false;
        draft.loggedIn = true;
        draft.user = action.payload;
        return;
      case ActionType.LOGIN_FAILURE:
        draft.loggingIn = false;
        draft.loggedIn = false;
        draft.error = action.payload;
        return;
      case ActionType.LOGOUT:
        return initialState;
      case ActionType.AUTH_SUCCESS:
        draft.checkingAuth = false;
        draft.loggedIn = true;
        draft.user = action.payload;
        return;
      case ActionType.AUTH_REQUEST:
        draft.checkingAuth = true;
        draft.error = undefined;
        return;
      case ActionType.AUTH_FAILURE:
        draft.checkingAuth = false;
        draft.loggedIn = false;
        draft.user = action.payload;
        return;
      case ActionType.MARK_UPLOAD_STEP:
        draft.reachedUploadStep = action.payload;
        return;
      case ActionType.MARK_QUOTATION_STEP:
        draft.reachedQuotationStep = action.payload;
        return;
      case ActionType.MARK_CHECKOUT_STEP:
        draft.reachedCheckoutStep = action.payload;
        return;
      default:
        return;
    }
  });

export default userReducer;
