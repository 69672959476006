import produce from 'immer';

import { NotificationState } from './state.interfaces';
import { MessageTypeEnum } from 'app/shared/enums';
import { ActionType } from 'app/store/actionTypes';
import { NotificationActionsType } from './actions';

const initialState: NotificationState = {
  content: undefined,
  type: MessageTypeEnum.Success,
  isVisible: false,
};

const notificationReducer = (
  state: NotificationState = initialState,
  action: NotificationActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.HIDE_NOTIFICATION:
        draft.isVisible = false;
        return;
      case ActionType.SHOW_NOTIFICATION:
        return { ...action.payload, isVisible: true };
      default:
        return state;
    }
  });

export default notificationReducer;
