import React from 'react';
import History from 'mdi-material-ui/History';
import ButtonBase from '@material-ui/core/ButtonBase';

import { SaveFormBoxProps } from './SaveFormBox.interfaces';
import { LabelEnum, RouteEnum } from 'app/shared/enums';
import { NavLink } from 'app/components/NavLink';
import { agencyMode } from 'app/services/env.service';
import { useStyles } from './SaveFormBox.styles';

export default function SaveFormBox(props: SaveFormBoxProps) {
  const classes = useStyles(props);

  if (agencyMode) {
    return null;
  }

  return (
    <NavLink to={{ pathname: RouteEnum.LOGIN, state: { modal: true } }}>
      <ButtonBase className={classes.root}>
        <History className={classes.icon} />
        <div className={classes.right}>
          <div className={classes.heading}>{LabelEnum.SaveForm}</div>
          {LabelEnum.EndLater}
        </div>
      </ButtonBase>
    </NavLink>
  );
}
