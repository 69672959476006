import { LabelEnum, ValidationErrorMessagesEnum } from 'app/shared/enums';
import {
  validateMinSelectedNo,
  validateCarLongRegistrationNumber,
  validateCarRegistrationNumber,
  validateOptionalCarRegistrationNumber,
  validateCarVin,
  validateDate,
  validateEmail,
  validatePassword,
  validatePesel,
  validatePhoneNumber,
  validateNipNumber,
  validateWord,
  validateHouseNumber,
  validateIban,
  validateNumber,
  validateStreetName,
  validatePhoneNumberIsAllowed,
} from 'app/validators';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import { agencyMode } from 'app/services/env.service';

const validationConfig = {
  CHECKBOX_GROUP: [
    {
      suffix: 'invalid_min_selected_no',
      content: LabelEnum.InvalidMinSelectedNo,
      validation: {
        validator: validateMinSelectedNo,
        params: ['minSelectedNo'],
      },
    },
  ],
  CAR_VIN: [
    {
      suffix: 'invalid_car_vin',
      content: LabelEnum.InvalidVin,
      validation: {
        validator: validateCarVin,
      },
    },
  ],
  ZIP_CODE: [
    {
      suffix: 'invalid_zip_code',
      content: LabelEnum.InvalidZipCode,
    },
  ],
  EMAIL: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
    {
      suffix: 'invalid_email',
      content: LabelEnum.InvalidEmail,
      validation: {
        validator: validateEmail,
      },
    },
  ],
  PHONE_NUMBER: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
    {
      suffix: 'not_allowed_phone_number',
      content: ValidationErrorMessagesEnum.PhoneNumberNotAllowed,
      validation: {
        validator: validatePhoneNumberIsAllowed,
      },
    },
    {
      suffix: 'invalid_phone_number',
      content: LabelEnum.InvalidPhoneNumber,
      validation: {
        validator: validatePhoneNumber,
      },
    },
  ],
  NIP_NUMBER: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
    {
      suffix: 'invalid_nip_number',
      content: LabelEnum.InvalidNipNumber,
      validation: {
        validator: validateNipNumber,
      },
    },
  ],
  STREETNAME: [
    {
      suffix: 'invalid_street_name',
      content: LabelEnum.InvalidStreetName,
      validation: {
        validator: validateStreetName,
      },
    },
  ],
  HOUSE_NUMBER: [
    {
      suffix: 'invalid_house_number',
      content: LabelEnum.InvalidHouseNumber,
      validation: {
        validator: validateHouseNumber,
      },
    },
  ],
  WORD: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
  ],
  NAME: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
  ],
  LASTNAME: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
  ],
  CAR_REGISTRATION_NUMBER: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
    {
      suffix: 'invalid_car_registration_number',
      content: LabelEnum.InvalidCarRegistrationNumber,
      validation: {
        validator: validateCarRegistrationNumber,
      },
    },
  ],
  EUROBAND_CAR_REGISTRATION_NUMBER: [
    {
      suffix: 'invalid_word',
      content: LabelEnum.InvalidChar,
      validation: {
        validator: validateWord,
      },
    },
    {
      suffix: 'invalid_car_registration_number',
      content: LabelEnum.InvalidCarRegistrationNumber,
      validation: {
        validator: validateOptionalCarRegistrationNumber,
      },
    },
  ],
  CAR_LONG_REGISTRATION_NUMBER: [
    {
      suffix: 'invalid_car_registration_number',
      content: agencyMode
        ? LabelEnum.InvalidCarLongRegistrationNumberAgency
        : LabelEnum.InvalidCarLongRegistrationNumber,
      validation: {
        validator: validateCarLongRegistrationNumber,
      },
    },
  ],
  PASSWORD: [
    {
      suffix: 'invalid_password',
      content: LabelEnum.PasswordNotStrongEnough,
      validation: {
        validator: validatePassword,
      },
    },
  ],
  DATE: [
    {
      suffix: 'invalid_date',
      content: LabelEnum.InvalidDate,
      validation: {
        validator: (
          data: ValidatorDataInterface,
          minDate: string,
          maxDate: string
        ) => validateDate(minDate, maxDate, data),
        params: ['minDate', 'maxDate'],
      },
    },
    {
      suffix: 'too_young_owner',
      content: LabelEnum.TooYoungOwner,
    },
  ],
  EXTENDED_DATE: [
    {
      suffix: 'invalid_date',
      content: LabelEnum.InvalidDate,
      validation: {
        validator: (
          data: ValidatorDataInterface,
          minDate: string,
          maxDate: string
        ) => validateDate(minDate, maxDate, data),
        params: ['minDate', 'maxDate'],
      },
    },
  ],
  PESEL: [
    {
      suffix: 'invalid_pesel',
      content: LabelEnum.InvalidPesel,
      validation: {
        validator: validatePesel,
        params: ['minAge'],
      },
    },
    {
      suffix: 'too_young_owner_pesel',
      content: LabelEnum.TooYoungOwner,
    },
    {
      suffix: 'notUnique_pesel',
      content: LabelEnum.NotUniquePesel,
    },
    {
      suffix: 'notUnique_pesel_short',
      content: LabelEnum.NotUniquePeselShort,
    },
  ],
  IBAN: [
    {
      suffix: 'invalid_iban',
      content: LabelEnum.InvalidIban,
      validation: {
        validator: validateIban,
      },
    },
  ],
  NUMBER: [
    {
      suffix: 'invalid_number',
      content: LabelEnum.InvalidNumber,
      validation: {
        validator: validateNumber,
        params: ['min', 'max'],
      },
    },
  ],
};
export default validationConfig;
