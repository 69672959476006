import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { ErrorData } from 'app/shared/interfaces/ErrorData.interface';

export const UserActions = {
  setUserPhoneNumber: (phoneNumber: string) =>
    createAction(ActionType.SET_USER_PHONE_NUMBER, phoneNumber),
  updateUserData: (user: Partial<UserInterface>) =>
    createAction(ActionType.UPDATE_USER_DATA, user),
  logout: () => createAction(ActionType.LOGOUT),
  loginRequest: () => createAction(ActionType.LOGIN_REQUEST),
  authRequest: () => createAction(ActionType.AUTH_REQUEST),
  authFailure: (user: UserInterface) =>
    createAction(ActionType.AUTH_FAILURE, user),
  authSuccess: (user: UserInterface) =>
    createAction(ActionType.AUTH_SUCCESS, user),
  loginSuccess: (user: UserInterface) =>
    createAction(ActionType.LOGIN_SUCCESS, user),
  loginFailure: (error: ErrorData) =>
    createAction(ActionType.LOGIN_FAILURE, error),
  markUploadStep: (stepNumber: number) =>
    createAction(ActionType.MARK_UPLOAD_STEP, stepNumber),
  markCheckoutStep: (stepNumber: number) =>
    createAction(ActionType.MARK_CHECKOUT_STEP, stepNumber),
  markQuotationStep: (stepNumber: number) =>
    createAction(ActionType.MARK_QUOTATION_STEP, stepNumber),
};

export type UserActionsType = ActionsUnion<typeof UserActions>;
