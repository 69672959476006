export enum ModelPathEnum {
  CarDisposedDescription = 'carDisposedDescription',
  CarDisposedStatus = 'carDisposedStatus',
  Coowners1NoDriverLicense = 'coOwners[0].noDriverLicense',
  Coowners1PeselNumber = 'coOwners[0].peselNumber',
  Coowners2NoDriverLicense = 'coOwners[1].noDriverLicense',
  Coowners2PeselNumber = 'coOwners[1].peselNumber',
  InsurantCountryOfOrigin = 'insurant.countryOfOrigin',
  InsurantHomeAddress = 'insurant.homeAddress',
  InsurantHomeAddressCity = 'insurant.homeAddress.city',
  InsurantHomeAddressZipCode = 'insurant.homeAddress.zipCode',
  InsurantLastName = 'insurant.lastName',
  InsurantLegalAddressCity = 'insurant.legalAddress.city',
  InsurantLegalAddressZipCode = 'insurant.legalAddress.zipCode',
  InsurantName = 'insurant.name',
  InsurantPassport = 'insurant.passport',
  InsurantPeselNumber = 'insurant.peselNumber',
  InsurantSelection = 'insurantSelection',
  LegalProtectionSectionInformation = 'legalProtection.insuredAmount',
  MainOwnerHomeAddressCity = 'mainOwner.homeAddress.city',
  MainOwnerHomeAddressZipCode = 'mainOwner.homeAddress.zipCode',
  MainOwnerLastName = 'mainOwner.lastName',
  MainOwnerName = 'mainOwner.name',
  MainOwnerPeselNumber = 'mainOwner.peselNumber',
  OwnerNoDriverLicense = 'mainOwner.noDriverLicense',
  PersonalDataPath = 'personalDataPath',
  PromotionId = 'promotionId',
  TravelInsurantPesel = 'insurant.pesel',
  TravelTraveler10Age = 'travelers[9].age',
  TravelTraveler10BirthDate = 'travelers[9].birthDate',
  TravelTraveler10Pesel = 'travelers[9].pesel',
  TravelTraveler1Age = 'travelers[0].age',
  TravelTraveler1BirthDate = 'travelers[0].birthDate',
  TravelTraveler1Pesel = 'travelers[0].pesel',
  TravelTraveler2Age = 'travelers[1].age',
  TravelTraveler2BirthDate = 'travelers[1].birthDate',
  TravelTraveler2Pesel = 'travelers[1].pesel',
  TravelTraveler3Age = 'travelers[2].age',
  TravelTraveler3BirthDate = 'travelers[2].birthDate',
  TravelTraveler3Pesel = 'travelers[2].pesel',
  TravelTraveler4Age = 'travelers[3].age',
  TravelTraveler4BirthDate = 'travelers[3].birthDate',
  TravelTraveler4Pesel = 'travelers[3].pesel',
  TravelTraveler5Age = 'travelers[4].age',
  TravelTraveler5BirthDate = 'travelers[4].birthDate',
  TravelTraveler5Pesel = 'travelers[4].pesel',
  TravelTraveler6Age = 'travelers[5].age',
  TravelTraveler6BirthDate = 'travelers[5].birthDate',
  TravelTraveler6Pesel = 'travelers[5].pesel',
  TravelTraveler7Age = 'travelers[6].age',
  TravelTraveler7BirthDate = 'travelers[6].birthDate',
  TravelTraveler7Pesel = 'travelers[6].pesel',
  TravelTraveler8Age = 'travelers[7].age',
  TravelTraveler8BirthDate = 'travelers[7].birthDate',
  TravelTraveler8Pesel = 'travelers[7].pesel',
  TravelTraveler9Age = 'travelers[8].age',
  TravelTraveler9BirthDate = 'travelers[8].birthDate',
  TravelTraveler9Pesel = 'travelers[8].pesel',
  VehicleType = 'vehicleType',
}
