import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { PromotionalCode } from 'app/shared/interfaces/PromotionalCode.interface';
import {
  Referral,
  ReferralStats,
  SkpEmployeeReferralStats,
} from 'app/shared/interfaces/Referral.interface';
import { PromotionState } from 'app/store/data/promotion';

export const PromotionCodeService = {
  setPromotionCode(
    quotationId: string,
    promotionId: string | null,
    promotionPartnerId: string | null,
    resultId: string | null,
    offerId: string | null
  ): AxiosPromise<PromotionalCode> {
    return ApiService.patch(
      `car-insurances/quotations/${quotationId}/promotions`,
      { promotionId, promotionPartnerId, resultId, offerId }
    );
  },
  getPromotion(
    quotationId: string,
    partial: boolean,
    resultId: string | null,
    offerId: string | null
  ): AxiosPromise<PromotionState> {
    return ApiService.get(
      `car-insurances/quotations/${quotationId}/promotions`,
      {
        params: {
          resultId,
          offerId,
          partial: partial ? '1' : null,
        },
      }
    );
  },
  getPromotionData(promotionId: string): AxiosPromise<PromotionalCode> {
    return ApiService.get(`dictionaries/promotions/${promotionId}`);
  },
  getVoucherData(): AxiosPromise<Referral> {
    return ApiService.get('referral-codes');
  },
  getVoucherDataForEmail(email: string): AxiosPromise<Referral> {
    return ApiService.get(`referral-codes/${encodeURIComponent(email)}`);
  },
  getVoucherStats(): AxiosPromise<ReferralStats | SkpEmployeeReferralStats> {
    return ApiService.get('car-insurances/quotations/stats/recommendations');
  },
  sendInvitations(emails: Array<string>): AxiosPromise {
    return ApiService.post('users/email-recommendations', { emails: emails });
  },
};
