import { ValidationObject } from 'app/services/validation.service';
import { ControlTypeEnum } from 'app/shared/enums';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export default function validateRequired(
  type: ControlTypeEnum,
  { value }: ValidatorDataInterface
): ValidationObject {
  const validationObject = {
    key: 'required',
    valid: true,
  };

  if (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim() === '') ||
    (type === 'CHECKBOX' && value === false)
  ) {
    validationObject.valid = false;
  }

  if (Array.isArray(value) && !value.length) {
    validationObject.valid = false;
  }

  return validationObject;
}
