import produce from 'immer';

import { ActionType } from 'app/store/actionTypes';
import { BonusPayoutState } from './state.interface';
import { BonusPayoutActionsType } from './actions';

const initialState: BonusPayoutState = {
  userName: '',
  lastName: '',
  promotionAmount: 0,
  postponedPromotionAmount: 0,
  processId: '',
  phoneNumber: '',
  registrationNumber: '',
  policyRecoveryKey: '',
  isPathAnonymous: false,
  isPostponedSelected: false,
  expiredDate: '',
};

const bonusPayoutReducer = (
  state: BonusPayoutState = initialState,
  action: BonusPayoutActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_BONUS_PAYOUT:
        draft.userName = action.payload.userName;
        draft.lastName = action.payload.lastName;
        draft.promotionAmount = action.payload.promotionAmount;
        return;
      case ActionType.CLEAR_BONUS_PAYOUT:
        draft.userName = initialState.userName;
        draft.lastName = initialState.lastName;
        draft.promotionAmount = initialState.promotionAmount;
        draft.processId = initialState.processId;
        draft.policyRecoveryKey = initialState.policyRecoveryKey;
        draft.phoneNumber = initialState.phoneNumber;
        draft.registrationNumber = initialState.registrationNumber;
        draft.isPostponedSelected = initialState.isPostponedSelected;
        return;
      case ActionType.SET_VERIFICATION_DATA:
        draft.phoneNumber = action.payload.phoneNumber;
        draft.registrationNumber = action.payload.registrationNumber;
        return;
      case ActionType.SET_PROCESS_ID:
        draft.processId = action.payload.processId;
        return;
      case ActionType.SET_POLICY_RECOVERY_KEY:
        draft.policyRecoveryKey = action.payload.policyRecoveryKey;
        return;
      case ActionType.SET_POSTPONED_PROMOTION_AMOUNT:
        draft.postponedPromotionAmount = action.payload;
        return;
      case ActionType.SET_POSTPONED_AS_SELECTED:
        draft.isPostponedSelected = action.payload;
        return;
      case ActionType.MARK_PATH_AS_ANONYMOUS:
        draft.isPathAnonymous = true;
        return;
      case ActionType.SET_BONUS_EXPIRE_DATE:
        draft.expiredDate = action.payload;
        return;
      default:
        return state;
    }
  });

export default bonusPayoutReducer;
