import React from 'react';
import classNames from 'clsx';

import { HamburgerAnimatedIconProps } from './HamburgerAnimatedIcon.interfaces';

const HamburgerAnimatedIcon: React.FunctionComponent<HamburgerAnimatedIconProps> = ({
  classes,
  isOpen,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(classes.root, {
        [classes.open]: isOpen,
      })}
    >
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};

export default HamburgerAnimatedIcon;
