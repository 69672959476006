export enum SkpRegistrationEnum {
  ContactDetails = 'Dane kontaktowe:',
  District = 'Okręgowa',
  Email = 'Adres e-mail',
  FirstName = 'Imię',
  LastName = 'Nazwisko',
  Phone = 'Numer telefonu',
  Primary = 'Podstawowa',
  Section1Label = 'Twoja stacja kontroli pojazdów',
  Section2SubLabel = 'Adres',
  Section3Label = 'Dane kontaktowe właściciela stacji',
  Section5Label = 'Zgody prawne',
  SkpId = 'Nr Stacji Kontroli Pojazdów',
  SkpType = 'Rodzaj Twojej Stacji',
  SmsModalChangeCode = 'Zmień',
  SmsModalDesc = 'Kod został wysłany na numer:',
  SmsModalHeading = 'Potwierdzenie rejestracji, wpisz kod SMS',
  SmsModalResendCode = 'Wyślij SMS jeszcze raz',
  StreetName = 'Ulica',
  StreetNumber = 'Numer',
  Submit = 'Zgłoś swoją stację',
  SuccessHeading = 'Dziękujemy za\xa0rejestrację do programu sieci partnerskiej Mubi!',
  SuccessText = 'Wkrótce na\xa0podany przez Ciebie numer telefonu zadzwoni konsultant Mubi w\xa0celu omówienia warunków współpracy',
  Title1 = 'Zgłoś swoją',
  Title2 = 'Stację Kontroli Pojazdów',
}

export enum SkpRegistrationResponseErrorCodesEnum {
  IncorrectConfirmationCode = 'skp-incorrect-confirmation-code',
  CodeConfirmationsLimitExceeded = 'skp-code-confirmations-limit-exceeded',
  ConfirmationCodeExpired = 'skp-confirmation-code-expired',
  SentSmsLimitExceeded = 'skp-sent-sms-limit-exceeded',
}

export enum SkpTypeEnum {
  Primary = 'podstawowa',
  District = 'okregowa',
}
