import * as React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import classNames from 'clsx';

import { NewButtonProps } from './NewButton.interfaces';

class NewButton extends React.PureComponent<NewButtonProps> {
  render() {
    const {
      classes,
      to,
      icon: Icon,
      suffixIcon: SuffixIcon,
      title,
      noWrap,
      overflowEllipsis = false,
      isLoading = false,
      iconComponent,
      className,
      disabled,
      customComponent,
      ...materialUiButtonProps
    } = this.props;

    return (
      <Button
        {...materialUiButtonProps}
        component={customComponent ? customComponent : to ? NavLink : 'button'}
        to={to}
        classes={{
          root: classNames(
            classes.root,
            {
              [classes.noWrap]: noWrap,
              [classes.wrap]: !noWrap,
              [classes.overflowEllipsis]: overflowEllipsis,
            },
            className
          ),
          contained: classes.contained,
          containedPrimary: classes.containedPrimary,
          label: classes.label,
          disabled: classes.disabled,
          sizeLarge: classes.sizeLarge,
        }}
        disabled={isLoading || disabled}
      >
        {Icon && <Icon className={classes.icon} />}
        {iconComponent}
        <span className={classes.labelTitle}>{title}</span>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {SuffixIcon && <SuffixIcon className={classes.suffixIcon} />}
      </Button>
    );
  }
}

export default NewButton;
