import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { NavLinkProps } from './NavLink.interfaces';

const NavLink: React.FunctionComponent<NavLinkProps> = ({
  children,
  classes,
  ...RouterNavLinkProps
}) => {
  return (
    <RouterNavLink className={classes.root} {...RouterNavLinkProps}>
      {children}
    </RouterNavLink>
  );
};

export default NavLink;
