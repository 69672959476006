import { RootState } from 'app/store/rootReducer';
import { FieldsEnum, FormsEnum } from 'app/shared/enums';
import { selectValueIfFieldVisible } from 'app/store/selectors/selectValueIfFieldVisible';

export const createIsAcClaimsCountVisible = (
  state: RootState,
  formName: FormsEnum
) => (acHistorySameQuestionKey: FieldsEnum) => {
  const isAcHistorySame = selectValueIfFieldVisible({
    state,
    formName,
    questionKey: acHistorySameQuestionKey,
  });
  return isAcHistorySame === 'NO';
};
