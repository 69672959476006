import produce from 'immer';
import isEqual from 'lodash-es/isEqual';

import { TravelQuotationResultState } from './state.interfaces';
import { ActionType } from 'app/store/actionTypes';
import { TravelQuotationResultActionsType } from './actions';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import { TravelOfferRecommendation } from 'app/shared/interfaces/TravelOfferRecommendation.interface';

const initialState: TravelQuotationResultState = {
  activeABTests: [],
  offers: {},
  insurers: {},
  results: {},
  calculationTriggeredByUser: false,
  fullResultsObject: [],
  recommendedOffers: {
    minPriceOffer: {} as TravelOfferInterface,
    recommendedOffer: {} as TravelOfferRecommendation,
    maxInsuranceSumOffer: {} as TravelOfferInterface,
  },
};

const travelQuotationResult = (
  state: TravelQuotationResultState = initialState,
  action: TravelQuotationResultActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.STORE_TRAVEL_RESULTS:
        const { activeABTests, results } = action.payload;

        draft.fullResultsObject = results;
        draft.activeABTests = activeABTests;

        const addInsurer = (insurer: typeof results[0]['insurer']) => {
          if (state.insurers[insurer.id] === undefined) {
            draft.insurers[insurer.id] = insurer;
          }
        };

        const addOffer = (offer: typeof results[0]['offers'][0]) => {
          if (state.offers[offer.id] === undefined) {
            draft.offers[offer.id] = offer;
          }
        };

        const setResult = (result: typeof results[0]) => {
          const resultNormalized = {
            ...result,
            offers: result.offers.map((offer) => offer.id),
          };
          if (!isEqual(state.results[resultNormalized.insurer.id], result)) {
            draft.results[resultNormalized.insurer.id] = resultNormalized;
          }
        };

        results.forEach((result) => {
          addInsurer(result.insurer);
          result.offers.forEach(addOffer);
          setResult(result);
        });

        return;
      case ActionType.CLEAR_TRAVEL_QUOTATION_RESULT:
        return { ...initialState };
      case ActionType.UPDATE_TRAVEL_OFFER:
        draft.offers[action.payload.id] = action.payload;
        return;
      case ActionType.SET_TRAVEL_RECOMMENDED_OFFER:
        draft.recommendedOffers = action.payload;
        return;
      default:
        return;
    }
  });

export default travelQuotationResult;
