import produce from 'immer';

import { FormSubmissionState } from './state.interfaces';
import { ActionType } from 'app/store/actionTypes';
import { FormSubmissionActionsType } from './actions';

const initialState: FormSubmissionState = {
  isLoadingResult: false,
};

const formReducer = (
  state: FormSubmissionState = initialState,
  action: FormSubmissionActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_LOADING_RESULT:
        draft.isLoadingResult = action.payload;
        return;
      default:
        return state;
    }
  });

export default formReducer;
