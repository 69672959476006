import React from 'react';
import { Route } from 'react-router';

import { AppMainNotification } from 'app/components/AppMainNotification';
import { DefaultAppRouteProps } from './DefaultAppRoute.interfaces';

export default function DefaultAppRoute({
  component: Component,
  ...rest
}: DefaultAppRouteProps) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <AppMainNotification />
          {Component && <Component {...matchProps} />}
        </>
      )}
    />
  );
}
