import { updateQuotationDebounced } from 'app/store/data/quotation/thunks';
import { updateTravelQuotationDebounced } from 'app/store/data/travelQuotation/thunks';
import { selectActiveQuotation } from 'app/store/data/quotationConfig';
import { ThunkResult } from 'app/store/helpers';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { QuotationType } from 'app/shared/enums';

export const updateQuotation = (
  modelPath: string,
  value: JsonPatchValueType
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const activeQuotation = selectActiveQuotation(state);

  if (activeQuotation === QuotationType.Vehicle) {
    dispatch(updateQuotationDebounced(modelPath, value));
  } else if (activeQuotation === QuotationType.Travel) {
    dispatch(updateTravelQuotationDebounced(modelPath, value));
  }
};
