import moment from 'moment';
import { LabelEnum } from 'app/shared/enums';

const minDateForIdCard = (value: string) => {
  const minDate = moment('2001-01-01', 'YYYY-MM-DD');

  const selectedDate = moment(value, 'YYYY-MM-DD');

  return selectedDate.isSameOrBefore(minDate, 'day')
    ? LabelEnum.IncorrectDate
    : undefined;
};

export default minDateForIdCard;
