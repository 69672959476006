export enum OfferFormInformationTypeEnum {
  Boolean = 'BOOLEAN',
  Description = 'DESCRIPTION',
  Distance = 'DISTANCE',
  Percent = 'PERCENT',
  Price = 'PRICE',
  TravelElement = 'TRAVEL_OFFER_ELEMENT',
  Url = 'URL',
  Value = 'VALUE',
  ValueList = 'VALUE_LIST',
}
