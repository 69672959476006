export enum InsurersEnum {
  Accredited = 'accredited',
  Allianz = 'allianz',
  Aviva = 'aviva',
  Axa = 'axa',
  AxaAssistance = 'axa_assistance',
  Balcia = 'balcia',
  Beesafe = 'beesafe',
  Benefia = 'benefia',
  Compensa = 'compensa',
  ErgoHestia = 'ergohestia',
  Euroins = 'euroins',
  Gefion = 'gefion',
  Generali = 'generali',
  Gothaer = 'gothaer',
  Hestia = 'hestia',
  InterRisk = 'interrisk',
  Link4 = 'link4',
  Mtu = 'mtu',
  Mtu24 = 'mtu24',
  Proama = 'proama',
  Pzu = 'pzu',
  Redclick = 'redclick',
  SignalIduna = 'signaliduna',
  Trasti = 'trasti',
  Tuz = 'tuz',
  Uniqa = 'uniqa',
  Wefox = 'wefox',
  Wiener = 'wiener',
  YouCanDrive = 'ycd',
}
