import { createSelector } from 'reselect';

import compact from 'lodash-es/compact';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';
import keys from 'lodash-es/keys';
import values from 'lodash-es/values';
import includes from 'lodash-es/includes';

import { RootState } from 'app/store/rootReducer';
import {
  Message,
  MessagesState,
  QuestionsState,
  ScreensState,
  SectionsState,
} from 'app/store/data/formsConfig';
import { getQuestionKeyFromMessageKey } from 'app/services/validation.service';
import {
  DictionariesEnum,
  FieldsEnum,
  FormsEnum,
  MessageTypeEnum,
  ScreenEnum,
  SectionsEnum,
} from 'app/shared/enums';
import { FormScreenInterface } from 'app/shared/interfaces/FormScreen.interface';
import { Dictionary, DictionaryEntry } from 'app/services/dict.service';
import { QuestionInterface } from 'app/shared/interfaces/Question.interface';
import { FormSectionInterface } from 'app/shared/interfaces/FormSection.interface';
import { selectIsQuestionVisible } from 'app/store/selectors/isQuestionVisible';

export const getScreens = (
  state: RootState,
  formName: FormsEnum
): ScreensState => state.formsConfig[formName].screens;

export const getScreensArray = createSelector(
  [getScreens],
  (screens): Array<FormScreenInterface> => values(screens)
);

export const getSections = (
  state: RootState,
  formName: FormsEnum
): SectionsState => state.formsConfig[formName].sections;

export const getScreenSectionsKeysArray = (
  state: RootState,
  formName: FormsEnum,
  screenKey: string | number
): Array<SectionsEnum> =>
  state.formsConfig[formName].screens[screenKey].sections;

export const selectSection = ({
  formName,
  sectionKey,
}: {
  formName: FormsEnum;
  sectionKey: SectionsEnum;
}) => (state: RootState): FormSectionInterface =>
  state.formsConfig[formName].sections[sectionKey];

export const selectQuestion = ({
  state,
  formName,
  questionKey,
}: {
  state: RootState;
  formName: FormsEnum;
  questionKey: string;
}): QuestionInterface => state.formsConfig[formName].questions[questionKey];

export const getQuestionMessageIds = (
  state: RootState,
  formName: FormsEnum,
  questionKey: string
) => state.formsConfig[formName].questions[questionKey]?.messages || [];

export const getQuestions = (
  state: RootState,
  formName: FormsEnum
): QuestionsState => state.formsConfig[formName]?.questions;

export const getMessages = (
  state: RootState,
  formName: FormsEnum
): MessagesState => state.formsConfig[formName].messages;

export const getActiveScreen = (
  state: RootState,
  formName: FormsEnum
): number => state.formsConfig[formName].activeScreen;

interface GetShouldAutofillIntefrace {
  state: RootState;
  formName: FormsEnum;
}

export const getShouldAutofill = ({
  state,
  formName,
}: GetShouldAutofillIntefrace): boolean =>
  state.formsConfig[formName].shouldAutofill;

export const getDictionary = (
  state: RootState,
  formName: FormsEnum,
  dictionaryKey: string
) => {
  if (dictionaryKey) {
    return state.formsConfig[formName].dictionaries[dictionaryKey];
  } else {
    return undefined;
  }
};

export const getVisibleDictionary = (
  state: RootState,
  formName: FormsEnum,
  dictionaryKey: string,
  answersDictionaryKey?: string
): Dictionary | undefined =>
  createSelector(
    [getDictionary, getHiddenDictionaryEntriesSelector],
    (dictionary, hiddenEntries) => {
      if (!dictionary || !hiddenEntries || hiddenEntries.length === 0) {
        return dictionary;
      }
      return dictionary.filter((entry: DictionaryEntry) => {
        const questionKey = dictionaryKey.split('_dictionary')[0];
        const hiddenDictionaryEntryKey = compact([
          answersDictionaryKey,
          questionKey,
          entry.key,
        ]).join('_');
        return !includes(hiddenEntries, hiddenDictionaryEntryKey);
      });
    }
  )(state, formName, dictionaryKey, answersDictionaryKey);

export const getVisibleDictionaryWithSubLabels = (
  state: RootState,
  formName: FormsEnum,
  dictionaryKey: string,
  answersDictionaryKey?: string
): Dictionary | undefined =>
  createSelector([getVisibleDictionary], (dictionary) => {
    const isDictionaryWithSublabel =
      answersDictionaryKey === DictionariesEnum.CarModelVersions ||
      answersDictionaryKey === DictionariesEnum.InfoekspertModelVersions;

    if (dictionary && isDictionaryWithSublabel) {
      return dictionary.map((dictionaryEntry) => {
        const dictionaryEntryValue = dictionaryEntry.value as string;
        const [value, ...subLabels] = dictionaryEntryValue.split(', ');

        return {
          ...dictionaryEntry,
          value,
          subLabels,
        };
      });
    }

    return dictionary;
  })(state, formName, dictionaryKey, answersDictionaryKey);

export const getHiddenDictionaryEntriesSelector = (
  state: RootState,
  formName: FormsEnum
) => {
  return state.formsConfig[formName].hiddenDictionaryEntries;
};

export const getBulbMessage = (
  state: RootState,
  name: FormsEnum,
  questionKey: string
) => {
  return createSelector(
    [getQuestionMessageIds, getMessages],
    (messageIds, messages) => {
      return messageIds
        .map((messageId: string) => messages[messageId])
        .find(
          (message: Message) =>
            message.visible && message.type === MessageTypeEnum.BulbInfo
        );
    }
  )(state, name, questionKey);
};

export const makeGetMessagesForFormRow = () => {
  return createSelector(
    [getQuestionMessageIds, getMessages],
    (messageIds, messages) => {
      const selectedMessages = messageIds
        .map((messageId: string) => messages[messageId])
        .filter(
          (message: Message) =>
            message.visible &&
            ![MessageTypeEnum.Danger, MessageTypeEnum.BulbInfo].includes(
              message.type
            )
        );
      return selectedMessages.length === 0 ? null : selectedMessages;
    }
  );
};

export const getFormConfig = (state: RootState, name: FormsEnum) => {
  return createSelector(
    [getScreens, getSections, getQuestions, getMessages],
    (screens, sections, questions, messages) => {
      return {
        screens: keys(screens).map((screenKey) => ({
          ...screens[screenKey],
          sections: screens[screenKey].sections.map(
            (sectionKey: SectionsEnum) => ({
              ...sections[sectionKey],
              formName: name,
              questions: sections[sectionKey].questions.map(
                (questionKey: string) => ({
                  ...questions[questionKey],
                  messages: questions[questionKey].messages.map(
                    (messageKey: string) => ({
                      ...messages[messageKey],
                    })
                  ),
                })
              ),
            })
          ),
        })),
      };
    }
  )(state, name);
};

export const getQuestionError = (
  state: RootState,
  key: string,
  formName: FormsEnum
): Message | undefined => {
  const messages = state.formsConfig[formName].messages;
  const question = state.formsConfig[formName].questions[key];
  if (!question) {
    return;
  }

  return Object.keys(messages)
    .filter(
      (messageKey) =>
        messages[messageKey].type === 'DANGER' &&
        (getQuestionKeyFromMessageKey(messageKey) === key ||
          includes(question.messages, messageKey))
    )
    .map((messageKey) => messages[messageKey])
    .find((message) => message.visible);
};

export const getIsFormValid = (
  state: RootState,
  formName: FormsEnum
): boolean => {
  const questions = state.formsConfig[formName].questions;

  return !values(questions).some((question) =>
    question.messages.some((messageKey: string) => {
      const message = state.formsConfig[formName].messages[messageKey];

      return (
        selectIsQuestionVisible({
          state,
          formName,
          questionKey: question.key,
        }) &&
        message.type === MessageTypeEnum.Danger &&
        message.visible
      );
    })
  );
};

export const getFormErrors = ({
  state,
  formName,
}: {
  state: RootState;
  formName: FormsEnum;
}): Array<string> => {
  let errors = [] as Array<string>;
  const messages = state.formsConfig[formName].messages;
  const questions = state.formsConfig[formName].questions;

  for (let key in questions) {
    if (questions.hasOwnProperty(key)) {
      const question = questions[key];

      question.messages.forEach((messageKey: string) => {
        const message = messages[messageKey];

        if (
          message &&
          selectIsQuestionVisible({
            state,
            formName,
            questionKey: question.key,
          }) &&
          !includes(errors, question.key) &&
          message.type === 'DANGER' &&
          message.visible
        ) {
          errors.push(question.key);
        }
      });
    }
  }

  return errors;
};

export const getQuestionsValid = ({
  state,
  formName,
  questionKeys,
}: {
  state: RootState;
  formName: FormsEnum;
  questionKeys: Array<FieldsEnum>;
}): boolean =>
  createSelector([getFormErrors], (formErrors) =>
    questionKeys.every((questionKey) => !formErrors.includes(questionKey))
  )({ state, formName });

/**
 * Sort messages by type.
 * Messages with status MessageTypeEnum.Danger are at the top of the list.
 * @param messages
 */
const sortMessagesByType = (messages: Array<Message>) =>
  messages.sort((a, b) => {
    if (
      a.type === MessageTypeEnum.Danger &&
      b.type !== MessageTypeEnum.Danger
    ) {
      return -1;
    }

    if (
      a.type !== MessageTypeEnum.Danger &&
      b.type === MessageTypeEnum.Danger
    ) {
      return 1;
    }

    return 0;
  });

export const getMessagesForQuestions = ({
  state,
  formName,
  questionKeys,
}: {
  state: RootState;
  formName: FormsEnum;
  questionKeys: Array<FieldsEnum>;
}): Array<Message> => {
  const getQuestionByKey = (questionKey: FieldsEnum) =>
    state.formsConfig[formName].questions[questionKey];

  const questions = questionKeys.map(getQuestionByKey);

  const visibleQuestions = questions.filter(
    (question) =>
      question &&
      selectIsQuestionVisible({ state, formName, questionKey: question.key })
  );

  const messageKeysForQuestions = flatten(
    visibleQuestions.map((visibleQuestion) => visibleQuestion.messages)
  );

  const getMessageByMessageKey = (messageKey: Message['key']) =>
    state.formsConfig[formName].messages[messageKey];

  const messagesForQuestions = messageKeysForQuestions.map(
    getMessageByMessageKey
  );

  return sortMessagesByType(messagesForQuestions);
};

export const isQuestionValidSelector = (
  state: RootState,
  key: string,
  formName: string
): boolean => {
  const errors = state.formsConfig[formName].errors;

  return find(errors, (question) => question.key === key) === undefined;
};

export const getVisibleQuestionsCount = ({
  state,
  formName,
  questionKeys,
}: {
  state: RootState;
  formName: FormsEnum;
  questionKeys: Array<string>;
}) => {
  const isQuestionVisible = (questionKey: string) =>
    state.formsConfig[formName].questions[questionKey].visible;

  const visibleQuestions = questionKeys.filter(isQuestionVisible);

  return visibleQuestions.length;
};

export const getOfferFormStructureSelector = (state: RootState) =>
  state.formsConfig.offerForm.formStructure;

export const getTravelOfferFormStructureSelector = (state: RootState) =>
  state.formsConfig.travelOfferForm.formStructure;

export const selectOverwrittenFormConfig = (state: RootState) =>
  state.formsConfig.overwrittenFormConfig;

export const selectIsLeasingLeadConfig = (state: RootState) => {
  const firstScreenKey =
    state.formsConfig.overwrittenFormConfig.config?.screens?.[0].key;
  return (
    firstScreenKey &&
    [
      ScreenEnum.LeasingLeadScreen1,
      ScreenEnum.LeasingLeadScreenShortPath,
    ].includes(firstScreenKey as ScreenEnum)
  );
};

export const selectPreviousProductionYear = (state: RootState) =>
  state.formsConfig.previousProductionYear;
