export enum DiagnosticPanelEnum {
  AllStations = 'Wszystkie stacje',
  Calculations = 'Kalkulacje',
  DiagnosticPanel = 'Panel diagnosty',
  DiagnosticiansStats = 'Statystyka diagnostów',
  Diagnostics = 'Diagności',
  FullName = 'Imię i nazwisko',
  OwnerPanel = 'Panel właściciela',
  PaidPolicies = 'Opłacone',
  Policies = 'Polisy',
  Provision = 'Łączne wynagrodzenie SKP brutto',
  Station = 'Stacja',
  StationPanel = 'Panel stacji',
  StatsStation = 'Statystyki stacji',
  Verified = 'Zweryfikowane',
  YourStats = 'Twoje statystyki',
}
