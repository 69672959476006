import { LabelEnum } from 'app/shared/enums';
import { isValidCarRegistrationNumber } from 'app/validators/validateCarRegistrationNumber';

const registrationNumber = (value: string) => {
  return isValidCarRegistrationNumber(value)
    ? undefined
    : LabelEnum.InvalidCarRegistrationNumber;
};

export default registrationNumber;
