import { change, getFormValues } from 'redux-form';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { ThunkResult } from 'app/store/helpers';
import {
  CommonClasses,
  ControlTypeEnum,
  FieldsEnum,
  FormsEnum,
  QuotationType,
  VehicleTypeEnum,
} from 'app/shared/enums';
import { FormsConfigActions } from './actions';
import {
  getFormErrors,
  getIsFormValid,
  getQuestionError,
  getVisibleDictionaryWithSubLabels,
  selectQuestion,
  selectSection,
} from './selectors';
import isMobileOrTablet from 'app/shared/helpers/isMobileOrTablet';
import { isCompactSelectControl } from 'app/services/env.service';
import { scrollToNewCarSelect } from 'app/components/NewCarSelectControl/useNewCarSelectControlScroll';
import { ScrollService } from 'app/services/scroll.service';
import { FormsConfigurationsService } from 'app/services/formsConfigurations.service';
import {
  ClearFieldPayload,
  ValidateFieldActionPayload,
  VisibilitySectionSetActionPayload,
} from 'app/store/data/formsConfig/actionPayload.interfaces';
import { selectQuotation } from 'app/store/data/quotation';
import {
  selectIsPolandSelected,
  selectTravelQuotation,
} from 'app/store/data/travelQuotation';
import { onNextFrame } from 'app/shared/helpers/onNextFrame';
import { isElementInViewport } from 'app/shared/helpers/isElementInViewport';
import { setTravelCheckoutFormConfig } from 'app/store/thunks/setTravelCheckoutFormConfig';
import { setTravelPaymentFormConfig } from 'app/store/thunks/setTravelPaymentFormConfig';
import { TravelQuotationResultWithOfferIdsInterface } from 'app/shared/interfaces/TravelQuotationResultWithOfferIds.interface';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import { isIpad } from 'app/shared/helpers/isMobileOrTablet';
import { TravelQuotationService } from 'app/services/travelQuotation.service';
import { QuotationService } from 'app/services/quotation.service';
import { selectActiveQuotation } from '../quotationConfig';

const excludedFromScrollToNextQuestion = [
  FieldsEnum.NumberOfInstallments,
  FieldsEnum.CarRegistrationNumberNotKnown,
  FieldsEnum.TravelDestinationCountryCodes,
  FieldsEnum.TravelGoals,
  FieldsEnum.TravelTravelers1Age,
  FieldsEnum.TravelTravelers2Age,
  FieldsEnum.TravelTravelers3Age,
  FieldsEnum.TravelTravelers4Age,
  FieldsEnum.TravelTravelers5Age,
  FieldsEnum.TravelTravelers6Age,
  FieldsEnum.TravelTravelers7Age,
  FieldsEnum.TravelTravelers8Age,
  FieldsEnum.TravelTravelers9Age,
  FieldsEnum.TravelTravelers10Age,
  isIpad && FieldsEnum.TravelStartDate,
  isIpad && FieldsEnum.TravelEndDate,
];

export const scrollToNextQuestion = (
  formName: FormsEnum,
  questionKey: string
): ThunkResult => (dispatch, getState) => {
  if (excludedFromScrollToNextQuestion.includes(questionKey as FieldsEnum)) {
    return;
  }
  const rootState = getState();
  const error = getQuestionError(rootState, questionKey, formName);
  const isValid = !error || !error.visible;
  const { type, answersDictionaryKey } = selectQuestion({
    state: rootState,
    formName,
    questionKey,
  });

  const dictionary =
    getVisibleDictionaryWithSubLabels(
      rootState,
      formName,
      `${questionKey}_dictionary`,
      answersDictionaryKey
    ) || [];

  if (isMobileOrTablet() && isValid) {
    const actionButton = document.querySelector(
      `.${CommonClasses.ActionButton}`
    );
    const actionButtonVisible =
      actionButton && isElementInViewport(actionButton);

    if (
      type === ControlTypeEnum.Button ||
      (type === ControlTypeEnum.CheckboxGroup && dictionary.length > 1) ||
      type === ControlTypeEnum.ZipCode ||
      actionButtonVisible
    ) {
      return;
    }

    if (
      questionKey === FieldsEnum.LegalStatusLeased &&
      isCompactSelectControl
    ) {
      onNextFrame(() => scrollToNewCarSelect());
      return;
    }
    onNextFrame(() => ScrollService.scrollToQuestion(questionKey));
  }
};

export const validateField = (
  payload: ValidateFieldActionPayload
): ThunkResult => (dispatch, getState) => {
  dispatch(FormsConfigActions.validateField(payload));
};

export const validateForm = (formName: FormsEnum): ThunkResult<boolean> => (
  dispatch,
  getState
) => {
  const rootState = getState();
  const formValues = getFormValues(formName)(rootState);
  dispatch(
    FormsConfigActions.validateFormAction({
      rootState,
      formName,
      formValues: formValues as FormData,
    })
  );
  const stateAfter = getState();
  const formErrors = getFormErrors({ state: stateAfter, formName });
  if (formErrors.length) {
    console.warn('Invalid form fields: ', formErrors);
  }
  return getIsFormValid(stateAfter, formName);
};

export const getOfferFormStructure = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();

  const isMotor = state.quotation.vehicleType === VehicleTypeEnum.Motorcycle;

  if (state.formsConfig.offerForm.formStructure === undefined) {
    FormsConfigurationsService.getOfferFormsConfig(isMotor).then(
      ({ data: structure }) => {
        dispatch(
          FormsConfigActions.setOfferFormStructureAction({
            structure,
            formName: FormsEnum.OfferForm,
          })
        );
      }
    );
  }
};

export const getTravelOfferFormStructure = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();
  const isPolandSelected = selectIsPolandSelected(state);

  const promise = isPolandSelected
    ? FormsConfigurationsService.getTravelOfferFormConfigForPoland()
    : FormsConfigurationsService.getTravelOfferFormConfig();

  if (state.formsConfig.travelOfferForm.formStructure === undefined) {
    promise.then(({ data: structure }) => {
      dispatch(
        FormsConfigActions.setOfferFormStructureAction({
          structure,
          formName: FormsEnum.TravelOfferForm,
        })
      );
    });
  }
};

export const getRecoveryRequestFormStructure = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();

  if (state.formsConfig.recoveryRequestForm.formStructure === undefined) {
    return FormsConfigurationsService.getRecoveryRequestFormConfig().then(
      ({ data }) => {
        dispatch(
          FormsConfigActions.setFormConfigAction({
            formConfig: data,
            name: FormsEnum.RecoveryRequestForm,
          })
        );
      }
    );
  }
  return Promise.resolve();
};

export const getCallbackFormStructure = (
  withoutPreferredTime?: boolean,
  noPatch?: boolean
): ThunkResult => (dispatch, getState) => {
  const state = getState();

  if (state.formsConfig.callbackForm.formStructure === undefined) {
    return FormsConfigurationsService.getCallbackFormConfig().then(
      ({ data: formConfig }) => {
        if (noPatch) {
          const phoneQuestion = get(
            formConfig,
            'screens[0].sections[0].questions[1]'
          );
          phoneQuestion.shouldPatch = false;
        }

        if (withoutPreferredTime) {
          formConfig.screens[0].sections[0].questions.shift();
        }

        dispatch(
          FormsConfigActions.setFormConfigAction({
            formConfig,
            name: FormsEnum.CallbackForm,
          })
        );
      }
    );
  }
  return Promise.resolve();
};

export const getCheckoutPaymentAlertFormStructure = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();

  if (isEmpty(state.formsConfig.checkoutPaymentAlertForm.screens)) {
    FormsConfigurationsService.getCheckoutPaymentAlertFormConfig().then(
      ({ data: formConfig }) => {
        dispatch(
          FormsConfigActions.setFormConfigAction({
            formConfig,
            name: FormsEnum.CheckoutPaymentAlertForm,
          })
        );
      }
    );
  }
};

export const clearFieldValue = ({
  questionKey,
  formName,
  disablePatch,
}: ClearFieldPayload): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const activeQuotation = selectActiveQuotation(state);
  const quotation = selectQuotation(state);
  const travelQuotation = selectTravelQuotation(state);
  const question = selectQuestion({ state, formName, questionKey });

  if (question?.modelPath && !disablePatch) {
    if (activeQuotation === QuotationType.Travel) {
      TravelQuotationService.update(
        travelQuotation.id,
        question.modelPath,
        null
      );
    } else if (activeQuotation === QuotationType.Vehicle) {
      QuotationService.update(quotation.id, question.modelPath, null);
    }
  }

  dispatch(change(formName, questionKey, null));
};

export const setSectionVisibility = (
  payload: VisibilitySectionSetActionPayload
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const { formName, key: sectionKey, visible } = payload;
  const section = selectSection({ formName, sectionKey })(state);
  if (section.visible === visible) {
    return;
  }

  dispatch(FormsConfigActions.setSectionVisibilityAction(payload));

  if (!visible) {
    section.questions.forEach((questionKey: string) => {
      const question = selectQuestion({ state, formName, questionKey });

      if (!question.clearOnHide) {
        return;
      }

      dispatch(clearFieldValue({ questionKey, formName }));
    });
  }
};

export const getTravelCheckoutFormStructure = (
  result: TravelQuotationResultWithOfferIdsInterface
): ThunkResult => (dispatch) => {
  return FormsConfigurationsService.getTravelCheckoutFormConfig(
    result.insurer.id,
    result.id
  ).then(({ data }) => {
    dispatch(setTravelCheckoutFormConfig(data));
  });
};

export const getTravelPaymentFormStructure = (
  result: TravelQuotationResultWithOfferIdsInterface,
  offer: TravelOfferInterface
): ThunkResult => (dispatch) => {
  return FormsConfigurationsService.getTravelPaymentFormConfig(
    result.insurer.id,
    result.id,
    offer.id
  ).then(({ data }) => {
    dispatch(setTravelPaymentFormConfig(data, offer));
  });
};
