import { EnvValue } from './EnvValue.type';

type AcceptedValuesEnum = {};

/**
 * Converts environment variable to value with enum type
 *
 * @param valueFromEnv - value from environment variable
 * @param acceptedValues - enum containing accepted values
 * @throws warning if value not in enum
 */
export const getEnvEnumValue = <T>(
  valueFromEnv: EnvValue,
  acceptedValues: AcceptedValuesEnum
): T | undefined => {
  const isValueDefined = !!valueFromEnv;
  const isValueValid = Object.values(acceptedValues).includes(valueFromEnv);

  if (isValueDefined && !isValueValid) {
    console.warn('Invalid config value.', { valueFromEnv }, { acceptedValues });
  }

  return isValueDefined && isValueValid
    ? ((valueFromEnv as unknown) as T)
    : undefined;
};
