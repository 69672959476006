export enum RouteEnum {
  ACTIVE_PROMOTION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/promotion-id/:promotionId',
  BANK_TRANSFER_DETAILS_EDIT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/bank-details-edit',
  CALCULATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/calculation',
  CALCULATION_FORM = '/ubezpieczenie-samochodu/kalkulator-oc-ac/calculation/form',
  CALCULATION_RESULTS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/calculation/results',
  CALL_ME_BACK = '/ubezpieczenie-samochodu/kalkulator-oc-ac/callmeback/:recoveryKey',
  CASE_REPORT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/case-report',
  CHANGE_BANK_TRANSFER_DATA = '/ubezpieczenie-samochodu/kalkulator-oc-ac/change-bank-transfer-data/:token',
  CHANGE_CONSENTS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/change-consents',
  CHANGE_PASSWORD = '/ubezpieczenie-samochodu/kalkulator-oc-ac/change-password',
  CHANGE_PASSWORD_PROFILE = '/ubezpieczenie-samochodu/kalkulator-oc-ac/change-password-profile',
  CHECKOUT_REDIRECTION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/checkout-redirection/:resultId/:offerId',
  CLAIM_BONUS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/odbierz-bonus',
  CLAIM_BONUS_SUCCESS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/odbierz-bonus/success',
  CLAIM_BONUS_VERIFICATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/odbierz-bonus/verification',
  COMPARE_CEPIK_DATA = '/ubezpieczenie-samochodu/kalkulator-oc-ac/compare-cepik-data',
  ClAIM_VOUCHER_FERRARI = '/ubezpieczenie-samochodu/kalkulator-oc-ac/odbierz-voucher-ferrari/verification',
  DIAGNOSTICIANS_STATS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/diagnosticians-stats',
  DISABLE_NOTIFICATIONS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/wylacz-powiadomienia/:encryptedQuotationId?',
  EMAIL_VERIFICATION_FAILURE = '/ubezpieczenie-samochodu/kalkulator-oc-ac/email-verification-failure',
  EMAIL_VERIFIED = '/ubezpieczenie-samochodu/kalkulator-oc-ac/email-verified',
  FERRARI_VOUCHER = '/ubezpieczenie-samochodu/kalkulator-oc-ac/odbierz-voucher-ferrari',
  HI_PRO_CONFIRM_PAYMENT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/hipro-confirm-payment',
  HOME = '/ubezpieczenie-samochodu/kalkulator-oc-ac',
  LEADS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/leads',
  LOAD_DOCUMENTS_1_STEP = '/ubezpieczenie-samochodu/kalkulator-oc-ac/dodaj-dokumenty/aztec/select-process',
  LOAD_DOCUMENTS_2_STEP_OBVERSE_CONFIRMATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/obverse-confirmation',
  LOAD_DOCUMENTS_2_STEP_OBVERSE_UPLOAD = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/obverse',
  LOAD_DOCUMENTS_2_STEP_POLICY_CONFIRMATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/previous-policy-confirmation',
  LOAD_DOCUMENTS_2_STEP_POLICY_UPLOAD = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/previous-policy',
  LOAD_DOCUMENTS_2_STEP_REVERSE_CONFIRMATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/reverse-confirmation',
  LOAD_DOCUMENTS_2_STEP_REVERSE_UPLOAD = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/reverse',
  LOAD_DOCUMENTS_2_STEP_SEND_DOCUMENTS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/upload/send-documents',
  LOAD_DOCUMENTS_3_STEP_DOCUMENTS_SAVED = '/ubezpieczenie-samochodu/kalkulator-oc-ac/ready/documents-saved',
  LOAD_DOCUMENTS_3_STEP_SMS_SENT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/ready/sms-sent',
  LOAD_SIMPLE_DOCUMENTS_1_STEP = '/ubezpieczenie-samochodu/kalkulator-oc-ac/dodaj-dokumenty/select-process',
  LOGIN = '/ubezpieczenie-samochodu/kalkulator-oc-ac/login/:redirectToOrigin?/:redirectUrl?',
  MOTORCYCLES = '/ubezpieczenie-samochodu/kalkulator-oc-ac/motocykle',
  MUBI_DUBIT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/mubi-dubit',
  MUBI_LOTTERY = '/ubezpieczenie-samochodu/kalkulator-oc-ac/mubi-loteria',
  NOTIFICATIONS_DISABLED_INFO = '/ubezpieczenie-samochodu/kalkulator-oc-ac/powiadomienia-wylaczone',
  OFFER_DETAILS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/offer',
  OPERATOR_LEADS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/operator-leads',
  OPERATOR_LEADS_NEW_CAR = '/ubezpieczenie-samochodu/kalkulator-oc-ac/operator-leads/NEW_CAR',
  ORDER_PAYOFF = '/ubezpieczenie-samochodu/kalkulator-oc-ac/order-payoff',
  PARTNER_LINK = '/ubezpieczenie-samochodu/kalkulator-oc-ac/partners/:partnerId/:brand',
  PASSWORD_RESET = '/ubezpieczenie-samochodu/kalkulator-oc-ac/password-reset',
  QUOTATION_BY_ID = '/ubezpieczenie-samochodu/kalkulator-oc-ac/quotation-by-id',
  RECOMMEND = '/ubezpieczenie-samochodu/kalkulator-oc-ac/recommend',
  REDIRECT_TO_OFFER = '/ubezpieczenie-samochodu/kalkulator-oc-ac/redirect-to-offer',
  REGISTER = '/ubezpieczenie-samochodu/kalkulator-oc-ac/register',
  REGISTER_BY_EMAIL = '/ubezpieczenie-samochodu/kalkulator-oc-ac/register-by-email',
  REGISTRATION_CONFIRM = '/ubezpieczenie-samochodu/kalkulator-oc-ac/registration-confirm',
  RENEWAL_OF_PROPOSAL = '/ubezpieczenie-samochodu/kalkulator-oc-ac/renewal-of-proposal/:encryptedQuotationId',
  RESEND_VERIFICATION_MAIL = '/ubezpieczenie-samochodu/kalkulator-oc-ac/resend-verification-mail',
  RESET_PASSWORD = '/ubezpieczenie-samochodu/kalkulator-oc-ac/reset-password',
  SAVED_CALCULATIONS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/user-profile/saved-calculations',
  SEND_BANK_TOKEN_CONFIRM = '/ubezpieczenie-samochodu/kalkulator-oc-ac/send-bank-token-confirm',
  SEND_CONFIRM = '/ubezpieczenie-samochodu/kalkulator-oc-ac/send-confirm',
  SEND_CONFIRM_LINK = '/ubezpieczenie-samochodu/kalkulator-oc-ac/send-confirm-link',
  SKP_APPLICATION = '/ubezpieczenie-samochodu/kalkulator-oc-ac/skp-formularz',
  STATION_STATS_MANAGER = '/ubezpieczenie-samochodu/kalkulator-oc-ac/station-stats-manager',
  TERMINATION_GENERATOR = '/ubezpieczenie-samochodu/kalkulator-oc-ac/generator-wypowiedzenia-oc',
  TRAVEL = '/kalkulator-ubezpieczenia-turystycznego',
  TRAVEL_CALCULATION_RESULTS = '/kalkulator-ubezpieczenia-turystycznego/travel-results',
  TRAVEL_CONVERTED_TO_APPLICATION = '/kalkulator-ubezpieczenia-turystycznego/converted-to-application',
  TRAVEL_FORM = '/kalkulator-ubezpieczenia-turystycznego/travel-form',
  TRAVEL_FORM_CHECKOUT = '/kalkulator-ubezpieczenia-turystycznego/travel-form-checkout',
  TRAVEL_FORM_FB = '/kalkulator-ubezpieczenia-turystycznego/travel-form/square',
  TRAVEL_FORM_PAYMENT = '/kalkulator-ubezpieczenia-turystycznego/travel-form-payment',
  TRAVEL_PAYMENT_REDIRECT = '/kalkulator-ubezpieczenia-turystycznego/travel-payment-redirect',
  TRAVEL_SUCCESSFUL_PURCHASE = '/kalkulator-ubezpieczenia-turystycznego/travel-successful-purchase',
  UPLOAD_DOCUMENTS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/dodaj-dokumenty/aztec',
  UPLOAD_SIMPLE_DOCUMENTS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/dodaj-dokumenty',
  USER_EDIT = '/ubezpieczenie-samochodu/kalkulator-oc-ac/user-edit',
  USER_PROFILE = '/ubezpieczenie-samochodu/kalkulator-oc-ac/user-profile',
  YOUR_RECOMMENDATIONS = '/ubezpieczenie-samochodu/kalkulator-oc-ac/your-recommendations',
  YOUR_RECOMMENDATIONS_DIAGNOSTIC = '/ubezpieczenie-samochodu/kalkulator-oc-ac/your-recommendations-diagnostic',
}
