import { InsurerInterface } from 'app/shared/interfaces/Insurer.interface';

export function getInsurerDisplayNameAfterRebranding(
  insurerName: InsurerInterface['name']
) {
  switch (insurerName) {
    case 'axa':
      return 'UNIQA';
    case 'LINK4':
      return 'Link4';
    case 'ergohestia':
      return 'Ergo Hestia';
    default:
      return insurerName;
  }
}
