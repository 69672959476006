import produce from 'immer';

import { ActionType } from 'app/store/actionTypes';
import { SelectedOfferActionsType } from './actions';
import { SelectedOfferState } from 'app/store/data/selectedOffer/state.interface';

const initialState: SelectedOfferState = {
  isRecommended: false,
  insurerDisplayName: '',
  hasAlcoholClause: false,
  hasAcUpsale: false,
  offerFromEmail: {
    carMake: '',
    carModel: '',
    carProductionYear: 0,
    productId: '',
    expired: false,
  },
};

const selectedOfferReducer = (
  state: SelectedOfferState = initialState,
  action: SelectedOfferActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_OFFER_CALCULATION_COMPLETION:
        draft.isComplete = action.payload;
        return;
      case ActionType.SET_SELECTED_OFFER:
        draft.offerId = action.payload.offerId;
        draft.isRecommended = action.payload.isOfferRecommended;
        draft.hasAlcoholClause = action.payload.hasAlcoholClause;
        draft.hasAcUpsale = action.payload.hasAcUpsale;
        return;
      case ActionType.SET_SELECTED_OFFER_INSURER_NAME:
        draft.insurerDisplayName = action.payload;
        return;
      case ActionType.CLEAR_SELECTED_OFFER_HAS_AC_UPSALE:
        draft.hasAcUpsale = false;
        return;
      case ActionType.SET_OFFER_FROM_EMAIL:
        draft.offerFromEmail = action.payload;
        return;
      case ActionType.SET_OFFER_FROM_EMAIL_AS_EXPIRED:
        draft.offerFromEmail.expired = true;
        return;
      default:
        return state;
    }
  });

export default selectedOfferReducer;
