import produce from 'immer';

import { SimpleDocumentsState } from './state.interface';
import { SimpleDocumentsActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: SimpleDocumentsState = {
  isLoading: false,
};

const SimpleDocumentsReducer = (
  state: SimpleDocumentsState = initialState,
  action: SimpleDocumentsActionsType
) =>
  produce(state, (draft) => {
    if (action.type === ActionType.SET_LOADING_STATUS) {
      draft.isLoading = action.payload.isLoading;
    } else {
      return state;
    }
  });

export default SimpleDocumentsReducer;
