import produce from 'immer';

import { QuotationConfigState } from './state.interface';
import { QuotationConfigActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: QuotationConfigState = {
  activeQuotation: undefined,
  isMotorConfig: false,
  isVehicleTypeChanged: false,
};

const QuotationConfigReducer = (
  state: QuotationConfigState = initialState,
  action: QuotationConfigActionsType
) =>
  produce(state, (draft) => {
    if (action.type === ActionType.SET_IS_VEHICLE_TYPE_CHANGED) {
      draft.isVehicleTypeChanged = action.payload;
      return;
    } else if (action.type === ActionType.SET_IS_MOTOR_CONFIG) {
      draft.isMotorConfig = action.payload;
      return;
    } else if (action.type === ActionType.SET_ACTIVE_QUOTATION) {
      draft.activeQuotation = action.payload;
    } else {
      return state;
    }
  });

export default QuotationConfigReducer;
