import { ActionType } from 'app/store/actionTypes';
import { createAction, ActionsUnion } from 'app/store/helpers';
import { QuotationSetActionPayload } from './actionPayload.interfaces';
import { QuotationStatusEnum } from 'app/shared/enums';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { CalculationDataByModelPathsInterface } from 'app/shared/interfaces/CalculationDataByModelPaths.interface';

export const QuotationActions = {
  setQuotation: (payload: QuotationSetActionPayload) =>
    createAction(ActionType.SET_QUOTATION, payload),
  clearQuotation: () => createAction(ActionType.CLEAR_QUOTATION),
  setQuotationStatus: (payload: QuotationStatusEnum) =>
    createAction(ActionType.SET_QUOTATION_STATUS, payload),
  setQuotationEmailAddress: (emailAddress: string) =>
    createAction(ActionType.SET_QUOTATION_EMAIL_ADDRESS, emailAddress),
  setQuotationPhoneNumber: (phoneNumber: string) =>
    createAction(ActionType.SET_QUOTATION_PHONE_NUMBER, phoneNumber),
  setQuotationQuestion: (path: string, value: JsonPatchValueType) =>
    createAction(ActionType.SET_QUOTATION_QUESTION, { path, value }),
  completeQuotationData: (
    payload: Partial<CalculationDataByModelPathsInterface>
  ) => createAction(ActionType.COMPLETE_QUOTATION_DATA, payload),
};

export type QuotationActionsType = ActionsUnion<typeof QuotationActions>;
