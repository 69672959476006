import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: 20,
      height: 15,
      position: 'relative',
      transform: 'rotate(0deg)',
      transition: '.5s ease-in-out',
      cursor: 'pointer',
      float: 'right',
      marginTop: 6,
      marginLeft: 6,
      marginRight: 6,
      '& span': {
        display: 'block',
        position: 'absolute',
        height: 2,
        width: '100%',
        background: palette.primary.main,
        borderRadius: 9,
        opacity: 1,
        left: 0,
        transform: 'rotate(0deg)',
        transition: '.25s ease-in-out',
      },
      '& span:nth-child(1)': {
        top: 0,
      },
      '& span:nth-child(2), & span:nth-child(3)': {
        top: 5,
      },
      '& span:nth-child(4)': {
        top: 10,
      },
      '&$open span:nth-child(1)': {
        top: 18,
        width: 0,
        left: '50%',
      },
      '&$open span:nth-child(2)': {
        transform: 'rotate(45deg)',
      },
      '&$open span:nth-child(3)': {
        transform: 'rotate(-45deg)',
      },
      '&$open span:nth-child(4)': {
        top: 18,
        width: '0%',
        left: '50%',
      },
    },
    open: {},
    [breakpoints.down('xs')]: {
      root: {
        marginTop: 3,
      },
    },
  });
