import produce from 'immer';

import { PaymentState } from './state.interface';
import { PaymentActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: PaymentState = {
  paymentCustomerId: undefined,
};

const PaymentReducer = (
  state: PaymentState = initialState,
  action: PaymentActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_PAYMENT_CUSTOMER_ID:
        draft.paymentCustomerId = action.payload;
        return;
      default:
        return state;
    }
  });

export default PaymentReducer;
