export enum ActionType {
  ADD_QUESTION_MESSAGE = 'ADD_QUESTION_MESSAGE',
  AUTH_FAILURE = 'AUTH_FAILURE',
  AUTH_REQUEST = 'AUTH_REQUEST',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  CLEAR_BONUS_PAYOUT = 'CLEAR_BONUS_PAYOUT',
  CLEAR_CEPIK_DATA = 'CLEAR_CEPIK_DATA',
  CLEAR_FORM_CONFIG = 'CLEAR_FORM_CONFIG',
  CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS',
  CLEAR_HIDDEN_DICTIONARY_ENTRIES = 'CLEAR_HIDDEN_DICTIONARY_ENTRIES',
  SET_OFFER_FROM_EMAIL_AS_EXPIRED = 'SET_OFFER_FROM_EMAIL_AS_EXPIRED',
  CLEAR_PROMOTION = 'CLEAR_PROMOTION',
  CLEAR_QUESTION_ERRORS = 'CLEAR_QUESTION_ERRORS',
  CLEAR_QUOTATION = 'CLEAR_QUOTATION',
  CLEAR_QUOTATION_RESULT = 'CLEAR_QUOTATION_RESULT',
  CLEAR_RECOMMENDATION = 'CLEAR_RECOMMENDATION',
  CLEAR_REFERRAL = 'CLEAR_REFERRAL',
  CLEAR_TRAVEL_QUOTATION = 'CLEAR_TRAVEL_QUOTATION',
  CLEAR_TRAVEL_QUOTATION_RESULT = 'CLEAR_TRAVEL_QUOTATION_RESULT',
  COMPLETE_QUOTATION_DATA = 'COMPLETE_QUOTATION_DATA',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  MARK_ACQUISITION_AS_READ = 'MARK_ACQUISITION_AS_READ',
  MARK_CHECKOUT_STEP = 'MARK_CHECKOUT_STEP',
  MARK_PATH_AS_ANONYMOUS = 'MARK_PATH_AS_ANONYMOUS',
  MARK_PROMOTIONAL_CODE_AS_NEW = 'MARK_PROMOTIONAL_CODE_AS_NEW',
  MARK_PROMOTION_AS_EXPIRED = 'MARK_AS_PROMOTION_AS_EXPIRED',
  MARK_PROMOTION_AS_FOR_AC_ONLY = 'MARK_PROMOTION_AS_FOR_AC_ONLY',
  MARK_PROMOTION_AS_FOR_RENEWAL_ONLY = 'MARK_PROMOTION_AS_FOR_RENEWAL_ONLY',
  MARK_PROMOTION_AS_OFFER_PRICE_TOO_LOW = 'MARK_PROMOTION_AS_OFFER_PRICE_TOO_LOW',
  MARK_QUOTATION_STEP = 'MARK_QUOTATION_STEP',
  MARK_TRAVEL_ACQUISITION_AS_READ = 'MARK_TRAVEL_ACQUISITION_AS_READ',
  MARK_UPLOAD_STEP = 'MARK_UPLOAD_STEP',
  RESTORE_DEFAULT_PROMOTIONAL_STATE = 'RESTORE_DEFAULT_PROMOTIONAL_STATE',
  SETUP_VALIDATOR_FOR_TYPE = 'SETUP_VALIDATOR_FOR_TYPE',
  SET_ACTIVE_QUOTATION = 'SET_ACTIVE_QUOTATION',
  SET_OVERWRITTEN_FORM_CONFIG = 'SET_OVERWRITTEN_FORM_CONFIG',
  SET_BONUS_EXPIRE_DATE = 'SET_BONUS_EXPIRE_DATE',
  SET_BONUS_PAYOUT = 'SET_BONUS_PAYOUT',
  SET_CALCULATION_TRIGGERED_BY_USER = 'SET_CALCULATION_TRIGGERED_BY_USER',
  SET_CEPIK_DATA = 'SET_CEPIK_DATA',
  SET_CEPIK_MERGE_DATA = 'SET_CEPIK_MERGE_DATA',
  SET_CEPIK_MODAL_SAVING_IN_PROGRESS = 'SET_CEPIK_MODAL_SAVING_IN_PROGRESS',
  SET_DICTIONARIES = 'SET_DICTIONARIES',
  SET_DICTIONARY = 'SET_DICTIONARY',
  SET_QUOTATION_WITH_CEPIK_DATA = 'SET_QUOTATION_WITH_CEPIK_DATA',
  SET_DICTIONARY_ENTRIES_VISIBILITY = 'SET_DICTIONARY_ENTRIES_VISIBILITY',
  SET_DICTIONARY_ENTRY_VISIBILITY = 'SET_DICTIONARY_ENTRY_VISIBILITY',
  SET_FORM_ACTIVE_SCREEN = 'SET_FORM_ACTIVE_SCREEN',
  SET_FORM_CONFIG = 'SET_FORM_CONFIG',
  SET_FORM_STRUCTURE = 'SET_FORM_STRUCTURE',
  SET_HIDDEN_DICTIONARIES_ENTRIES = 'SET_HIDDEN_DICTIONARIES_ENTRIES',
  SET_INTERNAL_PAYMENT_LINK_GENERATED = 'SET_INTERNAL_PAYMENT_LINK_GENERATED',
  SET_IS_MOTOR_CONFIG = 'SET_IS_MOTOR_CONFIG',
  SET_LOADING_RESULT = 'SET_LOADING_RESULT',
  SET_LOADING_STATUS = 'SET_LOADING_STATUS',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_MESSAGE_VISIBILITY = 'SET_MESSAGE_VISIBILITY',
  SET_OFFER_CALCULATION_COMPLETION = 'SET_OFFER_CALCULATION_COMPLETION',
  SET_OFFER_FROM_EMAIL = 'SET_OFFER_FROM_EMAIL',
  SET_PAYMENT_CUSTOMER_ID = 'SET_PAYMENT_CUSTOMER_ID',
  SET_POLICY_RECOVERY_KEY = 'SET_POLICY_RECOVERY_KEY',
  SET_POSTPONED_AS_SELECTED = 'SET_POSTPONED_AS_SELECTED',
  SET_POSTPONED_PROMOTION_AMOUNT = 'SET_POSTPONED_PROMOTION_AMOUNT',
  SET_PROCESS_ID = 'SET_PROCESS_ID',
  SET_PROMOTION = 'SET_PROMOTION',
  SET_PROMOTIONAL_CODE = 'SET_PROMOTIONAL_CODE',
  SET_QUESTION_AVAILABILITY = 'SET_QUESTION_AVAILABILITY',
  SET_QUESTION_IGNORE_ANONYMIZED = 'SET_QUESTION_IGNORE_ANONYMIZED',
  SET_QUESTION_INITIAL_FOCUSED_DATE = 'SET_QUESTION_INITIAL_FOCUSED_DATE',
  SET_QUESTION_LABEL = 'SET_QUESTION_LABEL',
  SET_QUESTION_MAX_DATE = 'SET_QUESTION_MAX_DATE',
  SET_QUESTION_MIN_DATE = 'SET_QUESTION_MIN_DATE',
  SET_QUESTION_PATCHABLE = 'SET_QUESTION_PATCHABLE',
  SET_QUESTION_READABILITY = 'SET_QUESTION_READABILITY',
  SET_QUESTION_REQUIRABILITY = 'SET_QUESTION_REQUIRABILITY',
  SET_QUESTION_VISIBILITY = 'SET_QUESTION_VISIBILITY',
  SET_QUESTION_CLEARABLE_ON_HIDE = 'SET_QUESTION_CLEARABLE_ON_HIDE',
  SET_QUESTION_DEPENDS_ON = 'SET_QUESTION_DEPENDS_ON',
  SET_QUOTATION = 'SET_QUOTATION',
  SET_QUOTATION_EMAIL_ADDRESS = 'SET_QUOTATION_EMAIL_ADDRESS',
  SET_QUOTATION_PHONE_NUMBER = 'SET_QUOTATION_PHONE_NUMBER',
  SET_QUOTATION_QUESTION = 'SET_QUOTATION_QUESTION',
  SET_QUOTATION_STATUS = 'SET_QUOTATION_STATUS',
  SET_RECOMMENDATION = 'SET_RECOMMENDATION',
  SET_REFERRAL = 'SET_REFERRAL',
  SET_SECTION_LABEL = 'SET_SECTION_LABEL',
  SET_SECTION_MESSAGE = 'SET_SECTION_MESSAGE',
  SET_SECTION_MESSAGE_VISIBILITY = 'SET_SECTION_MESSAGE_VISIBILITY',
  SET_SECTION_SUBLABEL = 'SET_SECTION_SUBLABEL',
  SET_SECTION_VISIBILITY = 'SET_SECTION_VISIBILITY',
  SET_SELECTED_OFFER = 'SET_SELECTED_OFFER',
  SET_SELECTED_OFFER_INSURER_NAME = 'SET_SELECTED_OFFER_INSURER_NAME',
  SET_SHOULD_AUTO_FILL = 'SET_SHOULD_AUTO_FILL',
  SET_IS_VEHICLE_TYPE_CHANGED = 'SET_IS_VEHICLE_TYPE_CHANGED',
  SET_TRAVEL_RECOMMENDED_OFFER = 'SET_TRAVEL_RECOMMENDED_OFFER',
  SET_TRAVEL_QUOTATION = 'SET_TRAVEL_QUOTATION',
  SET_TRAVEL_QUOTATION_EMAIL_ADDRESS = 'SET_TRAVEL_QUOTATION_EMAIL_ADDRESS',
  SET_TRAVEL_QUOTATION_QUESTION = 'SET_TRAVEL_QUOTATION_QUESTION',
  SET_TRAVEL_QUOTATION_STATUS = 'SET_TRAVEL_QUOTATION_STATUS',
  SET_USER_PHONE_NUMBER = 'SET_USER_PHONE_NUMBER',
  SET_VERIFICATION_DATA = 'SET_VERIFICATION_DATA',
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  STORE_RESULTS = 'STORE_RESULTS',
  STORE_TRAVEL_RESULTS = 'STORE_TRAVEL_RESULTS',
  UPDATE_OFFER = 'UPDATE_OFFER',
  UPDATE_TRAVEL_OFFER = 'UPDATE_TRAVEL_OFFER',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  VALIDATE_FIELD = 'VALIDATE_FIELD',
  VALIDATE_FORM = 'VALIDATE_FORM',
  CLEAR_SELECTED_OFFER_HAS_AC_UPSALE = 'CLEAR_SELECTED_OFFER_HAS_AC_UPSALE',
  SET_PREVIOUS_QUOTATION_DATE = 'SET_PREVIOUS_QUOTATION_DATE',
  MARK_QUOTATION_RENEWAL = 'MARK_QUOTATION_RENEWAL',
  CHANGE_RENEWAL_OWNERS_DATA = 'CHANGE_RENEWAL_OWNERS_DATA',
  CHANGE_RENEWAL_CAR_DATA = 'CHANGE_RENEWAL_CAR_DATA',
  SET_PREVIOUS_PRODUCTION_YEAR = 'SET_PREVIOUS_PRODUCTION_YEAR',
  SET_VEHICLE_TYPE_CHANGED = 'SET_VEHICLE_TYPE_CHANGED',
  SET_RECOMMENDED_OFFER_ID = 'SET_RECOMMENDED_OFFER_ID',
}
