import {
  FiltersEnums,
  LabelEnum,
  SortingCriterionEnum,
} from 'app/shared/enums';
import { Dictionary } from 'app/services/dict.service';

export const sortingCriterionDictionary: Dictionary<{
  key: SortingCriterionEnum;
  value: LabelEnum;
}> = [
  {
    key: SortingCriterionEnum.PriceAsc,
    value: LabelEnum.FromCheapest,
  },
  {
    key: SortingCriterionEnum.PriceDesc,
    value: LabelEnum.FromMostExpensive,
  },
  {
    key: SortingCriterionEnum.InsurerAsc,
    value: LabelEnum.InsurerAscending,
  },
  {
    key: SortingCriterionEnum.InsurerDesc,
    value: LabelEnum.InsurerDescending,
  },
];

export const sortingCriterionDictionaryMobile: Dictionary<{
  key: SortingCriterionEnum;
  value: FiltersEnums | LabelEnum;
}> = [
  {
    key: SortingCriterionEnum.PriceAsc,
    value: FiltersEnums.FromCheapest,
  },
  {
    key: SortingCriterionEnum.PriceDesc,
    value: FiltersEnums.FromMostExpensive,
  },
  {
    key: SortingCriterionEnum.InsurerAsc,
    value: LabelEnum.InsurerAscending,
  },
  {
    key: SortingCriterionEnum.InsurerDesc,
    value: LabelEnum.InsurerDescending,
  },
];

export const sortingCriterionDictionaryTravel: Dictionary<{
  key: SortingCriterionEnum;
  value: LabelEnum;
}> = [
  {
    key: SortingCriterionEnum.PriceAsc,
    value: LabelEnum.FromCheapest,
  },
  {
    key: SortingCriterionEnum.PriceDesc,
    value: LabelEnum.FromMostExpensive,
  },
  {
    key: SortingCriterionEnum.MedicalCostsDesc,
    value: LabelEnum.MedicalCostsDescending,
  },
  {
    key: SortingCriterionEnum.InsurerAsc,
    value: LabelEnum.InsurerAscending,
  },
  {
    key: SortingCriterionEnum.InsurerDesc,
    value: LabelEnum.InsurerDescending,
  },
];
