import { ActionsUnion, createAction } from 'app/store/helpers';
import {
  TravelOfferUpdateActionPayload,
  TravelResultsStoreActionPayload,
  TravelRecommendedOffersActionPayload,
} from './actionPayload.interfaces';
import { ActionType } from 'app/store/actionTypes';

export const TravelQuotationResultActions = {
  storeResults: (results: TravelResultsStoreActionPayload) =>
    createAction(ActionType.STORE_TRAVEL_RESULTS, results),
  updateOffer: (offer: TravelOfferUpdateActionPayload) =>
    createAction(ActionType.UPDATE_TRAVEL_OFFER, offer),
  clearQuotationResult: () =>
    createAction(ActionType.CLEAR_TRAVEL_QUOTATION_RESULT),
  setCalculationTriggeredByUser: (triggeredByUser: boolean) =>
    createAction(ActionType.SET_CALCULATION_TRIGGERED_BY_USER, triggeredByUser),
  setRecommendedOffers: (
    recommendedOffers: TravelRecommendedOffersActionPayload
  ) => createAction(ActionType.SET_TRAVEL_RECOMMENDED_OFFER, recommendedOffers),
};

export type TravelQuotationResultActionsType = ActionsUnion<
  typeof TravelQuotationResultActions
>;
