import { QuotationResultInterface } from 'app/shared/interfaces/QuotationResult.interface';
import { TravelQuotationResultInterface } from 'app/shared/interfaces/TravelQuotationResult.interface';
import { QuotationResultStatusEnum } from 'app/shared/enums';

export function haveResultsFinalStatusesOnly(
  results: Array<QuotationResultInterface | TravelQuotationResultInterface>
) {
  return (
    results.length > 0 &&
    results.every((result) =>
      [
        QuotationResultStatusEnum.Prepared,
        QuotationResultStatusEnum.UpdatePrepared,
        QuotationResultStatusEnum.InternallyRejected,
        QuotationResultStatusEnum.Expired,
        QuotationResultStatusEnum.Rejected,
        QuotationResultStatusEnum.UpdateRejected,
      ].includes(result.status)
    )
  );
}
