import { RootState } from 'app/store/rootReducer';
import { FieldsEnum, FormsEnum } from 'app/shared/enums';
import { selectQuestion } from 'app/store/data/formsConfig';
import { createIsClaimsYearVisible } from './insuranceHistoryHelpers/createIsClaimsYearVisible';
import { createIsOcClaimsCountVisible } from './insuranceHistoryHelpers/createIsOcClaimsCountVisible';
import { createIsAcClaimsCountVisible } from './insuranceHistoryHelpers/createIsAcClaimsCountVisible';
import { createIsAcHistorySameVisible } from './insuranceHistoryHelpers/createIsAcHistorySameVisible';
import { createIsAcHistoryLengthVisible } from './insuranceHistoryHelpers/createIsAcHistoryLenghtVisible';
import { createIsCoownerOcHistoryLengthVisible } from './insuranceHistoryHelpers/createIsCoownerOcHistoryLengthVisible';

export const selectIsCalculationFormQuestionVisible = (params: {
  state: RootState;
  formName: FormsEnum;
  questionKey: string;
}) => {
  const { state, formName, questionKey } = params;
  const question = selectQuestion(params);
  const isClaimsYearVisible = createIsClaimsYearVisible(state, formName);
  const isOcClaimsCountVisible = createIsOcClaimsCountVisible(state, formName);
  const isAcClaimsCountVisible = createIsAcClaimsCountVisible(state, formName);
  const isAcHistorySameVisible = createIsAcHistorySameVisible(state, formName);
  const isAcHistoryLengthVisible = createIsAcHistoryLengthVisible(
    state,
    formName
  );
  const isCoownerOcHistoryLengthVisible = createIsCoownerOcHistoryLengthVisible(
    state,
    formName
  );

  switch (questionKey) {
    case FieldsEnum.OwnerOcInsuranceHistoryClaimsCount: {
      return isOcClaimsCountVisible(FieldsEnum.OwnerOcInsuranceHistoryLength);
    }
    case FieldsEnum.OwnerOcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerOcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerOcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerOcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerOcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerOcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerOcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerOcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.OwnerOcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerOcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    case FieldsEnum.OwnerAcHistorySame: {
      return isAcHistorySameVisible(FieldsEnum.OwnerOcInsuranceHistoryLength);
    }
    case FieldsEnum.OwnerAcInsuranceHistoryLength: {
      return isAcHistoryLengthVisible(FieldsEnum.OwnerAcHistorySame);
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaimsCount: {
      return isAcClaimsCountVisible(FieldsEnum.OwnerAcHistorySame);
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerAcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerAcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerAcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerAcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.OwnerAcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.OwnerAcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryLength: {
      return isCoownerOcHistoryLengthVisible(
        FieldsEnum.Coowners1InsuranceHistorySame
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount: {
      return isOcClaimsCountVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryLength
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.Coowners1OcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    case FieldsEnum.Coowners1AcHistorySame: {
      return isAcHistorySameVisible(
        FieldsEnum.Coowners1OcInsuranceHistoryLength
      );
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryLength: {
      return isAcHistoryLengthVisible(FieldsEnum.Coowners1AcHistorySame);
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount: {
      return isAcClaimsCountVisible(FieldsEnum.Coowners1AcHistorySame);
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.Coowners1AcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners1AcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryLength: {
      return isCoownerOcHistoryLengthVisible(
        FieldsEnum.Coowners2InsuranceHistorySame
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount: {
      return isOcClaimsCountVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryLength
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.Coowners2OcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    case FieldsEnum.Coowners2AcHistorySame: {
      return isAcHistorySameVisible(
        FieldsEnum.Coowners2OcInsuranceHistoryLength
      );
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryLength: {
      return isAcHistoryLengthVisible(FieldsEnum.Coowners2AcHistorySame);
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount: {
      return isAcClaimsCountVisible(FieldsEnum.Coowners2AcHistorySame);
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaims1Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount,
        ['1', '2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaims2Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount,
        ['2', '3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaims3Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount,
        ['3', '4', '5_']
      );
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaims4Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount,
        ['4', '5_']
      );
    }
    case FieldsEnum.Coowners2AcInsuranceHistoryClaims5Year: {
      return isClaimsYearVisible(
        FieldsEnum.Coowners2AcInsuranceHistoryClaimsCount,
        ['5_']
      );
    }
    default: {
      return question.visible;
    }
  }
};
