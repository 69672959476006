import { ValidationObject } from 'app/services/validation.service';

export function isZipCodeValid(value: string) {
  return !!value && !!value.match('^[0-9]{2}-[0-9]{3}$');
}

export default function validateZipCode(value: string): ValidationObject {
  return {
    key: 'invalid_zip_code',
    valid: isZipCodeValid(value),
  };
}
