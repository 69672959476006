import { ActionType } from 'app/store/actionTypes';
import { createAction, ActionsUnion } from 'app/store/helpers';
import { NotificationShowActionPayload } from './actionPayload.interfaces';

export const NotificationActions = {
  hideNotification: () => createAction(ActionType.HIDE_NOTIFICATION),
  showNotification: (payload: NotificationShowActionPayload) =>
    createAction(ActionType.SHOW_NOTIFICATION, payload),
};

export type NotificationActionsType = ActionsUnion<typeof NotificationActions>;
