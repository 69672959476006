import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import merge from 'lodash-es/merge';

import { AgencyNameEnum } from 'app/shared/enums';
import { agencyName } from 'app/services/env.service';
import { newTheme } from 'app/styles/themes/newTheme';

export const createAgencyTheme = () => {
  const themesConfig: { [key in AgencyNameEnum]: ThemeOptions } = {};

  const agencyTheme = themesConfig[agencyName as AgencyNameEnum];

  return createMuiTheme(merge(newTheme, agencyTheme));
};
