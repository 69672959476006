import React, { useEffect } from 'react';
import { Modal as MaterialModal } from '@material-ui/core';
import classNames from 'clsx';

import { ModalProps } from './Modal.interfaces';
import { CloseIconButton } from 'app/components/CloseIconButton';
import { LabelEnum } from 'app/shared/enums';
import { NewButton } from 'app/components/NewButton';
import { isIE11Browser } from 'app/services/util.service';
import { useStyles } from './Modal.styles';
import isSafariOrIOS from 'app/shared/helpers/isSafariOrIos';
import { forceRepaint } from 'app/services/businessFunctions/helpers/forceRepaint';

export default function Modal(props: ModalProps) {
  const classes = useStyles(props);
  const {
    children,
    dataCy,
    footerCloseButtonTitle,
    onClose: handleClose,
    opened,
    stickyClose,
    withFooterCloseButton,
    withoutHeaderCloseButton,
    disableEscapeKeyDown,
  } = props;

  const renderHeaderCloseButton = () => {
    if (withoutHeaderCloseButton) {
      return null;
    }

    return (
      <div
        className={classNames({
          [classes.sticky]: stickyClose,
        })}
      >
        <CloseIconButton
          onClick={handleClose}
          className={classNames(
            classes.headerCloseButton,
            stickyClose && classes.headerCloseButtonSticky
          )}
        />
      </div>
    );
  };

  const renderFooterCloseButton = () => {
    return (
      withFooterCloseButton && (
        <NewButton
          color={'primary'}
          variant={'contained'}
          title={footerCloseButtonTitle || LabelEnum.CloseWindow}
          type={'submit'}
          onClick={handleClose}
          className={classes.footerCloseButton}
        />
      )
    );
  };

  useEffect(() => {
    if (isSafariOrIOS() && opened) {
      forceRepaint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialModal
      className={classNames('modal-opened', classes.materialModalRoot, {
        [classes.blurIE11]: isIE11Browser(),
      })}
      open={opened}
      onClose={handleClose}
      hideBackdrop
      disableEnforceFocus
      container={document.getElementById('root')}
      data-cy={dataCy}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <div className={classes.root}>
        {renderHeaderCloseButton()}
        {children}
        {renderFooterCloseButton()}
      </div>
    </MaterialModal>
  );
}
