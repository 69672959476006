import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, shape }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: shape.borderRadius,
    padding: 10,
    marginLeft: 20,
  },
  logoutButton: {
    padding: 10,
    marginLeft: 10,
    fontSize: 14,
    cursor: 'pointer',
  },
  logoutButtonForOperator: {
    padding: '0 10px',
    marginLeft: 0,
  },
  [breakpoints.down(769)]: {
    root: {
      display: 'none',
    },
  },
}));
