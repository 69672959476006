import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isValidNipNumber(nipNumber: string) {
  const reg = /^[0-9]{10}$/;

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nipNumber.substring(9, 10));
  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(nipNumber.slice(i, i + 1)) * weight[i];
  }

  return (
    nipNumber !== '0000000000' &&
    reg.test(nipNumber) &&
    sum % 11 === controlNumber
  );
}

export default function validateNipNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_nip_number',
    valid: isValidNipNumber(data.value.toString()),
  };
}
