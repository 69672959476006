import React from 'react';

import { RouteEnum } from 'app/shared/enums';
import isMobileOrTablet from 'app/shared/helpers/isMobileOrTablet';
import { scrollSmoothly, ScrollService } from 'app/services/scroll.service';
import { Dictionary } from 'app/services/dict.service';

const getDistanceToTop = (element: Element) => {
  const menuHeightWithMargin = isMobileOrTablet() ? 120 : 0;

  return element.getBoundingClientRect().top - menuHeightWithMargin;
};

export const getElementTopPosition = (element: Element) => {
  const bound = element.getBoundingClientRect();
  const margin = 20;

  return bound.top - document.body.getBoundingClientRect().top - margin;
};

const getDistanceToBottom = (element: Element) => {
  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight;

  return clientHeight - element.getBoundingClientRect().bottom;
};

export const blockScroll = (yPosition?: number) => {
  const scrollY = yPosition || window.scrollY;
  const scrollX = window.scrollX;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
  document.body.style.width = '100%';
  setTimeout(() => {
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('width');
    window.scroll(scrollX, scrollY);
  }, 200);
};

export const scrollToNewCarSelect = () => {
  const newCarComponent = document.querySelector<HTMLElement>(
    'div[aria-label="car autocomplete"]'
  );
  const isCepikModal = window.location.pathname.includes(
    RouteEnum.COMPARE_CEPIK_DATA
  );

  if (!newCarComponent || isCepikModal) {
    return;
  }
  let carComponentTopPosition = getElementTopPosition(newCarComponent);
  const infoExpertHeader = document.querySelector(
    'div[aria-label="Infoekspert header"]'
  );
  if (infoExpertHeader && getDistanceToTop(infoExpertHeader) < 0) {
    scrollToInfoEkspert();
  } else {
    const additionalMarginToTop = 70;
    scrollSmoothly(carComponentTopPosition - additionalMarginToTop);
  }
};

const canScrollNewCarSelectFromBelow = () => {
  const newCarComponent = document.querySelector<HTMLElement>(
    'div[aria-label="car autocomplete"]'
  );
  const isCepikModal = window.location.pathname.includes(
    RouteEnum.COMPARE_CEPIK_DATA
  );

  if (!newCarComponent || isCepikModal) {
    return false;
  }
  const distanceToBottom = getDistanceToBottom(newCarComponent);
  const distanceToTop = getDistanceToTop(newCarComponent);
  const distanceToTopAfterAutoScroll = 67;
  return distanceToTop > distanceToTopAfterAutoScroll && isMobileOrTablet()
    ? true
    : distanceToBottom < 270;
};

export function scrollToInfoEkspert() {
  const infoExpertHeader = document.querySelector<HTMLElement>(
    'div[aria-label="Infoekspert header"]'
  );

  if (infoExpertHeader === null) {
    return;
  }

  ScrollService.scrollToElement(infoExpertHeader);
}

export function useNewCarSelectScroll(value: Dictionary) {
  const tryScrollToInfoEkspert = (e: React.MouseEvent) => {
    const infoExpertHeader = document.querySelector<HTMLElement>(
      'div[aria-label="Infoekspert header"]'
    );

    if (e.currentTarget === null || infoExpertHeader === null) {
      return;
    }

    const canScrollFromBelow = canScrollNewCarSelectFromBelow();

    if (canScrollFromBelow) {
      scrollToInfoEkspert();
      return;
    }
    handleScroll(e);
  };

  const handleScroll = (e: React.MouseEvent) => {
    const target = e.currentTarget || e.target;
    if (target === null) {
      return;
    }
    const distanceToBottom = getDistanceToBottom(target);
    const distanceToTop = getDistanceToTop(target);

    const canScrollFromBelow = canScrollNewCarSelectFromBelow();
    if (canScrollFromBelow) {
      scrollToNewCarSelect();
    }

    const canScrollFromAbove = distanceToBottom >= 350 && distanceToTop <= 0;

    if (canScrollFromAbove) {
      scrollToNewCarSelect();
    }
  };

  const scrollIfNeeded = (e: React.MouseEvent) => {
    let wasScrolled = false;
    const setWasScrolled = () => {
      wasScrolled = true;
    };
    document.addEventListener('scroll', setWasScrolled);

    setTimeout(() => {
      if (!wasScrolled) handleScroll(e);
      document.removeEventListener('scroll', setWasScrolled);
    }, 300);
  };

  return {
    handleScroll,
    tryScrollToInfoEkspert,
    scrollIfNeeded,
  };
}
