export enum BonusPayoutEnum {
  AdditionalBenefitsHeading1 = 'Zgarnij gratisy',
  AdditionalBenefitsHeading2 = 'tylko dla klientów Mubi',
  Box1Desc = 'Na klientów Mubi czekają darmowe wejściówki na Mubi Dubit, największą imprezę motoryzacyjną w Polsce, która  odbywa się w Kielcach!',
  Box1Title = 'Mubi Dubit',
  Box2Desc1 = 'Tylko dla naszych klientów udostępniamy ponad 30 różnych aut — od elektrycznego Fiata 500 po Poloneza!',
  Box2Desc2 = 'Zgłoś się po odbiór vouchera na przejażdżkę!',
  Box2Title = 'Mubi Garage',
  Box3Desc1 = 'Raz w roku każdy Klient Mubi bierze udział w\xa0loterii, w\xa0której może wygrać Mustanga tuningowanego przez Michała Jesionowskiego!',
  Box3Desc2 = 'Obserwuj nasz ',
  Box3Desc3 = 'profil na Facebooku',
  Box3Desc4 = ', szczegóły wkrótce!',
  Box3Title = 'Wygraj auto w Loterii Mubi!',
  BoxBtnLabel = 'Dowiedz się więcej',
  FormFooter = 'Jeśli polisa została opłacona, bonus otrzymasz do 30&nbsp;dni od daty zgłoszenia.',
  FormSubtitle = 'Wprowadź informacje podane w\xa0trakcie zakupu polisy:',
  FormTitle = 'Odbierz bonus',
  LoginAndGetLink = 'Zaloguj się i odbierz link',
  MiddleBoxHeading = 'Zacznij zarabiać z Mubi! 💸',
  MiddleBoxInfo1 = 'Przekaż link polecający swoim bliskim, a każdy z nich otrzyma 100 zł zwrotu po opłaceniu polisy.',
  MiddleBoxInfo2 = 'Z kolei Ty otrzymasz 100 zł za każdego nowego klienta!',
  MiddleBoxInfo3 = 'To proste! Możesz zdobyć w ten sposób nawet 2000 zł w ciągu roku.',
  RegistrationNumber = 'Numer rejestracyjny',
}
