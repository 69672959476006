import { RootState } from 'app/store/rootReducer';
import { FieldsEnum, FormsEnum } from 'app/shared/enums';
import { selectValueIfFieldVisible } from 'app/store/selectors/selectValueIfFieldVisible';

export const createIsAcHistorySameVisible = (
  state: RootState,
  formName: FormsEnum
) => (ocHistoryLengthQuestionKey: FieldsEnum) => {
  const insuranceHistoryLength = selectValueIfFieldVisible({
    state,
    formName,
    questionKey: ocHistoryLengthQuestionKey,
  });
  return insuranceHistoryLength > 0;
};
