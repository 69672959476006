import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

const mod97 = (codeString: string) => {
  let checksumFragment = codeString.slice(0, 2);
  let checksumResult = null;

  for (let offset = 2; offset < codeString.length; offset += 7) {
    const fragment =
      checksumFragment + codeString.substring(offset, offset + 7);
    checksumResult = parseInt(fragment, 10) % 97;
  }

  return checksumResult === 1; // 1 - IBAN is valid, any other value - IBAN check digits invalid
};

export function isValidIban(value: string) {
  const ibanCodeLength = 28;
  const valueToCheck = `PL${value}`;

  const iban = valueToCheck.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  let digits = null;

  if (!code || iban.length !== ibanCodeLength) {
    return false;
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) =>
    String(letter.charCodeAt(0) - 55)
  );

  return mod97(digits);
}

export default function validateIban(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_iban',
    valid: isValidIban(data.value.toString()),
  };
}
