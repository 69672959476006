import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.common.white,
      color: palette.primary.main,
      fontSize: 14,
      fontWeight: 'bold',
    },
    softLabel: {
      fontWeight: 'normal',
    },
  });
