import { QuotationType } from 'app/shared/enums';
import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';

export const QuotationConfigActions = {
  setIsVehicleTypeChanged: (payload: boolean) =>
    createAction(ActionType.SET_IS_VEHICLE_TYPE_CHANGED, payload),
  setIsMotorConfig: (payload: boolean) =>
    createAction(ActionType.SET_IS_MOTOR_CONFIG, payload),
  setActiveQuotation: (payload: QuotationType) =>
    createAction(ActionType.SET_ACTIVE_QUOTATION, payload),
};

export type QuotationConfigActionsType = ActionsUnion<
  typeof QuotationConfigActions
>;
