import { AxiosError, AxiosResponse } from 'axios';
import debounce from 'lodash-es/debounce';

import { ThunkResult } from 'app/store/helpers';
import { TravelQuotationService } from 'app/services/travelQuotation.service';
import {
  TravelQuotationActions,
  selectTravelQuotationId,
} from 'app/store/data/travelQuotation';
import { TravelQuotationResultActions } from 'app/store/data/travelQuotationResults';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { TravelQuotationInterface } from 'app/shared/interfaces/TravelQuotation.interface';
import {
  LabelEnum,
  MessageTypeEnum,
  QuotationStatusEnum,
  QuotationType,
} from 'app/shared/enums';
import { NotificationActions } from 'app/store/data/notification';
import {
  afterCalculationStatuses,
  hasQuotationExpired,
} from 'app/shared/helpers/quotation';
import { QuotationConfigActions } from 'app/store/data/quotationConfig';
import {
  AcquisitionActions,
  getTravelAcquisition,
} from 'app/store/data/acquisition';
import { selectIsOperator } from 'app/store/data/user';
import {
  checkIQueryParamFromUrl,
  getAcquisitionParams,
} from 'app/shared/helpers/getAcquisitionParams';
import { addDataLayerFlowTypeEvent } from 'app/shared/helpers/addDataLayerFlowTypeEvent';

const finalizeTravelQuotation = ({
  data: quotation,
}: AxiosResponse<TravelQuotationInterface>): ThunkResult => (dispatch) => {
  dispatch(readAcquisitionParams(quotation.id));
  addDataLayerFlowTypeEvent({
    isTravel: true,
    quotationNumber: quotation.number,
    quotationId: quotation.id,
  });
  getValidQuotation(quotation).then((validQuotation) => {
    dispatch(TravelQuotationActions.setQuotation(validQuotation));
  });
};

const handleQuotationFetchError = (error: AxiosError): ThunkResult => (
  dispatch
) => {
  if (error.response && error.response.status === 403) {
    dispatch(
      NotificationActions.showNotification({
        content: LabelEnum.CalculationFetchFailedNoPermission,
        type: MessageTypeEnum.Danger,
      })
    );
  }
  dispatch(createTravelQuotation());
};

export const createTravelQuotation = (): ThunkResult => (dispatch) => {
  TravelQuotationService.create().then((quotation) =>
    dispatch(finalizeTravelQuotation(quotation))
  );
};

export const clearTravelQuotation = (): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const isOperator = selectIsOperator(state);
  const quotationId = selectTravelQuotationId(state);

  const promise = isOperator
    ? TravelQuotationService.create()
    : TravelQuotationService.createWithInheritedABTests(quotationId);

  dispatch(TravelQuotationActions.clearQuotation());

  promise.then(({ data: quotation }) => {
    dispatch(TravelQuotationActions.setQuotation(quotation));
    dispatch(
      TravelQuotationActions.setQuotationStatus(QuotationStatusEnum.Cleared)
    );
  });
};

export function getValidQuotation(
  quotation: TravelQuotationInterface
): Promise<TravelQuotationInterface> {
  const quotationExpired = hasQuotationExpired(quotation.startDate);
  if (quotationExpired && afterCalculationStatuses.includes(quotation.status)) {
    return TravelQuotationService.requestForCopy(
      quotation.id
    ).then(({ data: quotationCopy }) => Promise.resolve(quotationCopy));
  }

  return Promise.resolve(quotation);
}

export const initTravelQuotation = (recoveryKey?: string): ThunkResult => (
  dispatch
) => {
  dispatch(QuotationConfigActions.setActiveQuotation(QuotationType.Travel));

  const promise = recoveryKey
    ? TravelQuotationService.getQuotationById(recoveryKey)
    : TravelQuotationService.getLast();

  return promise
    .then((quotation) => dispatch(finalizeTravelQuotation(quotation)))
    .catch((error) => dispatch(handleQuotationFetchError(error)));
};

export const updateTravelQuotationDebounced = (
  modelPath: string,
  value: JsonPatchValueType
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const quotationId = selectTravelQuotationId(state);

  const updateTravelQuotation = () => {
    TravelQuotationService.update(quotationId, modelPath, value);
  };

  if (!modelPath) {
    return;
  }

  return debounce(updateTravelQuotation, 100)();
};

export const refreshQuotation = (): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const quotationId = selectTravelQuotationId(state);

  return TravelQuotationService.getQuotationById(quotationId).then(
    ({ data: updatedQuotation }: AxiosResponse<TravelQuotationInterface>) => {
      dispatch(TravelQuotationActions.setQuotation(updatedQuotation));
    }
  );
};

const readAcquisitionParams = (quotationId: string): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();
  const isAcquisitionRead = getTravelAcquisition(state);

  checkIQueryParamFromUrl();

  if (!isAcquisitionRead) {
    const acquisitionParams = getAcquisitionParams();

    TravelQuotationService.setAcquisitionParams(quotationId, acquisitionParams);
    dispatch(AcquisitionActions.markTravelAcquisitionAsRead());
  }
};

/**
 * @param id recoveryKey or quotationId
 */
export const cloneQuotation = (
  id: string
): ThunkResult<Promise<AxiosResponse<TravelQuotationInterface>>> => (
  dispatch
) => {
  return TravelQuotationService.requestForCopy(id).then((response) => {
    dispatch(TravelQuotationResultActions.clearQuotationResult());
    dispatch(TravelQuotationActions.setQuotation(response.data));
    return response;
  });
};
