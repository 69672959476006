export enum MubiDubitEnum {
  CodeInfo = 'Bilet zostanie wysłany do Ciebie w\xa0terminie 30\xa0dni na podany przy zakupie adres mailowy. Razem z\xa0biletem otrzymasz od nas kod zniżkowy na zakup biletu dla osoby towarzyszącej. ',
  Heading1 = 'MUBI DUBIT',
  Heading2 = 'TUNING FESTIVAL',
  Heading3 = '22-23.06',
  Heading4 = '.2024',
  Hint = 'Aby odebrać bilet wprowadź informacje podane w trakcie zakupu polisy:',
  PolicyNotFound1 = 'Niestety nie mamy polisy na podane przez Ciebie dane.',
  PolicyNotFound2 = 'Spróbuj ponownie lub skontaktuj się z\xa0nami na <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>.',
  PolicyNotVerified1 = 'Płatność za\xa0Twoją polisę jest obecnie weryfikowana i\xa0musi zostać potwierdzona.',
  PolicyNotVerified2 = 'W celu poprawnej weryfikacji, należy wysłać maila z\xa0potwierdzeniem płatności na adres <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>. Po\xa0wysłaniu potwierdzenia, zostanie ono ręcznie sprawdzone przez dział Obsługi Klienta.',
  SuccessModalText = 'Twój bilet wstępu na Mubi Dubit Tuning Festival oraz kod zniżkowy na zakup wejściówki dla osoby towarzyszącej zostanie wysłany do Ciebie na maila w\xa0ciągu 30\xa0dni!\n\nW\xa0razie pytań napisz na: <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>',
  TooEarlyToReceiveVoucher1 = 'Wygląda na\xa0to, że nie minęło jeszcze 30\xa0dni od zakupu Twojej polisy.',
  TooEarlyToReceiveVoucher2 = 'Po bilet na Mubi Dubit Tuning Festival możesz zgłosić się po tym czasie.\n\nW\xa0razie pytań napisz na: <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>',
  TooLateToReceiveVoucher1 = 'Niestety, ale wygląda na to, że Twoja polisa zakupiona w\xa0Mubi nie jest już aktywna.',
  TooLateToReceiveVoucher2 = 'Aby otrzymać bilet na Mubi Dubit Tuning Festival oraz kod zniżkowy na zakup wejściówki dla osoby towarzyszącej musisz posiadać aktywną polisę zakupioną w\xa0Mubi.\n\nW\xa0razie pytań napisz na: <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>',
  VoucherAlreadyUsed1 = 'Jesteś już na liście osób biorących udział w\xa0programie Mubi Dubit Tuning Festival.',
  VoucherAlreadyUsed2 = 'Dziękujemy za udział. Wygląda na to, że mamy już Twoje zgłoszenie do Mubi Dubit Tuning Festival i\xa0wysłaliśmy bilet. Jeśli go nie masz, prześlij do nas potwierdzenie opłaty za polisę na adres <a href="mailto:mubidubit@mubi.pl">mubidubit@mubi.pl</a>, a\xa0my zweryfikujemy sytuację i\xa0damy Ci znać.',
}

export enum MubiDubitResponseErrorCodesEnum {
  WrongConfirmationCodePassed = 'wrong-confirmation-code-passed',
  SentConfirmationSmsLimitExceeded = 'sent-confirmation-sms-limit-exceeded',
  CodeConfirmationSmsLimitExceeded = 'code-confirmations-limit-exceeded',
  IncorrectPhoneNumeber = 'request.phone:incorrectPhoneNumber',
  IncorrectRegistrationNumber = 'request.incorrect-registration-number',
  AlreadyRequestedForVoucher = 'already-requested-for-voucher',
  TooEarlyToReceiveVoucher = 'too-early-to-receive-voucher',
  PolicyPaidStatusNotVerified = 'policy-paid-status-not-verified',
  RequestedAfterPolicyEndDate = 'requested-after-policy-end-date',
}
