import { RootState } from 'app/store/rootReducer';

export const selectTravelQuotation = (state: RootState) =>
  state.travelQuotation;
export const selectTravelQuotationId = (state: RootState) =>
  state.travelQuotation.id;
export const selectTravelQuotationStatus = (state: RootState) =>
  state.travelQuotation.status;
export const selectTravelQuotationNumber = (state: RootState) =>
  state.travelQuotation.number;
export const selectTravelQuotationEmailAddress = (state: RootState) =>
  state.travelQuotation.emailAddress;
export const selectTravelQuotationStartDate = (state: RootState) =>
  state.travelQuotation.startDate;
export const selectTravelQuotationEndDate = (state: RootState) =>
  state.travelQuotation.endDate;
export const selectTravelQuotationNumberOfPersons = (state: RootState) =>
  state.travelQuotation.numberOfPersons;
export const selectTravelQuotationTravelers = (state: RootState) =>
  state.travelQuotation.travelers;
export const selectIsPolandSelected = (state: RootState) =>
  state.travelQuotation.countryCodes?.includes('PL');
export const selectIsUSASelected = (state: RootState) =>
  state.travelQuotation.countryCodes?.includes('US');
export const selectInsurantSelection = (state: RootState) =>
  state.travelQuotation.insurantSelection;
export const selectTravelQuotationPayment = (state: RootState) =>
  state.travelQuotation.payment;
export const selectTravelQuotationActiveAbTests = (state: RootState) =>
  state.quotation.activeABTests;
export const selectTravelQuotationTravelGoals = (state: RootState) =>
  state.travelQuotation.travelGoals;
export const selectTravelQuotationCurrentlyInPoland = (state: RootState) =>
  state.travelQuotation.allTravelersCurrentlyInPoland;
