import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash-es/debounce';

import { Milliseconds } from 'app/shared/types/Milliseconds.type';

export function useWindowSize(debounceWait?: Milliseconds) {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    const updateSizeDebounced = debounce(updateSize, debounceWait);

    const onResize =
      debounceWait === undefined ? updateSize : updateSizeDebounced;

    window.addEventListener('resize', onResize);

    updateSize();

    return () => window.removeEventListener('resize', onResize);
  }, [debounceWait]);

  return size;
}
