import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';

export const AcquisitionActions = {
  markAcquisitionAsRead: () =>
    createAction(ActionType.MARK_ACQUISITION_AS_READ),
  markTravelAcquisitionAsRead: () =>
    createAction(ActionType.MARK_TRAVEL_ACQUISITION_AS_READ),
  markQuotationAsRenewal: (isRenewal: boolean) =>
    createAction(ActionType.MARK_QUOTATION_RENEWAL, { isRenewal }),
  changeRenewalOwnersData: (payload: {
    ownerName: string;
    isChanged: boolean;
  }) => createAction(ActionType.CHANGE_RENEWAL_OWNERS_DATA, payload),
  changeRenewalCarData: (payload: { field: string; isChanged: boolean }) =>
    createAction(ActionType.CHANGE_RENEWAL_CAR_DATA, payload),
};

export type AcquisitionActionsType = ActionsUnion<typeof AcquisitionActions>;
