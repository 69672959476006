import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import { SimpleDocumentsSetActionPayload } from './actionPayload.interfaces';

export const SimpleDocumentsActions = {
  setLoadingStatus: (payload: SimpleDocumentsSetActionPayload) =>
    createAction(ActionType.SET_LOADING_STATUS, payload),
};

export type SimpleDocumentsActionsType = ActionsUnion<
  typeof SimpleDocumentsActions
>;
