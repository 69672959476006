import { parse, stringify } from 'query-string';
import isEmpty from 'lodash-es/isEmpty';

import {
  AcquisitionFromExternalSource,
  AcquisitionParams,
} from 'app/shared/interfaces/AcquisitionParams.interface';
import { history } from 'app/shared/helpers/getRouterHistory';
import { removeItem } from 'app/services/storage.service';

export const ACQUISITION_KEY = 'acquisition';

const isQueryEmptyOrHasOnlyReferrer = (
  queryObject: AcquisitionFromExternalSource
) => {
  const queryLength = Object.keys(queryObject).length;
  const isQueryEmpty = queryLength === 0;
  const hasOnlyReferrer =
    queryObject.referrer !== undefined && queryLength === 1;
  return isQueryEmpty || hasOnlyReferrer;
};

const iParsedQueryConfig = {
  '1': {
    source: 'renewal',
    content: 'FirstSms30',
  },
  '2': {
    source: 'renewal',
    content: 'MiddleSms14',
  },
  '2a': {
    source: 'renewal',
    content: 'AdditionalUniqaReminderSms',
  },
  '3': {
    source: 'renewal',
    content: 'FinalSms3',
  },
  '4': {
    source: 'rop',
    content: 'FirstSms30',
  },
  '5': {
    source: 'rop',
    content: 'MiddleSms14',
  },
  '6': {
    source: 'rop',
    content: 'FinalSms3',
  },
  '7': {
    source: 'renewal',
    content: 'FinalSms2',
  },
};

function parseQueryParams(
  queryObject: AcquisitionFromExternalSource
): AcquisitionParams {
  if (queryObject.referrer) {
    queryObject.referrer = queryObject.referrer.substring(0, 255);
  }

  if (
    isGoogleSearch(queryObject.referrer || document.referrer) &&
    isQueryEmptyOrHasOnlyReferrer(queryObject)
  ) {
    return {
      source: 'google-organic',
      referrer: queryObject.referrer || document.referrer,
    };
  }

  if (isEmpty(queryObject)) {
    return {
      source: 'direct',
    };
  }

  if (queryObject.gclid) {
    return {
      source: 'google-adwords',
      content: queryObject.adgroupId,
      referrer: queryObject.referrer,
    };
  }

  if (queryObject.utm_source) {
    const sourceMap = {
      fb: 'facebook',
      ig: 'instagram',
      yt: 'youtube',
    };
    // HACK: toString().split(',')[0] wrong data from mubi.pl localstorage
    // utm_source is like array, for example utm_source=["youtube", "youtube"] and backend can't parse it
    return {
      source:
        sourceMap[queryObject.utm_source] ||
        queryObject.utm_source.toString().split(',')[0],
      content: queryObject.utm_content?.toString().split(',')[0],
      ...(queryObject.referrer && { referrer: queryObject.referrer }),
    };
  }

  return {
    source: 'direct',
  };
}

function getAcquisitionFromLocalStorage(): AcquisitionFromExternalSource {
  try {
    return parse(localStorage.getItem(ACQUISITION_KEY) || '');
  } catch (e) {
    return {};
  }
}

function getAcquisitionFromUrl(): AcquisitionFromExternalSource {
  const search = window.location.search;
  const parsedData = parse(search);

  return {
    ...parsedData,
    referrer: (parsedData.referrer as string) || document.referrer,
  };
}

function isGoogleSearch(referrer: string) {
  const isGoogleSearchRegEx = /https:\/\/(www\.){0,1}google\..*/;
  return isGoogleSearchRegEx.test(referrer);
}

function extendIQueryParamFromUrl(iParamValue: string) {
  const queryString = window.location.search;
  const params = parse(queryString);
  const iParseQueryObj = iParsedQueryConfig[iParamValue];

  delete params.i;

  const extendedIQueryParam = iParseQueryObj && {
    utm_source: iParseQueryObj.source,
    utm_content: iParseQueryObj.content,
  };

  const extendedQueryString = `?${stringify({
    ...params,
    ...(extendedIQueryParam && extendedIQueryParam),
  })}`;

  return extendedQueryString;
}

export function checkIQueryParamFromUrl() {
  const { pathname, search } = window.location;

  const iQueryParam = parse(search).i as string;

  if (iQueryParam) {
    const extendedQueryString = extendIQueryParamFromUrl(iQueryParam);

    history.replace(`${pathname}${extendedQueryString}`);
  }
}

export function getAcquisitionParams(): AcquisitionParams {
  const acquisitionFromUrl = getAcquisitionFromUrl();
  const hasDataFromUrl =
    acquisitionFromUrl.utm_source?.toString() ||
    acquisitionFromUrl.gclid?.toString();

  const params = hasDataFromUrl
    ? acquisitionFromUrl
    : getAcquisitionFromLocalStorage();

  removeItem(ACQUISITION_KEY);
  return parseQueryParams(params);
}
