import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export default function validateCarLongRegistrationNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_car_registration_number',
    valid: /^[a-z0-9]{7,}$/i.test(data.value.toString()),
  };
}
