import { createSelector } from 'reselect';

import { selectIsOperator } from 'app/store/data/user/selectors';
import { selectIsShortCalculationAbTestActive } from 'app/store/data/quotation/selectors';

export const selectIsShortCalculationPath = createSelector(
  [selectIsOperator, selectIsShortCalculationAbTestActive],
  (isOperator, isShortCalculationAbTestActive) =>
    isOperator || isShortCalculationAbTestActive
);
