export enum PolicyStatusEnum {
  ApplicationConversionRequested = 'APPLICATION_CONVERSION_REQUESTED',
  ApplicationCreated = 'APPLICATION_CREATED',
  Failed = 'FAILED',
  FailedHiProPaymentCancelled = 'FAILED_HI_PRO_PAYMENT_CANCELLED',
  FailedHiProPaymentCancelledApplicationAlreadyExist = 'FAILED_HI_PRO_PAYMENT_CANCELLED_APPLICATION_ALREADY_EXIST',
  FailedHiProPaymentRejected = 'FAILED_HI_PRO_PAYMENT_REJECTED',
  FailedHiProPaymentRejectedApplicationAlreadyExist = 'FAILED_HI_PRO_PAYMENT_REJECTED_APPLICATION_ALREADY_EXIST',
  PaymentRequest = 'PAYMENT_REQUESTED',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Suspended = 'SUSPENDED',
}
