import React from 'react';

import { NewButton } from 'app/components/NewButton';
import { AppMenuLinkProps } from './AppMenuLink.interfaces';
import { useStyles } from './AppMenuLink.styles';

const AppMenuLink = (props: AppMenuLinkProps) => {
  const { contained, title, onClick, to } = props;

  const classes = useStyles(props);

  return (
    <NewButton
      title={title}
      className={classes.menuLink}
      onClick={onClick}
      to={to}
      variant={contained ? 'contained' : undefined}
      color={contained ? 'primary' : undefined}
    />
  );
};

export default AppMenuLink;
