export enum TravelInsurantSelectionEnum {
  Different = 'different',
  Traveler1 = 'traveler1',
  Traveler10 = 'traveler10',
  Traveler2 = 'traveler2',
  Traveler3 = 'traveler3',
  Traveler4 = 'traveler4',
  Traveler5 = 'traveler5',
  Traveler6 = 'traveler6',
  Traveler7 = 'traveler7',
  Traveler8 = 'traveler8',
  Traveler9 = 'traveler9',
}
