import { UserService } from 'app/services/user.service';
import { loginOAuth, registerOAuth } from 'app/services/oAuth.service';
import { Credentials } from 'app/shared/interfaces/Credentials';
import { UserActions } from './actions';
import { FormsEnum, OAuthEnum, RouteEnum } from 'app/shared/enums';
import { isGetLastForOperatorDisabled } from 'app/services/env.service';
import { StorageService } from 'app/services/storage.service';
import { TagManagerService } from 'app/services/tagManager.service';
import { ThunkResult } from 'app/store/helpers';
import { getQuotationNumber, QuotationActions } from 'app/store/data/quotation';
import {
  createNewQuotation,
  getLastQuotation,
} from 'app/store/data/quotation/thunks';
import { FormsConfigActions } from 'app/store/data/formsConfig';
import { createTravelQuotation } from 'app/store/data/travelQuotation';

export const getAuth = (): ThunkResult => (dispatch, getState) => {
  dispatch(UserActions.authRequest());

  UserService.getCurrentUserInfo().then(
    ({ data }) => {
      const isAuthFailure = data.anonymous;
      const action = isAuthFailure
        ? UserActions.authFailure(data)
        : UserActions.authSuccess(data);

      const quotationInitialized = getState().quotation.id;
      const isOperator = data.operator;

      if (!isAuthFailure && quotationInitialized) {
        if (isOperator && isGetLastForOperatorDisabled) {
          dispatch(createNewQuotation());
        } else {
          dispatch(getLastQuotation());
        }
      }
      dispatch(action);
    },
    ({ data }) => dispatch(UserActions.authFailure(data))
  );
};

export const login = (
  credentials: Credentials,
  loggedInFromScreen?: RouteEnum
): ThunkResult<Promise<void>> => (dispatch, getState) => {
  dispatch(UserActions.loginRequest());

  return UserService.login(credentials).then(
    ({ data }) => {
      if (loggedInFromScreen === RouteEnum.CALCULATION_RESULTS) {
        const quotationNumber = getQuotationNumber(getState());
        TagManagerService.addDataLayer({
          event: 'zapisz_w_profilu_save',
          eventCategory: 'strona_kalkulacji',
          eventAction: 'zapisz_w_profilu_save',
          numerPorownania: quotationNumber,
        });
      }
      const isOperator = data.operator;

      dispatch(QuotationActions.clearQuotation());
      dispatch(
        FormsConfigActions.setFormConfigClearAction(FormsEnum.OfferForm)
      );
      dispatch(
        FormsConfigActions.setFormConfigClearAction(FormsEnum.OfferForm)
      );
      dispatch(UserActions.loginSuccess(data));

      if (isOperator && isGetLastForOperatorDisabled) {
        dispatch(createNewQuotation());
      } else {
        dispatch(getLastQuotation());
      }
    },
    ({ response }) => {
      dispatch(UserActions.loginFailure(response.data));
      return response.data?.errorKey;
    }
  );
};

export const loginSocialGoogle = (
  loggedInFromScreen?: RouteEnum
): ThunkResult<Promise<void>> => (dispatch, getState) =>
  loginOAuth(OAuthEnum.Google).then(() => {
    if (loggedInFromScreen === RouteEnum.CALCULATION_RESULTS) {
      const quotationNumber = getQuotationNumber(getState());
      TagManagerService.addDataLayer({
        event: 'zapisz_w_profilu_save',
        eventCategory: 'strona_kalkulacji',
        eventAction: 'zapisz_w_profilu_save',
        numerPorownania: quotationNumber,
      });
    }
    dispatch(getAuth());
  });

export const loginSocialFacebook = (
  loggedInFromScreen?: RouteEnum
): ThunkResult<Promise<void>> => (dispatch, getState) =>
  loginOAuth(OAuthEnum.Facebook).then(() => {
    if (loggedInFromScreen === RouteEnum.CALCULATION_RESULTS) {
      const quotationNumber = getQuotationNumber(getState());
      TagManagerService.addDataLayer({
        event: 'zapisz_w_profilu_save',
        eventCategory: 'strona_kalkulacji',
        eventAction: 'zapisz_w_profilu_save',
        numerPorownania: quotationNumber,
      });
    }
    dispatch(getAuth());
  });

export const registerSocialFacebook = (): ThunkResult<Promise<void>> => (
  dispatch
) =>
  registerOAuth(OAuthEnum.Facebook).then(() => {
    TagManagerService.addDataLayer({
      event: 'formSubmitted',
      formName: 'Registration',
    });
    dispatch(getAuth());
  });

export const registerSocialGoogle = (): ThunkResult<Promise<void>> => (
  dispatch
) =>
  registerOAuth(OAuthEnum.Google).then(() => {
    TagManagerService.addDataLayer({
      event: 'formSubmitted',
      formName: 'Registration',
    });
    dispatch(getAuth());
  });

export const logout = (isTravel?: boolean): ThunkResult<Promise<void>> => (
  dispatch
) =>
  UserService.logout().then(() => {
    if (isTravel) {
      dispatch(
        FormsConfigActions.setFormConfigClearAction(FormsEnum.TravelForm)
      );
      dispatch(UserActions.logout());
      dispatch(createTravelQuotation());
    } else {
      StorageService.clearReachedScreen(FormsEnum.CalculationForm);
      StorageService.clearReachedScreen(FormsEnum.CheckoutForm);
      dispatch(
        FormsConfigActions.setFormConfigClearAction(FormsEnum.OfferForm)
      );
      dispatch(UserActions.logout());
      dispatch(createNewQuotation());
    }
  });
