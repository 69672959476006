import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { styles } from './FullScreenMenu.styles';
import FullScreenMenu from './FullScreenMenu';
import {
  DispatchToPropsInterface,
  StateToPropsInterface,
} from 'app/components/FullScreenMenu/FullScreenMenu.interfaces';
import { getQuotationNumber } from 'app/store/data/quotation';
import { RootState } from 'app/store/rootReducer';

const mapStateToProps = (state: RootState) => ({
  quotationNumber: getQuotationNumber(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  // example: (payload: ExampleActionPayload) => dispatch(exampleAction(payload))
});

const FullScreenMenuContainer = connect<
  StateToPropsInterface,
  DispatchToPropsInterface
>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FullScreenMenu));

export default FullScreenMenuContainer;
