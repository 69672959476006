import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { Provider } from 'react-redux';
import 'react-placeholder/lib/reactPlaceholder.css';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import CookieBot from 'react-cookiebot';

import { AppContent } from 'app/components/AppContent';
import { AppMainNotification } from 'app/components/AppMainNotification';
import store from 'app/store';
import { createMyTheme } from 'app/styles/createMyTheme';
import { GoogleReCaptcha } from 'app/components/GoogleReCaptcha';
import { DocumentHead } from 'app/components/DocumentHead';
import { isNewCookiesStyle, cookieBotIdReact } from 'app/services/env.service';

moment.tz.setDefault('Europe/Warsaw');
const theme = createMyTheme();

const App = () => {
  useEffect(() => {
    const root = document.querySelector('#root') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;

    const config = { attributes: true };

    const callback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          body.style.overflow = root.style.overflow;
        }
      });
    };

    try {
      const observer = new MutationObserver(callback);
      observer.observe(root, config);
    } catch (e) {
      console.warn('MutationObserver not supported');
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <DocumentHead />
        <CssBaseline />
        {isNewCookiesStyle && cookieBotIdReact && (
          <CookieBot domainGroupId={cookieBotIdReact} />
        )}
        <GoogleReCaptcha>
          <AppContent />
        </GoogleReCaptcha>
        <AppMainNotification />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
