import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';

import { useStyles } from './TopBar.styles';
import { TopBarProps } from './TopBar.interfaces';
import { LabelEnum } from 'app/shared/enums';
import { AppLogo } from 'app/components/AppLogo';
import { agencyMode } from 'app/services/env.service';
import { RouteEnum } from 'app/shared/enums';
import { useUser } from 'app/shared/hooks/useUser';

const TopBar: React.FunctionComponent<TopBarProps> = (props) => {
  const { children, className } = props;
  const classes = useStyles();
  const location = useLocation();
  const { isSkpUser } = useUser();

  const stickyDisabled =
    location.pathname === RouteEnum.TRAVEL_CALCULATION_RESULTS ||
    location.pathname === RouteEnum.CALCULATION_RESULTS;

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <div className={classes.rootFixedWrapper}>
      <AppBar
        position={stickyDisabled ? 'static' : 'fixed'}
        color="inherit"
        className={classNames(className, classes.root, {
          [classes.sticky]: !stickyDisabled && scrollTrigger,
        })}
      >
        <div className={classNames(classes.toolbarContainer)}>
          <Toolbar className={classes.toolbar} disableGutters>
            <div className={classes.toolbarColumn}>
              {isSkpUser ? (
                <AppLogo />
              ) : (
                <a
                  href={window.location.protocol + '//' + window.location.host}
                >
                  <AppLogo />
                </a>
              )}
              {!agencyMode && (
                <div className={classes.subheader}>
                  {LabelEnum.InsuranceComparison}
                </div>
              )}
            </div>
            <div className={classes.toolbarColumn}>{children}</div>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

export default TopBar;
