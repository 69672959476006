import { RootState } from 'app/store/rootReducer';
import { FormsEnum } from 'app/shared/enums';
import { selectQuestion } from 'app/store/data/formsConfig';
import { selectIsCalculationFormQuestionVisible } from './calculationForm/selectIsCalculationFormQuestionVisible';

export const selectIsQuestionVisible = (params: {
  state: RootState;
  formName: FormsEnum;
  questionKey: string;
}) => {
  const question = selectQuestion(params);
  const { formName } = params;

  switch (formName) {
    case FormsEnum.CalculationForm:
    case FormsEnum.CompareCepikDataForm: {
      return selectIsCalculationFormQuestionVisible(params);
    }
    default: {
      return question.visible;
    }
  }
};
