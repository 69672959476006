import produce from 'immer';

import { PromotionState } from './state.interface';
import { PromotionActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';
import { QuotationActionsType } from 'app/store/data/quotation';

const initialState: PromotionState = {};

const promotionReducer = (
  state: PromotionState = initialState,
  action: PromotionActionsType | QuotationActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_PROMOTION:
        return { ...action.payload, isNewCode: state.isNewCode };

      case ActionType.MARK_PROMOTION_AS_EXPIRED:
        return { expiredPromotion: action.payload.promotionId };

      case ActionType.MARK_PROMOTION_AS_FOR_AC_ONLY:
        return { promotionForAcOnly: action.payload.promotionId };

      case ActionType.MARK_PROMOTION_AS_FOR_RENEWAL_ONLY:
        return { promotionForRenewalOnly: action.payload.promotionId };

      case ActionType.MARK_PROMOTION_AS_OFFER_PRICE_TOO_LOW:
        return { promotionOfferPriceTooLow: action.payload.promotionId };

      case ActionType.MARK_PROMOTIONAL_CODE_AS_NEW:
        return {
          ...state,
          isNewCode: true,
        };

      case ActionType.RESTORE_DEFAULT_PROMOTIONAL_STATE:
        return { ...initialState };

      case ActionType.CLEAR_PROMOTION:
        return initialState;

      case ActionType.CLEAR_QUOTATION:
        return initialState;

      default:
        return state;
    }
  });

export default promotionReducer;
