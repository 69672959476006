import React, { useEffect, Suspense, useState } from 'react';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppContentProps } from './AppContent.interfaces';
import { ViewsSwitch } from 'app/components/ViewsSwitch';
import { getAuth } from 'app/store/data/user';
import { useStyles } from './AppContent.styles';
import { useSetTimeout } from 'app/shared/hooks/useSetTimeout';
import { isChatActive } from 'app/services/env.service';
import { useUser } from 'app/shared/hooks/useUser';
import { Loader } from 'app/components/Loader';
import { ChunkLoadErrorBoundary } from 'app/components/ChunkLoadErrorBoundary';
import retryImport from 'app/shared/helpers/retryImport';
import lazyWithReload from 'app/shared/helpers/lazyWithReload';
import { useWindowSize } from 'app/shared/hooks/useWindowSize';
import { history } from 'app/shared/helpers/getRouterHistory';
import { AppMenu } from 'app/components/AppMenu';
import { isNewCookiesStyle } from 'app/services/env.service';
import { CookieBarOld } from 'app/components/CookieBarOld';

import useInitCookies from 'useInitCookies';

const ChatWidgets = lazyWithReload(() =>
  retryImport(() => import('app/components/ChatWidgets'))
);

export default function AppContent(props: AppContentProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [, height] = useWindowSize(100);

  const [showChat, setShowChat] = useState<boolean>(false);
  const { checkingAuth, isLoggedIn } = useUser();
  useSetTimeout(() => setShowChat(true), 5000);

  const auth = () => {
    dispatch(getAuth());
  };

  useInitCookies();

  useEffect(() => {
    auth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <AppMenu />
      <ChunkLoadErrorBoundary>
        <div
          className={classes.appContent}
          style={{
            // HACK: window.innerHeight === 100vh,
            // bug in webkit: https://bugs.webkit.org/show_bug.cgi?id=141832
            minHeight: height,
          }}
        >
          {checkingAuth && isLoggedIn === undefined ? (
            <Loader />
          ) : (
            <Route component={ViewsSwitch} />
          )}
          {!isNewCookiesStyle && <CookieBarOld />}
          {isChatActive && showChat ? (
            <Suspense fallback={<div />}>
              <ChatWidgets />
            </Suspense>
          ) : null}
        </div>
      </ChunkLoadErrorBoundary>
    </Router>
  );
}
