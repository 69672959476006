export const documentHeadConfig = {
  default: {
    title: 'Kalkulator OC AC - porównaj oferty OC i zapłać nawet 2 x mniej!',
    description:
      'Oblicz OC w 3 min ✓ Zaoszczędź na OC nawet 700 zł  ✓ Pakiet OC + AC już od 445 zł ✓ Darmowy kalkulator OC AC Mubi.pl  ➤ polecają Kuba Błaszczykowski i Łukasz Piszczek!',
    canonical: 'https://mubi.pl/ubezpieczenie-samochodu/kalkulator-oc-ac/',
  },
  terminationGenerator: {
    title: 'Generator wypowiedzenia OC - dokument gotowy w 2 minuty',
    description:
      'Oblicz OC w 3 min ✓ Zaoszczędź na OC nawet 700 zł  ✓ Pakiet OC + AC już od 445 zł ✓ Darmowy kalkulator OC AC Mubi.pl  ➤ polecają Kuba Błaszczykowski i Łukasz Piszczek!',
    canonical:
      'https://mubi.pl/ubezpieczenie-samochodu/kalkulator-oc-ac/generator-wypowiedzenia-oc',
  },
  travel: {
    title:
      'Kalkulator ubezpieczeń turystycznych - porównaj i zapłać nawet 2 x mniej!',
    description:
      'Oblicz ubezpieczenie turystyczne w 3 min ✓ Kup dobre ubezpieczenie podróżne już od 35 zł ✓ Darmowy kalkulator ubezpieczeń turystycznych Mubi.pl',
    canonical: 'https://mubi.pl/kalkulator-ubezpieczenia-turystycznego/',
  },
};
