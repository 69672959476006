import { Dictionary } from 'app/services/dict.service';
import { OfferFormConfigInterface } from 'app/shared/interfaces/OfferFormConfig.interface';
import { ControlTypeEnum, FormsEnum, MessageTypeEnum } from 'app/shared/enums';
import { FormScreenInterface } from 'app/shared/interfaces/FormScreen.interface';
import { FormSectionInterface } from 'app/shared/interfaces/FormSection.interface';
import { AnswerGroup } from 'app/shared/interfaces/AnswerGroup.interface';
import { OverwrittenFormConfigActionPayload } from './actionPayload.interfaces';

export interface FormsConfigState {
  calculationForm: FormConfigState;
  checkoutForm: FormConfigState;
  offerForm: FormConfigState;
  recoveryRequestForm: FormConfigState;
  checkoutPaymentForm: FormConfigState;
  checkoutPaymentAlertForm: FormConfigState;
  callbackForm: FormConfigState;
  completeDataForm: FormConfigState;
  leadsForm: FormConfigState;
  operatorLeadsForm: FormConfigState;
  recommendationForm: FormConfigState;
  getBonusForm: FormConfigState;
  [FormsEnum.DisableNotificationsForm]: FormConfigState;
  [FormsEnum.TravelForm]: FormConfigState;
  [FormsEnum.TravelOfferForm]: FormConfigState;
  [FormsEnum.TravelCheckoutForm]: FormConfigState;
  [FormsEnum.TravelPaymentForm]: FormConfigState;
  [FormsEnum.TerminationGeneratorForm]: FormConfigState;
  [FormsEnum.OperatorLeadsNewCarForm]: FormConfigState;
  [FormsEnum.SkpRegistrationForm]: FormConfigState;
  [FormsEnum.RenewalOfProposalForm]: FormConfigState;
  [FormsEnum.Renewal]: FormConfigState;
  [FormsEnum.CompareCepikDataForm]: FormConfigState;
  overwrittenFormConfig: OverwrittenFormConfigActionPayload;
  previousProductionYear?: number;
  vehicleTypeChanged?: boolean;
  consentsCheckedByCheckAll?: boolean;
}

export interface FormConfigState {
  screens: ScreensState;
  sections: SectionsState;
  questions: QuestionsState;
  messages: MessagesState;
  dictionaries: DictionariesState;
  hiddenDictionaryEntries: Array<string>;
  activeScreen: number;
  errors: Array<FormError>;
  formStructure?: OfferFormConfigInterface;
  shouldAutofill?: boolean;
}

export interface FormError {
  key: string;
  type: string;
}

export interface ScreensState {
  [key: string]: FormScreenInterface;
}

export interface SectionsState {
  [key: string]: FormSectionInterface;
}

export interface QuestionState {
  answersDictionaryKey?: string;
  answersDictionaryParams?: Array<string>;
  answerGroups?: Array<AnswerGroup>;
  dependsOn?: Array<string>;
  disabled?: boolean;
  key: string;
  label: string;
  messages: Array<string>;
  modelPath: string;
  onChange?: Array<string>;
  required: boolean;
  readonly?: boolean;
  subLabel: string;
  tooltip: string;
  type: ControlTypeEnum;
  visible: boolean;
  clearOnHide?: boolean;
  minDate?: string;
  maxDate?: string;
}

export interface QuestionsState {
  [key: string]: QuestionState;
}

export interface Message {
  content: string;
  key: string;
  type: MessageTypeEnum;
  visible: boolean;
  questions?: Array<string>;
  element?: JSX.Element;
}

export interface MessagesState {
  [key: string]: Message;
}

export interface DictionariesState {
  [key: string]: Dictionary;
}
