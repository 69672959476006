import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    root: {
      background: 'transparent',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
  });
