import { FormsEnum, ViewsWithSteps } from 'app/shared/enums';
import { isNewCookiesStyle } from 'app/services/env.service';

export const getItem = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}');
  } catch (e) {
    console.warn(e);
    return {};
  }
};

export const setItem = (key: string, data: object) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.warn(e);
  }
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.warn(e);
  }
};

export const removeItemsStartsWith = (key: string) => {
  try {
    Object.keys(localStorage)
      .filter((item) => item.startsWith(key))
      .forEach((item) => localStorage.removeItem(item));
  } catch (e) {
    console.warn(e);
  }
};

export const StorageService = {
  getReachedScreen(formName: FormsEnum | ViewsWithSteps): number {
    if (!window.Cookiebot.consent.preferences && isNewCookiesStyle) {
      removeItem('reachedScreens');
      return 0;
    }
    const storageScreens = getItem('reachedScreens');

    return storageScreens[formName] || 0;
  },

  setReachedScreen(
    formName: FormsEnum | ViewsWithSteps,
    activeScreen: number
  ): void {
    if (!window.Cookiebot.consent.preferences && isNewCookiesStyle) {
      removeItem('reachedScreens');
    }
    const storageScreens = getItem('reachedScreens');

    storageScreens[formName] = activeScreen;

    setItem('reachedScreens', storageScreens);
  },

  clearReachedScreen(formName: FormsEnum | ViewsWithSteps): void {
    const storageScreens = getItem('reachedScreens');

    storageScreens[formName] = 0;

    setItem('reachedScreens', storageScreens);
  },
};
