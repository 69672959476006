/**
 * Allows to determine if element is visible in viewport
 *
 * [StackOverflow source](https://stackoverflow.com/a/7557433)
 */
export function isElementInViewport(elem: Element) {
  const rect = elem.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
