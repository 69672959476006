import { ComponentType, LazyExoticComponent, lazy } from 'react';

export default function lazyWithReload<T>(
  componentRetryImport: () => Promise<{ default: ComponentType<T> }>
): LazyExoticComponent<ComponentType<T>> {
  return lazy(async () => {
    const pageHasAlreadyBeenForceReloaded = JSON.parse(
      window.sessionStorage.getItem('page-has-been-force-reloaded') || 'false'
    );

    try {
      const component = await componentRetryImport();

      window.localStorage.setItem('page-has-been-force-reloaded', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceReloaded) {
        window.sessionStorage.setItem('page-has-been-force-reloaded', 'true');

        return new Promise((resolve, reject) => {
          window.location.reload();
          reject(error);
        });
      }

      throw error;
    }
  });
}
