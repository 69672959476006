import {
  InsuranceTypeEnum,
  MoreFiltersTypeEnum,
  InsuranceEnum,
} from 'app/shared/enums';

export const insuranceScopeFilterDictionary = [
  { title: InsuranceEnum.Ac, value: InsuranceTypeEnum.Ac },
  {
    title: InsuranceEnum.Nnw,
    value: InsuranceTypeEnum.Nnw,
  },
  {
    title: InsuranceEnum.Assistance,
    value: InsuranceTypeEnum.Assistance,
  },
];

export const filtersACSection = [
  MoreFiltersTypeEnum.ExcessType,
  MoreFiltersTypeEnum.Amortization,
  MoreFiltersTypeEnum.FormOfRepair,
];

export const filtersAssistanceSection = [
  MoreFiltersTypeEnum.AssistanceAfterFailure,
  MoreFiltersTypeEnum.SubstituteCar,
];

export const insuranceScopeFilter = new Map<InsuranceTypeEnum, InsuranceEnum>([
  [InsuranceTypeEnum.Ac, InsuranceEnum.Ac],
  [InsuranceTypeEnum.Nnw, InsuranceEnum.Nnw],
  [InsuranceTypeEnum.Assistance, InsuranceEnum.Assistance],
]);
