import { initialize } from 'redux-form';
import cloneDeep from 'lodash-es/cloneDeep';

import { FormsEnum, SectionsEnum } from 'app/shared/enums';
import { ThunkResult } from 'app/store/helpers';
import { FormConfig } from 'app/shared/interfaces/FormConfig.interface';
import { FormsConfigActions } from 'app/store/data/formsConfig';
import {
  selectTravelQuotation,
  TravelQuotationState,
} from 'app/store/data/travelQuotation';
import { CheckoutFormUtil } from 'app/components/TravelCheckoutForm/TravelCheckoutForm.util';
import { FormSectionConfigInterface } from 'app/shared/interfaces/FormSectionConfig.interface';

function setConsentVisibility(
  quotation: TravelQuotationState,
  section: FormSectionConfigInterface
) {
  if (quotation?.consents?.marketing?.consent) {
    section.visible = false;
  }
}

function setTravelerSectionVisibility(
  quotation: TravelQuotationState,
  section: FormSectionConfigInterface
) {
  const travelerIndex = Number(section.key.split('.').pop());
  const numberOfTravelers = Number(quotation.numberOfPersons);

  if (numberOfTravelers && travelerIndex < numberOfTravelers) {
    section.visible = true;
  }
}

function setSectionVisibility(
  quotation: TravelQuotationState,
  section: FormSectionConfigInterface
) {
  if (section.key.startsWith('section.travelers.')) {
    setTravelerSectionVisibility(quotation, section);
  }

  if (section.key === SectionsEnum.TravelCheckoutInsurantConsents) {
    setConsentVisibility(quotation, section);
  }
}

function getMappedTravelCheckoutFormConfig(
  conf: FormConfig,
  quotation: TravelQuotationState
) {
  const config = cloneDeep(conf);

  config.screens.forEach((screen) => {
    screen.sections.forEach((section) => {
      setSectionVisibility(quotation, section);
    });
  });

  return config;
}

export const initTravelCheckoutFormValues = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();
  const { travelQuotation, user } = state;
  const formValues = CheckoutFormUtil.getInitializeValuesFromQuotation(
    travelQuotation,
    user
  );

  dispatch(initialize(FormsEnum.TravelCheckoutForm, formValues));
};

export const setTravelCheckoutFormConfig = (
  formConfig: FormConfig
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const quotation = selectTravelQuotation(state);

  dispatch(
    FormsConfigActions.setFormConfigAction({
      formConfig: getMappedTravelCheckoutFormConfig(formConfig, quotation),
      name: FormsEnum.TravelCheckoutForm,
    })
  );
  dispatch(initTravelCheckoutFormValues());
};
