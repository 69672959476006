import { AxiosPromise, AxiosRequestConfig } from 'axios';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import set from 'lodash-es/set';

import { QuotationStatusEnum } from 'app/shared/enums';
import store from 'app/store';
import { ApiService } from 'app/services/api.service';
import { retryAxiosGet } from 'app/shared/helpers/axiosHelpers';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { TravelQuotationInterface } from 'app/shared/interfaces/TravelQuotation.interface';
import { parseModelPath } from 'app/shared/helpers/quotation';
import { QuestionsState } from 'app/store/data/formsConfig';
import {
  TravelQuotationState,
  TravelQuotationActions,
} from 'app/store/data/travelQuotation';
import { TravelQuotationResultsWithAbTestsInterface } from 'app/shared/interfaces/TravelQuotationResultsWithAbTests.interface';
import { TravelerInterface } from 'app/shared/interfaces/Traveler.interface';
import { RecoveryInterface } from 'app/components/RecoveryRequestForm/RecoveryRequestForm.interfaces';
import { AcquisitionParams } from 'app/shared/interfaces/AcquisitionParams.interface';
import { CheckoutMarkInterface } from 'app/shared/interfaces/CheckoutMark.interface';

export const TravelQuotationService = {
  url: 'travel-insurances/quotations',

  create(): AxiosPromise<TravelQuotationInterface> {
    return ApiService.post(this.url, {});
  },

  createWithInheritedABTests(
    currentQuotationId: string
  ): AxiosPromise<TravelQuotationInterface> {
    return ApiService.post(`${this.url}/${currentQuotationId}`, {});
  },

  getLast(): AxiosPromise<TravelQuotationInterface> {
    return ApiService.get(`${this.url}/last`);
  },

  getQuotationById(
    quotationId: string
  ): AxiosPromise<TravelQuotationInterface> {
    const url = `${this.url}/${quotationId}`;

    return ApiService.get(url)
      .catch(() => retryAxiosGet<TravelQuotationInterface>(url, 300))
      .catch(() => retryAxiosGet<TravelQuotationInterface>(url, 500))
      .catch(() => retryAxiosGet<TravelQuotationInterface>(url, 1000));
  },

  requestForCopy(id: string): AxiosPromise<TravelQuotationInterface> {
    const recoveryKeyRegex = new RegExp('^[0-9a-zA-Z]+$');
    const method = recoveryKeyRegex.test(id) ? 'copiesByRecoveryKey' : 'copies';

    return ApiService.post(`${this.url}/${id}/${method}`, {});
  },

  update(quotationId: string, modelPath: string, value: JsonPatchValueType) {
    if (modelPath) {
      store.dispatch(
        TravelQuotationActions.setQuotationQuestion(modelPath, value)
      );
    }

    return ApiService.patch(`${this.url}/${quotationId}`, [
      {
        op: 'replace',
        path: parseModelPath(modelPath),
        value,
      },
    ]);
  },

  getQuotationResultsFor(
    quotationId: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<TravelQuotationResultsWithAbTestsInterface> {
    const url = `${this.url}/${quotationId}/results`;
    return ApiService.retryableGet(url, config);
  },

  getValuesByKeys(
    data: TravelQuotationInterface,
    questions: QuestionsState
  ): TravelQuotationInterface {
    let values = {} as TravelQuotationInterface;

    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const value = get(data, question.modelPath);

      set(values, key, value);
    });

    return values;
  },

  getValuesByModelPaths<T>(
    formValues: object,
    questions: QuestionsState,
    quotation?: TravelQuotationState
  ): T {
    let values = quotation ? cloneDeep(quotation) : {};
    const travelers = get(values, 'travelers');

    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const value = get(formValues, key);

      if (
        !isNil(value) &&
        question.modelPath &&
        (question.visible || !question.clearOnHide)
      ) {
        set(values, question.modelPath, value);
      }
    });

    if (travelers.length) {
      const clearedTravelers = travelers.filter(
        (traveler: TravelerInterface) => traveler.age !== null
      );

      values = { ...values, travelers: clearedTravelers };
    }

    return values as T;
  },

  requestForQuotationResults({
    quotationId,
    data,
  }: {
    quotationId: string;
    data: TravelQuotationInterface;
    requestForNewResults?: boolean;
  }) {
    const dataToSend = {
      ...data,
      status:
        data.status === QuotationStatusEnum.Cleared
          ? QuotationStatusEnum.InProgress
          : data.status,
    };

    return ApiService.post(`${this.url}/${quotationId}/results`, dataToSend);
  },

  recoveryRequests(quotationId: string, data: RecoveryInterface) {
    return ApiService.post(
      `${this.url}/${quotationId}/recovery-requests`,
      data
    );
  },

  setAcquisitionParams(quotationId: string, data: AcquisitionParams) {
    return ApiService.post(`${this.url}/${quotationId}/acquisitions`, data);
  },

  markCheckout({ quotationId, resultId, offerId }: CheckoutMarkInterface) {
    const url = `${this.url}/${quotationId}/checkout-offers`;

    return ApiService.retryablePost(url, {
      resultId,
      offerId,
    });
  },
};
