import { AxiosPromise } from 'axios';
import get from 'lodash-es/get';

import { ApiService } from 'app/services/api.service';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { Credentials } from 'app/shared/interfaces/Credentials';
import { b64EncodeUnicode } from 'app/services/util.service';
import { ClientConsents } from 'app/shared/interfaces/ClientConsents.interface';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { FormsConfigurationsService } from 'app/services/formsConfigurations.service';
import { UserConsentInterface } from 'app/shared/interfaces/UserConsent.interface';
import { BankTransfersData } from 'app/shared/interfaces/BankTransfersData.interface';

export const getAuthorizationKey = ({ username, password }: Credentials) => {
  const authString = `${username}:${password}`;
  return 'Basic' + b64EncodeUnicode(authString);
};

export interface RegistrationInterface {
  username: string;
  password: string;
  consents: ClientConsents;
}

export const UserService = {
  url: 'users',

  getCurrentUserInfo(): AxiosPromise<UserInterface> {
    return ApiService.get(`${this.url}/me`);
  },

  logout() {
    return ApiService.get(`${this.url}/me`, { headers: { Logout: '' } });
  },

  login(credentials: Credentials): AxiosPromise<UserInterface> {
    return ApiService.get(`${this.url}/me`, {
      headers: {
        Authorization: getAuthorizationKey(credentials),
      },
    });
  },

  editUserData(
    data: Array<{ op: string; path: string; value: JsonPatchValueType }>
  ): AxiosPromise<void> {
    return ApiService.patch(`${this.url}/me`, data);
  },

  register({ username, password, consents }: RegistrationInterface) {
    return ApiService.post('registrations', {
      username,
      password,
      consents,
    });
  },

  validate(username: string): AxiosPromise<UserInterface> {
    return ApiService.post(
      '/registrations/validation',
      {},
      {
        params: {
          username,
        },
      }
    );
  },

  resendVerificationEmail(username: string): AxiosPromise<void> {
    return ApiService.post('registrations/mail-verification', {
      username,
    });
  },

  getConsents() {
    return FormsConfigurationsService.getRegisterFormConfig().then(
      ({ data }) => {
        return get(
          data,
          'screens[0].sections[0].questions'
        ).filter((question: { key: string }) =>
          question.key.includes('consent')
        );
      }
    );
  },

  getNewestConsents(): AxiosPromise<Array<UserConsentInterface>> {
    return ApiService.get(`${this.url}/me/consents/newest`);
  },

  updateConsents(data: {}): AxiosPromise<void> {
    return ApiService.post(`${this.url}/me/consents/updates`, data);
  },

  updateSingleConsent(consentId: string): AxiosPromise<void> {
    return ApiService.put(`${this.url}/me/consents/${consentId}`);
  },

  confirmBankAccountChange(token: string): AxiosPromise<void> {
    return ApiService.put(`${this.url}/bank-transfer-data/change-requests`, {
      token,
    });
  },

  changeBankAccountNo(data: BankTransfersData): AxiosPromise<void> {
    return ApiService.post(
      `${this.url}/bank-transfer-data/change-requests`,
      data
    );
  },
};
