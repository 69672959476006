import produce from 'immer';

import { AcquisitionState } from './state.interface';
import { AcquisitionActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: AcquisitionState = {
  isAcquisitionRead: false,
  isTravelAcquisitionRead: false,
  isRenewalQuotation: false,
  renewalOwnersDataChange: {
    owner: false,
    firstCoOwner: false,
    secondCoOwner: false,
  },
  renewalCarDataChange: {
    firstRegistrationCountry: false,
  },
};

const AcquisitionReducer = (
  state: AcquisitionState = initialState,
  action: AcquisitionActionsType
) =>
  produce(state, (draft) => {
    if (action.type === ActionType.MARK_ACQUISITION_AS_READ) {
      draft.isAcquisitionRead = true;
    } else if (action.type === ActionType.MARK_TRAVEL_ACQUISITION_AS_READ) {
      draft.isTravelAcquisitionRead = true;
    } else if (action.type === ActionType.MARK_QUOTATION_RENEWAL) {
      draft.isRenewalQuotation = action.payload.isRenewal;
    } else if (action.type === ActionType.CHANGE_RENEWAL_OWNERS_DATA) {
      draft.renewalOwnersDataChange[action.payload.ownerName] =
        action.payload.isChanged;
    } else if (action.type === ActionType.CHANGE_RENEWAL_CAR_DATA) {
      draft.renewalCarDataChange[action.payload.field] =
        action.payload.isChanged;
    } else {
      return state;
    }
  });

export default AcquisitionReducer;
