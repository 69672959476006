import { LabelEnum } from 'app/shared/enums';
import { UtilService } from 'app/services/util.service';

const smsCode = (value: string) => {
  const re = /^[0-9]{6}$/;

  const isValid = re.test(value) || !UtilService.hasValue(value);

  return isValid ? undefined : LabelEnum.InvalidSmsCode;
};

export default smsCode;
