import * as React from 'react';
import classNames from 'clsx';
import Cookie from 'mdi-material-ui/Cookie';
import Cookies from 'js-cookie';

import { NewButton } from 'app/components/NewButton';

import {
  CookieBarOldProps,
  CookieBarOldState,
} from './CookieBarOld.interfaces';
import { LabelEnum } from 'app/shared/enums';
import isLocalhost from 'app/shared/helpers/isLocalhost';
import { agencyMode } from 'app/services/env.service';
import { removeItem } from 'app/services/storage.service';

class CookieBarOld extends React.Component<
  CookieBarOldProps,
  CookieBarOldState
> {
  state = {
    open: true,
  };

  componentDidMount() {
    this.removeNewCookiesStyleSettings();
  }

  render() {
    const { classes, className } = this.props;

    if (agencyMode) {
      return null;
    }

    return (
      !this.cookiePolicyAccepted && (
        <div
          className={classNames(classes.root, className)}
          aria-label={'Cookie Agreement'}
        >
          <div className={classes.wrapper}>
            <Cookie className={classes.cookieIcon} />
            <div className={classes.infoContainer}>
              <span className={classes.text}>
                {LabelEnum.CookieInfo1Old}
                <a
                  className={classes.policyUrl}
                  href="https://mubi.pl/polityka-prywatnosci"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {LabelEnum.CookieInfo2Old}
                </a>
              </span>
            </div>
            <NewButton
              title={LabelEnum.OK}
              color="primary"
              variant="contained"
              classes={{ root: classes.buttonRoot }}
              onClick={this.handleClick}
              aria-label={'Accept cookies'}
            />
          </div>
        </div>
      )
    );
  }

  get cookiePolicyAccepted() {
    return Cookies.get('cookiesPolicyAccepted');
  }

  private handleClick = () => {
    const domain = isLocalhost()
      ? 'localhost'
      : process.env.REACT_APP_COOKIES_DOMAIN;

    Cookies.set('cookiesPolicyAccepted', 'true', {
      domain,
      expires: 3650,
    });

    this.setState({
      open: false,
    });
  };

  private removeNewCookiesStyleSettings = () => {
    removeItem('cookies');
  };
}

export default CookieBarOld;
