import { RootState } from 'app/store/rootReducer';

export const getAcquisition = (state: RootState) =>
  state.acquisition.isAcquisitionRead;

export const getTravelAcquisition = (state: RootState) =>
  state.acquisition.isTravelAcquisitionRead;

export const getRenewalQuotation = (state: RootState) =>
  state.acquisition.isRenewalQuotation;
