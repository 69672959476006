import { formValueSelector } from 'redux-form';

import { RootState } from 'app/store/rootReducer';
import { FormsEnum } from 'app/shared/enums';
import { selectIsQuestionVisible } from 'app/store/selectors/isQuestionVisible';

export const selectValueIfFieldVisible = (params: {
  state: RootState;
  formName: FormsEnum;
  questionKey: string;
}) => {
  const isQuestionVisible = selectIsQuestionVisible(params);

  if (!isQuestionVisible) {
    return;
  }

  const { state, formName, questionKey } = params;
  return formValueSelector(formName)(state, questionKey);
};
