import find from 'lodash-es/find';
import { captureMessage } from '@sentry/browser';

import { ThunkResult } from 'app/store/helpers';
import { SelectedOfferActions } from './actions';
import { selectSelectedOfferInsurerId } from './selectors';
import { DictionariesEnum } from 'app/shared/enums';
import { DictService } from 'app/services/dict.service';
import { getInsurerDisplayNameAfterRebranding } from 'app/shared/helpers/getInsurerDisplayNameAfterRebranding';

export const getInsurerDisplayName = (): ThunkResult => (
  dispatch,
  getState
) => {
  const state = getState();
  const insurerId = selectSelectedOfferInsurerId(state);

  DictService.getDictionary(DictionariesEnum.CarInsurers)
    .then(({ data }) => {
      const insurerDictionary = find(data, {
        key: insurerId,
      });
      const insurerName = insurerDictionary
        ? getInsurerDisplayNameAfterRebranding(insurerDictionary.value)
        : getInsurerDisplayNameAfterRebranding(insurerId);

      dispatch(SelectedOfferActions.setSelectedOfferInsurerName(insurerName));
    })
    .catch((e) => {
      captureMessage(
        `DictService.getDictionary ${DictionariesEnum.CarInsurers}, ${e}`
      );
      dispatch(SelectedOfferActions.setSelectedOfferInsurerName(insurerId));
    });
};
