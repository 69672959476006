import { ApiService, HeaderParamType } from 'app/services/api.service';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import set from 'lodash-es/set';

import store from 'app/store';
import { QuotationState, QuotationActions } from 'app/store/data/quotation';
import { QuestionsState, SectionsState } from 'app/store/data/formsConfig';
import { CalculationDataByKeysInterface } from 'app/shared/interfaces/CalculationDataByKeys.interface';
import { CalculationDataByModelPathsInterface } from 'app/shared/interfaces/CalculationDataByModelPaths.interface';
import { SavedCalculationInterface } from 'app/shared/interfaces/SavedCalculation.interface';
import { FormConfig } from 'app/shared/interfaces/FormConfig.interface';
import { AcquisitionParams } from 'app/shared/interfaces/AcquisitionParams.interface';
import {
  FieldsEnum,
  QuotationStatusEnum,
  SectionIdEnum,
  SectionsEnum,
  VehicleTypeEnum,
} from 'app/shared/enums';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { RecoveryInterface } from 'app/components/RecoveryRequestForm/RecoveryRequestForm.interfaces';
import { CheckoutFormByKeysInterface } from 'app/shared/interfaces/CheckoutForm.interface';
import { retryAxiosGet } from 'app/shared/helpers/axiosHelpers';
import { QuotationInterface } from 'app/shared/interfaces/Quotation.interface';
import { DisableNotificationsFormInterface } from 'app/shared/interfaces/DisableNotificationsForm.interface';
import { EncryptedQuotationIdType } from 'app/shared/types/EncryptedQuotationId.type';
import { parseModelPath } from 'app/shared/helpers/quotation';
import { parseCepikMergeResultKey } from 'app/shared/helpers/parseCepikMergeResultKey';
import { CheckoutMarkInterface } from 'app/shared/interfaces/CheckoutMark.interface';
import { CepikRequestData } from 'app/shared/interfaces/Cepik.interface';
import { QuotationResultsWithAbTestsInterface } from 'app/shared/interfaces/QuotationResultsWithAbTests.interface';
import { RenewalFormValues } from 'app/views/RenewalFormView/RenewalFormView.interface';
import { getIsLeasingLeadConfig } from 'app/shared/helpers/getIsLeasingLeadConfig';
import { CepikDataInterface } from 'app/shared/interfaces/CepikCarData.interface';

export const QuotationService = {
  url: 'car-insurances/quotations',

  create(
    initialValues: Partial<CalculationDataByModelPathsInterface> = {}
  ): AxiosPromise<QuotationState> {
    return ApiService.post(this.url, initialValues);
  },

  createWithInheritedABTests(
    initialValues: Partial<CalculationDataByModelPathsInterface> = {},
    currentQuotationId: string
  ): AxiosPromise<QuotationState> {
    return ApiService.post(`${this.url}/${currentQuotationId}`, initialValues);
  },

  createForPartner({
    brand,
    partnerId,
  }: {
    brand: string;
    partnerId: string;
  }): AxiosPromise<QuotationState> {
    return ApiService.post(`${this.url}/partners/${partnerId}/${brand}`, {});
  },

  createForOperator(
    initialValues: Partial<CalculationDataByModelPathsInterface> = {},
    quotationUserId: string
  ): AxiosPromise<QuotationState> {
    return ApiService.post(
      `users/${quotationUserId}/${this.url}`,
      initialValues
    );
  },

  createWithDefaultValues(): AxiosPromise<QuotationState> {
    return ApiService.post(`${this.url}/default`, {});
  },

  markCheckout({
    quotationId,
    resultId,
    offerId,
  }: CheckoutMarkInterface): AxiosPromise<QuotationState> {
    const url = `${this.url}/${quotationId}/checkout-offers`;

    return ApiService.retryablePost<QuotationState>(url, {
      resultId,
      offerId,
    });
  },

  getLast(vehicleType?: VehicleTypeEnum) {
    const params = {
      ...(vehicleType && { vehicleType: vehicleType.toUpperCase() }),
    };

    return ApiService.get(`${this.url}/last`, { params });
  },

  getLastForOperator(vehicleType: VehicleTypeEnum, quotationUserId: string) {
    const params = {
      vehicleType: vehicleType.toUpperCase(),
    };

    return ApiService.get(`users/${quotationUserId}/${this.url}/last`, {
      params,
    });
  },

  getQuotationById(quotationId: string) {
    const url = `${this.url}/${quotationId}`;
    return ApiService.get(url)
      .catch(() => retryAxiosGet(url, 300))
      .catch(() => retryAxiosGet(url, 500))
      .catch(() => retryAxiosGet(url, 1000));
  },

  getMine(): AxiosPromise<Array<SavedCalculationInterface>> {
    return ApiService.get(`${this.url}/mine`, {
      headers: { Accept: HeaderParamType.ApplicationSummaryJson },
    });
  },

  deleteQuotation(quotationId: string): AxiosPromise<{}> {
    return ApiService.delete(`${this.url}/${quotationId}`);
  },

  requestForCopy(id: string): AxiosPromise<QuotationInterface> {
    const recoveryKeyRegex = new RegExp('^[0-9a-zA-Z]+$');
    const method = recoveryKeyRegex.test(id) ? 'copiesByRecoveryKey' : 'copies';

    return ApiService.post(`${this.url}/${id}/${method}`, {});
  },

  update(
    quotationId: string,
    modelPath: string,
    value: JsonPatchValueType | { [key: string]: boolean | null }
  ) {
    if (modelPath && (value === null || typeof value !== 'object')) {
      store.dispatch(QuotationActions.setQuotationQuestion(modelPath, value));
    }

    return ApiService.patch(`${this.url}/${quotationId}`, [
      {
        op: 'replace',
        path: parseModelPath(modelPath),
        value,
      },
    ]);
  },

  requestForQuotationResults({
    quotationId,
    data,
    requestForNewResults,
  }: {
    quotationId: string;
    data: CalculationDataByModelPathsInterface;
    requestForNewResults?: boolean;
  }) {
    const dataToSend = {
      ...data,
      status:
        data.status === QuotationStatusEnum.Cleared
          ? QuotationStatusEnum.InProgress
          : data.status,
    };

    const endpoint = requestForNewResults ? 'new-results' : 'results';
    return ApiService.retryablePost(
      `${this.url}/${quotationId}/${endpoint}`,
      dataToSend
    );
  },

  requestForQuotationResultsWithReCaptcha({
    quotationId,
    data,
    captchaToken,
  }: {
    quotationId: string;
    data: CalculationDataByModelPathsInterface;
    captchaToken: string;
  }) {
    data.status =
      data.status === QuotationStatusEnum.Cleared
        ? QuotationStatusEnum.InProgress
        : data.status;

    return ApiService.post(`${this.url}/${quotationId}/quotation-results`, {
      body: data,
      captchaToken,
    });
  },

  recoveryRequests(quotationId: string, data: RecoveryInterface) {
    return ApiService.post(
      `${this.url}/${quotationId}/recovery-requests`,
      data
    );
  },

  requestCallback(quotationId: string, data: {}) {
    return ApiService.post(`${this.url}/${quotationId}/callbacks`, data);
  },

  getQuotationResultsFor(
    quotationId: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<QuotationResultsWithAbTestsInterface> {
    const url = `${this.url}/${quotationId}/results`;
    return ApiService.retryableGet(url, config);
  },

  getValuesByKeys(
    data: CalculationDataByModelPathsInterface,
    questions: QuestionsState
  ): CalculationDataByKeysInterface {
    let values = {} as CalculationDataByKeysInterface;

    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const value = get(data, question.modelPath);

      set(values, key, value);
    });

    return values;
  },

  getValuesByModelPaths<T>(
    formValues: object,
    questions: QuestionsState,
    quotation?: QuotationState,
    sections: SectionsState = {}
  ): T {
    const sectionsToClearIfInvisible = [
      {
        key: SectionsEnum.Coowners1,
        modelPath: 'coOwners[0]',
      },
      {
        key: SectionsEnum.Coowners2,
        modelPath: 'coOwners[1]',
      },
      {
        key: SectionsEnum.AdditionalDrivers1,
        modelPath: 'additionalDrivers[0]',
      },
      {
        key: SectionsEnum.CheckoutSectionCoowners1,
        modelPath: 'coOwners[0]',
      },
      {
        key: SectionsEnum.CheckoutSectionCoowners2,
        modelPath: 'coOwners[1]',
      },
    ];

    let values: QuotationState | {} = quotation ? cloneDeep(quotation) : {};

    if ('shortPath' in values) {
      delete values.shortPath;
    }

    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const value = get(formValues, key);

      if (
        !isNil(value) &&
        question.modelPath &&
        (question.visible || !question.clearOnHide)
      ) {
        set(values, question.modelPath, value);
      }
    });

    sectionsToClearIfInvisible.forEach((sectionConf) => {
      const section = sections[sectionConf.key];

      if (section && !section.visible) {
        set(values, sectionConf.modelPath, null);
      }
    });

    return values as T;
  },

  getActiveScreenByValues(
    values: CalculationDataByKeysInterface | CheckoutFormByKeysInterface,
    formConfig: FormConfig
  ) {
    const excludedValues = [
      FieldsEnum.VehicleType,
      FieldsEnum.InsuranceScope,
      FieldsEnum.InsurantEmail,
      FieldsEnum.InsurancePhoneNumber,
      FieldsEnum.LegalStatusLeased,
    ];
    let lastValueScreen = 0;

    if (getIsLeasingLeadConfig()) {
      excludedValues.pop();
    }

    formConfig.screens.forEach((screen, screenIndex) => {
      if (screenIndex > 0) {
        screen.sections.forEach((section) => {
          section.questions
            .filter(
              (question) => !excludedValues.includes(question.key as FieldsEnum)
            )
            .forEach((question) => {
              if (lastValueScreen !== screenIndex) {
                const questionValue = get(values, question.key);

                if (questionValue && question.visible) {
                  lastValueScreen = screenIndex;
                }
              }
            });
        });
      }
    });

    return lastValueScreen;
  },

  markQuotationAsRedirected(quotationId: string) {
    return ApiService.patch(`${this.url}/${quotationId}/redirected`, {});
  },

  requestForAztecLink(phoneNumber: string) {
    return ApiService.post(`${this.url}/aztec-request`, {
      phoneNumber: '+48' + phoneNumber,
    });
  },

  setAcquisitionParams(quotationId: string, data: AcquisitionParams) {
    return ApiService.post(`${this.url}/${quotationId}/acquisitions`, data);
  },

  getCarDetails(encryptedQuotationId: EncryptedQuotationIdType) {
    return ApiService.get(`${this.url}/cars`, {
      params: { q: encryptedQuotationId },
    });
  },

  requestForSectionData(
    data: CalculationDataByModelPathsInterface,
    quotationId: string,
    sectionId: SectionIdEnum
  ): AxiosPromise<QuotationInterface> {
    return ApiService.put(`${this.url}/${quotationId}`, data, {
      params: {
        section_id: sectionId,
      },
    });
  },

  requestForCepikData(quotationId: string): AxiosPromise<CepikDataInterface> {
    return ApiService.get(`dictionaries/cepik/insurer_data/${quotationId}`);
  },

  requestForCepikSectionData(
    data: CalculationDataByModelPathsInterface,
    quotationId: string,
    sectionId: SectionIdEnum
  ): AxiosPromise<QuotationInterface> {
    return ApiService.post(`${this.url}/${quotationId}/cepikData`, data, {
      params: {
        section_id: sectionId,
      },
    });
  },

  getCepikMergeData(quotationId: string): AxiosPromise<CepikDataInterface> {
    return ApiService.get(`${this.url}/${quotationId}/cepikData`);
  },

  removeCar(
    data: DisableNotificationsFormInterface & { encryptedQuotationId: string }
  ) {
    return ApiService.delete(`${this.url}/cars`, { data });
  },

  requestForCopyWithNewCar(
    data: Partial<QuotationInterface> & { encryptedQuotationId: string }
  ) {
    return ApiService.post(`${this.url}/copies/with-new-car`, data);
  },

  requestCepik(data: CepikRequestData) {
    return ApiService.post(`${this.url}/cepik/insurer-data`, data);
  },

  requestForCopyWithNewDate(
    data: Partial<RenewalFormValues> & {
      encryptedQuotationId: string;
    }
  ) {
    return ApiService.patch(`${this.url}/encrypted/start-dates`, data);
  },

  setCepikMergeResult(
    quotationId: string,
    fields: { key: FieldsEnum; value: boolean | null }[] | null
  ) {
    const delayIncrement = 200;
    let ms = 0;

    if (fields === null) {
      return QuotationService.update(quotationId, 'cepikMergeResult', null);
    }

    const requests: AxiosPromise[] = fields.map(async (field) => {
      ms += delayIncrement;
      await new Promise((resolve) => setTimeout(resolve, ms));
      return await QuotationService.update(quotationId, 'cepikMergeResult', {
        [parseCepikMergeResultKey(field.key)]: field.value,
      });
    });

    return Promise.all(requests);
  },
};
