import React, { memo } from 'react';
import { IconProps } from './Icon.interfaces';

const Icon = ({ name, className, style, size = 'auto' }: IconProps) => {
  return (
    <img
      style={{
        width: size,
        height: size,
        ...style,
      }}
      className={className}
      alt={name}
      src={`./assets/icons/${name}.svg`}
      loading="lazy"
    />
  );
};

export default memo(Icon);
