export enum ControlTypeEnum {
  Button = 'BUTTON',
  CarLongRegistrationNumber = 'CAR_LONG_REGISTRATION_NUMBER',
  CarRegistrationNumber = 'CAR_REGISTRATION_NUMBER',
  CarVin = 'CAR_VIN',
  Checkbox = 'CHECKBOX',
  CheckboxGroup = 'CHECKBOX_GROUP',
  Date = 'DATE',
  DateList = 'DATE_LIST',
  Documents = 'DOCUMENTS',
  Email = 'EMAIL',
  EurobandCarRegistrationNumber = 'EUROBAND_CAR_REGISTRATION_NUMBER',
  ExtendedDate = 'EXTENDED_DATE',
  GroupedCheckbox = 'GROUPED_CHECKBOX',
  HouseNumber = 'HOUSE_NUMBER',
  IconCheckboxGroup = 'ICON_CHECKBOX_GROUP',
  InsurersList = 'INSURERS_LIST',
  LastName = 'LASTNAME',
  List = 'LIST',
  ListVersion = 'LIST_VERSION',
  ListWithAdditionalInfo = 'LIST_WITH_ADDITIONAL_INFO',
  Mileage = 'MILEAGE',
  MultipleSelect = 'MULTIPLE_SELECT',
  Name = 'NAME',
  NipNumber = 'NIP_NUMBER',
  None = 'NONE',
  Number = 'NUMBER',
  OptionalCarRegistrationNumber = 'OPTIONAL_CAR_REGISTRATION_NUMBER',
  Password = 'PASSWORD',
  PaymentMethod = 'PAYMENT_METHOD',
  Pesel = 'PESEL',
  PhoneNumber = 'PHONE_NUMBER',
  PolicyNumber = 'POLICY_NUMBER',
  PromotionCode = 'PROMOTION_CODE',
  Radio = 'RADIO',
  RadioRow = 'RADIO_ROW',
  Select = 'SELECT',
  SkpNumber = 'SKP_NUMBER',
  StreetName = 'STREETNAME',
  Text = 'TEXT',
  Traveler = 'TRAVELER',
  VehicleType = 'VEHICLE_TYPE',
  Word = 'WORD',
  ZipCode = 'ZIP_CODE',
}
