import includes from 'lodash-es/includes';

import { RootState } from 'app/store/rootReducer';
import { FieldsEnum, FormsEnum } from 'app/shared/enums';
import { selectValueIfFieldVisible } from 'app/store/selectors/selectValueIfFieldVisible';

export const createIsClaimsYearVisible = (
  state: RootState,
  formName: FormsEnum
) => (
  damagesCountQuestionKey: FieldsEnum,
  fieldVisibleForDamages: Array<'1' | '2' | '3' | '4' | '5_'>
) => {
  const damagesCount = selectValueIfFieldVisible({
    state,
    formName,
    questionKey: damagesCountQuestionKey,
  });
  return includes(fieldVisibleForDamages, damagesCount);
};
