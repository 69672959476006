import isNil from 'lodash-es/isNil';

import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import { topLevelDomains } from './topLevelDomains';

export function isEmailValid(email: string, validateTopLevelDomain?: boolean) {
  const isEmailDefined = !isNil(email);

  if (!isEmailDefined) {
    return false;
  }

  // TODO: remove escapes
  // eslint-disable-next-line no-control-regex
  const re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/im;

  const isFormatValid = re.test(String(email).toLowerCase());

  if (validateTopLevelDomain) {
    const emailParts = email.split(/\.|@/);
    const lastEmailPart = emailParts[emailParts.length - 1].toLowerCase();
    const isTopLevelDomainValid = topLevelDomains.includes(lastEmailPart);

    return isFormatValid && isTopLevelDomainValid;
  } else {
    return isFormatValid;
  }
}

export default function validateEmail(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_email',
    valid: isEmailValid(data.value.toString(), true),
  };
}
