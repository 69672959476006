import moment from 'moment';
import reduce from 'lodash-es/reduce';

import { GenderEnum } from 'app/shared/enums/Gender.enum';
import { isMan } from 'app/services/pesel.service';

const isValidSumControl = (pesel: string) => {
  const reg = /^[0-9]{11}$/;

  if (!reg.test(pesel)) {
    return false;
  }

  const weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let sum = reduce(
    weights,
    (prevSum, weight, i) =>
      prevSum + parseInt(pesel.substring(i, i + 1), 10) * weight,
    0
  );

  sum = sum % 10;
  return parseInt(pesel.substring(10, 11), 10) === sum;
};

export const getBirthdayString = (pesel: string) => {
  let year = +(pesel[0] + pesel[1]);
  let month = +(pesel[2] + pesel[3]);
  const day = +(pesel[4] + pesel[5]);

  if (pesel[2] === '0' || pesel[2] === '1') {
    year += 1900;
  } else if (pesel[2] === '2' || pesel[2] === '3') {
    year += 2000;
    month -= 20;
  } else if (pesel[2] === '4' || pesel[2] === '5') {
    year += 2100;
    month -= 40;
  } else if (pesel[2] === '6' || pesel[2] === '7') {
    year += 2200;
    month -= 60;
  } else if (pesel[2] === '8' || pesel[2] === '9') {
    year += 1800;
    month -= 80;
  }

  const monthString = month < 10 ? '0' + month : '' + month;
  const dayString = day < 10 ? '0' + day : '' + day;
  const dateString = `${year}-${monthString}-${dayString}`;
  const isValidDate = moment(dateString, 'YYYY-MM-DD', true).isValid();

  return isValidDate ? dateString : undefined;
};

export function isPeselValid(pesel: string, minAge?: number): boolean {
  if (!isValidSumControl(pesel)) {
    return false;
  }
  const birthdayString = getBirthdayStringByPesel(pesel);
  if (!birthdayString) {
    return false;
  }
  const birthday = moment(birthdayString);
  const currentDate = moment();
  const diffInYears = currentDate.diff(birthday, 'years');
  const diffInDays = currentDate.diff(birthday, 'days');
  const isNotTooOld = diffInYears < 100;
  const isNotTooYoung = minAge === undefined || diffInYears >= minAge;
  const isBorn = diffInDays >= 1;

  return isNotTooOld && isNotTooYoung && isBorn;
}

export function getBirthdayStringByPesel(pesel: string) {
  return isValidSumControl(pesel) ? getBirthdayString(pesel) : undefined;
}

export function getAgeFromPesel(pesel: string) {
  const birthdayFromPesel = getBirthdayStringByPesel(pesel);
  const ageFromPesel = moment().diff(birthdayFromPesel, 'years');

  return ageFromPesel;
}

export function isPeselMatchingAge(pesel: string, age: number): boolean {
  const ageFromPesel = getAgeFromPesel(pesel);

  return age === ageFromPesel;
}

export function isPeselMatchingBirthday(
  pesel: string,
  birthday: string
): boolean {
  const birthdayFromPesel = getBirthdayStringByPesel(pesel);

  return birthdayFromPesel === birthday;
}

export function isPeselMatchingGender(pesel: string, gender: string): boolean {
  const genderFromPesel = isMan(pesel) ? GenderEnum.Man : GenderEnum.Woman;

  return gender === genderFromPesel;
}

export { isMan } from './isMan';
export { isPeselMatchingLicenseDate } from './isPeselMatchingLicenseDate';
export { stringifyPesel } from './stringifyPesel';
export type { IsPeselMatchingLicenseDateFunctionParams } from './isPeselMatchingLicenseDate';
