import { useEffect } from 'react';

import { initHotjar } from 'initHotjar';
import { initSentry } from 'initSentry';

export default function useInitCookies() {
  const init = () => {
    initSentry();
    initHotjar();
  };

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', init);

    return () => window.removeEventListener('CookiebotOnAccept', init);
  }, []);
}
