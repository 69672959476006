import { createStore, compose, applyMiddleware, AnyAction } from 'redux';
import thunk from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';
import { tagManagerMiddleware } from 'app/store/middlewares/tagManagerMiddleware';
import { ThunkAnyDispatch } from 'app/store/helpers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Function;
  }
}

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore<RootState, AnyAction, {}, {}>(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, tagManagerMiddleware))
);

if (window.Cypress) {
  //@ts-ignore
  window.Cypress.store = store;
}

export const dispatchThunk: ThunkAnyDispatch = store.dispatch;

export default store;
