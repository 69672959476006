import { Draft } from 'immer';

import { SetupValidatorForTypeActionPayload } from '../actionPayload.interfaces';
import { FormsConfigState, QuestionState } from '../state.interfaces';
import {
  FieldsEnum,
  FormsEnum,
  LabelEnum,
  MessagesEnum,
  MessageTypeEnum,
} from 'app/shared/enums';
import { getMessageKeyWithSuffix } from 'app/services/validation.service';
import { addQuestionMessage, setMessage } from '../reducer';
import validationConfig from './validationConfig';
import { ActionWithPayload } from 'app/store/helpers';

const customRequiredMessages = [
  MessagesEnum.CarRegistrationNumberError,
  MessagesEnum.TravelDestinationError,
  MessagesEnum.TravelStartDateRequired,
  MessagesEnum.TravelEndDateRequired,
  MessagesEnum.TravelGoalsError,
  MessagesEnum.TravelOver99DestinationsError,
];

const defaultRequiredMesssages = (questionKey: string) => {
  const requiredMessage = {
    [FieldsEnum.TravelNumberOfPersons]: LabelEnum.FieldRequiredTravel,
    [FieldsEnum.TravelTravelers1Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers2Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers3Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers4Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers5Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers6Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers7Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers8Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers9Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelTravelers10Age]: LabelEnum.ChooseAge,
    [FieldsEnum.TravelAllTravelersCurrentlyInPoland]:
      LabelEnum.FieldRequiredTravel,
  }[questionKey];

  return requiredMessage || LabelEnum.FieldRequired;
};

const requiredMessage = (
  question: QuestionState,
  formName: FormsEnum,
  state: FormsConfigState
) => {
  const message =
    (question.messages &&
      question.messages.find((msg) =>
        customRequiredMessages.includes(msg.toString() as MessagesEnum)
      )) ||
    '';

  if (message) {
    return state[formName].messages[message].content;
  }

  return defaultRequiredMesssages(question.key);
};

function getContentFromConfig(
  question: QuestionState,
  formName: FormsEnum,
  state: FormsConfigState,
  messageKey: string
) {
  const hasDefinedMessage = question.messages?.some(
    (message) => message === messageKey
  );
  if (hasDefinedMessage) {
    return state[formName].messages[messageKey].content;
  }
  return undefined;
}

export default function setupValidatorForType(
  state: FormsConfigState,
  action: ActionWithPayload<string, SetupValidatorForTypeActionPayload>,
  draft: Draft<FormsConfigState>
) {
  const { questionKey, formName } = action.payload;
  const question = state[formName].questions[questionKey];
  const validationMessages = validationConfig[question.type] || [];

  const addValidationMessage = (valData: {
    suffix: string;
    content: string;
  }) => {
    const { suffix, content: defaultContent } = valData;
    const messageKey = getMessageKeyWithSuffix(question.key, suffix);
    const content =
      getContentFromConfig(question, formName, state, messageKey) ||
      defaultContent;
    const message = {
      content,
      key: messageKey,
      type: MessageTypeEnum.Danger,
      visible: false,
      questions: [question.key],
    };
    setMessage(draft, { formName, message });
    addQuestionMessage(draft, {
      formName,
      questionKey: question.key,
      messageKey: message.key,
    });
  };

  if (question.required) {
    const content = requiredMessage(question, formName, state);
    const suffix = 'required';
    addValidationMessage({ content, suffix });
  }

  validationMessages.forEach(addValidationMessage);
}
