import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export default function validateCarVin(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_car_vin',
    valid: isVinValid(data.value.toString()),
  };
}

export function isVinValid(vin: string): boolean {
  if (!vin) {
    return false;
  }

  const reg = /(?=.*\d)(?=.*[a-zA-Z])(?=^[a-zA-Z0-9]{17}$)/;

  return reg.test(vin);
}

export function containsValidVinCharacters(vin: string): boolean {
  return /^[A-Za-z0-9*]+$/.test(vin);
}
