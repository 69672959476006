import { ActionCreatorsMapObject, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { RootState } from 'app/store/rootReducer';

export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export type ActionsUnion<T extends ActionCreatorsMapObject> = ReturnType<
  T[keyof T]
>;

export type ThunkResult<T = void> = ThunkAction<T, RootState, null, AnyAction>;

export type ThunkAnyDispatch = ThunkDispatch<RootState, null, AnyAction>;
