import { ActionType } from 'app/store/actionTypes';
import { createAction, ActionsUnion } from 'app/store/helpers';

export const FormSubmissionActions = {
  setIsLoadingResult: (payload: boolean) =>
    createAction(ActionType.SET_LOADING_RESULT, payload),
};

export type FormSubmissionActionsType = ActionsUnion<
  typeof FormSubmissionActions
>;
