import debounce from 'lodash-es/debounce';
import { FormErrors } from 'redux-form';

import { FieldsEnum } from 'app/shared/enums';

const focusElement = (element: HTMLElement) => {
  element.style.outline = 'none';
  element.setAttribute('tabindex', '-1');
  element.focus();
  element.removeAttribute('tabindex');
};

function scrollToYWithAnimation(
  top: number,
  options?: { duration?: number; scroller?: Element | null }
) {
  const duration = options?.duration || 400;
  const element = options?.scroller || document.scrollingElement;
  if (!element || element.scrollTop === Math.round(top)) {
    return;
  }

  const cosParameter = (element.scrollTop - top) / 2;
  let scrollCount = 0;
  let oldTimestamp: number | null = null;

  function step(newTimestamp: number) {
    if (!element) {
      return;
    }
    if (oldTimestamp !== null) {
      scrollCount =
        scrollCount + (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) {
        return (element.scrollTop = top);
      }
      element.scrollTop =
        cosParameter + top + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

export const scrollSmoothly = (
  top: number,
  customOffset?: number,
  options?: { scroller?: Element | null }
) => {
  const navbarHeight = options?.scroller ? 0 : 120;
  const offset = typeof customOffset === 'number' ? customOffset : navbarHeight;

  scrollToYWithAnimation(top - offset, { scroller: options?.scroller });
};

const focusAfterScrollListener = (element: HTMLElement, scrollTop: number) => {
  const listener = debounce(() => {
    const isScrollFinished = Math.abs(scrollTop - window.pageYOffset) < 2;
    if (isScrollFinished) {
      focusElement(element);
      window.removeEventListener('scroll', listener);
    }
    // tslint:disable-next-line:align
  }, 100);

  return listener;
};

const clearListenerWhenScrollAborted = (listener: EventListener) => {
  setTimeout(() => window.removeEventListener('scroll', listener), 5000);
};

const scrollAndFocus = (element: HTMLElement, scrollTop: number) => {
  const listener = focusAfterScrollListener(element, scrollTop);
  window.addEventListener('scroll', listener);
  scrollSmoothly(scrollTop);
  clearListenerWhenScrollAborted(listener);
};

export const ScrollService = {
  scrollToScreen(screenIndex: number) {
    const screenSelector = `.formScreen-${screenIndex}`;
    const screen = document.querySelector<HTMLElement>(screenSelector);

    if (screen) {
      this.scrollToElement(screen, { scrollToTop: true });
    }
  },
  scrollToFirstFormError() {
    const element =
      document.querySelector<HTMLElement>('.error') ||
      document.querySelector<HTMLElement>('div[data-field-error="true"]');

    if (element) {
      this.scrollToElement(element, { scrollToTop: true });
    }
  },
  scrollToQuestion(questionKey: FieldsEnum | string) {
    const elementSelector = `div[data-field-type="question"][data-field-key="${questionKey}"]`;
    const element = document.querySelector<HTMLElement>(elementSelector);

    if (element) {
      this.scrollToElement(element);
    }
  },
  scrollToElement(
    element: HTMLElement,
    options?: { scrollToTop?: Boolean; scroller?: Element | null }
  ) {
    const elementBottom = element.getBoundingClientRect().bottom;
    const elementTop = element.getBoundingClientRect().top;
    const scrollerFirstChildTop = options?.scroller?.firstElementChild?.getBoundingClientRect()
      .top;
    const margin = 20;

    let top;
    if (options?.scroller && scrollerFirstChildTop) {
      top =
        (options?.scrollToTop
          ? elementTop - scrollerFirstChildTop
          : elementBottom - scrollerFirstChildTop) - margin;
    } else {
      top =
        window.pageYOffset +
        (options?.scrollToTop ? elementTop : elementBottom) -
        margin;
    }

    scrollSmoothly(top, undefined, {
      scroller: options?.scroller,
    });
  },
  scrollToTopFormError() {
    const element = document.querySelector<HTMLElement>('.topFormError');

    if (element) {
      this.scrollToElement(element, { scrollToTop: true });
    }
  },
  scrollOnSubmitFail(
    errors: FormErrors<FormData>,
    options?: { scroller?: Element | null }
  ) {
    const firstError = Object.keys(errors)[0];
    const element = document.querySelector<HTMLElement>(
      `[name="${firstError}"]`
    );

    if (element) {
      this.scrollToElement(element, {
        scrollToTop: true,
        scroller: options?.scroller,
      });
    }
  },
  scrollAndFocus,
};
