import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import { ValidationObject } from 'app/services/validation.service';
import { containsNonNumeric } from 'app/shared/helpers/containeNonNumeric';

function isOutOfRange(value: string, min?: number, max?: number) {
  if (value !== '') {
    const numberValue = +value;
    if (min !== undefined && numberValue < min) {
      return true;
    }
    if (max !== undefined && numberValue > max) {
      return true;
    }
  }

  return false;
}

function isNumberValid(value: string, min?: number, max?: number) {
  if (containsNonNumeric(value) || isOutOfRange(value, min, max)) {
    return false;
  }

  return true;
}

export default function validateNumber(
  data: ValidatorDataInterface,
  min?: number,
  max?: number
): ValidationObject {
  return {
    key: 'invalid_number',
    valid: isNumberValid(data.value.toString(), min, max),
  };
}
