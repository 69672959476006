export enum CalendarEnum {
  All = 'Cały okres',
  April = 'Kwiecień',
  August = 'Sierpień',
  CurrentYear = 'Bieżący rok',
  December = 'Grudzień',
  February = 'Luty',
  January = 'Styczeń',
  July = 'Lipiec',
  June = 'Czerwiec',
  March = 'Marzec',
  May = 'Maj',
  November = 'Listopad',
  October = 'Październik',
  PrevYear = 'Poprzedni rok',
  September = 'Wrzesień',
}
