import { BrowserOptions, init, getCurrentHub } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import isLocalhost from 'app/shared/helpers/isLocalhost';
import { agencyMode, isNewCookiesStyle } from 'app/services/env.service';

export function initSentry() {
  const isSentryRunning = getCurrentHub()?.getClient();

  const disableSentry = () => {
    if (isSentryRunning) {
      getCurrentHub()!.getClient()!.getOptions().enabled = false;
      init();
    }
  };

  if (
    (!agencyMode &&
      !window.Cookiebot.consent.preferences &&
      isNewCookiesStyle) ||
    window.Cypress ||
    isLocalhost()
  ) {
    disableSentry();
    return;
  }
  const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_RELEASE,
    REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  } = process.env;
  if (!REACT_APP_SENTRY_DSN) {
    return;
  }
  const config: BrowserOptions = {
    release: REACT_APP_RELEASE || 'Frontend',
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: REACT_APP_SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 0,
    ignoreErrors: [
      '@webkit-masked-url', //caused by a Safari browser extension error, can safely be ignored
      'Non-Error promise rejection captured with keys: username', //occurs on register form sumbit when username alredy taken, redux-form error
    ],
  };
  if (!isSentryRunning) {
    init(config);
  }
}
