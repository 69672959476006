import { VehicleTypeEnum } from 'app/shared/enums';
import { TagManagerService } from 'app/services/tagManager.service';
import { DataLayerFlowTypeEventInterface } from 'app/shared/interfaces/DataLayerFlowTypeEvent.interface';

export function addDataLayerFlowTypeEvent({
  isDefault,
  quotationNumber,
  quotationId,
  vehicleType,
  isTravel,
}: DataLayerFlowTypeEventInterface) {
  if (isTravel) {
    TagManagerService.addDataLayer({
      event: 'travel_calculation_form',
      numer_porownania: quotationNumber,
      numer_kwotacji: quotationId,
    });
    return;
  }

  if (!vehicleType) {
    return;
  }

  const flowType = {
    [VehicleTypeEnum.Car]: 'samochód',
    [VehicleTypeEnum.Motorcycle]: 'motocykl',
  }[vehicleType];

  TagManagerService.addDataLayer({
    event: 'calculation_form',
    typFlow: flowType,
    isDefault,
    numer_porownania: quotationNumber,
    numer_kwotacji: quotationId,
  });
}
