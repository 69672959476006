declare global {
  interface Window {
    Cypress: object;
  }
}
export function storeEnvForCypress() {
  if (window.Cypress) {
    window.sessionStorage.setItem('env', JSON.stringify(process.env));
  }
}
