import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, breakpoints, mixins, palette }: Theme) => ({
    rootFixedWrapper: {
      height: mixins.toolbar.minHeight,
      width: '100%',
    },
    root: {
      fontFamily: 'Lato',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
    },
    sticky: {
      boxShadow: custom.shadow[1],
      zIndex: 15,
    },
    toolbar: {
      width: '100%',
      maxWidth: custom.pageWidth,
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
    },
    toolbarContainer: {
      backgroundColor: palette.common.white,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 9,
    },
    toolbarColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    subheader: {
      fontSize: 14,
      height: 36,
      paddingLeft: 25,
      marginLeft: 25,
      display: 'flex',
      alignItems: 'center',
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: custom.grey[1],
      color: custom.grey[2],
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);
