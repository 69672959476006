import moment from 'moment';

import { QuotationStatusEnum } from 'app/shared/enums';

export function parseModelPath(modelPath: string) {
  return `/${modelPath.replace(/\./g, '/').replace(/\[([0-9]*)\]/gi, '/$1')}`;
}

export const afterCalculationStatuses = [
  QuotationStatusEnum.SentToCalculation,
  QuotationStatusEnum.Calculated,
  QuotationStatusEnum.Checkout,
  QuotationStatusEnum.CheckoutError,
  QuotationStatusEnum.Redirected,
  QuotationStatusEnum.Upsale,
];

export const hasQuotationExpired = (quotationStartDate: string) => {
  const currentDate = moment();
  const startDate = moment(quotationStartDate, 'YYYY-MM-DD');
  return startDate.isBefore(currentDate, 'day');
};
