import { RootState } from 'app/store/rootReducer';

export const getBonusPayout = (state: RootState) => state.bonusPayout;

export const getBonusPayoutPromotionAmount = (state: RootState) =>
  state.bonusPayout.promotionAmount;

export const getBonusPayoutPostponedPromotionAmount = (state: RootState) =>
  state.bonusPayout.postponedPromotionAmount;

export const getBonusPayoutPhoneNumber = (state: RootState) =>
  state.bonusPayout.phoneNumber;

export const getBonusPayoutIsPathAnonymous = (state: RootState) =>
  state.bonusPayout.isPathAnonymous;

export const getBonusPayoutIsPostponedVariantSelected = (state: RootState) =>
  state.bonusPayout.isPostponedSelected;

export const getBonusExpiredDate = (state: RootState) =>
  state.bonusPayout.expiredDate;
