import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette, spacing, breakpoints, custom }: Theme) =>
  createStyles({
    root: {
      backgroundColor: custom.grey[3],
      color: custom.grey[5],
      lineHeight: 1.38,
      position: 'fixed',
      display: 'flex',
      bottom: 0,
      right: 0,
      left: 0,
      boxShadow: '0 5px 20px 0 rgba(32, 49, 91, 0.3)',
      padding: spacing(2, 4),
      zIndex: 9,
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 13,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
      maxWidth: custom.pageWidth,
    },
    infoContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 15,
      paddingRight: 30,
    },
    btnGroup: {
      textAlign: 'center',
    },
    cookieIcon: {
      color: custom.grey[4],
      width: 38,
      height: 38,
    },
    policyUrl: {
      textDecoration: 'none',
      color: palette.primary.main,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    buttonRoot: {
      width: 100,
    },
    [breakpoints.down(980)]: {
      text: {
        padding: 0,
      },
    },
    [breakpoints.down(769)]: {
      root: {
        flexDirection: 'column',
        padding: '20px 10px',
      },
      wrapper: {
        flexDirection: 'column',
      },
      btnGroup: {
        paddingBottom: spacing(2),
        paddingRight: 0,
        textAlign: 'center',
      },
      cookieIcon: {
        display: 'none',
      },
    },
    [breakpoints.down(540)]: {
      infoContainer: {
        display: 'block',
        paddingBottom: 16,
      },
    },
  });
