export default function isIdCardNumberValid(value: string): boolean {
  value = value.replace(/\s+/g, '');

  if (!value || value.length !== 9) return false;

  value = value.toUpperCase();
  const letterValues = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const getLetterValue = (letter: string) => letterValues.indexOf(letter);

  [0, 1, 2].forEach((i) => {
    if (getLetterValue(value[i]) < 10 || value[i] === 'O' || value === 'Q')
      return false;
  });

  [3, 4, 5, 6, 7, 8].forEach((i) => {
    if (getLetterValue(value[i]) < 0 || getLetterValue(value[i]) > 9)
      return false;
  });

  let checkSum =
    7 * getLetterValue(value[0]) +
    3 * getLetterValue(value[1]) +
    1 * getLetterValue(value[2]) +
    7 * getLetterValue(value[4]) +
    3 * getLetterValue(value[5]) +
    1 * getLetterValue(value[6]) +
    7 * getLetterValue(value[7]) +
    3 * getLetterValue(value[8]);

  checkSum %= 10;

  if (checkSum !== getLetterValue(value[3])) return false;

  return true;
}
