export enum UpsaleRiskTypeEnum {
  Ac = 'AC',
  AcMini = 'ACMINI',
  Assistance = 'ASSISTANCE',
  AssistanceTowing = 'ASSISTANCE_TOWING',
  Bls = 'DIRECT_CLAIMS_HANDLING',
  CarBattery = 'CAR_BATTERY',
  CarRent = 'carRent',
  ContinuationTreatment = 'continuationTreatment',
  DiscountProtection = 'DISCOUNT_PROTECTION',
  Glass = 'GLASS',
  Greencard = 'GREENCARD',
  HelpAfterTheft = 'HELP_AFTER_THEFT',
  Keys = 'KEYS',
  LegalProtection = 'LEGAL_PROTECTION',
  Luggage = 'LUGGAGE',
  Medic = 'MEDIC',
  MotorcycleAccessories = 'MOTORCYCLE_ACCESSORIES',
  Nnw = 'NNW',
  OcSport = 'ocSport',
  ReplacementCar = 'REPLACEMENT_CAR',
  ReplacementCarDays = 'REPLACEMENT_CAR_DAYS',
  Tire = 'TIRE',
}
