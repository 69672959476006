export enum AztecEnum {
  AztecScannerDisclaimer = 'Otwórz link na telefonie i zeskanuj kod z dowodu rejestracyjnego.',
  CalculationsLabel = 'Kalkulacje',
  CalculationsTooltip = 'Kalkulacje to pierwszy krok do otrzymania bonusu. Oznaczają klientów, którzy zostali przez Ciebie przekazani do\xa0Mubi i\xa0czekają na weryfikacje.',
  OpenScanner = 'Otwórz skaner AZTEC',
  PaidProvisionLabel = 'Prowizja brutto',
  PaidProvisionTooltip = 'Pamiętaj, że to wynagrodzenie całkowite, przed odliczeniem podatków oraz składek.',
  PoliciesSoldLabel = 'Opłaconych polis',
  PurchasesTooltip = 'Liczba zakupionych polis przez klientów, których do nas wysłałeś.',
  RepaidPurchasesTooltip = 'Liczba opłaconych polis przez klientów, których do nas wysłałeś. Trzymamy kciuki, żeby ta statystyka rosła na Twoim koncie jak najszybciej!',
  SettlementsLabel = 'Twoja prowizja',
  StatsSubHeader = 'Statystyki Twoich poleceń',
  TotalEarnings = 'Łączne zarobki z Mubi:',
  VerifiedCalculationsLabel = 'Zweryfikowane kalkulacje',
  VerifiedCalculationsLabel2 = 'Zweryfikowanych kalkulacji',
  VerifiedCalculationsTooltip = 'Liczba zweryfikowanych kalkulacji klientów, za które dostaniesz umówioną prowizję. Już niewiele dzieli ich od zakupu polisy.',
  YourAztecLinkLabel = 'Skaner AZTEC',
}
