import React from 'react';
import classNames from 'clsx';
import { useTheme } from '@material-ui/core';

import { AppLogoProps } from './AppLogo.interfaces';
import { useStyles } from './AppLogo.styles';

function AppLogo({ className }: AppLogoProps) {
  const classes = useStyles();
  const { custom } = useTheme();

  return (
    <img
      src={custom.logo.src}
      alt={custom.logo.alt}
      className={classNames(classes.root, className)}
      height={custom.logo.width}
      width={custom.logo.height}
      loading="lazy"
    />
  );
}

export default AppLogo;
