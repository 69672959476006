import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/includes';
import 'core-js/features/object/entries';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/object';
import 'core-js/features/promise/finally';
import 'raf/polyfill';
import 'intersection-observer/intersection-observer';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';

import './enableImmerES5';
import { storeEnvForCypress } from 'storeEnvForCypress';

import App from './App';
import './index.css';

import 'swiper/swiper.css';
import 'swiper/modules/navigation/navigation.min.css';

storeEnvForCypress();

const rootElement = document.getElementById('root') as HTMLElement;

ReactDOM.render(<App />, rootElement);
