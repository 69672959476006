import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette, breakpoints, custom }: Theme) =>
  createStyles({
    root: {
      backgroundColor: custom.grey[0],
      boxShadow: '0 11px 20px 0 rgba(0, 0, 0, 0.25)',
    },
    borderBottom: {
      borderBottom: `1px solid ${custom.grey[1]}`,
    },
    item: {
      width: '100%',
      fontSize: 14,
      padding: '16px 20px',
      color: custom.grey[3],
      '&:not(:last-child)': {
        borderBottom: `1px solid ${custom.grey[1]}`,
      },
      '&:hover': {
        color: palette.primary.main,
        fontWeight: 'bold',
      },
    },
    noBorderRadius: {
      borderRadius: 0,
    },
    saveFormBoxRoot: {
      backgroundColor: custom.grey[3],
      color: palette.common.white,
      padding: 20,
      borderRadius: 0,
    },
    saveFormBoxHeading: {
      color: palette.common.white,
    },
    quotationNumber: {
      color: custom.grey[2],
      padding: '16px 20px',
      fontSize: 13,
    },
    [breakpoints.up(769)]: {
      root: {
        display: 'none',
      },
    },
  });
