import { AnyAction, Middleware } from 'redux';

import {
  AbTestEnum,
  FieldsEnum,
  FormsEnum,
  InsuranceTypeEnum,
} from 'app/shared/enums';
import { TagManagerService } from 'app/services/tagManager.service';
import {
  sortingCriterionDictionary,
  sortingCriterionDictionaryTravel,
} from 'app/shared/dictionaries/sortingCriterionDictionary';
import {
  getQuotationNumber,
  QuotationSetActionPayload,
  selectQuotationActiveAbTests,
} from 'app/store/data/quotation';
import {
  selectIsPolandSelected,
  selectTravelQuotationActiveAbTests,
  selectTravelQuotationNumber,
} from 'app/store/data/travelQuotation';
import { getFilters, getTravelFilters } from 'app/store/data/form';
import { addDataLayerFlowTypeEvent } from 'app/shared/helpers/addDataLayerFlowTypeEvent';
import {
  ResultsStoreActionPayload,
  selectResultsActiveAbTests,
} from 'app/store/data/quotationResult';
import { selectTravelResultsActiveAbTests } from 'app/store/data/travelQuotationResults';
import { RootState } from 'app/store/rootReducer';
import { selectIsOperator } from 'app/store/data/user';

const addAbTestsDataLayer = (
  getState: () => RootState,
  payload: AnyAction['payload']
) => {
  const isOperator = selectIsOperator(getState());
  const quotationActiveAbTests = selectQuotationActiveAbTests(getState());
  const travelQuotationActiveAbTests = selectTravelQuotationActiveAbTests(
    getState()
  );
  const resultsActiveAbTests = selectResultsActiveAbTests(getState());
  const travelResultsActiveAbTests = selectTravelResultsActiveAbTests(
    getState()
  );

  const activeAbTests = [
    ...quotationActiveAbTests,
    ...resultsActiveAbTests,
    ...travelQuotationActiveAbTests,
    ...travelResultsActiveAbTests,
  ];
  const { activeABTests: nextActiveAbTests } = payload as
    | QuotationSetActionPayload
    | ResultsStoreActionPayload;

  if (isOperator) {
    return;
  }

  if (nextActiveAbTests && nextActiveAbTests.length > 0) {
    nextActiveAbTests.forEach((test: AbTestEnum) => {
      if (activeAbTests.includes(test)) return;

      TagManagerService.addDataLayer({
        event: 'ab_test_info',
        test_name: test,
      });
    });
  }
};

export const tagManagerMiddleware: Middleware = ({ getState }) => {
  const isPolandSelected = selectIsPolandSelected(getState());
  return (next: (action: AnyAction) => AnyAction) => (action: AnyAction) => {
    switch (action.type) {
      case '@@redux-form/CHANGE': {
        if (
          action.meta.form === FormsEnum.DisableNotificationsForm &&
          action.payload
        ) {
          const data = {
            event: 'customEvent',
            eventCategory: 'info_form',
            eventAction: action.meta.field,
            eventLabel: action.payload,
          };
          TagManagerService.addDataLayer(data);
        }

        if (
          action.meta.form === FormsEnum.OfferFilters &&
          action.meta.field === FieldsEnum.Sort
        ) {
          const quotationNumber = getQuotationNumber(getState());
          const eventLabel = sortingCriterionDictionary.find(
            (entry) => entry.key === action.payload
          )?.value;

          TagManagerService.addDataLayer({
            event: 'sortowanie_select',
            eventCategory: 'strona_kalkulacji',
            eventAction: 'sortowanie_select',
            eventLabel,
            numerPorownania: quotationNumber,
            typ_wynikow: isPolandSelected ? 'polska' : 'normal',
          });
        }

        if (
          action.meta.form === FormsEnum.OfferFilters &&
          action.meta.field === FieldsEnum.Scopes
        ) {
          const quotationNumber = getQuotationNumber(getState());
          const { scopes: oldScopesFilterValue = [] } =
            getFilters(getState()) || {};
          const { payload: newScopesFilterValue } = action;
          const selectedScope = newScopesFilterValue.find(
            (scope: InsuranceTypeEnum) => !oldScopesFilterValue.includes(scope)
          );
          if (selectedScope !== undefined) {
            TagManagerService.addDataLayer({
              event: 'pokaz_oferty_check',
              eventCategory: 'strona_kalkulacji',
              eventAction: 'pokaz_oferty_check',
              eventLabel: selectedScope,
              numerPorownania: quotationNumber,
              typ_wynikow: isPolandSelected ? 'polska' : 'normal',
            });
          }
        }

        if (
          action.meta.form === FormsEnum.TravelResultsFilters &&
          action.meta.field === FieldsEnum.Sort
        ) {
          const quotationNumber = selectTravelQuotationNumber(getState());
          const eventLabel = sortingCriterionDictionaryTravel.find(
            (entry) => entry.key === action.payload
          )?.value;

          TagManagerService.addDataLayer({
            event: 'sortowanie_select',
            numer_porownania: quotationNumber,
            sposob_sortowania: eventLabel,
            typ_wynikow: isPolandSelected ? 'polska' : 'normal',
          });
        }

        if (
          action.meta.form === FormsEnum.TravelResultsFilters &&
          action.meta.field === FieldsEnum.Scopes
        ) {
          const quotationNumber = selectTravelQuotationNumber(getState());
          const { scopes: oldScopesFilterValue = [] } =
            getTravelFilters(getState()) || {};
          const { payload: newScopesFilterValue } = action;
          const selectedScope = newScopesFilterValue.find(
            (scope: InsuranceTypeEnum) => !oldScopesFilterValue.includes(scope)
          );

          if (selectedScope !== undefined) {
            TagManagerService.addDataLayer({
              event: 'pokaz_oferty_check',
              numer_porownania: quotationNumber,
              wybrana_opcja: selectedScope,
              typ_wynikow: isPolandSelected ? 'polska' : 'normal',
            });
          }
        }

        return next(action);
      }
      case 'SET_QUOTATION': {
        const prevQuotation = getState().quotation;

        if (prevQuotation.vehicleType) {
          const {
            id: quotationId,
            number: quotationNumber,
            vehicleType,
          } = action.payload;
          const quotationChanged = prevQuotation.id !== quotationId;

          if (quotationChanged) {
            addDataLayerFlowTypeEvent({
              isDefault: false,
              quotationNumber,
              quotationId,
              vehicleType,
            });
          }
        }

        addAbTestsDataLayer(getState, action.payload);
        return next(action);
      }
      case 'STORE_RESULTS':
        addAbTestsDataLayer(getState, action.payload);
        return next(action);
      case 'STORE_TRAVEL_RESULTS':
        addAbTestsDataLayer(getState, action.payload);
        return next(action);
      default:
        return next(action);
    }
  };
};
