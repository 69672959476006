import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isValidHouseNumber(houseNumber: string): boolean {
  const reg = /^$|^\d+[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ]*(\/\d+[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ]*)?$/;

  return !houseNumber || reg.test(houseNumber);
}

export default function validateHouseNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_house_number',
    valid: isValidHouseNumber(data.value.toString()),
  };
}
