import { AxiosPromise } from 'axios';

import { ApiService } from 'app/services/api.service';
import { CheckoutRecalculationResponse } from 'app/shared/interfaces/CheckoutRecalculationResponse.interface';
import { RecoveryInterface } from 'app/components/RecoveryRequestForm/RecoveryRequestForm.interfaces';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import { UpsaleRiskDataInterface } from 'app/shared/interfaces/UpsaleRiskData.interface';
import { UpsaleRecalculationInterface } from 'app/shared/interfaces/UpsaleRecalculation.interface';

export const TravelQuotationResultsService = {
  url: 'travel-insurances/quotations/quotation-results',

  startRecalculation(
    resultId: string,
    offerId: string,
    data: object
  ): AxiosPromise<void> {
    return ApiService.retryablePost(
      `${this.url}/${resultId}/offers/${offerId}/recalculations`,
      data
    );
  },

  getRecalculationStatus(
    resultId: string,
    offerId: string
  ): AxiosPromise<CheckoutRecalculationResponse> {
    return ApiService.retryableGet(
      `${this.url}/${resultId}/offers/${offerId}/status`
    );
  },

  sendOfferViaEmail(
    resultId: string,
    offerId: string,
    data?: RecoveryInterface
  ): AxiosPromise<void> {
    return ApiService.post(
      `${this.url}/${resultId}/offers/${offerId}/email-requests`,
      data
    );
  },

  getOffer(
    resultId: string,
    offerId: TravelOfferInterface['id']
  ): AxiosPromise<TravelOfferInterface> {
    return ApiService.retryableGet(`${this.url}/${resultId}/offers/${offerId}`);
  },

  updateUpsaleRisk({
    offerId,
    resultId,
    data,
  }: UpsaleRiskDataInterface): AxiosPromise<TravelOfferInterface> {
    return ApiService.patch(
      `${this.url}/${resultId}/offers/${offerId}/upsaleRisks`,
      data
    );
  },

  triggerRecalculationBeforeUpsale({
    offerId,
    resultId,
    data,
  }: UpsaleRecalculationInterface): AxiosPromise<void> {
    return ApiService.post(
      `${this.url}/${resultId}/offers/${offerId}/recalculationsBeforeUpsale`,
      data
    );
  },

  triggerRecalculationAfterUpsale({
    offerId,
    resultId,
    data,
  }: UpsaleRecalculationInterface): AxiosPromise<void> {
    return ApiService.post(
      `${this.url}/${resultId}/offers/${offerId}/recalculationsAfterUpsale`,
      data
    );
  },
};
