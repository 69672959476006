export enum LeasingLeadEnum {
  CarMileageInlineTooltip = 'tys. km',
  CarMileageLabel = 'Stan licznika w tys. km',
  CarRegistrationNumberLabel = 'Numer rejestracyjny',
  CarRegistrationNumberNotKnownLabel = 'Nie znam nr rejestracyjnego',
  CarType = 'Rodzaj pojazdu',
  CarUsage = 'Użytkowanie samochodu',
  CheckAllScopes = 'Zaznacz wszystkie ubezpieczenia, które Cię interesują.',
  FieldNotRequired = 'Pole nieobowiązkowe',
  FieldObfuscatedMessage = 'Dla bezpieczeństwa Twoich danych, ukryliśmy to pole.',
  FirstRegistrationDateCantBeBeforeProdYear = 'Data pierwszej rejestracji nie może być wcześniejsza niż rok produkcji',
  FirstRegistrationDateCantBeInFuture = 'Data pierwszej rejestracji nie może być w przyszłości',
  FirstRegistrationDateCantBeToLate = 'Data pierwszej rejestracji nie może być późniejsza niż 3 lata od roku produkcji',
  FirstRegistrationDateLabel = 'Data pierwszej rejestracji',
  InsuranceStartDate = 'Start ubezpieczenia',
  IsLeased = 'Czy samochód jest w leasingu?',
  LesseeData = 'Dane leasingobiorcy',
  MjmServicesConsent = 'Wyrażam zgodę na przekazanie podanych przeze mnie danych oraz na kontakt telefoniczny w&nbsp;celu przedstawienia oferty ubezpieczeniowej do spółki MJM Services Sp.&nbsp;z&nbsp;o.o., z&nbsp;siedzibą przy ul.&nbsp;Klimczaka&nbsp;1, 02-797 Warszawa, która będzie administratorem tych danych.\n\nW dowolnym momencie mogę zapoznać się z&nbsp;pełną informacją o&nbsp;przetwarzaniu danych osobowych na stronie <a href="https://mjmservices.pl/pl/wazneinformacje/rodo" target="_blank">RODO - Ważne informacje - MJM Services</a>.',
  ModalTitle = 'Zamów rozmowę, aby poznać oferty dla auta w leasingu!',
  NipNumber = 'Numer NIP',
  OwnerBirthDateLabel = 'Data urodzenia właściciela',
  RegistrationNumberNotKnownWarningMessage = 'Dzięki podaniu nr rejestracyjnego jesteśmy w stanie wyliczyć dokładniejszą składkę.',
  SelectCarType = 'Wybierz rodzaj pojazdu',
  SuccessMessage = 'Dziękujemy za zamówienie rozmowy!\nNasz doradca wkrótce się z Tobą skontaktuje.',
  VehicleDetails = 'Szczegóły pojazdu',
  Version = 'Wersja',
  YourInsurance = 'Twoje ubezpieczenie',
}
