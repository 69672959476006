import moment from 'moment';
import get from 'lodash-es/get';

import { getBirthdayStringByPesel } from './index';
import { getQuotation } from 'app/services/businessFunctionHelpers';
import { ModelPathEnum } from 'app/shared/enums';

const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export interface IsPeselMatchingLicenseDateFunctionParams {
  pesel: string;
  driverLicenseYear: string | number | undefined;
  driverLicenseMonth: string | number | undefined | null;
  noDriverLicenseModelPath?: ModelPathEnum;
}

export function isPeselMatchingLicenseDate({
  pesel,
  driverLicenseYear = 0,
  driverLicenseMonth = 0,
  noDriverLicenseModelPath,
}: IsPeselMatchingLicenseDateFunctionParams) {
  const birthday = getBirthdayStringByPesel(pesel) || '';
  const momentBirthday = moment(birthday);
  const quotation = getQuotation() || {};

  const currentDate = moment();
  const yearsDiff = currentDate.diff(momentBirthday, 'years');
  const isAdult = yearsDiff >= 18;

  if (!isAdult) {
    return true;
  }

  if (noDriverLicenseModelPath) {
    const noDrivingLicense = get(quotation, noDriverLicenseModelPath);

    if (noDrivingLicense) {
      return true;
    }
  }

  const licenseMonth = !driverLicenseMonth
    ? momentBirthday.month() + 1
    : months.findIndex((i) => i === driverLicenseMonth) + 1;

  const minAgeToGetDriverLicense = () => {
    const birthdayYear = momentBirthday.year();
    if (birthdayYear <= 1980) {
      return 16;
    } else if (birthdayYear <= 1985) {
      return 17;
    } else {
      return 18;
    }
  };
  const momentlicenseDate = moment(
    `${momentBirthday.date()}-${licenseMonth}-${+driverLicenseYear}`,
    'DD-MM-YYYY'
  );
  const yearsBetweenLicenseAndBirthday = momentlicenseDate.diff(
    momentBirthday,
    'years'
  );
  const isAgeValid = !(
    yearsBetweenLicenseAndBirthday < minAgeToGetDriverLicense()
  );

  return isAgeValid;
}
