import React from 'react';
import classNames from 'clsx';

import { LabelEnum, RouteEnum } from 'app/shared/enums';
import { useUser } from 'app/shared/hooks/useUser';
import { AppMenuButton } from 'app/components/AppMenuButton';
import { agencyMode } from 'app/services/env.service';
import { useLogout } from 'app/shared/hooks/useLogout';
import { useStyles } from './LoggedUserMenu.styles';

const LoggedUserMenu = () => {
  const classes = useStyles();

  const logout = useLogout(RouteEnum.CALCULATION_FORM);
  const { isOperator, userRoute } = useUser();

  return (
    <div className={classes.root} data-cy="logged-user-menu">
      {!isOperator && !agencyMode && (
        <AppMenuButton
          title={LabelEnum.MyAccount}
          color={'primary'}
          variant={'outlined'}
          to={{
            pathname: userRoute,
          }}
          data-cy="my-account"
        />
      )}
      <div
        onClick={logout}
        className={classNames(classes.logoutButton, {
          [classes.logoutButtonForOperator]: isOperator,
        })}
        data-cy="logout"
      >
        {LabelEnum.LogOut}
      </div>
    </div>
  );
};

export default LoggedUserMenu;
