import produce from 'immer';
import set from 'lodash-es/set';

import { TravelQuotationState } from './state.interface';
import { ActionType } from 'app/store/actionTypes';
import { QuotationActionsType } from './actions';
import { PromotionActionsType } from 'app/store/data/promotion';

const initialState: TravelQuotationState = {
  id: '',
  number: '',
  status: undefined,
};

const quotationReducer = (
  state: TravelQuotationState = initialState,
  action: QuotationActionsType | PromotionActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_TRAVEL_QUOTATION:
        return { ...action.payload };
      case ActionType.CLEAR_TRAVEL_QUOTATION:
        return initialState;
      case ActionType.SET_TRAVEL_QUOTATION_STATUS:
        draft.status = action.payload;
        return;
      case ActionType.SET_TRAVEL_QUOTATION_QUESTION:
        set(draft, action.payload.path, action.payload.value);
        return;
      case ActionType.SET_TRAVEL_QUOTATION_EMAIL_ADDRESS:
        draft.emailAddress = action.payload;
        return;
      default:
        return state;
    }
  });

export default quotationReducer;
