import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isValidWord(word: string): boolean {
  const reg = /\*/;
  return !reg.test(word);
}

export default function validateWord(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_word',
    valid: isValidWord(data.value.toString()),
  };
}
