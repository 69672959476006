import * as React from 'react';
import classNames from 'clsx';
import isString from 'lodash-es/isString';

import { Icon } from 'app/components/Icon';
import { LinkProps } from './Link.interfaces';

class Link extends React.Component<LinkProps> {
  private get icon() {
    const { icon, classes } = this.props;

    if (!icon) {
      return null;
    }
    if (icon.name) {
      return (
        <div className={classes.icon}>
          <Icon name={icon.name} />
        </div>
      );
    }
    if (icon.component) {
      return <icon.component className={classes.iconMdi} />;
    }
    return null;
  }

  render() {
    const {
      classes,
      title,
      className,
      leftIcon: LeftIcon,
      dataCy,
    } = this.props;

    return (
      <span
        className={classNames(classes.root, className)}
        onClick={this.handleClick}
        data-cy={dataCy}
      >
        {this.icon}
        {LeftIcon && <LeftIcon className={classes.icon} />}
        {title}
      </span>
    );
  }

  private handleClick = () => {
    const { onClick, to, history } = this.props;

    if (to) {
      const pathname = isString(to) ? to : to.pathname;
      const state = isString(to) ? {} : to.state;

      if (pathname) {
        history.push(pathname, state);
      }
    }

    if (onClick) {
      onClick();
    }
  };
}

export default Link;
