import get from 'lodash-es/get';
import isString from 'lodash-es/isString';

import { ApiService } from 'app/services/api.service';
import { AxiosPromise, ResponseType } from 'axios';
import { OAuthEnum } from 'app/shared/enums';
import getWindowConfig from 'app/shared/helpers/getWindowConfig';

const axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  responseType: 'text' as ResponseType,
};

const getRegisterRedirection = (
  oAuthProvider: OAuthEnum
): AxiosPromise<string> => {
  return ApiService.get(`oauth2/${oAuthProvider}`, axiosConfig);
};

const getLoginRedirection = (oAuthProvider: OAuthEnum) => {
  return ApiService.get(`oauth2/${oAuthProvider}`, axiosConfig);
};

const wasInitialized = (win: Window) =>
  win && !win.location.href.includes('about:');

const openNewWindow = (): Window | null => {
  const win = window.open('', '', getWindowConfig());

  if (!win) {
    return null;
  }

  const reopen = (window: Window) => {
    window.close();
    return openNewWindow();
  };

  try {
    if (wasInitialized(win)) {
      return reopen(win);
    }
  } catch (e) {
    return reopen(win);
  }

  win.focus();
  return win;
};

const wasRedirected = (win: Window) =>
  win.location.href && win.location.href.includes('oauth2');

const getError = (win: Window) => {
  const bodyContent = get(win, 'document.body.textContent');
  const jsonContent = get(win, 'JSONView.json.data');
  const content = bodyContent || jsonContent;
  if (content === null) {
    return content;
  }
  try {
    console.error('fb login error', content);
    return JSON.parse(content.match(/{.*?}/)[0]);
  } catch (e) {
    console.error('parse error', e, content);
    if (isString(content)) {
      return content;
    }
    return null;
  }
};

const handleRedirection = (win: Window) =>
  new Promise((resolve, reject) => {
    let checkConnect: number;
    const checkIfRedirected = () => {
      if (win.closed) {
        clearInterval(checkConnect);
        return reject({ status: 'CLOSED' });
      }
      try {
        if (wasRedirected(win)) {
          clearInterval(checkConnect);
          const error = getError(win);
          win.close();

          if (error) {
            return reject(error);
          }
          return resolve();
        }
        // tslint:disable-next-line:no-empty
      } catch (e) {}
    };
    checkConnect = window.setInterval(checkIfRedirected, 1000);
  });

export const registerOAuth = (oAuthProvider: OAuthEnum) => {
  const win = openNewWindow();
  if (!win) {
    return Promise.reject('No window');
  }

  getRegisterRedirection(oAuthProvider).then(({ data: responseUrl }) => {
    win.location.replace(responseUrl);
  });
  return handleRedirection(win);
};

export const loginOAuth = (oAuthProvider: OAuthEnum) => {
  const win = openNewWindow();
  if (!win) {
    return Promise.reject('No window');
  }
  getLoginRedirection(oAuthProvider).then(({ data: responseUrl }) =>
    win.location.replace(responseUrl)
  );

  return handleRedirection(win);
};
