import cloneDeep from 'lodash-es/cloneDeep';
import findIndex from 'lodash-es/findIndex';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import remove from 'lodash-es/remove';
import includes from 'lodash-es/includes';

import produce, { Draft } from 'immer';

import { ActionType } from 'app/store/actionTypes';
import {
  MessageSetActionPayload,
  QuestionMessageAddActionPayload,
  VisibilitySetActionPayload,
} from './actionPayload.interfaces';
import {
  FormConfigState,
  FormError,
  FormsConfigState,
  Message,
} from './state.interfaces';
import {
  getQuestionKeyFromMessageKey,
  getSuffixFromMessageKey,
} from 'app/services/validation.service';
import { FormsEnum, MessageTypeEnum } from 'app/shared/enums';
import {
  makeFieldValidation,
  validateField,
} from './validation/formValidation';
import { FormSectionInterface } from 'app/shared/interfaces/FormSection.interface';
import { FormScreenInterface } from 'app/shared/interfaces/FormScreen.interface';
import { FormsConfigActionsType } from './actions';
import setupValidatorForType from './validation/setupValidatorForType';
import { selectIsQuestionVisible } from 'app/store/selectors/isQuestionVisible';
import { FormConfig } from 'app/shared/interfaces/FormConfig.interface';

export const setMessage = (
  draft: Draft<FormsConfigState>,
  { formName, message }: MessageSetActionPayload
) => (draft[formName].messages[message.key] = message);

export const addQuestionMessage = (
  draft: Draft<FormsConfigState>,
  { formName, questionKey, messageKey }: QuestionMessageAddActionPayload
) => draft[formName].questions[questionKey].messages.push(messageKey);

export const clearQuestionErrors = (
  state: Draft<FormsConfigState>,
  questionKey: string,
  formName: FormsEnum
) => {
  const messages = state[formName].messages;

  const question = state[formName].questions;
  const errors = state[formName].errors;

  for (let messageKey in messages) {
    if (messages.hasOwnProperty(messageKey)) {
      const message = messages[messageKey];

      if (
        (getQuestionKeyFromMessageKey(message.key) === questionKey &&
          message.type === MessageTypeEnum.Danger) ||
        includes(question.messages, messageKey)
      ) {
        message.visible = false;
      }
    }
  }

  remove(errors, (error: FormError) => error.key === questionKey);
};

export const setMessageVisibility = (
  state: Draft<FormsConfigState>,
  { formName, key, visible }: VisibilitySetActionPayload
) => {
  const message = state[formName].messages[key];
  if (!message) {
    console.warn(
      `Message not in the form. Can't set message visible: ${visible} ${formName}, ${key}`
    );
    return;
  }
  const suffix = getSuffixFromMessageKey(message.key);
  const questionIdsWithMessage = (message.questions || []).map(
    (questionKey: string) => ({
      key: questionKey,
      type: suffix,
    })
  );
  let errors = state[formName].errors;

  if (message.type === MessageTypeEnum.Danger) {
    questionIdsWithMessage.forEach((error: FormError) => {
      const errorIndex = findIndex(errors, (e: FormError) => {
        return e.key === error.key && e.type === suffix;
      });
      const hasSameError = errorIndex !== -1;

      if (!hasSameError && visible) {
        errors.push(error);
      }
      if (hasSameError && !visible) {
        errors.splice(errorIndex, 1);
      }
    });
  }
  state[formName].messages[message.key].visible = visible;
};

const getInitialFormConfigState = (): FormConfigState => ({
  screens: {},
  sections: {},
  questions: {},
  messages: {},
  dictionaries: {},
  hiddenDictionaryEntries: [],
  activeScreen: 0,
  errors: [],
});

const initialState: FormsConfigState = {
  calculationForm: getInitialFormConfigState(),
  checkoutForm: getInitialFormConfigState(),
  offerForm: getInitialFormConfigState(),
  recoveryRequestForm: getInitialFormConfigState(),
  checkoutPaymentForm: getInitialFormConfigState(),
  checkoutPaymentAlertForm: getInitialFormConfigState(),
  callbackForm: getInitialFormConfigState(),
  completeDataForm: getInitialFormConfigState(),
  leadsForm: getInitialFormConfigState(),
  operatorLeadsForm: getInitialFormConfigState(),
  recommendationForm: getInitialFormConfigState(),
  getBonusForm: getInitialFormConfigState(),
  [FormsEnum.DisableNotificationsForm]: getInitialFormConfigState(),
  [FormsEnum.TravelForm]: getInitialFormConfigState(),
  [FormsEnum.TravelOfferForm]: getInitialFormConfigState(),
  [FormsEnum.TravelCheckoutForm]: getInitialFormConfigState(),
  [FormsEnum.TravelPaymentForm]: getInitialFormConfigState(),
  [FormsEnum.TerminationGeneratorForm]: getInitialFormConfigState(),
  [FormsEnum.OperatorLeadsNewCarForm]: getInitialFormConfigState(),
  [FormsEnum.SkpRegistrationForm]: getInitialFormConfigState(),
  [FormsEnum.RenewalOfProposalForm]: getInitialFormConfigState(),
  [FormsEnum.Renewal]: getInitialFormConfigState(),
  [FormsEnum.CompareCepikDataForm]: getInitialFormConfigState(),
  overwrittenFormConfig: { config: {} as FormConfig, shouldUpdate: false },
  previousProductionYear: undefined,
  vehicleTypeChanged: undefined,
};

const formsConfigReducer = (
  state: FormsConfigState = initialState,
  action: FormsConfigActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_FORM_CONFIG: {
        const { formConfig, name } = action.payload;

        const screensConfig = formConfig.screens;
        let screens = {};
        let sections = {};
        let questions = {};
        let messages = {};

        screensConfig.forEach((screen) => {
          screen.sections.forEach((section) => {
            section.questions.forEach((question) => {
              (question.messages || []).forEach((message) => {
                messages[message.key] = {
                  ...message,
                  questions: [question.key],
                };
              });
              questions[question.key] = {
                ...question,
                defaultVisibility: question.visible,
                messages: (question.messages || []).map(({ key }) => key),
                disabled: !question.editable,
              };
            });
            sections[section.key] = {
              ...section,
              questions: section.questions.map(({ key }) => key),
            };
          });
          screens[screen.key] = {
            ...screen,
            sections: screen.sections.map(({ key }) => key),
          };
        });

        draft[name] = {
          ...state[name],
          screens,
          sections,
          questions,
          messages: {
            // TODO find out if this is needed
            ...state[name].messages,
            ...messages,
          },
        };

        return;
      }
      case ActionType.CLEAR_FORM_CONFIG: {
        const formName = action.payload;

        draft[formName] = initialState[formName];
        return;
      }
      case ActionType.SET_QUESTION_VISIBILITY: {
        const { formName, key, visible } = action.payload;
        const questionValue = state[formName].questions[key];
        const isValid = isEmpty(state[formName].errors);
        if (isValid && questionValue && questionValue.visible === visible) {
          return state;
        }

        const question = draft[formName].questions[key];
        draft[formName].errors = [];

        if (!question) {
          console.warn('Trying to set visible on undefined question: ', key);
        } else {
          question.visible = visible;
        }
        return;
      }
      case ActionType.SET_QUESTION_IGNORE_ANONYMIZED: {
        const { formName, key, ignoreAnonymized } = action.payload;

        const question = draft[formName].questions[key];
        question.ignoreAnonymized = ignoreAnonymized;
        return;
      }
      case ActionType.SET_QUESTION_LABEL: {
        const { formName, key, label } = action.payload;

        const question = draft[formName].questions[key];
        question.label = label;
        return;
      }
      case ActionType.SET_QUESTION_PATCHABLE: {
        const { formName, key, shouldPatch } = action.payload;

        const question = draft[formName].questions[key];
        draft[formName].errors = [];
        question.shouldPatch = shouldPatch;
        return;
      }
      case ActionType.SET_QUESTION_CLEARABLE_ON_HIDE: {
        const { questionKey, formName, clearOnHide } = action.payload;

        const question = draft[formName].questions[questionKey];
        question.clearOnHide = clearOnHide;
        return;
      }

      case ActionType.SET_SECTION_VISIBILITY: {
        const { formName, key, visible } = action.payload;

        if (draft[formName].sections[key].visible === visible) {
          return;
        }

        draft[formName].sections[key].visible = visible;
        draft[formName].sections[key].questions.forEach(
          (questionKey: string) => {
            const question = draft[formName].questions[questionKey];
            const messages = draft[formName].messages;

            question.visible = visible ? question.defaultVisibility : false;
            Object.keys(messages).forEach((messageKey: string) => {
              const message = messages[messageKey];

              if (
                message.questions &&
                message.questions.includes(questionKey) &&
                !visible
              ) {
                message.visible = false;
              }
            });
          }
        );
        return;
      }
      case ActionType.SET_SECTION_LABEL: {
        const { formName, key, label } = action.payload;

        draft[formName].sections[key].label = label;
        return;
      }
      case ActionType.SET_SECTION_SUBLABEL: {
        const { formName, key, subLabel } = action.payload;

        draft[formName].sections[key].subLabel = subLabel;
        return;
      }
      case ActionType.SET_SECTION_MESSAGE_VISIBILITY: {
        const { formName, key, visible } = action.payload;
        const sections = draft[formName].sections;
        Object.keys(sections).forEach((sectionKey: string) => {
          const section = sections[sectionKey];
          if (section) {
            section.messages = section.messages || [];
            section.messages.forEach((message: Message) => {
              if (message.key === key) {
                message.visible = visible;
              }
            });
          }
        });
        return;
      }
      case ActionType.SET_MESSAGE_VISIBILITY: {
        const { formName, key, visible } = action.payload;
        setMessageVisibility(draft, { formName, key, visible });
        return;
      }
      case ActionType.SET_SHOULD_AUTO_FILL: {
        const { formName, shouldAutofill } = action.payload;

        draft[formName].shouldAutofill = shouldAutofill;
        return;
      }
      case ActionType.SET_DICTIONARY: {
        const { formName, dictionaryKey, dictionary } = action.payload;

        draft[formName].dictionaries[dictionaryKey] = dictionary;
        return;
      }
      case ActionType.SET_DICTIONARIES: {
        action.payload.dictionaries.forEach(({ dictionaryKey, dictionary }) => {
          draft[action.payload.formName].dictionaries[
            dictionaryKey
          ] = dictionary;
        });
        return;
      }
      case ActionType.SET_DICTIONARY_ENTRY_VISIBILITY: {
        const { formName, dictionaryKey, entryKey, visible } = action.payload;
        const dictionaryEntryKey = `${dictionaryKey}_${entryKey}`;
        const entryIndex = state[formName].hiddenDictionaryEntries.indexOf(
          dictionaryEntryKey
        );
        const isEntryAlreadyHidden = entryIndex >= 0;

        if (visible) {
          if (isEntryAlreadyHidden) {
            draft[formName].hiddenDictionaryEntries.splice(entryIndex, 1);
          }
        } else {
          if (!isEntryAlreadyHidden) {
            draft[formName].hiddenDictionaryEntries.push(dictionaryEntryKey);
          }
        }
        return;
      }
      case ActionType.SET_DICTIONARY_ENTRIES_VISIBILITY: {
        const { formName, dictionaryKey, entriesKey, visible } = action.payload;

        if (visible) {
          const dictionaryEntriesToShow = (key: string) =>
            !includes(
              entriesKey.map((key) => `${dictionaryKey}_${key}`),
              key
            );

          draft[formName].hiddenDictionaryEntries = draft[
            formName
          ].hiddenDictionaryEntries.filter(dictionaryEntriesToShow);
        } else {
          const notHiddenYet = (key: string) =>
            !includes(draft[formName].hiddenDictionaryEntries, key);

          const dictionaryEntriesToHide = entriesKey
            .map((key) => `${dictionaryKey}_${key}`)
            .filter(notHiddenYet);

          draft[formName].hiddenDictionaryEntries.push(
            ...dictionaryEntriesToHide
          );
        }
        return;
      }
      case ActionType.CLEAR_HIDDEN_DICTIONARY_ENTRIES: {
        const { formName, dictionaryKey } = action.payload;

        draft[formName].hiddenDictionaryEntries = draft[
          formName
        ].hiddenDictionaryEntries.filter(
          (key: string) => !includes(key, dictionaryKey)
        );
        return;
      }
      case ActionType.SET_QUESTION_MIN_DATE: {
        const { formName, key, minDate } = action.payload;
        const question = draft[formName].questions[key];
        question.minDate = minDate;
        return;
      }
      case ActionType.SET_QUESTION_MAX_DATE: {
        const { formName, key, maxDate } = action.payload;

        const question = draft[formName].questions[key];
        question.maxDate = maxDate;
        return;
      }
      case ActionType.SET_QUESTION_INITIAL_FOCUSED_DATE: {
        const { formName, key, initialFocusedDate } = action.payload;

        const question = draft[formName].questions[key];
        question.initialFocusedDate = initialFocusedDate;
        return;
      }
      case ActionType.SET_QUESTION_AVAILABILITY: {
        const { formName, key, enabled } = action.payload;

        draft[formName].questions[key].disabled = !enabled;
        return;
      }
      case ActionType.SET_SECTION_MESSAGE: {
        const { formName, section, message } = action.payload;
        const sectionMessages = state[formName].sections[section].messages;
        const messageIndex = sectionMessages.findIndex(
          (msg: Message) => msg.key === message.key
        );

        if (messageIndex === -1) {
          draft[formName].sections[section].messages.push(message);
        } else {
          draft[formName].sections[section].messages[messageIndex] = message;
        }
        return;
      }
      case ActionType.SET_MESSAGE: {
        const { formName, message } = action.payload;

        setMessage(draft, { formName, message });
        return;
      }
      case ActionType.CLEAR_QUESTION_ERRORS: {
        const { formName, key } = action.payload;

        clearQuestionErrors(draft, key, formName);
        return;
      }
      case ActionType.CLEAR_FORM_ERRORS: {
        const { formName } = action.payload;
        const messages = cloneDeep(state[formName].messages);

        for (let key in messages) {
          if (messages.hasOwnProperty(key)) {
            const message = messages[key];

            message.visible = false;
          }
        }

        return {
          ...state,
          messages,
        };
      }
      case ActionType.ADD_QUESTION_MESSAGE: {
        const { formName, questionKey, messageKey } = action.payload;

        addQuestionMessage(draft, { formName, questionKey, messageKey });
        return;
      }
      case ActionType.SET_QUESTION_READABILITY: {
        const { formName, key, readonly } = action.payload;

        draft[formName].questions[key].readonly = readonly;
        return;
      }
      case ActionType.SET_QUESTION_REQUIRABILITY: {
        const { formName, key, required } = action.payload;

        (draft[formName].questions[key] || {}).required = required;
        return;
      }
      case ActionType.SET_FORM_ACTIVE_SCREEN: {
        const { screen, formName } = action.payload;

        draft[formName].activeScreen = screen;
        return;
      }
      case ActionType.SET_FORM_STRUCTURE: {
        const { formName, structure } = action.payload;

        draft[formName].formStructure = structure;
        return;
      }
      case ActionType.SET_HIDDEN_DICTIONARIES_ENTRIES: {
        const { formName, hiddenDictionaryEntries } = action.payload;
        draft[formName].hiddenDictionaryEntries = hiddenDictionaryEntries;
        return;
      }
      case ActionType.SETUP_VALIDATOR_FOR_TYPE: {
        setupValidatorForType(state, action, draft);
        return;
      }
      case ActionType.VALIDATE_FIELD: {
        validateField(state, action, draft);
        return;
      }
      case ActionType.VALIDATE_FORM: {
        const { rootState, formName, formValues } = action.payload;

        const activeScreen = state[formName].activeScreen + 1;
        Object.values(state[formName].screens)
          .slice(0, activeScreen)
          .forEach((screen: FormScreenInterface) => {
            screen.sections
              .map((sectionId: string) => state[formName].sections[sectionId])
              .filter((section: FormSectionInterface) => section.visible)
              .forEach((section: FormSectionInterface) => {
                section.questions.forEach((questionKey: string) => {
                  const question = state[formName].questions[questionKey];
                  const fieldValue = get(formValues, question.key);
                  const isAnonymized =
                    question.ignoreAnonymized &&
                    fieldValue &&
                    fieldValue.toString().includes('***');
                  if (
                    question &&
                    selectIsQuestionVisible({
                      state: rootState,
                      formName,
                      questionKey: question.key,
                    }) &&
                    !question.disabled &&
                    !isAnonymized
                  ) {
                    const validateData = {
                      questionKey: question.key,
                      formName,
                      value: fieldValue,
                    };
                    makeFieldValidation(state, validateData)(draft);
                  }
                });
              });
          });
        return;
      }
      case ActionType.SET_OVERWRITTEN_FORM_CONFIG: {
        draft.overwrittenFormConfig = action.payload;
        return;
      }
      case ActionType.SET_QUESTION_DEPENDS_ON: {
        const { formName, key, dependsOn } = action.payload;
        const questionValue = state[formName].questions[key];
        if (questionValue.dependsOn[0] === dependsOn[0]) {
          return state;
        }
        const question = draft[formName].questions[key];

        if (!question) {
          console.warn('Trying to set dependsOn on undefined question: ', key);
        } else {
          question.dependsOn = dependsOn;
        }
        return;
      }
      case ActionType.SET_PREVIOUS_PRODUCTION_YEAR:
        draft.previousProductionYear = action.payload;
        return;
      case ActionType.SET_VEHICLE_TYPE_CHANGED:
        draft.vehicleTypeChanged = action.payload;
        return;
      default:
        return;
    }
  });

export default formsConfigReducer;
