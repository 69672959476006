import isNil from 'lodash-es/isNil';

import { RootState } from 'app/store/rootReducer';
import { FieldsEnum, FormsEnum } from 'app/shared/enums';
import { selectValueIfFieldVisible } from 'app/store/selectors/selectValueIfFieldVisible';

export const createIsCoownerOcHistoryLengthVisible = (
  state: RootState,
  formName: FormsEnum
) => (coownerInsuranceHistorySameAsOwnerQuestionKey: FieldsEnum) => {
  const isCoownerInsuranceHistorySameAsOwner = selectValueIfFieldVisible({
    state,
    formName,
    questionKey: coownerInsuranceHistorySameAsOwnerQuestionKey,
  });

  return isNil(isCoownerInsuranceHistorySameAsOwner)
    ? false
    : !isCoownerInsuranceHistorySameAsOwner;
};
