import { LabelEnum, ValidationErrorMessagesEnum } from 'app/shared/enums';

const password = (value: string) => {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  const whitespaceRe = /^\s+|\s+$/;

  if (whitespaceRe.test(value)) {
    return ValidationErrorMessagesEnum.PasswordWhitespaceError;
  }

  return re.test(value) ? undefined : LabelEnum.PasswordNotStrongEnough;
};

export default password;
