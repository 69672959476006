declare global {
  interface Window {
    opera: string;
    MSStream: unknown;
    safari?: unknown;
  }
}
export default function isSafariOrIOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isDesktopSafari = window.safari !== undefined;

  // iPad detection from: https://stackoverflow.com/a/58017456
  const isNewIpad =
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  const isMobileIos =
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || isNewIpad;

  return isDesktopSafari || isMobileIos;
}
