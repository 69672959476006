import React, { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { reCaptchaSiteKey } from 'app/services/env.service';
import { GoogleReCaptchaProps } from './GoogleReCaptcha.interface';

export default function GoogleReCaptcha({ children }: GoogleReCaptchaProps) {
  const [clickedOrScrolled, setClickedOrScrolled] = useState(false);

  const initReCaptcha = useCallback(() => {
    setClickedOrScrolled(true);
  }, [setClickedOrScrolled]);

  useEffect(() => {
    if (reCaptchaSiteKey && !clickedOrScrolled) {
      document.addEventListener('click', initReCaptcha, false);
      document.addEventListener('scroll', initReCaptcha, false);
    } else {
      document.removeEventListener('click', initReCaptcha, false);
      document.removeEventListener('scroll', initReCaptcha, false);
    }

    return () => {
      document.removeEventListener('click', initReCaptcha, false);
      document.removeEventListener('scroll', initReCaptcha, false);
    };
  }, [clickedOrScrolled, initReCaptcha]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={clickedOrScrolled ? reCaptchaSiteKey : undefined}
      scriptProps={{
        async: true,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}
