import { FormState, formValueSelector, getFormValues } from 'redux-form';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';

import moment from 'moment';
import { createSelector } from 'reselect';

import { RootState } from 'app/store/rootReducer';
import { FormsEnum } from 'app/shared/enums';
import { OfferFiltersFormInterface } from './state.interfaces';
import { Address } from 'app/shared/interfaces/Address.interface';
import { getBirthdayStringByPesel } from 'app/services/pesel.service';

export const getFormVal = <T>(state: RootState, formName: FormsEnum) =>
  getFormValues(formName)(state) as T;

export const getFilters = (
  state: RootState
): OfferFiltersFormInterface | undefined =>
  getFormValues(FormsEnum.OfferFilters)(state) as OfferFiltersFormInterface;

export const getTravelFilters = (
  state: RootState
): OfferFiltersFormInterface | undefined =>
  getFormValues(FormsEnum.TravelResultsFilters)(
    state
  ) as OfferFiltersFormInterface;

const checkoutFormSelector = formValueSelector(FormsEnum.CheckoutForm);

const getAddressFromCheckoutForm = (state: RootState, path: string) => {
  return (checkoutFormSelector(state, path) || {}) as Address;
};

export const getCheckoutOwnerLegalAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'owner.legalAddress');

export const getCheckoutOwnerHomeAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'owner.homeAddress');

export const getCheckoutCoowner1LegalAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'coowners[1].legalAddress');

export const getCheckoutCoowner1HomeAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'coowners[1].homeAddress');

export const getCheckoutCoowner2LegalAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'coowners[2].legalAddress');

export const getCheckoutCoowner2HomeAddress = (state: RootState): Address =>
  getAddressFromCheckoutForm(state, 'coowners[2].homeAddress');

export const getParamValues = (
  state: RootState,
  formName: FormsEnum,
  paramNames: Array<string>
) => {
  const formValues = getFormValues(formName)(state);
  const calculationFormValues = getFormValues(FormsEnum.CalculationForm)(state);
  const paramValues: { [key: string]: string } = {};

  paramNames.forEach((paramName) => {
    if (paramName.includes(':')) {
      const [requestParamName, formParamName] = paramName.split(':');
      paramValues[requestParamName] = get(formValues, formParamName);
      if (
        formName === FormsEnum.CompareCepikDataForm &&
        (!paramValues[requestParamName] || isNil(paramValues[requestParamName]))
      ) {
        paramValues[requestParamName] = get(
          calculationFormValues,
          formParamName
        );
      }
    } else {
      paramValues[paramName] = get(formValues, paramName);
      if (
        formName === FormsEnum.CompareCepikDataForm &&
        (!paramValues[paramName] || isNil(paramValues[paramName]))
      ) {
        paramValues[paramName] = get(calculationFormValues, paramName);
      }
    }
  });

  if (
    Object.entries(paramValues).some(
      (value) => value === undefined || value === null
    )
  ) {
    return '{}';
  }
  return JSON.stringify(paramValues);
};

const getIsAdultByPesel = (state: RootState, peselField: string) => {
  const pesel = checkoutFormSelector(state, peselField);

  if (!pesel) {
    return false;
  }

  const birthdayString = getBirthdayStringByPesel(pesel);

  if (!birthdayString) {
    return false;
  }

  const birthday = moment(birthdayString);
  const yesterday = moment().subtract(1, 'days');
  const diffInYears = yesterday.diff(birthday, 'years');

  return diffInYears >= 18;
};

export const isOwnerAdult = (state: RootState) =>
  getIsAdultByPesel(state, 'owner.peselCheckoutForm');

export const isCoowner1Adult = (state: RootState) =>
  getIsAdultByPesel(state, 'coowners[1].peselCheckoutForm');

export const isCoowner2Adult = (state: RootState) =>
  getIsAdultByPesel(state, 'coowners[2].peselCheckoutForm');

const getFormState = (state: RootState) => state.form;

const getNamedFormState = (formName: FormsEnum) =>
  createSelector([getFormState], (formState = {}) => formState[formName]);

export const registeredFieldsSelector = (formName: FormsEnum) =>
  createSelector(
    [getNamedFormState(formName)],
    (namedFormState = {}): FormState['registeredFields'] =>
      namedFormState.registeredFields
  );
