import React from 'react';
import PhoneInTalk from 'mdi-material-ui/PhoneInTalk';
import classNames from 'clsx';

import { CallBoxProps } from './CallBox.interfaces';
import { LabelEnum } from 'app/shared/enums';
import { agencyMode, mainPhoneNumber } from 'app/services/env.service';
import { getPhoneNumberHref } from 'app/shared/helpers/getPhoneNumberHref';

const CallBox: React.FunctionComponent<CallBoxProps> = (
  { classes },
  className
) => {
  if (agencyMode) {
    return null;
  }

  return (
    <div className={classNames(classes.root, className)}>
      <PhoneInTalk className={classes.icon} />
      <div>
        <span className={classes.heading}>{LabelEnum.HaveQuestions}</span>
        <br />
        {LabelEnum.Call}
        {mainPhoneNumber && (
          <>
            {': '}
            <a
              className={classes.link}
              href={getPhoneNumberHref(mainPhoneNumber)}
            >
              {mainPhoneNumber}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default CallBox;
