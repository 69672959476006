import React from 'react';
import classNames from 'clsx';

import { FullScreenMenuProps } from './FullScreenMenu.interfaces';
import { CallBox } from 'app/components/CallBox';
import { SaveFormBox } from 'app/components/SaveFormBox';
import { Link } from 'app/components/Link';
import { LabelEnum } from 'app/shared/enums';

const FullScreenMenu: React.FunctionComponent<FullScreenMenuProps> = ({
  classes,
  items,
  onItemClick,
  quotationNumber,
  showCallbackBox,
  showQuotationNumber,
  showSaveFormBox,
}) => {
  return (
    <div className={classes.root}>
      {items.map((item) => (
        <Link
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }
            if (onItemClick) {
              onItemClick();
            }
          }}
          title={item.title}
          to={item.to}
          key={item.title}
          className={classes.item}
        />
      ))}
      {showCallbackBox && (
        <CallBox
          classes={{
            root: classNames(classes.noBorderRadius, {
              [classes.borderBottom]: !showSaveFormBox,
            }),
          }}
        />
      )}
      {showSaveFormBox && (
        <SaveFormBox
          classes={{
            root: classes.saveFormBoxRoot,
            heading: classes.saveFormBoxHeading,
          }}
        />
      )}
      {showQuotationNumber && (
        <div className={classes.quotationNumber}>
          {LabelEnum.ComparisonNumber}: {quotationNumber}
        </div>
      )}
    </div>
  );
};

export default FullScreenMenu;
