import React, { Suspense, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ViewsSwitchProps } from './ViewsSwitch.interfaces';
import { RouteEnum } from 'app/shared/enums';
import { Loader } from 'app/components/Loader';
import { DefaultAppRoute } from 'app/components/DefaultAppRoute';
import { FallbackModal } from 'app/components/FallbackModal';
import lazyWithReload from 'app/shared/helpers/lazyWithReload';
import retryImport from 'app/shared/helpers/retryImport';
import { ScrollToTop } from 'app/components/ScrollToTop';
import { uploadDocumentsActive } from 'app/services/env.service';
import { selectHasRequiredConsents } from 'app/store/data/user';

const CallMeBackView = lazyWithReload(() =>
  retryImport(() => import('app/views/CallMeBackView'))
);

const ConfirmConsentsModal = lazyWithReload(() =>
  retryImport(() => import('app/components/ConfirmConsentsModal'))
);

const OrderPayoffModal = lazyWithReload(() =>
  retryImport(() => import('app/components/OrderPayoffModal'))
);

const OfferDetailsView = lazyWithReload(() =>
  retryImport(() => import('app/views/OfferDetailsView'))
);

const WebToCaseModal = lazyWithReload(() =>
  retryImport(() => import('app/components/WebToCaseModal'))
);

const CompareCepikDataModal = lazyWithReload(() =>
  retryImport(() => import('app/components/CompareCepikDataModal'))
);

const ConvertedToApplication = lazyWithReload(() =>
  retryImport(() => import('app/components/ConvertedToApplication'))
);
const PaymentRedirection = lazyWithReload(() =>
  retryImport(() => import('app/views/PaymentRedirection'))
);
const LoginModal = lazyWithReload(() =>
  retryImport(() => import('app/components/LoginModal'))
);
const RegisterModal = lazyWithReload(() =>
  retryImport(() => import('app/components/RegisterModal'))
);
const ResendVerificationEmailModal = lazyWithReload(() =>
  retryImport(() => import('app/components/ResendVerificationEmailModal'))
);
const PasswordChangeModal = lazyWithReload(() =>
  retryImport(() => import('app/components/PasswordChangeModal'))
);
const SendConfirmModal = lazyWithReload(() =>
  retryImport(() => import('app/components/SendConfirmModal'))
);
const PasswordResetModal = lazyWithReload(() =>
  retryImport(() => import('app/components/PasswordResetModal'))
);
const ChangeBankTransferData = lazyWithReload(() =>
  retryImport(() => import('app/components/ConfirmBankAccountChange'))
);
const UserEditModal = lazyWithReload(() =>
  retryImport(() => import('app/components/UserEditModal'))
);
const ConsentsChangeModal = lazyWithReload(() =>
  retryImport(() => import('app/components/ConsentsChangeModal'))
);
const RegisterByEmailModal = lazyWithReload(() =>
  retryImport(() => import('app/components/RegisterByEmailModal'))
);
const RegistrationConfirmModal = lazyWithReload(() =>
  retryImport(() => import('app/components/RegistrationConfirmModal'))
);
const SendBankTokenConfirm = lazyWithReload(() =>
  retryImport(() => import('app/components/SendBankTokenConfirm'))
);

const SendConfirmLinkModal = lazyWithReload(() =>
  retryImport(() => import('app/components/SendConfirmLinkModal'))
);
const UserProfileView = lazyWithReload(() =>
  retryImport(() => import('app/views/UserProfileView'))
);
const EmailVerifiedView = lazyWithReload(() =>
  retryImport(() => import('app/views/EmailVerifiedView'))
);
const EmailVerifiedViewFailed = lazyWithReload(() =>
  retryImport(() => import('app/views/EmailVerifiedViewFailed'))
);
const CalculationBaseView = lazyWithReload(() =>
  retryImport(() => import('app/views/CalculationBaseView'))
);
const LoadDocumentsView = lazyWithReload(() =>
  retryImport(() => import('app/views/LoadDocumentsView'))
);
const LoadSimpleDocumentsView = lazyWithReload(() =>
  retryImport(() => import('app/views/LoadSimpleDocumentsView'))
);

const SuccessfulPurchaseView = lazyWithReload(() =>
  retryImport(() => import('app/views/SuccessfulPurchaseView'))
);
const RecoveredQuotation = lazyWithReload(() =>
  retryImport(() => import('app/components/RecoveredQuotation'))
);
const InternalPayment = lazyWithReload(() =>
  retryImport(() => import('app/views/InternalPaymentView'))
);
const PayByLinkRedirection = lazyWithReload(() =>
  retryImport(() => import('app/views/PayByLinkRedirection'))
);
const PayByLinkRedirectionFromSms = lazyWithReload(() =>
  retryImport(() => import('app/views/PayByLinkRedirectionFromSms'))
);

const RedirectToOfferView = lazyWithReload(() =>
  retryImport(() => import('app/views/RedirectToOfferView'))
);
const BonusPayoutView = lazyWithReload(() =>
  retryImport(() => import('app/views/BonusPayoutView'))
);

const TerminationGeneratorView = lazyWithReload(() =>
  retryImport(() => import('app/views/TerminationGeneratorView'))
);

const QuotationById = lazyWithReload(() =>
  retryImport(() => import('app/components/QuotationById'))
);
const RecommendView = lazyWithReload(() =>
  retryImport(() => import('app/components/RecommendView'))
);
const YourRecommendationsView = lazyWithReload(() =>
  retryImport(() => import('app/components/YourRecommendationsView'))
);
const StationStatsManager = lazyWithReload(() =>
  retryImport(() => import('app/components/StationStatsManager'))
);
const DiagnosticiansStatsManager = lazyWithReload(() =>
  retryImport(() => import('app/components/DiagnosticiansStatsManager'))
);
const YourRecommendationsViewDiagnostic = lazyWithReload(() =>
  retryImport(() => import('app/components/YourRecommendationsViewDiagnostic'))
);
const LeadEntryView = lazyWithReload(() =>
  retryImport(() => import('app/components/LeadEntryView'))
);
const OperatorLeadView = lazyWithReload(() =>
  retryImport(() => import('app/components/OperatorLeadView'))
);
const OperatorLeadViewNewCar = lazyWithReload(() =>
  retryImport(() => import('app/components/OperatorLeadViewNewCar'))
);

const NotificationsDisabledInfoView = lazyWithReload(() =>
  retryImport(() => import('app/components/NotificationsDisabledInfoView'))
);

const MubiDubitView = lazyWithReload(() =>
  retryImport(() => import('app/views/MubiDubitView'))
);

const MubiLotteryView = lazyWithReload(() =>
  retryImport(() => import('app/views/MubiLotteryView'))
);

const FerrariVoucherView = lazyWithReload(() =>
  retryImport(() => import('app/views/FerrariVoucherView'))
);

const TravelCalculationBaseView = lazyWithReload(() =>
  retryImport(() => import('app/views/TravelCalculationBaseView'))
);

const TravelCheckoutBaseView = lazyWithReload(() =>
  retryImport(() => import('app/views/TravelCheckoutBaseView'))
);

const TravelSuccessfulPurchaseView = lazyWithReload(() =>
  retryImport(() => import('app/views/TravelSuccessfulPurchaseView'))
);

const TravelPaymentRedirection = lazyWithReload(() =>
  retryImport(() => import('app/views/TravelPaymentRedirection'))
);

const TravelConvertedToApplication = lazyWithReload(() =>
  retryImport(() => import('app/views/TravelConvertedToApplication'))
);

const SkpRegistrationView = lazyWithReload(() =>
  retryImport(() => import('app/views/SkpRegistrationView'))
);

const ConfirmPaymentView = lazyWithReload(() =>
  retryImport(() => import('app/views/ConfirmPaymentView'))
);

const RenewalFormView = lazyWithReload(() =>
  retryImport(() => import('app/views/RenewalFormView/RenewalFormView'))
);

const CheckoutRedirectionView = lazyWithReload(() =>
  retryImport(() => import('app/views/CheckoutRedirectionView'))
);

function Modals() {
  return (
    <>
      <Route path={RouteEnum.LOGIN} component={LoginModal} />
      <Route
        path={RouteEnum.RESEND_VERIFICATION_MAIL}
        component={ResendVerificationEmailModal}
      />
      <Route path={RouteEnum.REGISTER} component={RegisterModal} />
      <Route path={RouteEnum.CHANGE_PASSWORD} component={PasswordChangeModal} />
      <Route
        path={RouteEnum.CHANGE_PASSWORD_PROFILE}
        component={PasswordChangeModal}
      />
      <Route path={RouteEnum.RESET_PASSWORD} component={PasswordResetModal} />
      <Route path={RouteEnum.PASSWORD_RESET} component={PasswordChangeModal} />
      <Route path={RouteEnum.USER_EDIT} component={UserEditModal} />
      <Route path={RouteEnum.ORDER_PAYOFF} component={OrderPayoffModal} />
      <Route path={RouteEnum.CHANGE_CONSENTS} component={ConsentsChangeModal} />
      <Route path={RouteEnum.SEND_CONFIRM} component={SendConfirmModal} />
      <Route
        path={RouteEnum.SEND_BANK_TOKEN_CONFIRM}
        component={SendBankTokenConfirm}
      />
      <Route
        path={RouteEnum.SEND_CONFIRM_LINK}
        component={SendConfirmLinkModal}
      />
      <Route
        path={RouteEnum.REGISTER_BY_EMAIL}
        component={RegisterByEmailModal}
      />
      <Route
        path={RouteEnum.REGISTRATION_CONFIRM}
        component={RegistrationConfirmModal}
      />
      <Route path={RouteEnum.CASE_REPORT} component={WebToCaseModal} />
      <Route
        path={RouteEnum.COMPARE_CEPIK_DATA}
        component={CompareCepikDataModal}
      />
    </>
  );
}

export default function ViewsSwitch(props: ViewsSwitchProps) {
  const location = useLocation();
  const history = useHistory();
  const [previousLocation, setPreviousLocation] = useState(location);

  useEffect(() => {
    const isNotModalLocation =
      history.action !== 'POP' && !location?.state?.modal;

    if (isNotModalLocation) {
      setPreviousLocation(location);
    }
  }, [history, location]);

  const isModal = location?.state?.modal && previousLocation !== location;
  const hasRequiredConsents = useSelector(selectHasRequiredConsents);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Switch location={isModal ? previousLocation : location}>
          <Route path="/" exact component={CalculationBaseView} />
          <Route path={RouteEnum.HOME} exact component={CalculationBaseView} />
          <Route path={RouteEnum.CALCULATION} component={CalculationBaseView} />
          <Route path={RouteEnum.MOTORCYCLES} component={CalculationBaseView} />
          <Route
            path={RouteEnum.ACTIVE_PROMOTION}
            component={CalculationBaseView}
          />
          <DefaultAppRoute
            path={RouteEnum.PARTNER_LINK}
            component={CalculationBaseView}
          />
          <DefaultAppRoute
            path={RouteEnum.USER_PROFILE}
            component={UserProfileView}
          />
          <DefaultAppRoute
            path={RouteEnum.EMAIL_VERIFIED}
            component={EmailVerifiedView}
          />
          <DefaultAppRoute
            path={RouteEnum.CHANGE_BANK_TRANSFER_DATA}
            component={ChangeBankTransferData}
          />
          <DefaultAppRoute
            path={RouteEnum.EMAIL_VERIFICATION_FAILURE}
            component={EmailVerifiedViewFailed}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/successful-purchase/:quotationId/:internalPayment?`}
            component={SuccessfulPurchaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/converted-to-application/:quotationId/:internalPayment?`}
            component={ConvertedToApplication}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/payment-response/:quotationId`}
            component={PaymentRedirection}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HI_PRO_CONFIRM_PAYMENT}/:quotationId`}
            component={ConfirmPaymentView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/quotations/:recoveryKey`}
            component={RecoveredQuotation}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/oplac-polise`}
            component={PayByLinkRedirection}
            exact
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/oplac-polise/sms`}
            component={PayByLinkRedirectionFromSms}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/quotations`}
            component={RecoveredQuotation}
          />
          <DefaultAppRoute
            path={`${RouteEnum.HOME}/quotation-by-id/:quotationId`}
            component={QuotationById}
          />
          <Route
            path={`${RouteEnum.REDIRECT_TO_OFFER}/:resultId/:offerId`}
            component={RedirectToOfferView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.LEADS}/:partnerId`}
            component={LeadEntryView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.OPERATOR_LEADS_NEW_CAR}`}
            component={OperatorLeadViewNewCar}
            exact
          />
          <DefaultAppRoute
            path={`${RouteEnum.OPERATOR_LEADS}/:partnerId`}
            component={OperatorLeadView}
          />
          <DefaultAppRoute
            path={RouteEnum.CALL_ME_BACK}
            component={CallMeBackView}
          />
          <DefaultAppRoute
            path={RouteEnum.RECOMMEND}
            component={RecommendView}
          />
          <DefaultAppRoute
            path={RouteEnum.YOUR_RECOMMENDATIONS}
            component={YourRecommendationsView}
          />
          <DefaultAppRoute
            path={RouteEnum.STATION_STATS_MANAGER}
            component={StationStatsManager}
          />
          <DefaultAppRoute
            path={RouteEnum.YOUR_RECOMMENDATIONS_DIAGNOSTIC}
            component={YourRecommendationsViewDiagnostic}
          />
          <DefaultAppRoute
            path={RouteEnum.DIAGNOSTICIANS_STATS}
            component={DiagnosticiansStatsManager}
          />
          <DefaultAppRoute
            path={RouteEnum.OFFER_DETAILS}
            component={OfferDetailsView}
          />
          <DefaultAppRoute
            path={RouteEnum.CLAIM_BONUS}
            component={BonusPayoutView}
          />
          {uploadDocumentsActive && (
            <Route
              path={RouteEnum.UPLOAD_DOCUMENTS}
              component={LoadDocumentsView}
            />
          )}
          {uploadDocumentsActive && (
            <Route
              path={RouteEnum.UPLOAD_SIMPLE_DOCUMENTS}
              component={LoadSimpleDocumentsView}
            />
          )}
          <DefaultAppRoute
            path={RouteEnum.NOTIFICATIONS_DISABLED_INFO}
            component={NotificationsDisabledInfoView}
          />
          <DefaultAppRoute
            path={RouteEnum.MUBI_DUBIT}
            component={MubiDubitView}
          />
          <DefaultAppRoute
            path={RouteEnum.MUBI_LOTTERY}
            component={MubiLotteryView}
          />
          <DefaultAppRoute
            path={RouteEnum.FERRARI_VOUCHER}
            component={FerrariVoucherView}
          />
          <DefaultAppRoute
            path={RouteEnum.TRAVEL}
            exact
            component={TravelCalculationBaseView}
          />
          <DefaultAppRoute
            path={RouteEnum.TRAVEL_FORM}
            component={TravelCalculationBaseView}
          />
          <DefaultAppRoute
            path={RouteEnum.TRAVEL_FORM_FB}
            component={TravelCalculationBaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL}/quotations/:recoveryKey`}
            component={RecoveredQuotation}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL}/oplac-polise`}
            component={InternalPayment}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL}/quotations`}
            component={RecoveredQuotation}
          />
          <DefaultAppRoute
            path={RouteEnum.TRAVEL_CALCULATION_RESULTS}
            component={TravelCalculationBaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL_SUCCESSFUL_PURCHASE}/:travelQuotationId/:internalPayment?`}
            component={TravelSuccessfulPurchaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL_FORM_CHECKOUT}/:offerId`}
            component={TravelCheckoutBaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL_FORM_PAYMENT}/:offerId`}
            component={TravelCheckoutBaseView}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL_PAYMENT_REDIRECT}/:travelQuotationId`}
            component={TravelPaymentRedirection}
          />
          <DefaultAppRoute
            path={`${RouteEnum.TRAVEL_CONVERTED_TO_APPLICATION}/:travelQuotationId/:internalPayment?`}
            component={TravelConvertedToApplication}
          />
          <DefaultAppRoute
            path={RouteEnum.TERMINATION_GENERATOR}
            component={TerminationGeneratorView}
          />
          <DefaultAppRoute
            path={RouteEnum.SKP_APPLICATION}
            component={SkpRegistrationView}
          />
          <DefaultAppRoute
            path={RouteEnum.RENEWAL_OF_PROPOSAL}
            component={RenewalFormView}
          />
          <DefaultAppRoute
            path={RouteEnum.DISABLE_NOTIFICATIONS}
            component={RenewalFormView}
          />
          <DefaultAppRoute
            path={RouteEnum.CHECKOUT_REDIRECTION}
            component={CheckoutRedirectionView}
          />
          {!isModal && <Modals />}
        </Switch>
      </Suspense>
      {isModal && (
        <Suspense fallback={<FallbackModal />}>
          <Switch>
            <Modals />
          </Switch>
        </Suspense>
      )}
      {!hasRequiredConsents && (
        <Suspense fallback={<FallbackModal />}>
          <ConfirmConsentsModal />
        </Suspense>
      )}
    </>
  );
}
