import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';

import HamburgerAppMenu from './HamburgerAppMenu';
import { styles } from './HamburgerAppMenu.styles';
import {
  HamburgerAppMenuOwnProps,
  HamburgerAppMenuRouteProps,
} from 'app/components/HamburgerAppMenu/HamburgerAppMenu.interfaces';

const HamburgerAppMenuContainer = withStyles(styles)(
  withRouter<HamburgerAppMenuRouteProps & HamburgerAppMenuOwnProps>(
    HamburgerAppMenu
  )
);

export default HamburgerAppMenuContainer;
