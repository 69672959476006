export enum AcUpsaleEnum {
  AddAcAction = 'TAK, chcę ubezpieczenie AC',
  AddAcInfo1 = 'uszkodzeniem samochodu z\xa0Twojej\xa0winy',
  AddAcInfo2 = 'kradzieżą auta, katalizatora, kół',
  AddAcInfo3 = 'żywiołami -\xa0gradem, powodzią, wichurą',
  AddAcSubtitle = 'Ochroń się przed:',
  AddAcTitle = 'Dodaj\xa0AC',
  CheaperBy = 'taniej\xa0o ',
  Description = 'Wybrana oferta nie posiada ochrony w\xa0razie wypadku lub kradzieży. Polecam rozszerzyć ofertę o\xa0najtańsze\xa0AC.',
  NoAcAction = 'Kontynuuję bez ochrony AC',
  NoAcInfo1 = 'wszystkie koszty wypadku, holowania czy kradzieży poniesiesz\xa0sam!',
  NoAcInfo2 = 'co 30\xa0sekund w\xa0Polsce ktoś uszkadza swój samochód!',
  NoAcSubtitle = 'Czy wiesz że:',
  NoAcTitle = 'Brak\xa0AC',
  RecommendedLabel = 'Ekspert Mubi poleca',
  SelectedLabel = 'Wybrane',
  Title = 'Rozszerz ochronę!',
}
