import { isPeselValid } from 'app/services/pesel.service';
import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export default function validatePesel(
  data: ValidatorDataInterface,
  minAge?: number
): ValidationObject {
  const validationObject = {
    key: 'invalid_pesel',
    valid: true,
  };

  if (!isPeselValid(data.value.toString())) {
    validationObject.valid = false;
  } else if (!!minAge && !isPeselValid(data.value.toString(), minAge)) {
    validationObject.key = 'too_young_owner_pesel';
    validationObject.valid = false;
  }

  return validationObject;
}
