export enum RenewalEnum {
  DifferentDate = 'Inny termin ubezpieczenia',
  DifferentDateValue = 'different_date',
  DontHaveACar = 'Nie masz samochodu?',
  Info = 'Wprowadzając dane o Twoim nowym aucie, przedstawimy Ci w przyszłości ofertę ubezpieczenia, dzięki której oszczędzisz nawet do 70%! Z Mubi nie przepłacisz!',
  InfoTitle = 'Dlaczego warto podać te dane?',
  NewCar = 'Mam nowy samochód',
  NewCarValue = 'new_car',
  StopNotification = 'Zatrzymaj powiadomienia',
  UpdateCar = 'Zaktualizuj mój samochód',
  UpdateDate = 'Zaktualizuj datę ubezpieczenia',
  UpdateInformation = 'Zaktualizuj informacje ',
  UpdateInformationMore = 'dotyczące',
  YouHaveNewCar = 'Masz nowy samochód?',
}
