export enum LoadDocumentsEnum {
  LoadDocuments1 = 'Wczytaj dokumenty samochodu za pośrednictwem ',
  LoadDocuments2 = 'komputera',
  LoadDocumentsDataSafetyInfo = 'Twoje dane są bezpieczne, używamy ich tylko do obliczenia ubezpieczenia.',
  LoadDocumentsFastProcessInfo = 'Szybki i prosty proces, bez wypełniania formularza!',
  LoadDocumentsHowItWorks1 = 'Przygotuj swój dowód rejestracyjny i\xa0opcjonalnie ostatnią polisę auta.',
  LoadDocumentsHowItWorks10 = 'Zostaw numer telefonu abyśmy mogli przygotować dla Ciebie spersonalizowane, najtańsze oferty! Uwaga Twój numer, nie zostanie przekazany do żadnej firmy zewnętrznej.',
  LoadDocumentsHowItWorks11 = 'Zaraz po zebraniu najtańszych ofert, skontaktujemy\xa0się z\xa0Tobą i\xa0pomożemy wybrać najlepszą polisę. Nasz kontakt będzie jednorazowy.',
  LoadDocumentsHowItWorks2 = 'Wybierz czy dane swojego samochodu chcesz wczytać za pomocą skanera kodu Aztec dostępnego z telefonu, czy przesyłając zdjęcia z komputera.',
  LoadDocumentsHowItWorks3 = 'Jeśli chcesz zeskanować kod Aztec z dowodu rejestacyjnego, wyślij link do skanera podając swój numer telefonu.',
  LoadDocumentsHowItWorks4 = 'Jeśli wybierzesz opcję przesłania zdjęć z komputera, postępuj według instukcji na kolejnym kroku.',
  LoadDocumentsHowItWorks5 = 'Wyjmij dowód rejestracyjny z\xa0foliowego pokrowca.',
  LoadDocumentsHowItWorks6 = 'Zrób zdjęcie dowodu rejestracyjnego z\xa0przodu i\xa0z\xa0tyłu.',
  LoadDocumentsHowItWorks7 = 'Prześlij zdjęcia na swój komputer i\xa0wgraj je korzystając z\xa0opcji "Przeciągnij tu zdjęcie" lub klikając w\xa0przycisk "Wybierz plik".',
  LoadDocumentsHowItWorks8 = 'Zrób zdjęcie z\xa0poprzedniej polisy lub wyszukaj e-mail od Towarzystwa Ubezpieczeniowego z\xa0plikiem pdf polisy.',
  LoadDocumentsHowItWorks9 = 'Prześlij zdjęcie lub plik pdf z\xa0poprzedniej polisy korzystając z\xa0opcji "Przeciągnij tu zdjęcie" lub klikając w\xa0przycisk "Wybierz plik".',
  LoadDocumentsSavingsInfo = 'Oszczędzisz kupując polisę tańszą nawet o 70%!',
}
