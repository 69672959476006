import React from 'react';
import { useSelector } from 'react-redux';

import { LoginButtonProps } from './LoginButton.interfaces';
import { LabelEnum, RouteEnum } from 'app/shared/enums';
import { AppMenuButton } from 'app/components/AppMenuButton';
import { isFormSubmitting } from 'app/store/data/formSubmission';

export default function LoginButton({ redirect, className }: LoginButtonProps) {
  const isLoading = useSelector(isFormSubmitting);

  return (
    <AppMenuButton
      to={{
        pathname: isLoading ? undefined : RouteEnum.LOGIN,
        state: {
          modal: true,
          from: redirect,
        },
      }}
      variant={'outlined'}
      color={'primary'}
      title={LabelEnum.LogIn}
      className={className}
      data-cy="login"
    />
  );
}
