import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    root: {
      textDecoration: 'none',
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      },
    },
  });
