import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import { PromotionSetActionPayload } from 'app/store/data/promotion';

export const PromotionActions = {
  setPromotion: (payload: PromotionSetActionPayload) =>
    createAction(ActionType.SET_PROMOTION, payload),
  markPromotionAsExpired: (promotionId: string) =>
    createAction(ActionType.MARK_PROMOTION_AS_EXPIRED, { promotionId }),
  markPromotionAsForAcOnly: (promotionId: string) =>
    createAction(ActionType.MARK_PROMOTION_AS_FOR_AC_ONLY, { promotionId }),
  markPromotionAsForRenewalOnly: (promotionId: string) =>
    createAction(ActionType.MARK_PROMOTION_AS_FOR_RENEWAL_ONLY, {
      promotionId,
    }),
  markPromotionAsOfferPriceTooLow: (promotionId: string) =>
    createAction(ActionType.MARK_PROMOTION_AS_OFFER_PRICE_TOO_LOW, {
      promotionId,
    }),
  restoreDefaultPromotionalState: () =>
    createAction(ActionType.RESTORE_DEFAULT_PROMOTIONAL_STATE),
  markPromotionalCodeAsNew: () =>
    createAction(ActionType.MARK_PROMOTIONAL_CODE_AS_NEW),
  clearPromotion: () => createAction(ActionType.CLEAR_PROMOTION),
};

export type PromotionActionsType = ActionsUnion<typeof PromotionActions>;
