import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Phone from '@material-ui/icons/Phone';

import { getQuotationNumber } from 'app/store/data/quotation';
import { useStyles } from './TopBarCallBox.styles';
import { LabelEnum } from 'app/shared/enums';
import { CompactModal } from 'app/components/CompactModal';
import { AppMenuButton } from 'app/components/AppMenuButton';
import { NewButton } from 'app/components/NewButton';
import isMobileOrTablet from 'app/shared/helpers/isMobileOrTablet';
import { mainPhoneNumber } from 'app/services/env.service';
import { TopBarCallBoxProps } from './TopBarCallBox.interfaces';

function TopBarCallBox({ phoneNumber }: TopBarCallBoxProps) {
  const classes = useStyles();
  const quotationNumber = useSelector(getQuotationNumber);
  const [toggleModal, setToggleModal] = useState(false);
  const isMobile = isMobileOrTablet();

  const phone = phoneNumber || mainPhoneNumber;

  const telWithoutSpaces = 'tel:' + phone?.replace(/\s/g, '');

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  return isMobile && quotationNumber ? (
    <>
      <AppMenuButton
        classes={{
          root: classes.phoneButton,
        }}
        onClick={handleToggleModal}
        icon={Phone}
        title={phone}
      />
      {toggleModal && (
        <CompactModal className={classes.modal} onClose={handleToggleModal}>
          <div className={classes.modalContent}>
            <Phone className={classes.icon} />
            <h3 className={classes.heading}>
              {LabelEnum.CalculationNumber}
              <div className={classes.quotationNumber}>{quotationNumber}</div>
            </h3>
            <div className={classes.description}>
              {LabelEnum.CalculationNumberHint}
            </div>
            <NewButton
              href={telWithoutSpaces}
              title={LabelEnum.Call}
              variant={'contained'}
              color={'primary'}
              fullWidth
            />
          </div>
        </CompactModal>
      )}
    </>
  ) : (
    <AppMenuButton
      classes={{
        root: classes.phoneButton,
      }}
      href={telWithoutSpaces}
      icon={Phone}
      title={phone}
    />
  );
}

export default TopBarCallBox;
