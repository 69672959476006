import store from 'app/store/index';
import { ScreenEnum } from 'app/shared/enums';

export const getIsLeasingLeadConfig = () => {
  const firstScreenKey = store.getState().formsConfig.overwrittenFormConfig
    .config?.screens?.[0].key;
  return (
    firstScreenKey &&
    [
      ScreenEnum.LeasingLeadScreen1,
      ScreenEnum.LeasingLeadScreenShortPath,
    ].includes(firstScreenKey as ScreenEnum)
  );
};
