export enum DestinationCountriesEnum {
  Albania = 'Albania',
  Austria = 'Austria',
  Bulgaria = 'Bułgaria',
  CanaryIslands = 'Wyspy Kanaryjskie',
  Croatia = 'Chorwacja',
  Cyprus = 'Cypr',
  Czech = 'Czechy (Republika Czeska)',
  Egypt = 'Egipt',
  France = 'Francja',
  Georgia = 'Gruzja',
  Germany = 'Niemcy',
  GreatBritain = 'Wielka Brytania',
  Greece = 'Grecja',
  Hungary = 'Węgry',
  Iceland = 'Islandia',
  Italy = 'Włochy',
  Malta = 'Malta',
  Montenegro = 'Czarnogóra',
  Netherlands = 'Holandia (Niderlandy)',
  Norway = 'Norwegia',
  Portugal = 'Portugalia',
  Slovakia = 'Słowacja',
  Spain = 'Hiszpania',
  Sweden = 'Szwecja',
  Switzerland = 'Szwajcaria',
  Thailand = 'Tajlandia',
  Tunisia = 'Tunezja',
  Turkey = 'Turcja - cały kraj',
  TurkeyEUPart = 'Turcja - część europejska',
  USA = 'Stany Zjednoczone Ameryki (USA)',
  ZEA = 'Zjednoczone Emiraty Arabskie (ZEA)',
}
