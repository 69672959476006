import compact from 'lodash-es/compact';
import filter from 'lodash-es/filter';
import flatten from 'lodash-es/flatten';
import fromPairs from 'lodash-es/fromPairs';
import includes from 'lodash-es/includes';
import orderBy from 'lodash-es/orderBy';
import reject from 'lodash-es/reject';
import toPairs from 'lodash-es/toPairs';
import values from 'lodash-es/values';

import { RootState } from 'app/store/rootReducer';
import { TravelQuotationResults } from 'app/shared/interfaces/TravelQuotationResults.interface';
import { createSelector } from 'reselect';
import { TravelQuotationResultWithOfferIdsInterface } from 'app/shared/interfaces/TravelQuotationResultWithOfferIds.interface';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import {
  InsuranceTypeEnum,
  InsurersEnum,
  QuotationResultStatusEnum,
  SortingCriterionEnum,
} from 'app/shared/enums';
import { getTravelFilters } from 'app/store/data/form';
import { PriceRangeType } from 'app/shared/types/PriceRange.type';
import { getInsurerNameAfterRebranding } from 'app/shared/helpers/getInsurerNameAfterRebranding';
import { haveResultsFinalStatusesOnly } from 'app/shared/helpers/hasOnlyFinalStatuses';
import { SortOrder } from 'app/shared/types/SortOrder.type';
import { selectIsPolandSelected } from 'app/store/data/travelQuotation';
import {
  TravelOffersState,
  TravelRecommendedOffersState,
} from './state.interfaces';

export const getOffer = (offerId?: TravelOfferInterface['id']) => (
  state: RootState
) => {
  return offerId !== undefined
    ? state.travelQuotationResult.offers[offerId]
    : undefined;
};

const getOffers = (state: RootState): TravelOffersState =>
  fromPairs(
    toPairs(state.travelQuotationResult.offers).filter(
      ([key, offer]) => !!offer.price || !!offer.priceFrom
    )
  );

const getOffersFilteredByInsurersName = (
  offers: Array<TravelOfferInterface>,
  insurerIds: Array<InsurersEnum>
): Array<TravelOfferInterface> => {
  return offers.filter(({ insurerName }) => insurerIds.includes(insurerName));
};

const getOffersFilteredByInsuranceScope = (
  offers: Array<TravelOfferInterface>,
  insuranceScopes: Array<InsuranceTypeEnum>
): Array<TravelOfferInterface> => {
  const insuranceScopeNames = insuranceScopes.map((scopeName) =>
    scopeName.toLowerCase()
  );

  return reject(offers, (offer) =>
    insuranceScopeNames.some((scopeName) => offer[scopeName] === null)
  );
};

const getOffersFilteredByPriceRange = (
  offers: Array<TravelOfferInterface>,
  priceRange: PriceRangeType
): Array<TravelOfferInterface> => {
  return filter(
    offers,
    ({ price: { finalPrice } }) =>
      finalPrice >= priceRange[0] && finalPrice <= priceRange[1]
  );
};

const getOffersSortedBySamePriceAndInsuranceSum = (
  offers: Array<TravelOfferInterface>
) => {
  return offers.sort((a, b) => {
    if (
      !a.medicalExpenses?.insuranceSum?.insuranceSum ||
      !b.medicalExpenses?.insuranceSum?.insuranceSum ||
      a.price.finalPrice !== b.price.finalPrice
    ) {
      return 0;
    }

    return a.medicalExpenses?.insuranceSum?.insuranceSum >
      b.medicalExpenses?.insuranceSum?.insuranceSum
      ? -1
      : 1;
  });
};

const getOffersSorted = (
  offers: Array<TravelOfferInterface>,
  sortingCriterion: SortingCriterionEnum
): Array<TravelOfferInterface> => {
  const iterateOverInsurerName = ({ insurerName }: TravelOfferInterface) =>
    getInsurerNameAfterRebranding(insurerName);

  let iteratee, order;

  switch (sortingCriterion) {
    case SortingCriterionEnum.PriceAsc:
      iteratee = ['price.finalPrice'];
      break;
    case SortingCriterionEnum.PriceDesc:
      iteratee = ['price.finalPrice'];
      order = ['desc' as SortOrder];
      break;
    case SortingCriterionEnum.MedicalCostsDesc:
      iteratee = [
        'medicalExpenses.insuranceSum.insuranceSum',
        'price.finalPrice',
      ];
      order = ['desc' as SortOrder];
      break;
    case SortingCriterionEnum.InsurerAsc:
      iteratee = [iterateOverInsurerName, 'price.finalPrice'];
      break;
    case SortingCriterionEnum.InsurerDesc:
      iteratee = [iterateOverInsurerName, 'price.finalPrice'];
      order = ['desc' as SortOrder];
      break;
  }

  return orderBy(offers, iteratee, order);
};

export const getRecommendedOffers = (
  state: RootState
): TravelRecommendedOffersState =>
  state.travelQuotationResult.recommendedOffers;

export const getIsOfferRecommended = (offerId?: TravelOfferInterface['id']) => (
  state: RootState
) => {
  switch (offerId) {
    case undefined:
      return 'nierekomendowana';
    case state.travelQuotationResult.recommendedOffers.minPriceOffer.id:
      return 'rekomendowana_najtańsza';
    case state.travelQuotationResult.recommendedOffers.recommendedOffer.offer
      ?.id:
      return 'rekomendowana_polecana';
    case state.travelQuotationResult.recommendedOffers.maxInsuranceSumOffer.id:
      return 'rekomendowana_maksymalna';
    default:
      return 'nierekomendowana';
  }
};

export const getResults = (state: RootState): TravelQuotationResults => {
  return state.travelQuotationResult.results;
};

export const getResultsArray = createSelector(
  [getResults],
  (results): Array<TravelQuotationResultWithOfferIdsInterface> =>
    values(results)
);

export const getFullResultsObject = (state: RootState) => {
  return state.travelQuotationResult.fullResultsObject;
};

export const getResultById = (
  state: RootState,
  resultId: string
): TravelQuotationResultWithOfferIdsInterface =>
  createSelector(
    [getResultsArray],
    (resultsArray) =>
      resultsArray.find(
        (result) => result.id === resultId
      ) as TravelQuotationResultWithOfferIdsInterface
  )(state);

export const getPreparedOffersArray = createSelector(
  [getOffers, getResultsArray],
  (offers, results): Array<TravelOfferInterface> => {
    const preparedResultsStatuses = [
      QuotationResultStatusEnum.Prepared,
      QuotationResultStatusEnum.UpdatePrepared,
    ];

    const isPreparedResult = ({
      status,
    }: TravelQuotationResultWithOfferIdsInterface) =>
      includes(preparedResultsStatuses, status);

    const preparedResults = filter(results, isPreparedResult);
    const offerIds = flatten(preparedResults.map((result) => result.offers));

    return compact(
      offerIds
        .map((offerId) => offers[offerId])
        .filter((offer) => !offer.doNotShow)
    );
  }
);

export const getOffersCount = (state: RootState): number =>
  getOffersFiltered(state).length;

const parseToNumber = (value: string) => {
  return parseFloat(value.replace(',', '.').replace(' zł', ''));
};

export const getOffersFiltered = createSelector(
  [
    getPreparedOffersArray,
    (state) => getTravelFilters(state),
    (state) => selectIsPolandSelected(state),
  ],
  (offers, filters, isPolandSelected) => {
    if (isPolandSelected) {
      return offers.sort((a, b) => {
        const priceA = a.priceFrom ? parseToNumber(a.priceFrom) : 0;
        const priceB = b.priceFrom ? parseToNumber(b.priceFrom) : 0;

        return priceA - priceB;
      });
    }

    if (filters === undefined) {
      return offers;
    }

    if (Array.isArray(filters.insurers) && filters.insurers.length > 0) {
      offers = getOffersFilteredByInsurersName(offers, filters.insurers);
    }

    if (filters.scopes) {
      offers = getOffersFilteredByInsuranceScope(offers, filters.scopes);
    }

    if (filters.price) {
      offers = getOffersFilteredByPriceRange(offers, filters.price);
    }

    offers = getOffersSortedBySamePriceAndInsuranceSum(offers);

    offers = filters.sort ? getOffersSorted(offers, filters.sort) : offers;

    return offers;
  }
);

export const selectResultByBrandId = (brandId: InsurersEnum) => (
  state: RootState
) => {
  const COMPANIES_MAPPING: {} = {
    [InsurersEnum.Proama]: [InsurersEnum.Proama, InsurersEnum.Generali],
    [InsurersEnum.Hestia]: [InsurersEnum.Mtu24, InsurersEnum.YouCanDrive],
    [InsurersEnum.Axa]: [InsurersEnum.Uniqa],
    [InsurersEnum.Gothaer]: [InsurersEnum.Wiener],
  };

  const insurerId =
    Object.keys(COMPANIES_MAPPING).find((key) =>
      COMPANIES_MAPPING[key].includes(brandId)
    ) || brandId;

  return state.travelQuotationResult.results[insurerId];
};

export const selectResultStatusByBrandId = (brandId: InsurersEnum) => (
  state: RootState
) => {
  const result = selectResultByBrandId(brandId)(state);

  return result?.status;
};

export const selectIsCalculationTriggeredByUser = (state: RootState) => {
  return state.quotationResult.calculationTriggeredByUser;
};

export const selectHasOnlyFinalStatuses = createSelector(
  [getResultsArray],
  (results) => haveResultsFinalStatusesOnly(results)
);

export const selectTravelResultsActiveAbTests = (state: RootState) =>
  state.travelQuotationResult.activeABTests;
