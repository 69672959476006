export enum QuestionMessagesEnum {
  CepikStartDateWarning = 'Według danych z\xa0CEPiK prawidłowa data startu to dd.mm.rrrr\xa0r. Upewnij się, że\xa0podajesz poprawną datę.',
  Coowner1FamilyNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko współwłaściciela',
  Coowner1LastNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko rodowe współwłaściciela',
  Coowner2FamilyNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko drugiego współwłaściciela',
  Coowner2LastNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko rodowe drugiego współwłaściciela',
  DifferentFirstRegistrationDateFromCepikWarning1 = 'Według danych z CEPiK prawidłowa data pierwszej rejestracji to ',
  DifferentFirstRegistrationDateFromCepikWarning2 = ' r.\nUpewnij się, że podajesz poprawną datę.',
  InsurancePastDateNotAllowedError = 'Data rozpoczęcia nowej polisy nie może być z\xa0przeszłości.',
  NeedAnotherOfferError = 'Potrzebujesz innej oferty? Zadzwoń pod numer 22 250 02 00 lub napisz do nas na pomoc@mubi.pl, aby uzyskać indywidualną ofertę ubezpieczenia samochodu',
  NoModelAndVersionError = 'Aby porównać oferty ubezpieczenia, wybierz model i wersję pojazdu. Na liście brakuje Twojego pojazdu? Zadzwoń pod numer 22 250 02 00, a pomożemy Ci dokończyć wyliczenie.',
  NoModelIeWarning = '<strong>Na liście brakuje modelu Twojego pojazdu?</strong> Możesz przejść dalej, ale nie obliczymy składek od firm ubezpieczeniowych, które korzystają z bazy Info-Expert. Potrzebujesz pomocy? Zadzwoń pod numer 22 250 02 00, a pomożemy Ci wypełnić formularz.',
  NoOptionSelectedError = 'Wybierz jedną opcję, aby przejść dalej',
  NoVersionEtWarning = '<strong>Na liście brakuje wersji Twojego samochodu?</strong> Możesz przejść dalej, ale nie obliczymy składek od firm ubezpieczeniowych, które korzystają z bazy Eurotax. Potrzebujesz pomocy? Zadzwoń pod numer 22 250 02 00, a pomożemy Ci dokończyć wyliczenie.',
  NoVersionIeAndEtError = 'Aby porównać oferty ubezpieczenia, wybierz model i wersję pojazdu. Na liście brakuje Twojego auta? Zadzwoń pod numer 22 250 02 00, a pomożemy Ci dokończyć wyliczenie.',
  OldCarWarning = '<strong>Twoje auto ma więcej niż 25 lat?</strong> Oferujemy porównanie ofert i zakup ubezpieczenia, jeśli pojazd ma standardowe białe tablice rejestracyjne. Jeżeli Twoje auto jest zarejestrowane jako zabytkowe, skontaktuj się bezpośrednio z wybranym ubezpieczycielem.',
  OwnerFamilyNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko właściciela',
  OwnerLastNameNotUniqueError = 'Podane nazwisko zostało już wykorzystane na formularzu w polu Nazwisko rodowe właściciela',
  StartDateNotConfirmedError = 'Potwierdź datę startu, aby przejść dalej',
}
