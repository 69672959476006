import { useSelector } from 'react-redux';

import {
  getUserData,
  isCheckingAuth,
  isUserLoggedIn,
} from 'app/store/data/user';
import { RouteEnum } from 'app/shared/enums';

export function useUser() {
  const user = useSelector(getUserData);
  const isLoggedIn = useSelector(isUserLoggedIn);
  const checkingAuth = useSelector(isCheckingAuth);
  const isOperator = Boolean(user && user.operator);
  const isSkpEmployee = Boolean(user && user.skpEmployee);
  const isSkpOwner = Boolean(user && user.skpOwner);
  const isSkpUser = isSkpEmployee || isSkpOwner;

  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const userName = user?.username || '';
  const iban = user?.iban || '';
  const id = user?.id || '';
  const phoneNumber = user?.phoneNumber || '';
  const consents = user?.consents;

  const userRoute = isSkpEmployee
    ? RouteEnum.YOUR_RECOMMENDATIONS_DIAGNOSTIC
    : isSkpOwner
    ? RouteEnum.STATION_STATS_MANAGER
    : RouteEnum.SAVED_CALCULATIONS;

  return {
    user,
    isOperator,
    isSkpEmployee,
    isLoggedIn,
    firstName,
    lastName,
    userName,
    iban,
    checkingAuth,
    id,
    phoneNumber,
    consents,
    userRoute,
    isSkpUser,
    isSkpOwner,
  };
}
