import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

function isValidPassword(password: string) {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  return re.test(password);
}

export default function validatePassword(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_password',
    valid: isValidPassword(data.value.toString()),
  };
}
