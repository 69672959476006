import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';

import {
  ControlTypeEnum,
  FieldsEnum,
  FormsEnum,
  InsurersEnum,
  LabelEnum,
  MessagesEnum,
  SectionsEnum,
} from 'app/shared/enums';
import { ThunkResult } from 'app/store/helpers';
import { FormConfig } from 'app/shared/interfaces/FormConfig.interface';
import { FormsConfigActions } from 'app/store/data/formsConfig';
import { selectTravelQuotation } from 'app/store/data/travelQuotation';
import { TravelOfferInterface } from 'app/shared/interfaces/TravelOffer.interface';
import { FormSectionConfigInterface } from 'app/shared/interfaces/FormSectionConfig.interface';
import { QuestionInterface } from 'app/shared/interfaces/Question.interface';
import { Message } from 'app/shared/interfaces/Message.interface';
import { ApiService } from 'app/services/api.service';
import { clearFieldValue } from 'app/services/businessFunctionHelpers';

export const setTravelPaymentFormConfig = (
  formConfig: FormConfig,
  offer: TravelOfferInterface
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const quotation = selectTravelQuotation(state);

  const gatherDictToCleanAfterPriceChange = (
    question: QuestionInterface,
    dataUrl: string
  ) => {
    if (question.editable) {
      updateDictionary(question.key, dataUrl as string);
    }
  };

  const updateDictionary = (key: string, dataUrl: string) => {
    ApiService.get(dataUrl as string).then(({ data }) => {
      if (data.length) {
        clearFieldValue(key, FormsEnum.CheckoutPaymentForm);
        dispatch(
          FormsConfigActions.setDictionaryAction({
            dictionaryKey: `${key}_dictionary`,
            dictionary: data,
            formName: FormsEnum.CheckoutPaymentForm,
          })
        );
      }
    });
  };

  const parseQuestions = (questions: Array<QuestionInterface>) => {
    return questions.map((question) => {
      if (question.dataUrl) {
        const dataUrl = parseUrl(question.dataUrl);
        question.dataUrl = dataUrl;
        gatherDictToCleanAfterPriceChange(question, dataUrl);
      }

      if (question.modelPath?.includes('consent')) {
        question.width = 'full';
      }

      if (question.modelPath?.startsWith('paymentConsents')) {
        question.visible = false;
      }

      if (question.messages) {
        parseMesages(question.messages);
      }

      return question;
    });
  };

  const parseMesages = (messages: Array<Message>) => {
    messages.forEach((message) => {
      if (
        message.key === MessagesEnum.RememberToPayForThePolicy &&
        offer.insurerName === InsurersEnum.Gefion
      ) {
        const isToday = moment().isSame(quotation.startDate, 'day');
        if (isToday) {
          message.visible = true;
        }
      }
    });
  };

  const parseUrl = (dataUrl: string) => {
    return dataUrl
      .replace(/\${offerDto\?id}/, offer.id)
      .replace(/\${offerDto\?resultId}/, offer.resultId)
      .substring(1);
  };

  const getMappedTravelPaymentFormConfig = (conf: FormConfig) => {
    const config = cloneDeep(conf);

    config.screens.forEach((screen) => {
      screen.sections.forEach((section) => {
        if (section.key === SectionsEnum.PaymentSectionConsents) {
          setConsentsSection(section);
        }

        section.questions = parseQuestions(section.questions);
      });
    });

    return config;
  };

  const setConsentsSection = (section: FormSectionConfigInterface) => {
    const hasUnselectedConsents = section.questions
      .filter((question) => question.modelPath.includes('consent'))
      .some((question) => question.visible);

    if (hasUnselectedConsents) {
      addCheckAllConsentsCheckbox(section);
    }
  };

  const addCheckAllConsentsCheckbox = (section: FormSectionConfigInterface) => {
    const { questions } = section;

    questions.splice(0, 0, {
      key: FieldsEnum.ButtonConsentsPayment,
      label: LabelEnum.CheckAllRequiredByInsurer,
      onChange: ['fillAllRequiredTravelPaymentConsents'],
      type: ControlTypeEnum.Checkbox,
      modelPath: '',
      visible: true,
      required: false,
      shouldPatch: false,
      width: 'full',
    });
  };

  dispatch(
    FormsConfigActions.setFormConfigAction({
      formConfig: getMappedTravelPaymentFormConfig(formConfig),
      name: FormsEnum.TravelPaymentForm,
    })
  );
};
