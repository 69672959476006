import { TravelQuotationState } from 'app/store/data/travelQuotation';
import { UserState } from 'app/store/data/user';
import { TravelCheckoutFormByKeysInterface } from 'app/shared/interfaces/TravelCheckoutForm.interface';
import { TravelerInterface } from 'app/shared/interfaces/Traveler.interface';
import { TravelInsurantSelectionEnum } from 'app/shared/enums';

const getTravelersData = (
  quotation: TravelQuotationState
): Partial<TravelerInterface>[] => {
  const { travelers } = quotation;

  const mapped = travelers?.map((traveler) => {
    const { homeAddress, name, surname, pesel } = traveler;

    return { homeAddress, name, surname, pesel };
  });

  return mapped ?? [];
};

const getInsurantData = (quotation: TravelQuotationState) => {
  const { insurant, insurantSelection } = quotation;

  if (
    insurant &&
    insurantSelection &&
    insurantSelection !== TravelInsurantSelectionEnum.Different
  ) {
    const { name, surname, pesel, ...restData } = insurant;

    return restData;
  }

  return insurant;
};

export const CheckoutFormUtil = {
  getInitializeValuesFromQuotation(
    quotation: TravelQuotationState,
    userData: UserState
  ): TravelCheckoutFormByKeysInterface {
    const {
      consents,
      emailAddress = '',
      insurantSelection,
      phoneNumber,
    } = quotation;

    let { user } = userData;

    if (user && user.operator) {
      user = undefined;
    }

    return {
      emailAddress,
      phoneNumber,
      travel: {
        travelers: getTravelersData(quotation),
        contract: {
          signer: insurantSelection,
        },
      },
      consent: {
        marketing: !!consents?.marketing?.consent,
      },
      insurant: getInsurantData(quotation),
    };
  },
};
