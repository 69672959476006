import { EnvValue } from './EnvValue.type';

/**
 * Converts environment variable to boolean value
 *
 * Only string 'true' is converted to true
 *
 * Other values are converted to false
 *
 * @param valueFromEnv - value from environment variable
 */
export const getEnvBooleanValue = (valueFromEnv: EnvValue) =>
  valueFromEnv === 'true';
