import produce from 'immer';

import { CepikDataState } from './state.interface';
import { CepikDataActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: CepikDataState = {
  data: null,
  mergeData: null,
  isCepikModalSavingInProgress: null,
  quotationWithCepikData: null,
};

const CepikDataReducer = (
  state: CepikDataState = initialState,
  action: CepikDataActionsType
) =>
  produce(state, (draft) => {
    if (action.type === ActionType.SET_CEPIK_DATA) {
      draft.data = action.payload;
    } else if (action.type === ActionType.SET_CEPIK_MERGE_DATA) {
      draft.mergeData = action.payload;
    } else if (action.type === ActionType.CLEAR_CEPIK_DATA) {
      return initialState;
    } else if (action.type === ActionType.SET_CEPIK_MODAL_SAVING_IN_PROGRESS) {
      draft.isCepikModalSavingInProgress = action.payload;
    } else if (action.type === ActionType.SET_QUOTATION_WITH_CEPIK_DATA) {
      draft.quotationWithCepikData = action.payload;
    } else {
      return state;
    }
  });

export default CepikDataReducer;
