import { AgencyNameEnum, ChatEnum, InsurersEnum } from 'app/shared/enums';
import { getEnvBooleanValue } from './getEnvBooleanValue';
import { getEnvEnumValue } from './getEnvEnumValue';
import { getEnvUrlValue } from './getEnvUrlValue';
import { getEnvStringValue } from 'app/services/env.service/getEnvStringValue';
import { getEnvNumberValue } from 'app/services/env.service/getEnvNumberValue';
import { getEnvArrayValue } from 'app/services/env.service/getEnvArrayValue';

const {
  REACT_APP_PHONE_NUMBER,
  REACT_APP_AGENCY_MODE,
  REACT_APP_AGENCY_NAME,
  REACT_APP_USE_COMPACT_CAR_SELECT_CONTROL,
  REACT_APP_CHAT_TYPE,
  REACT_APP_COOKIES_CHROME,
  REACT_APP_COOKIES_CHROME_MOBILE,
  REACT_APP_COOKIES_CHROME_MOBILE_IOS,
  REACT_APP_COOKIES_EDGE,
  REACT_APP_COOKIES_EDGE_CHROMIUM,
  REACT_APP_COOKIES_FIREFOX,
  REACT_APP_COOKIES_FIREFOX_MOBILE,
  REACT_APP_COOKIES_IE,
  REACT_APP_COOKIES_OPERA,
  REACT_APP_COOKIES_SAFARI,
  REACT_APP_COOKIES_SAFARI_MOBILE,
  REACT_APP_COOKIES_SAMSUNG_INTERNET,
  REACT_APP_COOKIES_POPUP_ACTIVE,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_USE_EXTENDED_INFO_EKSPERT_DICTIONARY,
  REACT_APP_NEW_COOKIES_STYLE,
  REACT_APP_SHOW_REFERRAL,
  PUBLIC_URL,
  REACT_APP_UPLOAD_DOCUMENTS_ACTIVE,
  REACT_APP_MGM_POSTPONED_BONUS,
  BACKGROUND_OFFERS_LOADER_VISIBLE = 'true',
  REACT_APP_OFFERS_LOADING_RETRY_TIMEOUT,
  REACT_APP_OFFERS_LOADING_LOADER_MODAL_TIMEOUT,
  REACT_APP_OFFERS_LOADING_BACKGROUND_LOADER_TIMEOUT,
  REACT_APP_OFFERS_LOADING_RESULTS_TIMEOUT,
  REACT_APP_OFFERS_LOADING_MINIMUM_RESULTS_TO_SHOW,
  REACT_APP_SHOW_OFFER_RECOMMENDATION,
  REACT_APP_OFFER_RECOMMENDATION_ONLY_FOR_OPERATOR,
  REACT_APP_VEHICLE_TYPE_CONTROL_ACTIVE,
  REACT_APP_OPEN_INSURANCE_START_DATE_PICKER_ON_INPUT_CLICK,
  REACT_APP_MOTO_BANNER_LINK_PZU,
  REACT_APP_MOTO_BANNER_LINK_UNIQA,
  REACT_APP_NEW_QUOTATION_INSTEAD_OF_GET_LAST_FOR_OPERATOR,
  REACT_APP_HISTORY_QUESTIONS_VISIBILITY_BASED_ON_PERSONAL_DATA,
  REACT_APP_INSURERS_VISIBLE_IN_REJECTION_INFO,
  REACT_APP_INSURERS_VISIBLE_IN_LOADER_MODAL_CAR,
  REACT_APP_INSURERS_VISIBLE_IN_LOADER_MODAL_MOTOR,
  REACT_APP_NEW_OFFERS_LOADER,
  REACT_APP_SHOW_INFO_MESSAGE_FOR_SMALLER_NUMBER_OF_OFFERS,
  REACT_APP_AZTEC_REDIRECTION_BANNER,
  REACT_APP_SEND_REGISTRATION_CERTIFICATE_BOX_ENABLED,
  REACT_APP_CHECKOUT_FIXED_SUM_INFO_BOX,
  REACT_APP_TRAVEL_BANNER_LINK_SIGNAL_IDUNA,
  REACT_APP_TRAVEL_BANNER_LINK_UNIQA,
  REACT_APP_SHOW_TRAVEL_RECOMMENDATION_MESSAGE,
  REACT_APP_SHOW_FILTERS_MORE_FOR_CC_USERS,
  REACT_APP_SHOW_FILTERS_MORE_FOR_ALL_USERS,
  REACT_APP_ASSISTANCE_MAIL,
  REACT_APP_ASSISTANCE_PHONE,
  REACT_APP_AZTEC_SKP_URL,
  REACT_APP_SKP_APPLICATION_CONTACT_MAIL,
  REACT_APP_SKP_APPLICATION_CONTACT_PHONE,
  REACT_APP_MOTO_INFO_EKSPERT_DISABLE,
  REACT_APP_COOKIE_BOT_ID,
  REACT_APP_MUBI_LOTTERY_ACTIVE,
  REACT_APP_HARPIE_LEADS_ACTIVE,
  REACT_APP_MJM_LEADS_ACTIVE,
  REACT_APP_PHONE_NUMBER_NOT_ALLOWED,
  REACT_APP_CEPIK_PROCESS_ACTIVE,
  REACT_APP_CEPIK_COMPARE_MODAL_ACTIVE,
  REACT_APP_ENABLE_CEPIK_REQUEST_ON_CALCULATION_FORM_SUBMIT,
} = process.env;

export const agencyMode = getEnvBooleanValue(REACT_APP_AGENCY_MODE);

export const agencyName = getEnvEnumValue<AgencyNameEnum>(
  REACT_APP_AGENCY_NAME,
  AgencyNameEnum
);

export const isCompactSelectControl = getEnvBooleanValue(
  REACT_APP_USE_COMPACT_CAR_SELECT_CONTROL
);

export const isMotorInfoEkspertDisable = getEnvBooleanValue(
  REACT_APP_MOTO_INFO_EKSPERT_DISABLE
);

export const isChatActive = getEnvEnumValue<ChatEnum>(
  REACT_APP_CHAT_TYPE,
  ChatEnum
);

export const isNewCookiesStyle = getEnvBooleanValue(
  REACT_APP_NEW_COOKIES_STYLE
);

export const chromeLink = getEnvUrlValue(REACT_APP_COOKIES_CHROME);
export const chromeMobileLink = getEnvUrlValue(REACT_APP_COOKIES_CHROME_MOBILE);
export const chromeMobileIosLink = getEnvUrlValue(
  REACT_APP_COOKIES_CHROME_MOBILE_IOS
);
export const edgeLink = getEnvUrlValue(REACT_APP_COOKIES_EDGE);
export const edgeChromiumLink = getEnvUrlValue(REACT_APP_COOKIES_EDGE_CHROMIUM);
export const firefoxLink = getEnvUrlValue(REACT_APP_COOKIES_FIREFOX);
export const firefoxMobileLink = getEnvUrlValue(
  REACT_APP_COOKIES_FIREFOX_MOBILE
);
export const ieLink = getEnvUrlValue(REACT_APP_COOKIES_IE);
export const operaLink = getEnvUrlValue(REACT_APP_COOKIES_OPERA);
export const safariLink = getEnvUrlValue(REACT_APP_COOKIES_SAFARI);
export const safariMobileLink = getEnvUrlValue(REACT_APP_COOKIES_SAFARI_MOBILE);
export const samsungLink = getEnvUrlValue(REACT_APP_COOKIES_SAMSUNG_INTERNET);

export const isCookiesPopupActive = getEnvBooleanValue(
  REACT_APP_COOKIES_POPUP_ACTIVE
);

export const mainPhoneNumber = getEnvStringValue(REACT_APP_PHONE_NUMBER);

export const reCaptchaSiteKey = getEnvStringValue(REACT_APP_RECAPTCHA_SITE_KEY);

export const useExtendedInfoEkspertDictionary = getEnvBooleanValue(
  REACT_APP_USE_EXTENDED_INFO_EKSPERT_DICTIONARY
);

export const isReferralEnabled = getEnvBooleanValue(REACT_APP_SHOW_REFERRAL);
export const publicUrl = PUBLIC_URL;

export const uploadDocumentsActive = getEnvBooleanValue(
  REACT_APP_UPLOAD_DOCUMENTS_ACTIVE
);

export const isPostponedBonusActive = getEnvBooleanValue(
  REACT_APP_MGM_POSTPONED_BONUS
);

export const isBackgroundOffersLoaderVisible = getEnvBooleanValue(
  BACKGROUND_OFFERS_LOADER_VISIBLE
);

export const isCepikProcessActive = getEnvBooleanValue(
  REACT_APP_CEPIK_PROCESS_ACTIVE
);

export const offersLoadingRetryTimeout = getEnvNumberValue(
  REACT_APP_OFFERS_LOADING_RETRY_TIMEOUT,
  3000
);

export const offersLoadingLoaderModalTimeout = getEnvNumberValue(
  REACT_APP_OFFERS_LOADING_LOADER_MODAL_TIMEOUT,
  20000
);

export const offersLoadingBackgroundLoaderTimeout = getEnvNumberValue(
  REACT_APP_OFFERS_LOADING_BACKGROUND_LOADER_TIMEOUT,
  40000
);

export const offersLoadingResultsTimeout = getEnvNumberValue(
  REACT_APP_OFFERS_LOADING_RESULTS_TIMEOUT,
  70000
);

export const offersLoadingMinimumResultsToShow = getEnvNumberValue(
  REACT_APP_OFFERS_LOADING_MINIMUM_RESULTS_TO_SHOW,
  2
);

export const showOfferRecommendation = getEnvBooleanValue(
  REACT_APP_SHOW_OFFER_RECOMMENDATION
);

export const offerRecommendationOnlyForOperator = getEnvBooleanValue(
  REACT_APP_OFFER_RECOMMENDATION_ONLY_FOR_OPERATOR
);

export const isVehicleTypeControlActive = getEnvBooleanValue(
  REACT_APP_VEHICLE_TYPE_CONTROL_ACTIVE
);

export const openInsuranceStartDatePickerOnInputClick = getEnvBooleanValue(
  REACT_APP_OPEN_INSURANCE_START_DATE_PICKER_ON_INPUT_CLICK
);

export const travelBannerLinkUniqa = getEnvUrlValue(
  REACT_APP_TRAVEL_BANNER_LINK_UNIQA
);

export const travelBannerLinkSignalIduna = getEnvUrlValue(
  REACT_APP_TRAVEL_BANNER_LINK_SIGNAL_IDUNA
);

export const motoBannerLinkPzu = getEnvUrlValue(REACT_APP_MOTO_BANNER_LINK_PZU);
export const motoBannerLinkUNIQA = getEnvUrlValue(
  REACT_APP_MOTO_BANNER_LINK_UNIQA
);

export const isGetLastForOperatorDisabled = getEnvBooleanValue(
  REACT_APP_NEW_QUOTATION_INSTEAD_OF_GET_LAST_FOR_OPERATOR
);

export const historyQuestionsVisibilityBasedOnPersonalData = getEnvBooleanValue(
  REACT_APP_HISTORY_QUESTIONS_VISIBILITY_BASED_ON_PERSONAL_DATA
);

export const insurersVisibleInRejectionInfo = getEnvArrayValue<InsurersEnum>(
  REACT_APP_INSURERS_VISIBLE_IN_REJECTION_INFO
);

export const brandsVisibleInLoaderModalCar = getEnvArrayValue<InsurersEnum>(
  REACT_APP_INSURERS_VISIBLE_IN_LOADER_MODAL_CAR
);

export const brandsVisibleInLoaderModalMotor = getEnvArrayValue<InsurersEnum>(
  REACT_APP_INSURERS_VISIBLE_IN_LOADER_MODAL_MOTOR
);

export const brandsVisibleOnlyInPersonalDataPath = getEnvArrayValue<InsurersEnum>(
  REACT_APP_INSURERS_VISIBLE_IN_REJECTION_INFO
);

export const newOffersLoader = getEnvBooleanValue(REACT_APP_NEW_OFFERS_LOADER);

export const showInfoMessageForSmallerNumberOfOffers = getEnvBooleanValue(
  REACT_APP_SHOW_INFO_MESSAGE_FOR_SMALLER_NUMBER_OF_OFFERS
);

export const aztecRedirectionBanner = getEnvBooleanValue(
  REACT_APP_AZTEC_REDIRECTION_BANNER
);

export const sendRegistrationCertificateBoxEnabled = getEnvBooleanValue(
  REACT_APP_SEND_REGISTRATION_CERTIFICATE_BOX_ENABLED
);

export const isCheckoutFixedSumInfoActive = getEnvBooleanValue(
  REACT_APP_CHECKOUT_FIXED_SUM_INFO_BOX
);

export const shouldShowTravelResultsRecommendationMessage = getEnvBooleanValue(
  REACT_APP_SHOW_TRAVEL_RECOMMENDATION_MESSAGE
);
export const shouldShowAllFiltersForCCUsers = getEnvBooleanValue(
  REACT_APP_SHOW_FILTERS_MORE_FOR_CC_USERS
);
export const shouldShowAllFiltersForAllUsers = getEnvBooleanValue(
  REACT_APP_SHOW_FILTERS_MORE_FOR_ALL_USERS
);

export const assistantMail = getEnvStringValue(REACT_APP_ASSISTANCE_MAIL);

export const assistancePhone = getEnvStringValue(REACT_APP_ASSISTANCE_PHONE);

export const aztecSkpLink = getEnvUrlValue(REACT_APP_AZTEC_SKP_URL);

export const skpRegistrationMail = getEnvStringValue(
  REACT_APP_SKP_APPLICATION_CONTACT_MAIL
);

export const skpRegistrationPhone = getEnvStringValue(
  REACT_APP_SKP_APPLICATION_CONTACT_PHONE
);

export const cookieBotIdReact = getEnvStringValue(REACT_APP_COOKIE_BOT_ID);

export const isMubiLotteryActive = getEnvBooleanValue(
  REACT_APP_MUBI_LOTTERY_ACTIVE
);

export const isHarpieLeadsActive = getEnvBooleanValue(
  REACT_APP_HARPIE_LEADS_ACTIVE
);

export const isCepikCompareModalActive = getEnvBooleanValue(
  REACT_APP_CEPIK_COMPARE_MODAL_ACTIVE
);

export const isMjmLeadsActive = getEnvBooleanValue(REACT_APP_MJM_LEADS_ACTIVE);

export const getNotAllowedPhones = getEnvArrayValue(
  REACT_APP_PHONE_NUMBER_NOT_ALLOWED
);

export const isCepikRequestOnCalculationFormSubmitEnabled = getEnvBooleanValue(
  REACT_APP_ENABLE_CEPIK_REQUEST_ON_CALCULATION_FORM_SUBMIT
);
