export enum TerminationGeneratorEnum {
  Example = 'Przykład',
  TerminationDocumentInfo = 'Dokument pobrany z mubi.pl',
  TerminationGeneratorSection1 = 'Ubezpieczony:',
  TerminationGeneratorSection2 = 'Dotyczy polisy:',
  TerminationPreviewLabel1 = 'miejscowość, data',
  TerminationPreviewLabel2 = 'nazwa zakładu ubezpieczeń',
  TerminationPreviewLabel3 = 'adres zakładu ubezpieczeń',
  TerminationPreviewLabel4 = 'imię i nazwisko ubezpieczonego',
  TerminationPreviewLabel5 = 'ulica, nr domu, mieszkania/lokalu',
  TerminationPreviewLabel6 = 'kod pocztowy, miejscowość',
  TerminationPreviewLabel7 = 'marka, model pojazdu',
  TerminationPreviewLabel8 = 'nr rejestracyjny',
  TerminationPreviewLabel9 = 'nr polisy',
  TerminationSignature = 'data, podpis',
}

export enum TerminationReasonEnum {
  Article28 = 'article28',
  Article28a = 'article28a',
  Article31 = 'article31',
}
