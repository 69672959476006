export interface ValidationObject {
  key: string;
  valid: boolean;
}

const SUFFIX_SEPARATOR = '$';

export function getMessageKeyWithSuffix(questionKey: string, suffix: string) {
  return `${questionKey}${SUFFIX_SEPARATOR}${suffix}`;
}

export function getQuestionKeyFromMessageKey(messageKey: string) {
  return messageKey.includes(SUFFIX_SEPARATOR)
    ? messageKey.split(SUFFIX_SEPARATOR)[0]
    : messageKey;
}

export function getSuffixFromMessageKey(messageKey: string) {
  return messageKey.includes(SUFFIX_SEPARATOR)
    ? messageKey.split(SUFFIX_SEPARATOR)[1]
    : messageKey;
}
