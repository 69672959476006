export enum ValidationErrorCodeEnum {
  CoOwnerLastNameNotSet = 'CO_OWNER_LAST_NAME_NOT_SET',
  CoOwnerNameNotSet = 'CO_OWNER_NAME_NOT_SET',
  CoOwnerPeselNotSet = 'CO_OWNER_PESEL_NOT_SET',
  MainOwnerLastNameNotSet = 'MAIN_OWNER_LAST_NAME_NOT_SET',
  MainOwnerNameNotSet = 'MAIN_OWNER_NAME_NOT_SET',
  MainOwnerPeselNotSet = 'MAIN_OWNER_PESEL_NOT_SET',
  NoVinNumber = 'NO_VIN_NUMBER',
  ToManyUnpaidPolicies = 'toManyUnpaidPolicies',
  WrongAgeGroupForRisk = 'WRONG_AGE_GROUP_FOR_RISK',
}
