import React from 'react';
import { CircularProgress } from '@material-ui/core';

import classNames from 'clsx';

import { LoaderProps } from './Loader.interface';
import { useStyles } from './Loader.styles';

export default function Loader({ className }: LoaderProps) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <CircularProgress />
    </div>
  );
}
