export enum InstallmentsEnum {
  AcceptInstalment = 'Zaakceptuj warunki',
  AgreementSigned = 'Raty Mubi zostały przyznane!',
  AgreementSignedDescription1 = ' Niezbędne dokumenty dotyczące rat wyślemy na Twojego maila.',
  AgreementSignedDescription2 = ' Teraz dokończ zakup ubezpieczenia z ratami Mubi.',
  AllAmount = 'Kwota całkowita',
  AmountOfInstallment = 'Wysokość raty',
  Asterisk = '*',
  BackToPaymentOptions = 'Wróć do wyboru płatności',
  Begin = 'Rozpocznij składanie wniosku',
  Bulletpoint = '\u2022 ',
  CloseAndContinue = 'Zamknij i kontnuuj zakup',
  CloseAndCorrect = 'Zamknij i popraw dane',
  CompletePolicyPurchase = 'Dokończ zakup polisy',
  Congratulations = 'Gratulacje!',
  CongratulationsMubiInstalment = 'Gratulacje! Raty Mubi zostały przyznane.',
  ContinueCheckout = 'Kontynuuj zakup polisy, aby je aktywować.',
  CorrectData = 'Popraw go i dokończ wystawianie wniosku o raty mubi.',
  Cost = 'Koszt',
  CountryOfBirth = 'Kraj urodzenia',
  CreditGranted = 'Pozytywna decyzja! Zaakceptuj warunki by kontynuować.',
  DecisionNegative = 'Decyzja negatywna.',
  DecisionNegativeDescription = 'Przykro nam, ale wygląda na to, że raty Mubi nie są dla Ciebie dostępne. Wróć do podsumowania ubezpieczenia i\xa0wybierz inną metodę płatności.',
  Description1 = 'Rozbij kwotę na ',
  Description2 = ' wygodnych rat i\xa0zamiast obciążać swój budżet jednorazowo, płać co\xa0miesiąc! Po przejściu dalej pojawi\xa0się okno z\xa0weryfikacją.',
  ErrorAddressApartmentNumberTitle = 'Ups... wygląda na to, że podany przez Ciebie numer mieszkania jest nieprawidłowy.',
  ErrorAddressHouseNumberTitle = 'Ups... wygląda na to, że podany przez Ciebie numer domy jest nieprawidłowy.',
  ErrorAddressMailTitle = 'Ups... wygląda na to, że podany przez Ciebie adres e-mail jest nieprawidłowy.',
  ErrorAddressMailUnAcceptedSubTitle = 'Podaj inny adres mailowy i dokończ wystawianie wniosku o raty mubi',
  ErrorAddressMailUnAcceptedTitle = 'Ups! Wygląda na to, że podany przez Ciebie e-mail nie jest akceptowany przez pożyczkodawcę.',
  ErrorAddressMainAndPhoneTitle = 'Ups! Wygląda na to, że podane przez Ciebie numer telefonu i e-mail nie są akceptowane przez pożyczkodawcę.',
  ErrorAddressMainAndPhoneTitleSubTitle = 'Podaj numer komórkowy i poprawny adres e-mail i dokończ wystawienie wniosku o raty mubi.',
  ErrorAddressPhoneNumberSubTitle = 'Popraw go podając numer komórkowy i dokończ wystawianie wniosku o raty mubi.',
  ErrorAddressPhoneNumberTitle = 'Ups! Wygląda na to, że podany przez Ciebie numer telefonu jest nieprawidłowy lub jest numerem stacjonarnym.',
  ErrorAddressPhoneUnAcceptedSubTitle = 'Podaj numer komórkowy i dokończ wystawianie wniosku o raty mubi.',
  ErrorAddressPhoneUnAcceptedTitle = 'Ups! Wygląda na to, że podany przez Ciebie numer telefonu nie jest akceptowany przez pożyczkodawcę.',
  ErrorAddressStreetSubTitle = 'Popraw ją i dokończ wystawianie wniosku o raty mubi. Jeśli Twoja miejscowość nie posiada ulic, wpisz ponownie nazwę miejscowości.',
  ErrorAddressStreetTitle = 'Ups... wygląda na to, że podana przez Ciebie ulica jest nieprawidłowa.',
  ErrorAddressZipCodeTitle = 'Ups... wygląda na to, że podany przez Ciebie kod pocztowy jest nieprawidłowy.',
  ErrroCallBack = 'Nie martw się, oddzwonimy do Ciebie i postaramy się pomóc. Jeśli chcesz załatwić sprawę pilnie, skontaktuj się z nami pod numerem 22 250 02 00.',
  EventModalStep = 'modal_step_view',
  EventNameAllConsents = 'all_consents',
  EventNameBeginApplication = 'begin_application',
  EventNameConsentNextStep = 'consent_next_screen',
  EventNameContractSign = 'contract_sign',
  EventNameFirstSmsConsentNextStep = 'first_consent_sms_next_screen',
  EventNameFirstSmsResend = 'first_SMS_resent',
  EventNameHipro = 'hipro',
  EventNameLoanTermsNextStep = 'loan_terms_next_screen',
  EventNameModalExit = 'modal_exit',
  EventNameRefusalReturn = 'refusal_return',
  EventNameSecondSmsResend = 'second_sms_resent',
  EventNameWongaTerms = 'wonga_terms_agreed',
  EventStepNameEntry = 'entry',
  EventStepNameFirstConsentSms = 'first_consent_sms',
  EventStepNameLoanTerms = 'loan_terms',
  EventStepNameMotionVerification = 'motion_verification',
  EventStepNamePersonalData = 'personal_data',
  EventStepNameRefusal = 'refusal',
  EventStepNameSecondConsentSms = 'second_sms_consent',
  EventStepNameSuccessfulFinish = 'successful_finish',
  FillData = 'Uzupełnij dane',
  FinishCompletingApplication = 'Dokończ wystawianie wniosku',
  FirstInstallmentPaymentBy = 'Pierwsza rata płatna do',
  FrameworkAgreement = 'Umowa ramowa',
  GetReadyDocuments = 'Przygotuj swój dowód osobisty.',
  GoToNextStep = 'Przejdź dalej',
  HiProApplicationNotFilled = 'Aby kontynuować zawarcie umowy ubezpieczenia z Ratami Mubi wypełnij wniosek klikając w “Złóż wniosek o raty Mubi” lub zmień sposób płatności.',
  HiProApplicationNotFilledontinue = 'Aby kontynuować zawarcie umowy ubezpieczenia z Ratami Mubi wypełnij wniosek klikając w “Dokończ wystawianie wniosku” lub zmień sposób płatności.',
  IdCardExpiryDate = 'Data ważności dowodu',
  IdCardIndefinitelyValid = 'Dowód bezterminowy',
  IdCardIssueDate = 'Data wydania dowodu',
  IdCardNumber = 'Numer dowodu osobistego',
  InTotal = 'Razem: ',
  InformationForm = 'Formularz informacyjny',
  Installment = 'rata',
  InstallmentsAreSupportedBy = 'Raty Mubi obsługiwane są przez',
  InstallmentsFrom = 'raty od',
  InterestRate = 'Oprocentowanie',
  LenderConsents = 'Zgody dla pożyczkodawcy',
  MainTitleError = 'Ups! Wygląda na to, że wystąpił błąd',
  ModalBulletpoint1 = 'Wniosek zajmie Ci ',
  ModalBulletpoint1Bold = 'nie więcej niż 60 sekund.',
  ModalBulletpoint2 = 'Polisa będzie aktywna ',
  ModalBulletpoint2Bold = 'w\xa0około 5 minut.',
  ModalBulletpoint3 = 'Pierwszą ratę płacisz ',
  ModalBulletpoint3Bold = 'za miesiąc.',
  ModalBulletpoint4 = 'O zbliżającym się terminie przypomnimy Ci SMSowo.',
  MubiInstallments = 'Raty Mubi',
  MubiInstallmentsRejected = 'Raty Mubi (niedostępne)',
  Next = 'Przejdź dalej',
  OpenApplication = 'Złóż wniosek o raty',
  OpenApplicationOnMubiInstallments = 'Złóż wniosek o raty Mubi',
  PeselLabel = 'PESEL: ',
  PhoneNumberLabel = 'tel. +48 ',
  PleaseBePatient = 'Prosimy o chwilę cierpliwości, powinno to zająć około 30 sekund…',
  PutSmsCode = 'Wprowadź kod SMS',
  RedirectMessage1 = 'Za chwilę dokończysz proces zakupu polisy.',
  RedirectMessage2 = 'Przetwarzamy Twój wniosek ratalny.',
  RequiredFields = ' pola wymagane',
  RrsoDescription = 'Reprezentatywny przykład dla pożyczki ratalnej: Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi 75,45%, całkowita kwota kredytu (bez kredytowanych kosztów) 1000\xa0zł, całkowita kwota do zapłaty 1287,25\xa0zł, oprocentowanie zmienne 18,50%, całkowity koszt kredytu 287,25\xa0zł (w\xa0tym: kredytowana prowizja 183,00\xa0zł, odsetki 104,25\xa0zł), 9\xa0miesięcznych równych rat w\xa0kwocie 128,56\xa0zł i\xa0ostatnia rata w\xa0wysokości 130,21\xa0zł. Kalkulacja została dokonana na dzień 5.02.2024\xa0r. na reprezentatywnym przykładzie.\n\nWysokość pożyczki, wysokość raty oraz liczba dostępnych rat są ustalane indywidualnie w\xa0oparciu o\xa0ocenę zdolności kredytowej klienta. Koszt pożyczki uzależniony jest również od okresu na jaki jej udzielono.',
  RrsoDescriptionTrasti = 'Reprezentatywny przykład dla pożyczki ratalnej: Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi 0%, całkowita kwota kredytu (bez kredytowanych kosztów) 1000 zł, całkowita kwota do zapłaty 1000 zł^^, oprocentowanie stałe 0%, całkowity koszt kredytu 0 zł (w tym: kredytowana prowizja 0 zł, odsetki 0 zł), 5 miesięcznych rat równych w kwocie 166,66 zł i ostatnia rata w wysokości 166,70 zł. Dane aktualne na dzień 13.12.2022 r. Wysokość pożyczki, wysokość raty oraz liczba dostępnych rat są ustalane indywidualnie w oparciu o ocenę zdolności kredytowej klienta. Koszt pożyczki uzależniony jest również od okresu na jaki jej udzielono.',
  SendSmsCodeAgain = 'Wyślij SMS jeszcze raz',
  SignAgreement = 'Podpisz umowę',
  SignBySms = 'Podpisz kodem SMS',
  Slash = '/',
  SmsCode = 'Kod SMS',
  SmsCodeWasSend = 'Kod został wysłany na numer:',
  SmsErrorResent = 'Wysłaliśmy Ci nowy kod, wprowadź go.',
  SmsErrorResentTitle = ' Wprowadzony kod SMS jest nieprawidłowy.',
  StreetLabel = 'ul. ',
  Submit = 'Przejdź do wniosku',
  TickRequiredConsents = 'Zaznacz wymagane zgody',
  VerificationInProgress = 'Trwa weryfikacja Twojego wniosku o&nbsp;raty Mubi',
  WrongSmsCode = 'Wprowadzony kod SMS jest błędny, sprawdź jego poprawność.',
}
