import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'clsx';

import { AppMenuProps, ItemInterface } from './AppMenu.interfaces';
import { MenuItemsEnum, RouteEnum } from 'app/shared/enums';
import { LoggedUserMenu } from 'app/components/LoggedUserMenu';
import { AppMenuLink } from 'app/components/AppMenuLink';
import { HamburgerAppMenu } from 'app/components/HamburgerAppMenu';
import { TopBar } from 'app/components/TopBar';
import { TopBarCallBox } from 'app/components/TopBarCallBox';
import { LoginButton } from 'app/components/LoginButton';
import { useStyles } from './AppMenu.styles';
import { useLogout } from 'app/shared/hooks/useLogout';
import { useUser } from 'app/shared/hooks/useUser';
import { agencyMode, skpRegistrationPhone } from 'app/services/env.service';

const travelNavigationLayoutRoutes = [
  RouteEnum.TRAVEL,
  RouteEnum.TRAVEL_FORM,
  RouteEnum.TRAVEL_FORM_FB,
  RouteEnum.TRAVEL_CALCULATION_RESULTS,
  RouteEnum.TRAVEL_SUCCESSFUL_PURCHASE,
  RouteEnum.TRAVEL_FORM_CHECKOUT,
  RouteEnum.TRAVEL_FORM_PAYMENT,
  RouteEnum.TRAVEL_PAYMENT_REDIRECT,
  RouteEnum.TRAVEL_CONVERTED_TO_APPLICATION,
];

const skpUserAccountRoutes = [
  RouteEnum.USER_EDIT,
  RouteEnum.CHANGE_PASSWORD_PROFILE,
  RouteEnum.YOUR_RECOMMENDATIONS_DIAGNOSTIC,
  RouteEnum.STATION_STATS_MANAGER,
];

export default function AppMenu(props: AppMenuProps) {
  const location = useLocation();
  const classes = useStyles();
  const {
    checkingAuth,
    isLoggedIn,
    isOperator,
    isSkpUser,
    userRoute,
  } = useUser();
  const logout = useLogout(RouteEnum.CALCULATION_FORM);

  const isTravelRoute = travelNavigationLayoutRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  const isSkpUserAccountRoute = skpUserAccountRoutes.some(
    (route) => location.pathname.startsWith(route) && isSkpUser
  );

  const isSkpRegistrationRoute = location.pathname.startsWith(
    RouteEnum.SKP_APPLICATION
  );

  const shouldAddExtraStyleOnAddDocumentView = location.pathname.startsWith(
    RouteEnum.UPLOAD_SIMPLE_DOCUMENTS
  );

  const items: ItemInterface[] = [
    {
      class: classes.calculateOc,
      to: RouteEnum.CALCULATION_FORM,
      title: MenuItemsEnum.OcAcCalculator,
      visible:
        !agencyMode &&
        !isTravelRoute &&
        !isSkpUserAccountRoute &&
        !isSkpRegistrationRoute,
    },
    {
      class: classes.skpRegistration,
      to: RouteEnum.SKP_APPLICATION,
      title: MenuItemsEnum.SkpRegistration,
      visible: isSkpRegistrationRoute,
      contained: true,
    },
  ].filter((item) => item.visible);

  const itemsMobile = [
    ...items,
    {
      to: {
        pathname: RouteEnum.LOGIN,
        state: {
          modal: true,
          from: location.pathname,
        },
      },
      title: MenuItemsEnum.LogIn,
      visible: !isLoggedIn && !isTravelRoute,
    },
    {
      to: {
        pathname: userRoute,
      },
      title: MenuItemsEnum.MyAccount,
      visible: isLoggedIn && !isOperator && !isTravelRoute,
    },
    {
      onClick: logout,
      title: MenuItemsEnum.LogOut,
      visible: isLoggedIn && !isTravelRoute,
    },
  ].filter((item) => item.visible);

  return (
    <TopBar
      className={classNames({
        [classes.addDocumentMenu]: shouldAddExtraStyleOnAddDocumentView,
      })}
    >
      {items.map((item) => (
        <AppMenuLink
          classes={{
            menuLink: classNames(item.class, classes.link),
          }}
          to={item.to}
          title={item.title}
          key={item.title}
          contained={item.contained}
        />
      ))}
      {!agencyMode &&
        !isTravelRoute &&
        !isSkpUserAccountRoute &&
        (isSkpRegistrationRoute ? (
          <TopBarCallBox phoneNumber={skpRegistrationPhone} />
        ) : (
          <TopBarCallBox />
        ))}
      {!checkingAuth &&
        !isTravelRoute &&
        (isLoggedIn ? (
          <LoggedUserMenu />
        ) : (
          <LoginButton className={classes.loginButton} />
        ))}
      {!!itemsMobile.length && (
        <HamburgerAppMenu
          loggedIn={isLoggedIn}
          classes={{
            root: classes.hamburgerMenu,
          }}
          items={itemsMobile}
        />
      )}
    </TopBar>
  );
}
