import { combineReducers } from 'redux';
import { reducer as form, FormStateMap } from 'redux-form';

import { FormsEnum } from 'app/shared/enums';
import { CalculationDataByKeysInterface } from 'app/shared/interfaces/CalculationDataByKeys.interface';
import { FormValuesState, OfferFiltersFormInterface } from './data/form';
import { quotation, QuotationState } from './data/quotation';
import { travelQuotation, TravelQuotationState } from './data/travelQuotation';
import { quotationResult, QuotationResultState } from './data/quotationResult';
import { formSubmission, FormSubmissionState } from './data/formSubmission';
import {
  travelQuotationResult,
  TravelQuotationResultState,
} from './data/travelQuotationResults';
import { formsConfig, FormsConfigState } from './data/formsConfig';
import { user, UserState } from './data/user';
import { notification, NotificationState } from './data/notification';
import { promotionalCode, PromotionalCodeState } from './data/promotionalCode';
import { promotion, PromotionState } from './data/promotion';
import { acquisition, AcquisitionState } from './data/acquisition';
import { quotationConfig, QuotationConfigState } from './data/quotationConfig';
import { referral, ReferralState } from './data/referral';
import { bonusPayout, BonusPayoutState } from './data/bonusPayout';
import { simpleDocuments, SimpleDocumentsState } from './data/simpleDocuments';
import { payment, PaymentState } from './data/payment';
import { DisableNotificationsFormInterface } from 'app/shared/interfaces/DisableNotificationsForm.interface';
import {
  selectedOffer,
  SelectedOfferState,
} from 'app/store/data/selectedOffer';
import { cepikData, CepikDataState } from './data/cepikData';
import { SkpRegistrationFormInterface } from 'app/shared/interfaces/SkpRegistrationForm.interface';

export interface RootState {
  form:
    | FormStateMap
    | {
        [FormsEnum.CalculationForm]: FormValuesState<CalculationDataByKeysInterface>;
        [FormsEnum.CheckoutForm]: FormValuesState<{}>;
        [FormsEnum.OfferFilters]: FormValuesState<OfferFiltersFormInterface>;
        [FormsEnum.CheckoutPaymentForm]: FormValuesState<{}>;
        [FormsEnum.CheckoutPaymentAlertForm]: FormValuesState<{}>;
        [FormsEnum.CallbackForm]: FormValuesState<{}>;
        [FormsEnum.DisableNotificationsForm]: FormValuesState<DisableNotificationsFormInterface>;
        [FormsEnum.TerminationGeneratorForm]: FormValuesState<{}>;
        [FormsEnum.SkpRegistrationForm]: FormValuesState<SkpRegistrationFormInterface>;
        [FormsEnum.RenewalOfProposalForm]: FormValuesState<{}>;
        [FormsEnum.Renewal]: FormValuesState<{}>;
      };
  quotation: QuotationState;
  travelQuotation: TravelQuotationState;
  quotationResult: QuotationResultState;
  travelQuotationResult: TravelQuotationResultState;
  quotationConfig: QuotationConfigState;
  formsConfig: FormsConfigState;
  user: UserState;
  notification: NotificationState;
  payment: PaymentState;
  promotionalCode: PromotionalCodeState;
  promotion: PromotionState;
  referral: ReferralState;
  acquisition: AcquisitionState;
  bonusPayout: BonusPayoutState;
  simpleDocuments: SimpleDocumentsState;
  selectedOffer: SelectedOfferState;
  formSubmission: FormSubmissionState;
  cepikData: CepikDataState;
}

const rootReducer = combineReducers<RootState>({
  form,
  quotation,
  travelQuotation,
  quotationConfig,
  quotationResult,
  travelQuotationResult,
  formsConfig,
  user,
  notification,
  payment,
  promotionalCode,
  promotion,
  referral,
  acquisition,
  bonusPayout,
  simpleDocuments,
  selectedOffer,
  formSubmission,
  cepikData,
});

export default rootReducer;
