import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, shape }: Theme) => ({
  root: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    padding: 15,
    borderRadius: shape.borderRadius,
    fontFamily: 'Lato',
    fontSize: 14,
    lineHeight: 1.5,
    cursor: 'pointer',
    width: '100%',
  },
  icon: {
    marginRight: 15,
    fill: palette.primary.main,
  },
  heading: {
    color: palette.primary.main,
    fontWeight: 'bold',
  },
  right: {
    width: '100%',
    textAlign: 'left',
  },
}));
