import { withStyles } from '@material-ui/core';

import Link from './Link';
import { styles } from './Link.styles';
import { withRouter } from 'react-router';
import { LinkOwnProps, LinkRouteComponentProps } from './Link.interfaces';

const LinkContainer = withRouter<LinkRouteComponentProps & LinkOwnProps>(
  withStyles(styles)(Link)
);

export default LinkContainer;
