import produce from 'immer';

import { PromotionalCodeState } from './state.interface';
import { PromotionalCodeActionsType } from './actions';
import { ActionType } from 'app/store/actionTypes';

const initialState: PromotionalCodeState = {
  id: '',
  text: '',
  promotionAmount: 0,
  header: '',
  promotionType: '',
  promotionTypeDescription: '',
  question: '',
};

const promotionalCodeReducer = (
  state: PromotionalCodeState = initialState,
  action: PromotionalCodeActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_PROMOTIONAL_CODE:
        draft.id = action.payload.id;
        draft.text = action.payload.text;
        draft.promotionAmount = action.payload.promotionAmount;
        draft.header = action.payload.header;
        draft.promotionType = action.payload.promotionType;
        draft.promotionTypeDescription =
          action.payload.promotionTypeDescription;
        draft.question = action.payload.question;
        return;

      default:
        return state;
    }
  });

export default promotionalCodeReducer;
