import { ValidationObject } from 'app/services/validation.service';

export default function validateMinSelectedNo(
  data: { value: Array<string> },
  minSelectedNo?: number
): ValidationObject {
  return {
    key: 'invalid_min_selected_no',
    valid: minSelectedNo ? minSelectedNo <= data.value.length : true,
  };
}
