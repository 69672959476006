import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppMainNotificationProps } from './AppMainNotification.interface';
import { Notification } from 'app/components/Notification';
import { NotificationActions } from 'app/store/data/notification';
import { RootState } from 'app/store/rootReducer';

function AppMainNotification(props: AppMainNotificationProps) {
  const dispatch = useDispatch();
  const notification = useSelector((state: RootState) => state.notification);
  const hideNotification = () =>
    dispatch(NotificationActions.hideNotification());

  return (
    <Notification
      open={notification.isVisible}
      type={notification.type}
      content={notification.content}
      onClose={hideNotification}
    />
  );
}

export default AppMainNotification;
