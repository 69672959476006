import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints }) => ({
  hamburgerMenu: {
    marginLeft: 20,
    display: 'none',
  },
  link: {
    '&:not(:first-child)': {
      marginLeft: 10,
    },
  },
  loginButton: {
    marginLeft: 20,
  },
  [breakpoints.down(980)]: {
    root: {
      padding: '0 20px',
    },
  },
  [breakpoints.down(769)]: {
    loginButton: {
      display: 'none',
    },
    calculateOc: {
      display: 'none',
    },
    skpRegistration: {
      display: 'none',
    },
    howItWorks: {
      display: 'none',
    },
    helpContactBox: {
      display: 'none',
    },
    hamburgerMenu: {
      display: 'block',
      marginLeft: 10,
    },
    loginBtn: {
      display: 'none',
    },
  },
  addDocumentMenu: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
