import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'clsx';
import { useSelector } from 'react-redux';

import { HamburgerAppMenuProps } from './HamburgerAppMenu.interfaces';
import { FullScreenMenu } from 'app/components/FullScreenMenu';
import { NewButton } from 'app/components/NewButton';
import { LabelEnum, RouteEnum } from 'app/shared/enums';
import { HamburgerAnimatedIcon } from 'app/components/HamburgerAnimatedIcon';
import { isFormSubmitting } from 'app/store/data/formSubmission';

export default function HamburgerAppMenu(props: HamburgerAppMenuProps) {
  const isLoading = useSelector(isFormSubmitting);

  const isMounted = useRef(false);
  const routesWithSaveFormBox = [RouteEnum.HOME, RouteEnum.CALCULATION_FORM];
  const routesWithCallBox = [
    RouteEnum.HOME,
    RouteEnum.CALCULATION_FORM,
    RouteEnum.CALCULATION_RESULTS,
  ];
  const routesWithQuotationNumber = [
    RouteEnum.HOME,
    RouteEnum.CALCULATION_FORM,
    RouteEnum.CALCULATION_RESULTS,
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useLayoutEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClick = () => {
    if (isLoading) return;
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    if (!isMounted) {
      return;
    }
    setIsOpen(false);
  };

  const {
    classes,
    items,
    location: { pathname },
    loggedIn,
  } = props;

  return (
    <>
      <NewButton
        onClick={handleClick}
        title={<span className={classes.title}>{LabelEnum.Menu}</span>}
        className={classNames(classes.root, {
          [classes.open]: isOpen,
        })}
        variant={'contained'}
        iconComponent={
          <HamburgerAnimatedIcon onClick={handleClick} isOpen={isOpen} />
        }
      />
      <div
        className={classNames(classes.hamburger, {
          [classes.visibleMenu]: isOpen,
        })}
      >
        <FullScreenMenu
          items={items}
          onItemClick={handleClose}
          showCallbackBox={routesWithCallBox.includes(pathname as RouteEnum)}
          showSaveFormBox={
            !loggedIn && routesWithSaveFormBox.includes(pathname as RouteEnum)
          }
          showQuotationNumber={routesWithQuotationNumber.includes(
            pathname as RouteEnum
          )}
        />
      </div>
    </>
  );
}
