import { EnvValue } from 'app/services/env.service/EnvValue.type';

export function getEnvNumberValue(
  valueFromEnv: EnvValue,
  defaultValue: number
) {
  const convertedNumber = Number(valueFromEnv);

  if (valueFromEnv === '' || isNaN(convertedNumber)) {
    console.warn('Invalid number. Default value returned.');
    return defaultValue;
  }

  return convertedNumber;
}
