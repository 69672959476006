import produce from 'immer';
import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import { QuotationState } from './state.interface';
import { ActionType } from 'app/store/actionTypes';
import { QuotationActionsType } from './actions';
import { PromotionActionsType } from 'app/store/data/promotion';
import { InsuranceTypeEnum } from 'app/shared/enums';

const initialState: QuotationState = {
  id: '',
  number: '',
  status: undefined,
  activeABTests: [],
  cepikMergeResult: null,
};

const quotationReducer = (
  state: QuotationState = initialState,
  action: QuotationActionsType | PromotionActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_QUOTATION:
        const defaultDraftScope = {
          components: [InsuranceTypeEnum.Oc],
        };

        merge(draft, { ...action.payload });

        if (!draft.scope?.components) {
          draft.scope = defaultDraftScope;
        }

        return;
      case ActionType.CLEAR_QUOTATION:
        return initialState;
      case ActionType.CLEAR_PROMOTION:
        draft.promotionId = '';
        return;
      case ActionType.SET_QUOTATION_STATUS:
        draft.status = action.payload;
        return;
      case ActionType.SET_QUOTATION_EMAIL_ADDRESS:
        draft.emailAddress = action.payload;
        return;
      case ActionType.SET_QUOTATION_PHONE_NUMBER:
        draft.phoneNumber = action.payload;
        return;
      case ActionType.SET_QUOTATION_QUESTION:
        set(draft, action.payload.path, action.payload.value);
        return;
      case ActionType.COMPLETE_QUOTATION_DATA:
        return merge({}, state, action.payload);
      default:
        return state;
    }
  });

export default quotationReducer;
