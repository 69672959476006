import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ custom, breakpoints }: Theme) => ({
  root: {
    maxWidth: 400,
    margin: 'auto 20px',
    top: 'auto',
    maxHeight: '96%',
    overflow: 'auto',
    scrollbarColor: 'light',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      borderRadius: 6,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d0d5e1',
      borderRadius: 6,
    },
  },
  rootIE11: {
    top: 100,
  },
  materialModalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'unset',
  },
  title: {
    marginTop: 0,
    fontSize: 32,
    fontFamily: 'Merriweather',
    color: custom.grey[3],
    textAlign: 'center',
  },
}));
