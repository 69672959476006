// tslint:disable:max-line-length

export enum TooltipsEnum {
  InsuranceScope = '<h4>Jaki zakres ubezpieczenia Cię interesuje?</h4><div class="content">Wybierz jaki zakres ubezpieczenia Cię interesuje. Wybór możesz zmienić na dalszym etapie.\n<b>OC</b> - obowiązkowe ubezpieczenie Odpowiedzialności Cywilnej.\n<b>Autocasco</b> - ochrona pojazdu wraz z jego wyposażeniem w zakresie utraty, zniszczenia, uszkodzenia lub kradzieży.\n<b>NNW</b> - Ubezpieczenie od Następstw Nieszczęśliwych Wypadków kierowcy i pasażerów, które powstały w związku z ruchem lub użytkowaniem pojazdu.\n<b>Assistance</b> - pomoc techniczna w nieprzewidzianych sytuacjach na drodze.</div>',
  SparePartsOrOriginal = 'W przypadku braku dostępności alternatywnych części zamiennych, do naprawy zostaną użyte części oryginalne z potrąceniem amortyzacji.',
  TerminationGeneratorAddressDetailsTooltip = '<div class="content">Przepisz adres zameldowania z\xa0dowodu rejestracyjnego. Jeśli dopiero kupiłeś pojazd podaj adres aktualnego właściciela z\xa0umowy kupna\xa0–\xa0sprzedaży.</div>',
  TerminationGeneratorCarDetailsTooltip = '<div class="content">Podaj dane pojazdu z dowodu rejestracyjnego. Wybierz odpowiedzi z list i przepisz numer rejestracyjny.</div>',
  TerminationGeneratorEmailTooltip = '<div class="content">Podaj aktualny adres e-mail, na\xa0który otrzymasz wygenerowany dokument. Wiadomość z\xa0wypowiedzeniem OC wyślemy na\xa0Twoją skrzynkę wkrótce po\xa0kliknięciu przycisku "Generuj wypowiedzenie". Jeśli e-mail nie\xa0dotrze, sprawdź folder Spam lub skontaktuj\xa0się z\xa0nami, pisząc na\xa0wypowiedzenia@mubi.pl lub\xa0dzwoniąc na\xa0+48\xa0(22)\xa0250\xa002\xa000.</div>',
  TerminationGeneratorOwnerDetailsTooltip = '<div class="content">Wypowiedzenie może złożyć właściciel lub współwłaściciel auta. Jeśli w\xa0dowodzie rejestracyjnym widnieje jeszcze poprzedni właściciel, podaj dane aktualnego właściciela.</div>',
  TerminationGeneratorPhoneNumberTooltip = '<div class="content">Podanie numeru telefonu jest opcjonalne. Jeśli podasz numer, możemy skontaktować się z\xa0Tobą, aby pomóc\xa0Ci wybrać najlepsze ubezpieczenie pojazdu.</div>',
  TerminationGeneratorPolicyEndingTooltip = '<div class="content">Jeśli spłacasz składkę ubezpieczenia OC w ratach, podaj datę zakończenia umowy, nie termin płatności następnej lub ostatniej raty.</div>',
  TerminationGeneratorPolicyNumberTooltip = '<div class="content">Podaj numer polisy z\xa0umowy ubezpieczenia OC i\xa0wybierz towarzystwo ubezpieczeń z\xa0listy.</div>',
  TravelGoalCompetitiveSports = '<div class="content">Wybierz to rozszerzenie jeżeli uprawiasz sport zawodowo, bierzesz udział m.in. w\xa0zawodach, imprezach, obozach kondycyjnych i\xa0szkoleniowych.</div>',
  TravelGoalDiving = '<div class="content">Wybierz to rozszerzenie jeśli zamierzasz uprawiać freediving lub nurkowanie z\xa0butlą. Snorkeling nie wymaga zaznaczenia opcji "Nurkowanie".</div>',
  TravelGoalExtremeSports = '<div class="content">Jeżeli zamierzasz uprawiać sporty ekstremalne, sprawdź w\xa0OWU ubezpieczyciela czy uprawiana przez Ciebie dyscyplina jest objęta ochroną.</div>',
  TravelGoalPhysicalWork = '<div class="content">Wybierz to rozszerzenie jeśli wyjeżdżasz do pracy za granicę (np. jeżeli będziesz pracować na wysokościach, wykonywać prace remontowo-budowlane, czy pracować przy rozładunku w\xa0transporcie).</div>',
  TravelGoalSightseeing = '<div class="content">Wybierz to rozszerzenie jeśli zamierzasz odpoczywać i\xa0zwiedzać. Zakres ubezpieczenia będzie obejmował podstawowe sporty takie jak np. aerobik na basenie, spacery po górach lub snorkeling.</div>',
  TravelGoalSkiing = '<div class="content">Zaznacz jeśli będziesz uprawiać narciarstwo lub snowboard na oznakowanych trasach.</div>',
}
