import React from 'react';
import classNames from 'clsx';

import { AppMenuButtonProps } from './AppMenuButton.interfaces';
import { NewButton } from 'app/components/NewButton';

const AppMenuButton: React.FunctionComponent<AppMenuButtonProps> = ({
  classes,
  softLabel,
  ...newButtonProps
}) => {
  return (
    <NewButton
      classes={{
        root: classNames(classes.root, {
          [classes.softLabel]: softLabel,
        }),
      }}
      {...newButtonProps}
    />
  );
};

export default AppMenuButton;
