export enum ScreenEnum {
  CheckoutScreen1 = 'checkout.screen.1',
  CompareCepikDataScreen1 = 'compareCepikData.screen.1',
  CompareCepikDataScreen2 = 'compareCepikData.screen.2',
  DisableNotificationsScreen1 = 'disableNotifications.screen.1',
  LeadScreen1 = 'lead.screen.1',
  LeasingLeadScreen1 = 'leasingLead.screen.1',
  LeasingLeadScreen2 = 'leasingLead.screen.2',
  LeasingLeadScreen3 = 'leasingLead.screen.3',
  LeasingLeadScreenShortPath = 'leasingLead.screen.shortPath',
  OperatorLeadScreen1 = 'operatorLead.screen.1',
  RecommendationScreen1 = 'recommendation.screen.1',
  RenewalRopForm = 'renewal.rop.form',
  Screen1 = 'screen.1',
  Screen2 = 'screen.2',
  Screen3 = 'screen.3',
  ScreenCompleteData = 'screen.complete_data',
  ScreenEmailAddress = 'screen.emailAddress',
  ScreenInsurance = 'screen.insurance',
  ShortCalculationPath = 'calculation.screen.shortPath',
  TerminationGeneratorScreen1 = 'terminationGenerator.screen.1',
  TerminationGeneratorScreen2 = 'terminationGenerator.screen.2',
}
