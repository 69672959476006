import { ActionType } from 'app/store/actionTypes';
import { createAction, ActionsUnion } from 'app/store/helpers';
import { QuotationStatusEnum } from 'app/shared/enums';
import { TravelQuotationState } from 'app/store/data/travelQuotation/state.interface';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';

export const TravelQuotationActions = {
  setQuotation: (payload: TravelQuotationState) =>
    createAction(ActionType.SET_TRAVEL_QUOTATION, payload),
  clearQuotation: () => createAction(ActionType.CLEAR_TRAVEL_QUOTATION),
  setQuotationStatus: (payload: QuotationStatusEnum) =>
    createAction(ActionType.SET_TRAVEL_QUOTATION_STATUS, payload),
  setQuotationQuestion: (path: string, value: JsonPatchValueType) =>
    createAction(ActionType.SET_TRAVEL_QUOTATION_QUESTION, { path, value }),
  setQuotationEmailAddress: (emailAddress: string) =>
    createAction(ActionType.SET_TRAVEL_QUOTATION_EMAIL_ADDRESS, emailAddress),
};

export type QuotationActionsType = ActionsUnion<typeof TravelQuotationActions>;
