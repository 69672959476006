import { EnvValue } from './EnvValue.type';

export const getEnvUrlValue = (valueFromEnv: EnvValue) => {
  const isValueDefined = !!valueFromEnv;
  const isValueValid =
    valueFromEnv?.startsWith('http://') || valueFromEnv?.startsWith('https://');

  if (isValueDefined && !isValueValid) {
    console.warn('Invalid url.');
  }

  return isValueDefined && isValueValid ? valueFromEnv : undefined;
};
