import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import { ReferralSetActionPayload } from './actionPayload.interfaces';

export const ReferralActions = {
  setReferral: (payload: ReferralSetActionPayload) =>
    createAction(ActionType.SET_REFERRAL, payload),
  clearReferral: () => createAction(ActionType.CLEAR_REFERRAL),
};

export type ReferralActionsType = ActionsUnion<typeof ReferralActions>;
