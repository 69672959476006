import { ValidationObject } from 'app/services/validation.service';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

export function isValidCarRegistrationNumber(carRegistrationNumber: string) {
  const hasDifferentThanZeros = /[^0]/.test(carRegistrationNumber);

  return /^[a-z0-9]{4,}$/i.test(carRegistrationNumber) && hasDifferentThanZeros;
}

export default function validateCarRegistrationNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_car_registration_number',
    valid: isValidCarRegistrationNumber(data.value.toString()),
  };
}
