import { withStyles } from '@material-ui/core';

import { styles } from './HamburgerAnimatedIcon.styles';
import HamburgerAnimatedIcon from './HamburgerAnimatedIcon';

const HamburgerAnimatedIconContainer = withStyles(styles)(
  HamburgerAnimatedIcon
);

export default HamburgerAnimatedIconContainer;
