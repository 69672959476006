export enum FieldsEnum {
  AdditionalDrivers1BirthDate = 'additionalDrivers.youngestDriver.birthDate',
  AdditionalDrivers1LicenseMonth = 'additionalDrivers.youngestDriver.licenseMonth',
  AdditionalDrivers1LicenseYear = 'additionalDrivers.youngestDriver.licenseYear',
  AdvertisementCallMeBackConsent = 'consent',
  AdvertisementCallMeBackName = 'name',
  AdvertisementCallMeBackPhone = 'phoneNumber',
  ArriveDate = 'arriveDate',
  BankId = 'policy.cession.bankId',
  BankZipCode = 'policy.cession.bankZipCode',
  ButtonConsentsCalculation = 'consent.all.calculation',
  ButtonConsentsPayment = 'consent.all.payment',
  ButtonConsentsTerminationGenerator = 'allConsentsChecked',
  ButtonConsentsTravel = 'consent.all.travel',
  CarCoownersCount = 'car.coownersCount',
  CarDamageStatus = 'car.damageStatus',
  CarDisposedDescription = 'carDisposedDescription',
  CarDisposedStatus = 'carDisposedStatus',
  CarDoorsCount = 'car.doorsCount',
  CarDrivingWheelLocation = 'car.drivingWheelLocation',
  CarEngineCapacity = 'car.engineCapacity',
  CarFirstRegistrationCountry = 'car.firstRegistrationCountry',
  CarFirstRegistrationDate = 'car.firstRegistrationDate',
  CarFuelType = 'car.fuelType',
  CarHasAdditionalDrivers = 'car.hasAdditionalDrivers',
  CarHorsePower = 'car.horsePower',
  CarIsImportedInTheLast12Months = 'car.isImportedInTheLast12Months',
  CarLeasingCustomMake = 'car.leasingCustomMake',
  CarLeasingCustomModel = 'car.leasingCustomModel',
  CarLeasingCustomProductionYear = 'car.leasingCustomProductionYear',
  CarLeasingVehicleType = 'car.leasingVehicleType',
  CarMake = 'car.make',
  CarMileage = 'car.mileage',
  CarModel = 'car.model',
  CarModelIe = 'car.modelIe',
  CarOwnerPurchaseYear = 'car.ownerPurchaseYear',
  CarPreviousInsurer = 'car.previousInsurer',
  CarProductionYear = 'car.productionYear',
  CarRegistrationNumber = 'car.registrationNumber',
  CarRegistrationNumberNotKnown = 'car.registrationNumberNotKnown',
  CarShowLessRestrictedIeModels = 'car.showLessRestrictedIeModels',
  CarVersionEt = 'car.versionEt',
  CarVersionIe = 'car.versionIe',
  CarVin = 'car.vin',
  CepikFormCoownersCount = 'cepikForm.coownersCount',
  CessionRequired = 'policy.cession.cessionRequired',
  CheckoutCarRegistrationNumber = 'car.checkoutRegistrationNumber',
  CheckoutCoowners1FamilyName = 'coowners.1.familyNameCheckoutForm',
  CheckoutCoowners1LastName = 'coowners.1.lastNameCheckoutForm',
  CheckoutCoowners1Pesel = 'coowners.1.peselCheckoutForm',
  CheckoutCoowners2FamilyName = 'coowners.2.familyNameCheckoutForm',
  CheckoutCoowners2LastName = 'coowners.2.lastNameCheckoutForm',
  CheckoutCoowners2Pesel = 'coowners.2.peselCheckoutForm',
  CheckoutInsurantCountryOfOrigin = 'checkout.insurant.countryOfOrigin',
  CheckoutInsurantDifferentHomeAddress = 'checkout.insurant.differentHomeAddress',
  CheckoutInsurantEmail = 'emailAddress',
  CheckoutInsurantHomeAddressApartmentNumber = 'checkout.insurant.homeAddress.apartmentNumber',
  CheckoutInsurantHomeAddressCity = 'checkout.insurant.homeAddress.city',
  CheckoutInsurantHomeAddressStreetName = 'checkout.insurant.homeAddress.streetName',
  CheckoutInsurantHomeAddressStreetNumber = 'checkout.insurant.homeAddress.streetNumber',
  CheckoutInsurantHomeAddressZipCode = 'checkout.insurant.homeAddress.zipCode',
  CheckoutInsurantLastName = 'checkout.insurant.lastName',
  CheckoutInsurantLegalAddressCity = 'checkout.insurant.legalAddress.city',
  CheckoutInsurantLegalAddressZipCode = 'checkout.insurant.legalAddress.zipCode',
  CheckoutInsurantName = 'checkout.insurant.name',
  CheckoutInsurantPassport = 'checkout.insurant.passport',
  CheckoutInsurantPesel = 'checkout.insurant.peselNumber',
  CheckoutInsurantPhone = 'phoneNumber',
  CheckoutOwnerFamilyName = 'owner.familyNameCheckoutForm',
  CheckoutOwnerLastName = 'owner.lastNameCheckoutForm',
  CheckoutOwnerName = 'owner.nameCheckoutForm',
  CheckoutOwnerPesel = 'owner.peselCheckoutForm',
  CheckoutPaymentDiscountCode = 'payment.discountCode',
  CheckoutPaymentMethod = 'payment.paymentMethod',
  CheckoutPaymentType = 'payment.paymentType',
  CheckoutPromotionId = 'promotionId',
  CheckoutSkipFraudDetection = 'skipFraudDetection',
  ConsentDataChange = 'consent.dataChange',
  ConsentMarketing = 'consent.marketing',
  ConsentRegulations = 'consent.regulations',
  ContractSigner = 'contract.signer',
  Coowners1AcHistorySame = 'coowners.1.acHistorySame',
  Coowners1AcInsuranceHistoryBonusMalus = 'coowners.1.acInsuranceHistory.bonusMalus',
  Coowners1AcInsuranceHistoryClaims1Year = 'coowners.1.acInsuranceHistory.claims.1.year',
  Coowners1AcInsuranceHistoryClaims2Year = 'coowners.1.acInsuranceHistory.claims.2.year',
  Coowners1AcInsuranceHistoryClaims3Year = 'coowners.1.acInsuranceHistory.claims.3.year',
  Coowners1AcInsuranceHistoryClaims4Year = 'coowners.1.acInsuranceHistory.claims.4.year',
  Coowners1AcInsuranceHistoryClaims5Year = 'coowners.1.acInsuranceHistory.claims.5.year',
  Coowners1AcInsuranceHistoryClaimsCount = 'coowners.1.acInsuranceHistory.claimsCount',
  Coowners1AcInsuranceHistoryLength = 'coowners.1.acInsuranceHistory.length',
  Coowners1BirthDate = 'coowners.1.birthDate',
  Coowners1ChildAge = 'coowners.1.childAge',
  Coowners1DifferentHomeAddress = 'coowners.1.differentHomeAddress',
  Coowners1DriverLicenseMonth = 'coowners.1.licenseMonth',
  Coowners1DriverLicenseYear = 'coowners.1.licenseYear',
  Coowners1FamilyName = 'coowners.1.familyName',
  Coowners1Gender = 'coowners.1.gender',
  Coowners1HasChildren = 'coowners.1.hasChildren',
  Coowners1HomeAddressCity = 'coowners.1.homeAddress.city',
  Coowners1HomeAddressNotSameAsLegal = 'coowners.1.homeAddress.notSameAsLegal',
  Coowners1HomeAddressZipCode = 'coowners.1.homeAddress.zipCode',
  Coowners1InsuranceHistorySame = 'coowners.1.insuranceHistory.same',
  Coowners1LastName = 'coowners.1.lastName',
  Coowners1LegalAddressApartmentNumber = 'coowners.1.legalAddress.apartmentNumber',
  Coowners1LegalAddressCity = 'coowners.1.legalAddress.city',
  Coowners1LegalAddressStreetName = 'coowners.1.legalAddress.streetName',
  Coowners1LegalAddressStreetNumber = 'coowners.1.legalAddress.streetNumber',
  Coowners1LegalAddressZipCode = 'coowners.1.legalAddress.zipCode',
  Coowners1LicenseMonth = 'coowners.1.licenseMonth',
  Coowners1LicenseYear = 'coowners.1.licenseYear',
  Coowners1MaritalStatus = 'coowners.1.maritalStatus',
  Coowners1Name = 'coowners.1.name',
  Coowners1NoDrivingLicense = 'coowners.1.noDrivingLicense',
  Coowners1OcInsuranceHistoryBonusMalus = 'coowners.1.ocInsuranceHistory.bonusMalus',
  Coowners1OcInsuranceHistoryClaims1Year = 'coowners.1.ocInsuranceHistory.claims.1.year',
  Coowners1OcInsuranceHistoryClaims2Year = 'coowners.1.ocInsuranceHistory.claims.2.year',
  Coowners1OcInsuranceHistoryClaims3Year = 'coowners.1.ocInsuranceHistory.claims.3.year',
  Coowners1OcInsuranceHistoryClaims4Year = 'coowners.1.ocInsuranceHistory.claims.4.year',
  Coowners1OcInsuranceHistoryClaims5Year = 'coowners.1.ocInsuranceHistory.claims.5.year',
  Coowners1OcInsuranceHistoryClaimsCount = 'coowners.1.ocInsuranceHistory.claimsCount',
  Coowners1OcInsuranceHistoryLength = 'coowners.1.ocInsuranceHistory.length',
  Coowners1Occupation = 'coowners.1.occupation',
  Coowners1OldestChildAge = 'coowners.1.oldestChildBirthYear',
  Coowners1Pesel = 'coowners.1.pesel',
  Coowners1YoungestChildAge = 'coowners.1.youngestChildAge',
  Coowners2AcHistorySame = 'coowners.2.acHistorySame',
  Coowners2AcInsuranceHistoryBonusMalus = 'coowners.2.acInsuranceHistory.bonusMalus',
  Coowners2AcInsuranceHistoryClaims1Year = 'coowners.2.acInsuranceHistory.claims.1.year',
  Coowners2AcInsuranceHistoryClaims2Year = 'coowners.2.acInsuranceHistory.claims.2.year',
  Coowners2AcInsuranceHistoryClaims3Year = 'coowners.2.acInsuranceHistory.claims.3.year',
  Coowners2AcInsuranceHistoryClaims4Year = 'coowners.2.acInsuranceHistory.claims.4.year',
  Coowners2AcInsuranceHistoryClaims5Year = 'coowners.2.acInsuranceHistory.claims.5.year',
  Coowners2AcInsuranceHistoryClaimsCount = 'coowners.2.acInsuranceHistory.claimsCount',
  Coowners2AcInsuranceHistoryLength = 'coowners.2.acInsuranceHistory.length',
  Coowners2BirthDate = 'coowners.2.birthDate',
  Coowners2ChildAge = 'coowners.2.childAge',
  Coowners2DifferentHomeAddress = 'coowners.2.differentHomeAddress',
  Coowners2DriverLicenseMonth = 'coowners.2.licenseMonth',
  Coowners2DriverLicenseYear = 'coowners.2.licenseYear',
  Coowners2FamilyName = 'coowners.2.familyName',
  Coowners2Gender = 'coowners.2.gender',
  Coowners2HasChildren = 'coowners.2.hasChildren',
  Coowners2HomeAddressCity = 'coowners.2.homeAddress.city',
  Coowners2HomeAddressNotSameAsLegal = 'coowners.2.homeAddress.notSameAsLegal',
  Coowners2HomeAddressZipCode = 'coowners.2.homeAddress.zipCode',
  Coowners2InsuranceHistorySame = 'coowners.2.insuranceHistory.same',
  Coowners2LastName = 'coowners.2.lastName',
  Coowners2LegalAddressApartmentNumber = 'coowners.2.legalAddress.apartmentNumber',
  Coowners2LegalAddressCity = 'coowners.2.legalAddress.city',
  Coowners2LegalAddressStreetName = 'coowners.2.legalAddress.streetName',
  Coowners2LegalAddressStreetNumber = 'coowners.2.legalAddress.streetNumber',
  Coowners2LegalAddressZipCode = 'coowners.2.legalAddress.zipCode',
  Coowners2LicenseMonth = 'coowners.2.licenseMonth',
  Coowners2LicenseYear = 'coowners.2.licenseYear',
  Coowners2MaritalStatus = 'coowners.2.maritalStatus',
  Coowners2Name = 'coowners.2.name',
  Coowners2NoDrivingLicense = 'coowners.2.noDrivingLicense',
  Coowners2OcInsuranceHistoryBonusMalus = 'coowners.2.ocInsuranceHistory.bonusMalus',
  Coowners2OcInsuranceHistoryClaims1Year = 'coowners.2.ocInsuranceHistory.claims.1.year',
  Coowners2OcInsuranceHistoryClaims2Year = 'coowners.2.ocInsuranceHistory.claims.2.year',
  Coowners2OcInsuranceHistoryClaims3Year = 'coowners.2.ocInsuranceHistory.claims.3.year',
  Coowners2OcInsuranceHistoryClaims4Year = 'coowners.2.ocInsuranceHistory.claims.4.year',
  Coowners2OcInsuranceHistoryClaims5Year = 'coowners.2.ocInsuranceHistory.claims.5.year',
  Coowners2OcInsuranceHistoryClaimsCount = 'coowners.2.ocInsuranceHistory.claimsCount',
  Coowners2OcInsuranceHistoryLength = 'coowners.2.ocInsuranceHistory.length',
  Coowners2Occupation = 'coowners.2.occupation',
  Coowners2OldestChildAge = 'coowners.2.oldestChildBirthYear',
  Coowners2Pesel = 'coowners.2.pesel',
  Coowners2YoungestChildAge = 'coowners.2.youngestChildAge',
  CorrespondanceAddress = 'checkout.insurant.correspondenceAddress',
  Documents = 'documents',
  EmailAddress = 'emailAddress',
  FillNameAndLastName = 'fill.nameAndLastName',
  HiProConsent = 'hi_pro',
  InsuranceCepikConsent = 'consent.cepik',
  InsuranceContactConsent = 'consent.contact',
  InsuranceEmail = 'insurance.email',
  InsuranceMarketingConsent = 'consent.marketing',
  InsuranceMarketingEmailConsent = 'consent.marketing#2',
  InsurancePhoneNumber = 'insurance.phoneNumber',
  InsuranceScope = 'insurance.scope',
  InsuranceStartDate = 'insurance.startDate',
  InsurantEmail = 'insurant.email',
  InsurantFillData = 'checkout.insurant.fill.data',
  InsurantNip = 'insurant.nip',
  IsSickPerson = 'travel.isSickPerson',
  LegalStatusLeased = 'legalStatus.leased',
  Link4InspectionConsent = 'link4:INSPECTION',
  ListOfPeople = 'listOfPeople',
  MubiLotteryInsurantPesel = 'pesel',
  NumberOfInstallments = 'payment.numberOfInstallments',
  NumberOfPersons = 'travel.numberOfPersons',
  OwnerAcHistorySame = 'owner.acHistorySame',
  OwnerAcInsuranceHistoryBonusMalus = 'owner.acInsuranceHistory.bonusMalus',
  OwnerAcInsuranceHistoryClaims1Year = 'owner.acInsuranceHistory.claims.1.year',
  OwnerAcInsuranceHistoryClaims2Year = 'owner.acInsuranceHistory.claims.2.year',
  OwnerAcInsuranceHistoryClaims3Year = 'owner.acInsuranceHistory.claims.3.year',
  OwnerAcInsuranceHistoryClaims4Year = 'owner.acInsuranceHistory.claims.4.year',
  OwnerAcInsuranceHistoryClaims5Year = 'owner.acInsuranceHistory.claims.5.year',
  OwnerAcInsuranceHistoryClaimsCount = 'owner.acInsuranceHistory.claimsCount',
  OwnerAcInsuranceHistoryLength = 'owner.acInsuranceHistory.length',
  OwnerBirthDate = 'owner.birthDate',
  OwnerCountryOfOrigin = 'owner.countryOfOrigin',
  OwnerDataConsent = 'consent.ownerData',
  OwnerDriverLicenseMonth = 'owner.driverLicenseMonth',
  OwnerDriverLicenseYear = 'owner.driverLicenseYear',
  OwnerFamilyName = 'owner.familyName',
  OwnerGender = 'owner.gender',
  OwnerHasChildren = 'owner.hasChildren',
  OwnerHomeAddressApartmentNumber = 'owner.homeAddress.apartmentNumber',
  OwnerHomeAddressCity = 'owner.homeAddress.city',
  OwnerHomeAddressNotSameAsLegal = 'owner.homeAddress.notSameAsLegal',
  OwnerHomeAddressStreetName = 'owner.homeAddress.streetName',
  OwnerHomeAddressStreetNumber = 'owner.homeAddress.streetNumber',
  OwnerHomeAddressZipCode = 'owner.homeAddress.zipCode',
  OwnerLastName = 'owner.lastName',
  OwnerLegalAddressApartmentNumber = 'owner.legalAddress.apartmentNumber',
  OwnerLegalAddressCity = 'owner.legalAddress.city',
  OwnerLegalAddressStreetName = 'owner.legalAddress.streetName',
  OwnerLegalAddressStreetNumber = 'owner.legalAddress.streetNumber',
  OwnerLegalAddressZipCode = 'owner.legalAddress.zipCode',
  OwnerMaritalStatus = 'owner.maritalStatus',
  OwnerName = 'owner.name',
  OwnerNoDrivingLicense = 'owner.noDrivingLicense',
  OwnerOcInsuranceHistoryBonusMalus = 'owner.ocInsuranceHistory.bonusMalus',
  OwnerOcInsuranceHistoryClaims1Year = 'owner.ocInsuranceHistory.claims.1.year',
  OwnerOcInsuranceHistoryClaims2Year = 'owner.ocInsuranceHistory.claims.2.year',
  OwnerOcInsuranceHistoryClaims3Year = 'owner.ocInsuranceHistory.claims.3.year',
  OwnerOcInsuranceHistoryClaims4Year = 'owner.ocInsuranceHistory.claims.4.year',
  OwnerOcInsuranceHistoryClaims5Year = 'owner.ocInsuranceHistory.claims.5.year',
  OwnerOcInsuranceHistoryClaimsCount = 'owner.ocInsuranceHistory.claimsCount',
  OwnerOcInsuranceHistoryLength = 'owner.ocInsuranceHistory.length',
  OwnerOccupation = 'owner.occupation',
  OwnerOldestChildAge = 'owner.oldestChildBirthYear',
  OwnerPassport = 'owner.passport',
  OwnerPesel = 'owner.pesel',
  PaymentType = 'payment.paymentType',
  PayoutAnswer = 'answer',
  PayoutBonus = 'bonus',
  PayoutCode = 'code',
  PayoutEmail = 'email',
  PayoutFile = 'file',
  PayoutIban = 'iban',
  PayoutLastname = 'lastName',
  PayoutPhoneNumber = 'phoneNumber',
  PayoutRegistrationNumber = 'registrationNumber',
  PayoutUsername = 'userName',
  PersonalDataPath = 'personalDataPath',
  PhoneNumber = 'phone',
  PlannedUsageNightParkingPlace = 'plannedUsage.nightParkingPlace',
  PlannedUsageWayOfUse = 'plannedUsage.wayOfUse',
  PlannedUsageYearlyMileage = 'plannedUsage.yearlyMileage',
  RecoveryEmailAddress = 'recoveryRequest.emailAddress',
  RegisterPassword = 'password',
  RegisterPasswordRepeat = 'passwordRepeat',
  RegisterUsername = 'username',
  RegistrationNumber = 'registrationNumber',
  RenewalOfProposalPolicyStart = 'startDate',
  Scopes = 'scopes',
  SecurityProtections = 'security.protections',
  SkpRegistrationCity = 'city',
  SkpRegistrationEmail = 'email',
  SkpRegistrationMarketingConsent = 'marketingConsent',
  SkpRegistrationOwnerFirstName = 'ownerFirstName',
  SkpRegistrationOwnerLastName = 'ownerLastName',
  SkpRegistrationPhone = 'phone',
  SkpRegistrationRegulationsConsent = 'regulationsConsent',
  SkpRegistrationSkpId = 'stationNumber',
  SkpRegistrationSkpType = 'type',
  SkpRegistrationStreetName = 'streetName',
  SkpRegistrationStreetNumber = 'streetNumber',
  SkpRegistrationZipCode = 'postCode',
  Sort = 'sort',
  TerminationApartmentNumber = 'vehicleOwner.legalAddress.apartmentNumber',
  TerminationCarRegistrationNumber = 'car.registrationNumber',
  TerminationCity = 'vehicleOwner.legalAddress.city',
  TerminationConsentMarketing = 'consents.marketing.consent',
  TerminationConsentRegulations = 'consents.regulations.consent',
  TerminationEmailAddress = 'emailAddress',
  TerminationInsurancePolicyEnding = 'endDate',
  TerminationInsurancePolicyStart = 'startDate',
  TerminationInsurerName = 'insurerName',
  TerminationLastName = 'vehicleOwner.lastName',
  TerminationName = 'vehicleOwner.name',
  TerminationPhoneNumber = 'phoneNumber',
  TerminationPolicyNumber = 'previousPolicyNumber',
  TerminationPreviousInsurerName = 'previousInsurerName',
  TerminationReason = 'noticeReason',
  TerminationStreetName = 'vehicleOwner.legalAddress.streetName',
  TerminationStreetNumber = 'vehicleOwner.legalAddress.streetNumber',
  TerminationZipCode = 'vehicleOwner.legalAddress.zipCode',
  TravelAllTravelersCurrentlyInPoland = 'travel.allTravelersCurrentlyInPoland',
  TravelCheckoutContractSigner = 'travel.contract.signer',
  TravelCheckoutInsurantLastName = 'insurant.surname',
  TravelCheckoutInsurantName = 'insurant.name',
  TravelCheckoutInsurantPesel = 'insurant.pesel',
  TravelCheckoutInsurantZipCode = 'insurant.homeAddress.zipCode',
  TravelCheckoutTraveler10Pesel = 'travel.travelers.9.pesel',
  TravelCheckoutTraveler1HomeAddressZipCode = 'travel.travelers.0.homeAddress.zipCode',
  TravelCheckoutTraveler1Pesel = 'travel.travelers.0.pesel',
  TravelCheckoutTraveler2HomeAddressZipCode = 'travel.travelers.1.homeAddress.zipCode',
  TravelCheckoutTraveler2Pesel = 'travel.travelers.1.pesel',
  TravelCheckoutTraveler3Pesel = 'travel.travelers.2.pesel',
  TravelCheckoutTraveler4Pesel = 'travel.travelers.3.pesel',
  TravelCheckoutTraveler5Pesel = 'travel.travelers.4.pesel',
  TravelCheckoutTraveler6Pesel = 'travel.travelers.5.pesel',
  TravelCheckoutTraveler7Pesel = 'travel.travelers.6.pesel',
  TravelCheckoutTraveler8Pesel = 'travel.travelers.7.pesel',
  TravelCheckoutTraveler9Pesel = 'travel.travelers.8.pesel',
  TravelDestinationCountryCodes = 'travel.destination.countryCodes',
  TravelDestinationResultsForPoland = 'travel.destination.resultsForPoland',
  TravelEmailAddress = 'travel.emailAddress',
  TravelEndDate = 'travel.endDate',
  TravelGoals = 'travel.travelGoals',
  TravelInsurantPesel = 'insurant.pesel',
  TravelNumberOfPersons = 'travel.numberOfPersons',
  TravelStartDate = 'travel.startDate',
  TravelTravelers10Age = 'travel.travelers.9.age',
  TravelTravelers1Age = 'travel.travelers.0.age',
  TravelTravelers2Age = 'travel.travelers.1.age',
  TravelTravelers3Age = 'travel.travelers.2.age',
  TravelTravelers4Age = 'travel.travelers.3.age',
  TravelTravelers5Age = 'travel.travelers.4.age',
  TravelTravelers6Age = 'travel.travelers.5.age',
  TravelTravelers7Age = 'travel.travelers.6.age',
  TravelTravelers8Age = 'travel.travelers.7.age',
  TravelTravelers9Age = 'travel.travelers.8.age',
  VehicleType = 'vehicleType',
}
