export enum ReferralCodeEnum {
  ReferralCodeModalHeadingA1 = 'Podaruj ',
  ReferralCodeModalHeadingA2 = '\xa0zł od Mubi',
  ReferralCodeModalHeadingC = 'Zaproś znajomych',
  ReferralCodeModalText1 = 'Sprezentuj ',
  ReferralCodeModalText2 = '\xa0zł cashbacku na polisę OC/AC od\xa0Mubi i\xa0otrzymaj 100\xa0zł. Całkowicie za\xa0darmo!',
  ReferralCodeShare1 = 'Bonus dla moich znajomych!',
  ReferralCodeShare2 = 'do każdego ubezpieczenia w porównywarce Mubi. Korzystajcie!',
  ReferralCodeText1 = 'Przekaż znajomemu ',
  ReferralCodeText2 = '\xa0zł cashbacku na polisę OC/AC od\xa0Mubi i\xa0otrzymaj 100\xa0zł. Całkowicie za\xa0darmo!',
  ReferralCodeText3 = 'lub udostępnij w komunikatorze',
}
