import React from 'react';
import { CircularProgress } from '@material-ui/core';
import classNames from 'clsx';

import { FallbackModalProps } from './FallbackModal.interface';
import { CompactModal } from 'app/components/CompactModal';

const FallbackModal: React.FC<FallbackModalProps> = ({
  classes,
  className,
}) => (
  <CompactModal
    withoutHeaderCloseButton
    className={classNames(classes.root, className)}
  >
    <CircularProgress />
  </CompactModal>
);

export default FallbackModal;
