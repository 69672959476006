export enum FerrariVoucherEnum {
  AnswerTheQuestion = 'Odpowiedz na pytanie konkursowe',
  ApplicationAcceptedDescription = 'Voucher wyślemy na przypisany do polisy adres e-mail w ciągu 40 dni. W przypadku negatywnej weryfikacji zgłoszenia, skontaktujemy się z\xa0Tobą na dane podane przy zakupie polisy.',
  ApplicationAcceptedTitle = 'Zgłoszenie przyjęte!',
  FillFormDescription = 'Uzupełnij poniższy formularz, by odebrać voucher.\nJeśli polisa została opłacona, voucher otrzymasz do 40 dni od daty wysłania zgłoszenia.',
  FillInCodeHere = 'Tu wpisz kod',
  GoToBeginning = 'Wróć do początku',
  Heading = 'Odbierz voucher\nna jazdę Ferrari',
  IncorrectPromotionToReceiveVoucher1 = 'Wygląda na to, że nie posiadasz polisy zakupionej ze specjalnym linkiem promocyjnym Ferrari',
  IncorrectPromotionToReceiveVoucher2 = 'Aby otrzymać voucher na przejazd Ferrari musisz posiadać aktywną polisę zakupioną w\xa0Mubi przez specjalny link promocyjny w\xa0czasie trwania akcji.\n\nJeśli uważasz, że spełniasz te warunki, napisz do nas na adres <a href="mailto:ferrari@mubi.pl">ferrari@mubi.pl</a>',
  InformationSafetyInfo = 'Twoje dane są bezpieczne i\xa0nie przekażemy ich do innych firm.',
  PhoneNumberHint = 'Na ten numer telefonu wyślemy kod SMS.',
  PolicyNotFound1 = 'Wygląda na to, że nie posiadasz aktywnej polisy w\xa0Mubi',
  PolicyNotFound2 = 'Aby otrzymać voucher na przejazd Ferrari musisz posiadać aktywną polisę zakupioną w\xa0Mubi przez specjalny link promocyjny w\xa0czasie trwania akcji.\n\nJeśli uważasz, że spełniasz te warunki, napisz do nas na adres <a href="mailto:ferrari@mubi.pl">ferrari@mubi.pl</a>',
  PolicyNotVerified1 = 'Wymagana weryfikacja płatności za polisę',
  PolicyNotVerified2 = 'W celu weryfikacji, wyślij maila z\xa0potwierdzeniem płatności za polisę na adres <a href="mailto:ferrari@mubi.pl">ferrari@mubi.pl</a>\n\nOdpowiadamy zwykle w\xa0ciągu 2\xa0dni roboczych.',
  RegulationsInfo1 = 'Obowiązuje ',
  RegulationsInfo2 = 'regulamin konkursu',
  RegulationsUrl = 'https://mubi.pl/dokumenty/regulamin_ferrari_20240728.pdf',
  SendResponse = 'Wyślij odpowiedź',
  SmsConfirmation1 = 'Potwierdzenie SMS',
  SmsConfirmation2 = 'Wpisz kod wysłany na podany numer telefonu (+48 ',
  SmsConfirmation3 = ')',
  SmsNotReceived = 'Kod SMS nie dotarł? ',
  TooEarlyToReceiveVoucher1 = 'Nie minęło jeszcze 30 dni od zakupu Twojej polisy',
  TooEarlyToReceiveVoucher2 = 'Pamiętaj, że po odbiór vouchera na przejazd Ferrari możesz zgłosić się najwcześniej po 30 dniach od daty zakupu polisy.\n\nJeśli Twoim zdaniem minęło 30 dni, \nnapisz do nas na <a href="mailto:ferrari@mubi.pl">ferrari@mubi.pl</a>',
  VoucherAlreadyUsed1 = 'Jesteś już na liście osób do odbioru vouchera',
  VoucherAlreadyUsed2 = 'Wygląda na to, że mamy już Twoje zgłoszenie odbioru vouchera na przejazd Ferrari. Pamiętaj, że voucher otrzymasz po 40 dniach od zgłoszenia po odbiór.\n\nJeśli uważasz, że 40 dni już minęło, a\xa0nadal nie masz vouchera, napisz do nas na <a href="mailto:ferrari@mubi.pl">ferrari@mubi.pl</a>',
  YourData = 'Twoje dane',
}

export enum FerrariVoucherResponseErrorCodesEnum {
  WrongConfirmationCodePassed = 'wrong-confirmation-code-passed',
  SentConfirmationSmsLimitExceeded = 'sent-confirmation-sms-limit-exceeded',
  CodeConfirmationSmsLimitExceeded = 'code-confirmations-limit-exceeded',
  IncorrectPhoneNumeber = 'request.phone:incorrectPhoneNumber',
  IncorrectRegistrationNumber = 'request.incorrect-registration-number',
  AlreadyRequestedForVoucher = 'already-requested-for-voucher',
  TooEarlyToReceiveVoucher = 'too-early-to-receive-voucher',
  PolicyPaidStatusNotVerified = 'policy-paid-status-not-verified',
  RequestedAfterPolicyEndDate = 'requested-after-policy-end-date',
  IncorrectPromotionToReceiveVoucher = 'incorrect-promotion-to-receive-voucher',
}
