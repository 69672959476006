import { Draft } from 'immer';

import { FormsConfigState } from '../state.interfaces';
import { ValidateFieldActionPayload } from '../actionPayload.interfaces';
import validationConfig from './validationConfig';
import { clearQuestionErrors, setMessageVisibility } from '../reducer';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';
import {
  ControlTypeEnum,
  FieldsEnum,
  FormsEnum,
  LabelEnum,
} from 'app/shared/enums';
import {
  getMessageKeyWithSuffix,
  ValidationObject,
} from 'app/services/validation.service';
import { validateRequired } from 'app/validators';
import { QuestionInterface } from 'app/shared/interfaces/Question.interface';
import { ActionWithPayload } from 'app/store/helpers';

interface ValidatorConfig {
  suffix: string;
  content: string;
  validation?: {
    validator: (
      value: ValidatorDataInterface,
      ...params: Array<QuestionInterface[keyof QuestionInterface]>
    ) => ValidationObject;
    params?: Array<keyof QuestionInterface>;
  };
}

const requireValidatorConfig = {
  suffix: 'required',
  content: LabelEnum.FieldRequired,
  validation: {
    validator: (data: ValidatorDataInterface, type: ControlTypeEnum) =>
      validateRequired(type, data),
    params: ['type'],
  },
};

const makeValidation = (
  validatorConfig: ValidatorConfig,
  {
    question,
    value,
    formName,
  }: {
    value: string | number | boolean;
    question: QuestionInterface;
    formName: FormsEnum;
    formSection?: string;
  }
) => {
  const validate = validatorConfig.validation!.validator;
  const params = validatorConfig.validation!.params || [];

  const validatorData: ValidatorDataInterface = {
    questionKey: question.key as FieldsEnum,
    value,
    formName,
  };
  return validate(
    validatorData,
    ...params.map((questionKey) => question[questionKey])
  );
};

export const makeFieldValidation = (
  state: FormsConfigState,
  payload: ValidateFieldActionPayload
) => (draft: Draft<FormsConfigState>) => {
  const { formName, questionKey, value, formSection } = payload;
  const question = state[formName].questions[questionKey];
  if (!question) return;
  const validatorsForField = [...(validationConfig[question.type] || [])];
  if (question.required) {
    validatorsForField.unshift(requireValidatorConfig);
  }
  validatorsForField
    .filter((config: ValidatorConfig) => config && config.validation)
    .some((validatorConfig: ValidatorConfig) => {
      if (!question.required && !value) {
        clearQuestionErrors(draft, question.key, formName);
        return true;
      }
      const validationError = makeValidation(validatorConfig, {
        formName,
        value,
        formSection,
        question,
      });

      const messageKey = getMessageKeyWithSuffix(
        question.key,
        validationError.key
      );

      setMessageVisibility(draft, {
        formName,
        key: messageKey,
        visible: !validationError.valid,
      });

      return !validationError.valid;
    });
};

export const validateField = (
  state: FormsConfigState,
  action: ActionWithPayload<string, ValidateFieldActionPayload>,
  draft: Draft<FormsConfigState>
) => {
  makeFieldValidation(state, action.payload)(draft);
};
