import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import {
  OfferFromEmailSetActionPayload,
  SelectedOfferSetActionPayload,
} from 'app/store/data/selectedOffer';

export const SelectedOfferActions = {
  setOfferCalculationCompletion: (payload: boolean) =>
    createAction(ActionType.SET_OFFER_CALCULATION_COMPLETION, payload),
  setSelectedOffer: (payload: SelectedOfferSetActionPayload) =>
    createAction(ActionType.SET_SELECTED_OFFER, payload),
  setSelectedOfferInsurerName: (payload: string) =>
    createAction(ActionType.SET_SELECTED_OFFER_INSURER_NAME, payload),
  clearSelectedOfferHasAcUpsale: () =>
    createAction(ActionType.CLEAR_SELECTED_OFFER_HAS_AC_UPSALE),
  setOfferFromEmail: (payload: OfferFromEmailSetActionPayload) =>
    createAction(ActionType.SET_OFFER_FROM_EMAIL, payload),
  setOfferFromEmailAsExpired: () =>
    createAction(ActionType.SET_OFFER_FROM_EMAIL_AS_EXPIRED),
};

export type SelectedOfferActionsType = ActionsUnion<
  typeof SelectedOfferActions
>;
