import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette, shape, shadows, custom }: Theme) =>
  createStyles({
    root: {
      borderRadius: shape.borderRadius,
      fontFamily: 'inherit',
      transition: 'all 0.1s ease-in-out',
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      minHeight: 40,
      fontWeight: 700,
      '& $containedSecondary': {
        fontWeight: 'bold',
      },
    },
    containedSecondary: {},
    label: {
      lineHeight: 1.5,
      textTransform: 'none',
    },
    contained: {},
    containedPrimary: {
      fontWeight: 'bold',
    },
    icon: {
      marginRight: 4,
      width: 20,
      height: 20,
    },
    suffixIcon: {
      marginTop: 1,
      marginLeft: 4,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    wrap: {
      whiteSpace: 'normal',
    },
    labelTitle: {},
    overflowEllipsis: {
      '& $labelTitle': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
      },
    },
    disabled: {},
    buttonProgress: {
      color: palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    sizeLarge: {
      fontWeight: 500,
      borderRadius: 8,
      padding: '14px 25px',
    },
  });
