import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, palette, breakpoints }: Theme) => ({
    phoneButton: {
      marginLeft: 20,
      flexShrink: 0,
      fontWeight: 'normal',
      color: custom.grey[2],
      backgroundColor: custom.grey[0],
      '& svg': {
        color: palette.primary.main,
      },
    },
    modal: {
      maxWidth: 320,
      padding: 0,
      fontFamily: 'Lato',
    },
    modalContent: {
      padding: 20,
      textAlign: 'center',
    },
    icon: {
      height: 60,
      width: 60,
      color: palette.primary.main,
    },
    heading: {
      fontSize: 18,
      color: custom.grey[4],
      fontWeight: 500,
    },
    quotationNumber: {
      fontSize: 22,
      color: custom.grey[3],
    },
    description: {
      fontSize: 14,
      lineHeight: 1.44,
      color: custom.grey[2],
      marginBottom: 20,
    },
    button: {
      fontWeight: 'bold',
    },
    [breakpoints.down('xs')]: {
      phoneButton: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  })
);
