import { LabelEnum } from 'app/shared/enums';
import { UtilService } from 'app/services/util.service';

const phoneNumber = (value: string) => {
  const re = /^[0-9]{9}$/;

  const isValid =
    (re.test(value) && !value?.startsWith('000')) ||
    !UtilService.hasValue(value);

  return isValid ? undefined : LabelEnum.InvalidPhoneNumber;
};

export default phoneNumber;
