import { RootState } from 'app/store/rootReducer';

export const selectCepikData = (state: RootState) => state.cepikData.data;

export const selectCepikMergeData = (state: RootState) =>
  state.cepikData.mergeData;

export const selectIsCepikModalSavingInProgress = (state: RootState) =>
  state.cepikData.isCepikModalSavingInProgress;

export const selectQuotationWithCepikData = (state: RootState) =>
  state.cepikData.quotationWithCepikData;
