import { ThunkResult } from 'app/store/helpers';
import { TravelQuotationResultsService } from 'app/services/travelQuotationResults.service';
import { TravelQuotationResultActions } from 'app/store/data/travelQuotationResults';

export const updateOffer = (offerId: string, resultId: string): ThunkResult => (
  dispatch
) => {
  return TravelQuotationResultsService.getOffer(resultId, offerId).then(
    ({ data }) => {
      dispatch(TravelQuotationResultActions.updateOffer(data));
    }
  );
};
