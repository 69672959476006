import { FieldsEnum, MessagesEnum, ModelPathEnum } from 'app/shared/enums';
import { getMessageKeyWithSuffix } from '../validation.service';

export const calculationOwnersPeselsKeys = {
  questionField: {
    owner: FieldsEnum.OwnerPesel,
    coowner1: FieldsEnum.Coowners1Pesel,
    coowner2: FieldsEnum.Coowners2Pesel,
  },
};

export const completeDataOwnersPeselsKeys = calculationOwnersPeselsKeys;

export const checkoutOwnersPeselKeys = {
  questionField: {
    owner: FieldsEnum.CheckoutOwnerPesel,
    coowner1: FieldsEnum.CheckoutCoowners1Pesel,
    coowner2: FieldsEnum.CheckoutCoowners2Pesel,
    insurant: FieldsEnum.CheckoutInsurantPesel,
  },
  peselAndLicenseYearMismatchMessage: {
    owner: MessagesEnum.CheckoutPeselAndLicenseYearMismatch,
    coowner1: MessagesEnum.CheckoutCoownersPeselAndLicenseYearMismatch,
    coowner2: MessagesEnum.CheckoutCoowners2PeselAndLicenseYearMismatch,
  },
  peselAndGenderMismatchMessage: {
    owner: MessagesEnum.CheckoutOwnerPeselAndGenderMismatch,
    coowner1: MessagesEnum.CheckoutCoownersPeselAndGenderMismatch,
    coowner2: MessagesEnum.CheckoutCoowners2PeselAndGenderMismatch,
  },
  noDriverLicenseInfoProvidedMessage: {
    owner: MessagesEnum.CheckoutOwnerPeselAndGenderMismatch,
    coowner1: MessagesEnum.CheckoutCoownersPeselAndGenderMismatch,
    coowner2: MessagesEnum.CheckoutCoowners2PeselAndGenderMismatch,
  },
  invalidPeselMessage: {
    owner: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutOwnerPesel,
      'invalid_pesel'
    ),
    coowner1: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutCoowners1Pesel,
      'invalid_pesel'
    ),
    coowner2: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutCoowners2Pesel,
      'invalid_pesel'
    ),
  },
  tooYoungOwnerPeselMessage: {
    owner: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutOwnerPesel,
      'too_young_owner_pesel'
    ),
    coowner1: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutCoowners1Pesel,
      'too_young_owner_pesel'
    ),
    coowner2: getMessageKeyWithSuffix(
      FieldsEnum.CheckoutCoowners2Pesel,
      'too_young_owner_pesel'
    ),
  },
  familyNameField: {
    owner: FieldsEnum.CheckoutOwnerFamilyName,
    coowner1: FieldsEnum.CheckoutCoowners1FamilyName,
    coowner2: FieldsEnum.CheckoutCoowners2FamilyName,
  },
  noDriverLicenseModelPath: {
    owner: ModelPathEnum.OwnerNoDriverLicense,
    coowner1: ModelPathEnum.Coowners1NoDriverLicense,
    coowner2: ModelPathEnum.Coowners2NoDriverLicense,
  },
};

export const peselFieldsList = [
  FieldsEnum.OwnerPesel,
  FieldsEnum.Coowners1Pesel,
  FieldsEnum.Coowners2Pesel,
  FieldsEnum.CheckoutOwnerPesel,
  FieldsEnum.CheckoutCoowners1Pesel,
  FieldsEnum.CheckoutCoowners2Pesel,
  FieldsEnum.CheckoutInsurantPesel,
  FieldsEnum.TravelCheckoutTraveler1Pesel,
  FieldsEnum.TravelCheckoutTraveler2Pesel,
  FieldsEnum.TravelCheckoutTraveler3Pesel,
  FieldsEnum.TravelCheckoutTraveler4Pesel,
  FieldsEnum.TravelCheckoutTraveler5Pesel,
  FieldsEnum.TravelCheckoutTraveler6Pesel,
  FieldsEnum.TravelCheckoutTraveler7Pesel,
  FieldsEnum.TravelCheckoutTraveler8Pesel,
  FieldsEnum.TravelCheckoutTraveler9Pesel,
  FieldsEnum.TravelCheckoutTraveler10Pesel,
  FieldsEnum.TravelInsurantPesel,
];
