import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ custom, shape, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: custom.grey[0],
      borderRadius: shape.borderRadius,
      padding: 20,
      fontFamily: 'Lato',
      fontSize: 14,
      lineHeight: 1.5,
      color: custom.grey[2],
    },
    icon: {
      marginRight: 17,
      fill: palette.primary.main,
    },
    link: {
      color: palette.primary.main,
      textDecoration: 'none',
    },
    heading: {
      color: custom.grey[3],
      fontWeight: 'bold',
    },
  });
