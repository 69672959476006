import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import { PromotionalCodeSetActionPayload } from './actionPayload.interfaces';

export const PromotionalCodeActions = {
  setPromotionalCode: (payload: PromotionalCodeSetActionPayload) =>
    createAction(ActionType.SET_PROMOTIONAL_CODE, payload),
};

export type PromotionalCodeActionsType = ActionsUnion<
  typeof PromotionalCodeActions
>;
