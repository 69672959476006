import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ breakpoints, mixins, palette, custom }: Theme) =>
  createStyles({
    root: {
      color: palette.common.black,
      paddingRight: 6,
      paddingLeft: 15,
      paddingBottom: 5,
      paddingTop: 5,
      fontSize: 14,
      '&:hover': {
        backgroundColor: 'inherit',
        color: 'inherit',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      },
      '&$open': {
        backgroundColor: custom.grey[0],
        boxShadow: 'none',
      },
    },
    open: {},
    hamburger: {
      display: 'none',
      top: mixins.toolbar.minHeight,
      left: 0,
      right: 0,
      position: 'absolute',
      zIndex: 9,
    },
    visibleMenu: {
      display: 'block',
    },
    title: {
      marginRight: 4,
    },
    [breakpoints.down('xs')]: {
      root: {
        paddingLeft: 6,
        minWidth: 0,
      },
      title: {
        display: 'none',
      },
    },
  });
