import { LabelEnum } from 'app/shared/enums';
import get from 'lodash-es/get';

const isPasswordMatching = (passwordKey: string) => (
  value: string,
  allValues: {}
) => {
  return value === get(allValues, passwordKey)
    ? undefined
    : LabelEnum.PasswordsNotMatching;
};

export default isPasswordMatching;
