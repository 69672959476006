import moment from 'moment';
import { LabelEnum } from 'app/shared/enums';

const minDateToday = (value: string) => {
  const today = moment();
  const selectedDate = moment(value);

  return selectedDate.isSameOrAfter(today, 'day')
    ? undefined
    : LabelEnum.IncorrectDate;
};

export default minDateToday;
