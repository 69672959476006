import { AxiosPromise, AxiosError } from 'axios';

import { ApiService, RequestParams } from 'app/services/api.service';

export function isNetworkError(error: AxiosError) {
  const isNetworkError = !error.response && error.message.match(/network/gi);
  const isResponseWith500Error = error.response?.status === 500;
  return isNetworkError || isResponseWith500Error;
}

export function retryAxiosPost<T>(
  url: string,
  timeout: number,
  params?: RequestParams
): AxiosPromise<T> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(ApiService.post(url, params)), timeout);
  });
}

export function retryAxiosGet<T>(
  url: string,
  timeout: number,
  params?: RequestParams
): AxiosPromise<T> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(ApiService.get(url, params)), timeout);
  });
}

export function retryAxiosOnNetworkError<T>(
  axiosFn: () => AxiosPromise<T>
): AxiosPromise<T> {
  const tryOnceAgain = (timeout: number) => (
    e: AxiosError
  ): AxiosPromise<T> => {
    return new Promise((resolve, reject) => {
      if (isNetworkError(e)) {
        setTimeout(() => {
          axiosFn().then(resolve, reject);
        }, timeout);
      } else {
        reject(e);
      }
    });
  };
  return axiosFn()
    .catch(tryOnceAgain(300))
    .catch(tryOnceAgain(500))
    .catch(tryOnceAgain(1000));
}
