import produce from 'immer';

import { ActionType } from 'app/store/actionTypes';
import { ReferralState } from './state.interface';
import { ReferralActionsType } from './actions';

const initialState: ReferralState = {
  code: '',
  validFrom: '',
  validTo: '',
  promotionAmount: 0,
  referrerPromotionAmount: 0,
};

const referralReducer = (
  state: ReferralState = initialState,
  action: ReferralActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_REFERRAL:
        draft.code = action.payload.code;
        draft.validFrom = action.payload.validFrom;
        draft.validTo = action.payload.validTo;
        draft.promotionAmount = action.payload.promotionAmount;
        draft.referrerPromotionAmount = action.payload.referrerPromotionAmount;
        return;
      case ActionType.CLEAR_REFERRAL:
        return initialState;
      default:
        return state;
    }
  });

export default referralReducer;
