import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import merge from 'lodash-es/merge';

import store from 'app/store/index';
import { NotificationActions } from 'app/store/data/notification';
import { LabelEnum, MessageTypeEnum } from 'app/shared/enums';
import isLocalhost from 'app/shared/helpers/isLocalhost';
import { retryAxiosOnNetworkError } from 'app/shared/helpers/axiosHelpers';

export const apiUrl =
  (isLocalhost()
    ? process.env.REACT_APP_LOCALHOST_API_URL
    : process.env.REACT_APP_API_URL) ||
  process.env.PUBLIC_URL + process.env.REACT_APP_API_ROOT;

const serverErrorCodes = [
  500,
  501,
  502,
  503,
  504,
  505,
  506,
  507,
  508,
  510,
  511,
];

let abortController: AbortController | undefined;

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    let notificationContent;
    const isNetworkError = !error.response;

    if (isNetworkError) {
      notificationContent = LabelEnum.NetworkError;
    } else {
      const responseStatusCode = error.response!.status;
      const isServerError = serverErrorCodes.includes(responseStatusCode);
      notificationContent = isServerError ? LabelEnum.ServerError : undefined;
    }

    if (notificationContent && !axios.isCancel(error)) {
      store.dispatch(
        NotificationActions.showNotification({
          type: MessageTypeEnum.Danger,
          content: notificationContent,
        })
      );
    }

    return Promise.reject(error);
  }
);

export interface RequestParams {
  [key: string]: string | number | object;
}

export enum HeaderParamType {
  ApplicationJson = 'application/json',
  ApplicationSummaryJson = 'application/summary+json',
  ApplicationJsonPatchJson = 'application/json-patch+json',
  ApplicationData = 'multipart/form-data',
}

const getUrl = (path: string) => apiUrl + path;

const baseConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': HeaderParamType.ApplicationJson,
  },
};

const getConfig = merge({}, baseConfig, {
  responseType: 'json',
});

const postConfig = merge({}, baseConfig, {
  responseType: 'json',
  headers: {
    Accept: HeaderParamType.ApplicationJson,
  },
});

const deleteConfig = merge({}, baseConfig, {
  responseType: 'json',
  headers: {
    Accept: HeaderParamType.ApplicationJson,
  },
});

const patchConfig = merge({}, baseConfig, {
  headers: {
    'Content-Type': HeaderParamType.ApplicationJsonPatchJson,
  },
});

const uploadConfig = merge({}, baseConfig, {
  headers: {
    'Content-Type': HeaderParamType.ApplicationData,
  },
});

export const ApiService = {
  get(path: string, config?: AxiosRequestConfig): AxiosPromise {
    const url = apiUrl + path;

    return axios.get(url, merge({}, getConfig, config));
  },

  getWithoutRace(path: string, config?: AxiosRequestConfig): AxiosPromise {
    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    const url = apiUrl + path;

    return axios
      .get(url, merge({ signal: abortController.signal }, getConfig, config))
      .catch((error) => {
        if (error.code === 'ERR_CANCELED') return new Promise(() => {});
        else throw error;
      });
  },

  retryableGet<T = unknown>(
    path: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return retryAxiosOnNetworkError<T>(() => this.get(path, config));
  },

  getPdf(path: string): AxiosPromise {
    return axios({
      method: 'get',
      url: path,
      responseType: 'blob',
      withCredentials: true,
    });
  },

  getProtectedPdf(path: string, password: string): AxiosPromise {
    return axios({
      method: 'post',
      url: path,
      responseType: 'blob',
      withCredentials: true,
      data: password,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  getMock(mockName: string, config?: AxiosRequestConfig): AxiosPromise {
    return axios.get(`mocks/${mockName}.json`, merge({}, getConfig, config));
  },

  post<T = unknown>(path: string, data?: {}, config?: AxiosRequestConfig) {
    return axios.post<T>(getUrl(path), data, merge({}, postConfig, config));
  },

  retryablePost<T = unknown>(
    path: string,
    data?: {},
    config?: AxiosRequestConfig
  ) {
    return retryAxiosOnNetworkError<T>(() => this.post(path, data, config));
  },

  put(path: string, data?: {}, config?: AxiosRequestConfig): AxiosPromise {
    return axios.put(getUrl(path), data, merge({}, postConfig, config));
  },

  patch(path: string, data: {}, config?: AxiosRequestConfig): AxiosPromise {
    return axios.patch(getUrl(path), data, merge({}, patchConfig, config));
  },

  delete(path: string, config?: AxiosRequestConfig) {
    return axios.delete(getUrl(path), merge({}, deleteConfig, config));
  },

  upload(path: string, data?: {}, config?: AxiosRequestConfig) {
    return axios.post(getUrl(path), data, merge({}, uploadConfig, config));
  },
};
