import React from 'react';
import classNames from 'clsx';
import { useHistory } from 'react-router-dom';

import { CompactModalProps } from './CompactModal.interfaces';
import { Modal } from 'app/components/Modal';
import { isIE11Browser } from 'app/services/util.service';
import { useStyles } from './CompactModal.styles';

export default function CompactModal({
  children,
  className,
  onClose,
  title,
  withoutHeaderCloseButton,
}: CompactModalProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Modal
      opened
      withoutHeaderCloseButton={withoutHeaderCloseButton}
      onClose={onClose || history.goBack}
      classes={{
        root: classNames(classes.root, className, {
          [classes.rootIE11]: isIE11Browser(),
        }),
        materialModalRoot: classes.materialModalRoot,
      }}
    >
      {title && <h2 className={classes.title}>{title}</h2>}
      {children}
    </Modal>
  );
}
