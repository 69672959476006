import { ActionType } from 'app/store/actionTypes';
import { ActionsUnion, createAction } from 'app/store/helpers';
import {
  BonusPayoutSetActionPayload,
  BonusPayoutVerificationActionPayload,
} from './actionPayload.interfaces';

export const BonusPayoutActions = {
  setBonusPayout: (payload: BonusPayoutSetActionPayload) =>
    createAction(ActionType.SET_BONUS_PAYOUT, payload),
  setVerificationData: (payload: BonusPayoutVerificationActionPayload) =>
    createAction(ActionType.SET_VERIFICATION_DATA, payload),
  setProcessId: (processId: string) =>
    createAction(ActionType.SET_PROCESS_ID, { processId }),
  setPolicyRecoveryKey: (policyRecoveryKey: string) =>
    createAction(ActionType.SET_POLICY_RECOVERY_KEY, { policyRecoveryKey }),
  markPathAsAnonymous: () => createAction(ActionType.MARK_PATH_AS_ANONYMOUS),
  setPostponedVariantAsSelected: (payload: boolean) =>
    createAction(ActionType.SET_POSTPONED_AS_SELECTED, payload),
  setPostponedPromotionAmount: (payload: number) =>
    createAction(ActionType.SET_POSTPONED_PROMOTION_AMOUNT, payload),
  setBonusExpireDate: (payload: string) =>
    createAction(ActionType.SET_BONUS_EXPIRE_DATE, payload),
  clearBonusPayout: () => createAction(ActionType.CLEAR_BONUS_PAYOUT),
};

export type BonusPayoutActionsType = ActionsUnion<typeof BonusPayoutActions>;
