import React from 'react';

import { ChunkLoadErrorFallbackProps } from './ChunkLoadErrorFallback.interface';
import { useStyles } from './ChunkLoadErrorFallback.styles';
import { LabelEnum } from 'app/shared/enums';
import { NewButton } from 'app/components/NewButton';
import { CompactModal } from 'app/components/CompactModal';

export default function ChunkLoadErrorFallback(
  props: ChunkLoadErrorFallbackProps
) {
  const classes = useStyles();

  return (
    <CompactModal withoutHeaderCloseButton>
      <h3 className={classes.title}>{LabelEnum.NetworkError}</h3>
      <NewButton
        onClick={() => window.location.reload()}
        title={LabelEnum.RefreshSite}
        color={'primary'}
        variant={'contained'}
        fullWidth
      />
    </CompactModal>
  );
}
