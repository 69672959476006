import { EnvValue } from './EnvValue.type';

/**
 * Converts environment variable to array
 *
 * @param valueFromEnv - value from environment variable
 */
export const getEnvArrayValue = <T>(valueFromEnv: EnvValue): T[] => {
  return valueFromEnv !== undefined
    ? ((valueFromEnv.split(',') as unknown[]) as T[])
    : [];
};
