import { parse } from 'query-string';
import moment from 'moment';
import { matchPath } from 'react-router';

import { QuotationInterface } from 'app/shared/interfaces/Quotation.interface';
import { PromotionCodeResponseErrorCodesEnum } from 'app/shared/enums';
import { QuotationService } from 'app/services/quotation.service';
import { PromotionCodeService } from 'app/services/promotionCode.service';
import { ThunkResult } from 'app/store/helpers';
import { PromotionActions } from 'app/store/data/promotion';
import {
  QuotationActions,
  QuotationState,
  getValidQuotation,
} from 'app/store/data/quotation';
import { publicUrl } from 'app/services/env.service';

export interface CheckPromotionCodeInterface {
  promotionId: string | null;
  promotionPartnerId: string | null;
  quotationId: string;
  resultId: string | null;
  offerId: string | null;
}

export const checkPromotionCodeFromUrl = () => {
  const { pathname, search } = window.location;
  const promoPath = '/promotion-id/:promotionId';

  return (
    (parse(search).promotionId as string | undefined) ||
    (parse(search).p as string | undefined) ||
    matchPath<{ promotionId?: string }>(pathname, {
      path: promoPath,
    })?.params?.promotionId ||
    matchPath<{ promotionId?: string }>(pathname, {
      path: `${publicUrl}${promoPath}`,
    })?.params?.promotionId
  );
};

const checkPromotionPartnerIdFromUrl = (): string | null => {
  return (parse(window.location.search).pid as string) || null;
};

const shouldAddPartialParameter = () =>
  !window.location.pathname.endsWith('/payment');

export const setPromotionData = (
  quotationId: string,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  return PromotionCodeService.getPromotion(
    quotationId,
    shouldAddPartialParameter(),
    resultId,
    offerId
  )
    .then(({ data }) => {
      dispatch(PromotionActions.setPromotion(data));
    })
    .catch((data) => {
      if (data && data.response && data.response.status === 404) {
        if (data.response.data.errorKey) {
          dispatch(
            PromotionActions.setPromotion({
              errorKey: data.response.data.errorKey,
            })
          );
        }
        return PromotionCodeService.setPromotionCode(
          quotationId,
          null,
          null,
          resultId,
          offerId
        );
      }
    });
};

export const checkPromotionCode = ({
  promotionId,
  promotionPartnerId,
  quotationId,
  resultId,
  offerId,
}: CheckPromotionCodeInterface): ThunkResult<Promise<void>> => (dispatch) => {
  return PromotionCodeService.setPromotionCode(
    quotationId,
    promotionId,
    promotionPartnerId,
    resultId,
    offerId
  )
    .then(() => {
      if (promotionId) {
        dispatch(PromotionActions.markPromotionalCodeAsNew());
        return dispatch(setPromotionData(quotationId, resultId, offerId));
      } else {
        dispatch(PromotionActions.clearPromotion());
      }
    })
    .catch(({ response }) => {
      if (promotionId) {
        switch (response?.data?.errorKey) {
          case PromotionCodeResponseErrorCodesEnum.PromotionForAcOnly:
            dispatch(PromotionActions.markPromotionAsForAcOnly(promotionId));
            break;
          case PromotionCodeResponseErrorCodesEnum.PromotionForRenewalOnly:
            dispatch(
              PromotionActions.markPromotionAsForRenewalOnly(promotionId)
            );
            break;
          case PromotionCodeResponseErrorCodesEnum.OfferPriceTooLow:
            dispatch(
              PromotionActions.markPromotionAsOfferPriceTooLow(promotionId)
            );
            break;
          default:
            dispatch(PromotionActions.markPromotionAsExpired(promotionId));
        }
      }
    });
};

const quotationCopyWithSetPromotion = (
  quotationCopy: QuotationInterface,
  promotionCodeFromUrl: string,
  promotionPartnerIdFromUrl: string | null,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  dispatch(QuotationActions.setQuotation(quotationCopy));
  dispatch(
    checkPromotionCode({
      quotationId: quotationCopy.id,
      promotionId: promotionCodeFromUrl,
      promotionPartnerId: promotionPartnerIdFromUrl,
      resultId,
      offerId,
    })
  );
};

const isPromotionActive = (quotation: QuotationInterface | QuotationState) =>
  quotation.promotionApplicableTo
    ? moment(quotation.promotionApplicableTo).diff(moment(), 'hours') >= 0
    : false;

export const syncPromotionWithBackend = (
  quotation: QuotationInterface | QuotationState,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  const promotionCodeFromUrl = checkPromotionCodeFromUrl();
  if (quotation.promotionId && isPromotionActive(quotation)) {
    dispatch(setPromotionData(quotation.id, resultId, offerId));
  } else if (
    !promotionCodeFromUrl &&
    quotation.promotionId &&
    !isPromotionActive(quotation)
  ) {
    dispatch(
      checkPromotionCode({
        quotationId: quotation.id,
        promotionId: null,
        promotionPartnerId: null,
        resultId,
        offerId,
      })
    );
  }
};

export const finalizeQuotationWithPromotion = (
  quotation: QuotationInterface,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  const promotionCodeFromUrl = checkPromotionCodeFromUrl();
  const promotionPartnerIdFromUrl = checkPromotionPartnerIdFromUrl();

  if (promotionCodeFromUrl || quotation.promotionId) {
    if (
      !!promotionCodeFromUrl &&
      quotation.promotionId !== promotionCodeFromUrl
    ) {
      return QuotationService.requestForCopy(quotation.id).then(
        ({ data: quotationCopy }) => {
          return dispatch(
            quotationCopyWithSetPromotion(
              quotationCopy,
              promotionCodeFromUrl,
              promotionPartnerIdFromUrl,
              resultId,
              offerId
            )
          );
        }
      );
    }

    getValidQuotation(quotation).then((validQuotation) => {
      dispatch(syncPromotionWithBackend(validQuotation, resultId, offerId));
      dispatch(QuotationActions.setQuotation(validQuotation));
    });
  } else {
    dispatch(PromotionActions.clearPromotion());
  }
};

export const finalizeNewQuotationWithPromotion = (
  quotationId: string,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  const promotionCodeFromUrl = checkPromotionCodeFromUrl();
  const promotionPartnerIdFromUrl = checkPromotionPartnerIdFromUrl();

  if (promotionCodeFromUrl) {
    dispatch(
      checkPromotionCode({
        promotionId: promotionCodeFromUrl,
        promotionPartnerId: promotionPartnerIdFromUrl,
        quotationId: quotationId,
        resultId,
        offerId,
      })
    );
  }
};

export const finalizeNewQuotationForPartnerWithPromotion = (
  quotationId: string,
  resultId: string | null,
  offerId: string | null
): ThunkResult => (dispatch) => {
  QuotationService.getQuotationById(quotationId).then(({ data: quotation }) => {
    dispatch(syncPromotionWithBackend(quotation, resultId, offerId));
  });
};
