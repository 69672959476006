import { createSelector } from 'reselect';

import compact from 'lodash-es/compact';
import filter from 'lodash-es/filter';
import flatten from 'lodash-es/flatten';
import fromPairs from 'lodash-es/fromPairs';
import includes from 'lodash-es/includes';
import orderBy from 'lodash-es/orderBy';
import reject from 'lodash-es/reject';
import toPairs from 'lodash-es/toPairs';
import uniq from 'lodash-es/uniq';
import values from 'lodash-es/values';

import { insurersVisibleInRejectionInfo } from 'app/services/env.service';
import { RootState } from 'app/store/rootReducer';
import { getFilters, OfferFiltersFormInterface } from 'app/store/data/form';
import { CarState, OffersState } from './state.interfaces';
import { OfferInterface } from 'app/shared/interfaces/Offer.interface';
import {
  FiltersExcessType,
  InsuranceTypeEnum,
  InsurersEnum,
  MoreFiltersTypeEnum,
  QuotationResultStatusEnum,
  SortingCriterionEnum,
  ValidationErrorCodeEnum,
  FilterAmortizationType,
  FilterRepairType,
} from 'app/shared/enums';

import { QuotationResults } from 'app/shared/interfaces/QuotationResults.interface';
import { getSelectedInsuranceScope } from 'app/store/data/quotation';
import { QuotationResultWithOfferIdsInterface } from 'app/shared/interfaces/QuotationResultWithOfferIds.interface';
import { ValidationError } from 'app/shared/interfaces/ValidationError.interface';
import { getInsurerNameAfterRebranding } from 'app/shared/helpers/getInsurerNameAfterRebranding';
import { SortOrder } from 'app/shared/types/SortOrder.type';
import { InsurerInterface } from 'app/shared/interfaces/Insurer.interface';
import {
  filtersACSection,
  filtersAssistanceSection,
} from 'app/shared/dictionaries/insuranceScopeFilterDictionary';
import { SectionFiltersName } from 'app/components/MoreResultsFilters/MoreResultsFilters.interfaces';

export const getOffer = (offerId?: OfferInterface['id']) => (
  state: RootState
): OfferInterface | undefined => {
  return offerId !== undefined
    ? state.quotationResult.offers[offerId]
    : undefined;
};

const getOffers = (state: RootState): OffersState =>
  fromPairs(
    toPairs(state.quotationResult.offers).filter(
      ([key, offer]) => !!offer.price
    )
  );

const getOffersFilteredByInsurersName = (
  offers: Array<OfferInterface>,
  insurerIds: Array<InsurersEnum>
): Array<OfferInterface> => {
  return offers.filter(({ insurerName }) => insurerIds.includes(insurerName));
};

const getOffersFilteredByInsuranceScope = (
  offers: Array<OfferInterface>,
  insuranceScopes: Array<InsuranceTypeEnum>
): Array<OfferInterface> => {
  const insuranceScopeNames = insuranceScopes.map((scopeName) =>
    scopeName.toLowerCase()
  );

  return reject(offers, (offer) =>
    insuranceScopeNames.some((scopeName) => offer[scopeName] === null)
  );
};

const getOffersFilteredByMoreFilters = (
  selectedMoreFilters: MoreFiltersTypeEnum[],
  selectedFilters: OfferFiltersFormInterface,
  offers: OfferInterface[]
): OfferInterface[] => {
  return offers.filter((offer) => {
    return selectedMoreFilters.every((filterName) => {
      const { selectedFilterValue, filterSectionName } = getSettingsForFilter(
        filterName,
        selectedFilters
      );

      const options = offer.carInsuranceOfferViewFilterDto[filterSectionName];
      return options && selectedFilterValue.includes(options[filterName]);
    });
  });
};

const getSettingsForFilter = (
  filterName: MoreFiltersTypeEnum,
  selectedFilters: OfferFiltersFormInterface
) => {
  const isAcFilterSection = filtersACSection.includes(filterName);
  const filterSectionName = isAcFilterSection
    ? SectionFiltersName.AC
    : SectionFiltersName.Assistance;

  const selectedFilterValue: Array<
    FilterAmortizationType | FilterRepairType | FiltersExcessType | boolean
  > = selectedFilters[filterName];

  return { selectedFilterValue, filterSectionName };
};

const getOffersSorted = (
  offers: Array<OfferInterface>,
  sortingCriterion: SortingCriterionEnum,
  selectedInsuranceScope: Array<InsuranceTypeEnum>
): Array<OfferInterface> => {
  const iterateOverInsurerName = ({ insurerName }: OfferInterface) =>
    getInsurerNameAfterRebranding(insurerName);

  let iteratee, order;

  switch (sortingCriterion) {
    case SortingCriterionEnum.PriceAsc:
      iteratee = ['price.finalPrice', iterateOverInsurerName];
      break;
    case SortingCriterionEnum.PriceDesc:
      iteratee = ['price.finalPrice', iterateOverInsurerName];
      order = ['desc' as SortOrder];
      break;
    case SortingCriterionEnum.InsurerAsc:
      iteratee = [iterateOverInsurerName, 'price.finalPrice'];
      break;
    case SortingCriterionEnum.InsurerDesc:
      iteratee = [iterateOverInsurerName, 'price.finalPrice'];
      order = ['desc' as SortOrder];
      break;
  }

  return orderBy(offers, iteratee, order);
};

export const getResults = (state: RootState): QuotationResults => {
  return state.quotationResult.results;
};

export const getResultsArray = createSelector(
  [getResults],
  (results): Array<QuotationResultWithOfferIdsInterface> => values(results)
);

export const getResultById = (
  state: RootState,
  resultId: string
): QuotationResultWithOfferIdsInterface =>
  createSelector(
    [getResultsArray],
    (resultsArray) =>
      resultsArray.find(
        (result) => result.id === resultId
      ) as QuotationResultWithOfferIdsInterface
  )(state);

const FINAL_STATUSES = [
  QuotationResultStatusEnum.Prepared,
  QuotationResultStatusEnum.UpdatePrepared,
  QuotationResultStatusEnum.InternallyRejected,
  QuotationResultStatusEnum.Expired,
  QuotationResultStatusEnum.Rejected,
  QuotationResultStatusEnum.UpdateRejected,
];

export const hasOnlyFinalStatuses = createSelector(
  [getResultsArray],
  (results) => results.every((result) => FINAL_STATUSES.includes(result.status))
);

export const getPreparedOffersArray = createSelector(
  [getOffers, getResultsArray],
  (offers, results): Array<OfferInterface> => {
    const preparedResultsStatuses = [
      QuotationResultStatusEnum.Prepared,
      QuotationResultStatusEnum.UpdatePrepared,
    ];

    const isPreparedResult = ({
      status,
    }: QuotationResultWithOfferIdsInterface) =>
      includes(preparedResultsStatuses, status);

    const preparedResults = filter(results, isPreparedResult);
    const offerIds = flatten(preparedResults.map((result) => result.offers));

    return compact(
      offerIds
        .map((offerId) => offers[offerId])
        .filter((offer) => !offer.doNotShow)
    );
  }
);

export const getOffersCount = (state: RootState): number =>
  getPreparedOffersArray(state).length;

export const getFilteredOffersCount = (state: RootState): number =>
  getOffersFiltered(state).length;

export const getOffersFilteredByGroup = (section: MoreFiltersTypeEnum) =>
  createSelector([getPreparedOffersArray, getFilters], (offers, filters) => {
    if (filters === undefined) {
      return [];
    }

    const selectedMoreFilters = [
      ...filtersACSection,
      ...filtersAssistanceSection,
    ].filter((key) => key in filters && filters[key].length);

    const selectedMoreFiltersWithoutSection = selectedMoreFilters.filter(
      (selectedFilter) => selectedFilter !== section
    );

    return getOffersFilteredByMoreFilters(
      selectedMoreFiltersWithoutSection,
      filters,
      offers
    );
  });

export const getOffersFiltered = createSelector(
  [getPreparedOffersArray, getFilters, getSelectedInsuranceScope],
  (offers, filters, selectedInsuranceScope) => {
    if (filters === undefined) {
      return offers;
    }

    if (Array.isArray(filters.insurers) && filters.insurers.length > 0) {
      offers = getOffersFilteredByInsurersName(offers, filters.insurers);
    }

    if (filters.scopes) {
      offers = getOffersFilteredByInsuranceScope(offers, filters.scopes);
    }

    const selectedMoreFilters = [
      ...filtersACSection,
      ...filtersAssistanceSection,
    ].filter((key) => key in filters && filters[key].length);

    if (selectedMoreFilters.length) {
      offers = getOffersFilteredByMoreFilters(
        selectedMoreFilters,
        filters,
        offers
      );
    }

    offers = filters.sort
      ? getOffersSorted(offers, filters.sort, selectedInsuranceScope)
      : offers;

    return offers;
  }
);

export const selectCarDetails = (state: RootState): CarState | undefined =>
  state.quotationResult.car;

export const getInsuranceStartDate = (state: RootState) => {
  if (state.quotationResult.insuranceDuration !== undefined) {
    return state.quotationResult.insuranceDuration.policyStartDate;
  }

  return '';
};

export const hasSomeOfferVinNumberError = (state: RootState) => {
  return !!state.quotationResult.results[
    InsurersEnum.Hestia
  ]?.validationErrors?.some(
    (error) => error.code === ValidationErrorCodeEnum.NoVinNumber
  );
};

export const selectInsuranceDuration = (state: RootState) =>
  state.quotationResult.insuranceDuration;

export const getRejectedResults = (
  state: RootState
): Array<QuotationResultWithOfferIdsInterface> => {
  const results = state.quotationResult.results;
  const resultsArray = values(results);

  return filter(resultsArray, {
    status: QuotationResultStatusEnum.InternallyRejected,
  });
};

export const getRejectedResultsFilteredByErrorCodes = (
  requiredErrorCodes: ValidationErrorCodeEnum[]
) =>
  createSelector(
    [getRejectedResults],
    (rejectedResults): Array<QuotationResultWithOfferIdsInterface> => {
      const hasErrorCodesFromRequiredList = (
        result: QuotationResultWithOfferIdsInterface
      ) =>
        result.validationErrors?.every(
          ({ code }) => code && requiredErrorCodes.includes(code)
        );

      rejectedResults = rejectedResults.filter(
        (result) =>
          insurersVisibleInRejectionInfo.includes(result.insurer.id) &&
          hasErrorCodesFromRequiredList(result)
      );

      return rejectedResults;
    }
  );

export const getRejectionReasonCodes = createSelector(
  [getRejectedResults],
  (rejectedResults): Array<ValidationErrorCodeEnum> => {
    const rejectedResultsValidationErrors = flatten(
      compact(rejectedResults.map((result) => result.validationErrors))
    );

    return uniq(
      compact(
        rejectedResultsValidationErrors.map(
          (error: ValidationError) => error.code
        )
      )
    );
  }
);

export const getActiveMoreFilters = createSelector([getFilters], (filters) => {
  if (filters === undefined) {
    return [];
  }

  const activeMoreFilters = [...filtersACSection, ...filtersAssistanceSection]
    .map((key) => {
      if (key in filters && filters[key].length) {
        return {
          [key]: filters[key],
        };
      }
      return null;
    })
    .filter(Boolean);

  return activeMoreFilters;
});

export const getActiveScopeFilters = createSelector(
  [getFilters],
  (filters) => filters && filters.scopes
);

export const getInsurersVisibleInRejectionInfoRejectedResults = createSelector(
  [getRejectedResults],
  (rejectedResults) =>
    rejectedResults.filter((result) => {
      const insurersVisibleInRejectionInfoConfig =
        process.env.REACT_APP_INSURERS_VISIBLE_IN_REJECTION_INFO;
      const insurersVisibleInRejectionInfo = insurersVisibleInRejectionInfoConfig
        ? insurersVisibleInRejectionInfoConfig.split(',')
        : [];
      return insurersVisibleInRejectionInfo.includes(result.insurer.id);
    })
);

export const hasInsurersVisibleInRejectionInfoRejections = createSelector(
  [getInsurersVisibleInRejectionInfoRejectedResults],
  (InsurersVisibleInRejectionInfoRejectedResults) =>
    InsurersVisibleInRejectionInfoRejectedResults.length > 0
);

export const getInsurersVisibleInRejectionInfoValidationErrors = createSelector(
  [getInsurersVisibleInRejectionInfoRejectedResults],
  (InsurersVisibleInRejectionInfoRejectedResults) =>
    flatten(
      compact(
        InsurersVisibleInRejectionInfoRejectedResults.map(
          (result) => result.validationErrors
        )
      )
    )
);

export const getFullResultsObject = (state: RootState) =>
  state.quotationResult.fullResultsObject;

export const selectGtmOfferName = (
  offerId: OfferInterface['id'],
  state: RootState
) => {
  const selectedOffer = getOffer(offerId)(state);
  const carDetails = selectCarDetails(state);

  return `${selectedOffer?.insurerName} - ${carDetails?.make} ${
    carDetails?.modelLabel
  } - ${selectInsuranceDuration(state)?.policyDuration}`;
};

export const selectInsurersFromResults = (state: RootState) =>
  state.quotationResult.insurers;

export const selectResultByBrandId = (brandId: InsurersEnum) => (
  state: RootState
) => {
  const COMPANIES_MAPPING: {} = {
    [InsurersEnum.Proama]: [InsurersEnum.Proama, InsurersEnum.Generali],
    [InsurersEnum.Hestia]: [
      InsurersEnum.Mtu24,
      InsurersEnum.YouCanDrive,
      InsurersEnum.ErgoHestia,
    ],
    [InsurersEnum.Axa]: [InsurersEnum.Uniqa],
    [InsurersEnum.Gothaer]: [InsurersEnum.Wiener],
  };

  const insurerId =
    Object.keys(COMPANIES_MAPPING).find((key) =>
      COMPANIES_MAPPING[key].includes(brandId)
    ) || brandId;

  return state.quotationResult.results[insurerId];
};

export const selectResultStatusByBrandId = (brandId: InsurersEnum) => (
  state: RootState
) => {
  const result = selectResultByBrandId(brandId)(state);

  return result?.status;
};

export const getInsurer = (
  state: RootState,
  insurerId: InsurerInterface['id']
): InsurerInterface | undefined => {
  return insurerId !== undefined
    ? state.quotationResult.insurers[insurerId]
    : undefined;
};

export const selectResultsActiveAbTests = (state: RootState) =>
  state.quotationResult.activeABTests;

export const getPreviousQuotationState = (state: RootState) =>
  state.quotationResult.previousQuotation;

export const selectRecommendedOfferId = (state: RootState) =>
  state.quotationResult.recommendedOfferId;
