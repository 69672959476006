import { getNotAllowedPhones } from 'app/services/env.service';
import { ValidationObject } from 'app/services/validation.service';
import { FormsEnum } from 'app/shared/enums';
import { ValidatorDataInterface } from 'app/shared/interfaces/ValidatorData.interface';

const isPhoneNotAllowed = (phone: string) => {
  const phoneNotAllowed = getNotAllowedPhones;

  return phoneNotAllowed.includes(phone);
};

export function isValidPhoneNumber(phoneNumber: string) {
  const reg = /^[0-9]{9}$/;

  return reg.test(phoneNumber) && !isPhoneNotAllowed(phoneNumber);
}

export const validatePhoneNumberIsAllowed = (data: ValidatorDataInterface) => {
  const forms = [
    FormsEnum.CalculationForm,
    FormsEnum.CheckoutForm,
    FormsEnum.TravelCheckoutForm,
  ];
  const { formName } = data;

  return {
    key: 'not_allowed_phone_number',
    valid:
      !forms.includes(formName) || !isPhoneNotAllowed(data.value.toString()),
  };
};

export default function validatePhoneNumber(
  data: ValidatorDataInterface
): ValidationObject {
  return {
    key: 'invalid_phone_number',
    valid: isValidPhoneNumber(data.value.toString()),
  };
}
