import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      whiteSpace: 'pre',
      alignItems: 'center',
      fontSize: 13,
      color: palette.primary.main,
      fontWeight: 400,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    icon: {
      marginRight: spacing(),
      display: 'flex',
      fontSize: 14,
    },
    iconMdi: {
      fontSize: 17,
      marginRight: 5,
    },
  });
