export enum PaymentEnum {
  AmountToPay = 'Kwota do zapłaty: ',
  AnonymousPaymentConfirmation = 'Potwierdzamy zakup ubezpieczenia w Mubi!',
  AuthorizationBankTransfer = 'Autoryzacji dokonasz na stronie banku.',
  BLIK = 'BLIK',
  BankAccountNumber = 'Nr konta bankowego: ',
  BlikCode = 'Kod BLIK',
  BlikCodeDescription = 'z\xa0aplikacji banku',
  BlikPaymentErrorOccured = 'Nie udało się potwierdzić płatności BLIK w\xa0aplikacji banku. Sprawdź, czy masz wystarczające środki na\xa0Twoim koncie.',
  BonusInfo1 = 'Bonus ',
  BonusInfo2 = '\xa0zł jest Twój!',
  BonusInfo3 = 'Instrukcję odbioru wysłaliśmy mailem.',
  CARD = 'Karta płatnicza',
  ChooseBankOrWireTransfer = 'Wybierz bank lub przelew tradycyjny',
  ChooseYourBank = 'Wybierz Twój bank',
  CloseButtonLabel = 'Zamknij',
  ConfirmBlikPayment1 = 'Potwierdź płatność PINem w\xa0aplikacji banku',
  ConfirmBlikPayment2 = 'w\xa0Twoim telefonie...',
  DocumentSendNoEmailAddress = 'Dokumenty oraz dane do płatności wysłaliśmy również na Twojego maila.',
  DocumentSent = 'Dokumenty oraz dane do płatności wysłaliśmy również na Twojego maila: ',
  DownloadApplication = 'pobierz wniosek.',
  DownloadApplicationInfo = 'Wniosek możesz pobrać także teraz: ',
  DownloadPolicy = 'pobierz polisę.',
  DownloadPolicyInfo = 'Polisę możesz pobrać także teraz: ',
  ErrorOccured1 = 'Ups! Coś\xa0poszło\xa0nie\xa0tak...',
  GetReferralLink = 'Odbierz swój link',
  GoToPayment = 'Przejdź do płatności',
  GoToResultsButton = 'Przejdź do rankingu ofert',
  GoToResultsLabel = 'Przejdź do rankingu by wybrać inną ofertę:',
  ImojeConsent1 = 'Płacąc za pośrednictwem Imoje oświadczasz, że zapoznałeś się i\xa0akceptujesz ',
  ImojeConsent2 = 'Regulamin Imoje',
  ImojeConsent3 = ' oraz ',
  ImojeConsent4 = 'Informację o\xa0danych osobowych Imoje',
  ImojeConsent5 = '.',
  ImojeRODOLink = 'https://data.imoje.pl/docs/imoje_informacja_administratora_danych_osobowych.pdf',
  ImojeRegulationsLink = 'https://data.imoje.pl/docs/imoje_regulamin_platnosci.pdf',
  Installment = 'RATA',
  InstallmentPaymentConfirmation = 'potwierdzamy płatność kolejnej raty!',
  InstallmentPaymentConfirmationLast = 'potwierdzamy płatność ostatniej raty!',
  InstallmentsThankYouForPurchasing = 'Dziękujemy i\xa0życzymy bezszkodowej jazdy.',
  InvalidBlikCode = 'Niepoprawny kod BLIK',
  InviteFriends = 'Zaproś znajomych',
  MubiPlusLabel = 'Tylko dla klientów',
  NextInstallmentPaymentDeadline = 'Termin płatności następnej raty to: ',
  Of = 'Z',
  Online = ' online',
  OnlineTransfer = 'Przelew online',
  Paid = 'OPŁACONA',
  PayByBlik = 'Zapłać BLIKIEM',
  PayByTransfer = 'Zapłać przelewem',
  PayInstallment = 'Opłać ratę',
  PayInstallment1 = 'opłać',
  PayInstallment2 = 'ratę za polisę',
  PayInstallment3 = 'dla',
  PayInstallment4 = 'z',
  PayNowForPolicy = 'Dokonaj płatności już teraz.',
  PayPolicy = 'Opłać polisę',
  PayeeDetails = 'Dane odbiorcy: ',
  PaymentConfirmation = 'potwierdzamy płatność za polisę!',
  PaymentConfirmationHeading = 'Polisa opłacona!',
  PaymentConfirmationText1 = 'Dziękujemy za dołączenie do grona naszych klientów.',
  PaymentConfirmationText2 = 'Polisę i dokumenty wysłaliśmy na Twój adres e\u2011mail. ',
  PaymentConfirmationText3 = 'Ubezpieczenie będzie ważne od: ',
  PaymentDeadline = 'Termin płatności mija',
  PaymentDetails = 'Dane do płatności',
  PaymentDetailsWarning1 = 'Uwaga - nie zmieniaj tytułu ani kwoty przelewu!',
  PaymentDetailsWarning2 = 'Pamiętaj o dokonaniu płatności do ',
  PaymentDetailsWarning3 = ', aby ubezpieczenie było ważne.',
  PaymentTimedOut = 'Niestety czas na opłacenie tego ubezpieczenia już minął...',
  PaymentTitle = 'Tytuł płatności: ',
  PolicyAlreadyPaidErrorOccured = 'Wygląda na to, że polisa została już opłacona.',
  PolicyPaidAndValid = 'Twoja polisa jest opłacona i\xa0ważna do: ',
  PolicyValidDate = 'Ochrona rozpoczyna się',
  PolicyValidDate2 = 'Twoja polisa będzie ważna od:',
  PolicyWithDocsSendToEMail = 'Polisę wraz z wymaganymi dokumentami wysłaliśmy na Twojego maila',
  PurchasingConfirm = 'potwierdzamy zakup ubezpieczenia w\xa0Mubi!',
  PurchasingFailed = 'Twoja płatność nie powiodła się.',
  ReferralBoxHeadingA = 'Podaruj bliskim ',
  ReferralBoxHeadingB = 'Podaruj znajomemu zniżkę ',
  ReferralBoxHeadingC = 'Zarabiaj z Mubi',
  ReferralBoxTextA1 = 'Przekaż swój link polecający z ',
  ReferralBoxTextA2 = '\xa0zł cashbacku za polisę znajomym i\xa0odbierz 100\xa0zł po jej opłaceniu!',
  ReferralBoxTextB1 = 'Udostępnij swój link, a znajomy otrzyma cashback ',
  ReferralBoxTextB2 = '\xa0zł za polisę. Odbierz 100\xa0zł po jej opłaceniu!',
  ReferralBoxTextC = 'Przekaż znajomym swój link do skorzystania z\xa0naszej porównywarki i\xa0zarabiaj 100\xa0złotych za każdą opłaconą polisę!',
  RegisterTransactionErrorOccured = 'Nie udało się zainicjować transakcji. Spróbuj ponownie lub wybierz inną metodę płatności.',
  RemindAboutPayment = 'Dokonaj płatności by otrzymać polisę.',
  RemindAboutPayment2 = 'Opłać teraz aby zachować ciągłość ochrony.',
  RemindAboutPaymentWithHour = 'Aby otrzymać polisę, dokonaj płatności do godz. ',
  RenewalPolice = 'odnów swoją polisę',
  SendTransferBy = 'Zrealizuj do: ',
  ShowLess = 'Pokaż mniej',
  ShowMore = 'Pokaż więcej',
  SubTitleBlik = 'Wpisz 6-cyfrowy kod BLIK',
  SubTitleCard = 'Wprowadź dane karty',
  SubTitleOnlineTransfer = 'Wybierz swój bank',
  SubTitleTransfer = 'Online lub tradycyjny',
  ThankYouForPurchasing = 'Dziękujemy za zakup ubezpieczenia w\xa0Mubi i życzymy bezszkodowej jazdy.',
  ThankYouText1 = 'Życzymy bezszkodowej jazdy!',
  ThankYouText2 = 'Zespół Mubi',
  ToPay = 'Do zapłaty: ',
  Transfer = 'Przelew',
  TravelThankYouForPurchasing = 'Dziękujemy za zakup ubezpieczenia w\xa0Mubi i życzymy bezpiecznej podróży.',
  TryAgain = 'Spróbuj opłacić jeszcze raz.',
  WireTransfer = 'Przelew tradycyjny',
  WithMubi = 'z Mubi:',
  YearShortcut = '\xa0r.',
  YouCanResign = 'Jeśli jednak się rozmyślisz, możesz odstąpić od umowy w ciągu 30 dni od dziś.',
  YourPolicyWillBeValid = 'Twoje ubezpieczenie będzie ważne od dnia ',
}
