import { RootState } from 'app/store/rootReducer';
import { getOffer } from 'app/store/data/quotationResult';
import { InsurersEnum } from 'app/shared/enums';

export const selectOfferCalculationCompletion = (state: RootState) => {
  const isComplete = state.selectedOffer.isComplete;
  const isMarked = isComplete !== undefined;

  if (isMarked) {
    return isComplete;
  }

  return Boolean(state.quotation.car?.registrationNumber);
};

export const selectCalculationType = (state: RootState) =>
  selectOfferCalculationCompletion(state) ? 'pełna' : 'niepełna';
export const selectIsSelectedRecommendedOffer = (state: RootState) =>
  state.selectedOffer.isRecommended;

export const selectRecommendedOfferType = (state: RootState) =>
  selectIsSelectedRecommendedOffer(state)
    ? 'rekomendowana'
    : 'nierekomendowana';

export const selectSelectedOfferId = (state: RootState) =>
  state.selectedOffer.offerId;

export const selectSelectedOfferInsurerId = (state: RootState) =>
  getOffer(state.selectedOffer.offerId)(state)?.insurerName as InsurersEnum;

export const selectSelectedOfferInsurerName = (state: RootState) =>
  state.selectedOffer.insurerDisplayName;

export const selectSelectedOfferInternalInstallmentsEstimation = (
  state: RootState
) =>
  getOffer(state.selectedOffer.offerId)(state)?.internalInstallmentsEstimation;

export const selectHasSelectedOfferAlcoholClause = (state: RootState) =>
  state.selectedOffer.hasAlcoholClause;

export const selectHasSelectedOfferAcUpsale = (state: RootState) =>
  state.selectedOffer.hasAcUpsale;

export const selectOfferFromEmail = (state: RootState) =>
  state.selectedOffer.offerFromEmail;
