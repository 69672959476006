export enum MappedOfferDetailsEnum {
  ACMiniScope = 'Zakres AC Mini: ',
  AsoRepair = 'Naprawa w autoryzowanym serwisie',
  EstimateRepair = 'Odszkodowanie AC wypłacane na konto',
  NnwLabel = 'Ubezpieczenie zdrowia',
  SubstituteCar = 'Auto zastępcze',
  TowingAfterAccident = 'Holowanie w razie wypadku',
  TowingAfterFailure = 'Holowanie w razie wypadku lub awarii',
  WorkshopRepair = 'Naprawa w serwisie partnerskim',
}
