export enum MubiLotteryEnum {
  AllPoliciesAlreadyReported1 = 'Zgłosiłeś już wszystkie swoje polisy.',
  AllPoliciesAlreadyReported2 = 'Nie posiadasz nowych polis, które mógłbyś zgłosić.',
  CarEngine = '5.0 V8 GT',
  CarModel = 'Mustanga',
  DrawDateInfo = 'Losowanie odbędzie się: ',
  GetMoreVouchers1a = 'Pomagaj znajomym i zarabiaj!',
  GetMoreVouchers1b = 'Otrzymaj więcej losów!',
  GetMoreVouchers2a = 'Poleć Mubi znajomym poniższym linkiem i podaruj bonus 100 zł na zakup polisy OC lub OC/AC, a otrzymasz 100 zł za każdego poleconego!',
  GetMoreVouchers2b = 'Poleć Mubi znajomym poniższym linkiem i podaruj bonus 100 zł na zakup polisy OC lub OC/AC, a otrzymasz 100 zł za każdego poleconego oraz dodatkowy los.',
  IfYouThinkOtherwise1 = 'Jeśli uważasz, że jest inaczej, napisz do nas na adres ',
  IfYouThinkOtherwise2 = ' i\xa0opisz sytuację. Nasz dział Obsługi Klienta sprawdzi sprawę.',
  JoinToLottery = 'ZGŁOŚ SWÓj UDZIAŁ W\xa0LOTERII',
  LotteryEmail = 'wielkaloteriamubi@mubi.pl',
  MubiFacebookUrl = 'https://pl-pl.facebook.com/mubi.ubezpieczenia/',
  MubiInstagramUrl = 'https://www.instagram.com/mubi.pl/',
  NeedHelp = 'Potrzebujesz pomocy?',
  OtherPoliciesNeedsConfirmation1 = 'Twoim koncie znajdują się też inne polisy, które wymagają dodatkowego potwierdzenia!',
  OtherPoliciesNeedsConfirmation2 = 'Prześlij potwierdzenie zapłaty na adres ',
  OtherPoliciesNeedsConfirmation3 = ', aby otrzymać wszystkie losy.',
  PaymentNeedsToBeConfirmed1 = 'Płatność za Twoją polisę jest obecnie weryfikowana i\xa0musi zostać przez Ciebie potwierdzona.',
  PaymentNeedsToBeConfirmed2 = 'W tym celu prześlij potwierdzenie płatności za polisę na adres ',
  PaymentNeedsToBeConfirmed3 = '. Zostanie ono ręcznie sprawdzone przez dział Obsługi Klienta.',
  PolicyListTextCar = '1 x Polisa ubezpieczenia pojazdu: ',
  PolicyListTextReferral = '1 x Aktywny uczestnik akcji promocyjnej „Polecaj i\xa0zarabiaj”',
  PolicyListTextTravel = '1 x Polisa turystyczna: ',
  PolicyNotFound1 = 'Nie możemy znaleźć polisy.',
  PolicyNotFound2 = 'Na podane dane nie ma aktualnie polis spełniających warunki uczestnictwa w loterii.',
  RegulationsUrl = 'https://promocje-mubi.pl/dokumenty/regulamin-wielka-loteria-mubi-1',
  Ticket = 'los',
  Tickets1 = 'losy',
  Tickets2 = 'losów',
  WatchOurSocials = 'Obserwuj nasze Social Media, by nie przegapić losowania:',
  WinCar = 'Wygraj Forda',
  WriteToUs = 'Napisz na adres <a href=mailto:wielkaloteriamubi@mubi.pl>wielkaloteriamubi@mubi.pl</a>.<br/>Odpowiadamy zwykle w\xa0ciągu 24 godzin.',
  Year = '\xa0r.',
  YouReceived = 'Otrzymujesz',
}

export enum MubiLotteryResponseErrorCodesEnum {
  WrongConfirmationCodePassed = 'wrong-confirmation-code-passed',
  SentConfirmationSmsLimitExceeded = 'sent-confirmation-sms-limit-exceeded',
  CodeConfirmationSmsLimitExceeded = 'code-confirmations-limit-exceeded',
  IncorrectPhoneNumeber = 'request.phone:incorrectPhoneNumber',
  IncorrectPeselNumber = 'request.pesel:incorrectPesel',
}

export enum VoucherSourceEnum {
  Car = 'CAR_POLICY',
  Travel = 'TRAVEL_POLICY',
  Referral = 'REFERRAL',
}
