import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shadows, breakpoints, custom }: Theme) => ({
    root: {
      position: 'relative',
      width: '100%',
      backgroundColor: palette.common.white,
      padding: 8 * 4,
      maxWidth: 960,
      outline: 'none',
      boxShadow: shadows[7],
      margin: '50px auto',
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
    materialModalRoot: {
      overflowY: 'scroll',
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      '-webkit-overflow-scrolling': 'touch',
    },
    blurIE11: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    sticky: {
      height: '100%',
      zIndex: 3,
      position: 'sticky',
      top: 10,
    },
    headerCloseButton: {
      position: 'absolute',
      zIndex: 1,
      right: 8,
      top: 8,
      cursor: 'pointer',
      color: custom.grey[1],
      [breakpoints.down(380)]: {
        right: 0,
        top: 0,
      },
    },
    headerCloseButtonSticky: {
      top: -16,
      right: -16,
    },
    footerCloseButton: {
      margin: '32px auto',
      padding: '7px 55px',
      display: 'block',
      backgroundColor: custom.grey[0],
      fontSize: 15,
      fontWeight: 'bold',
      color: custom.grey[2],
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: custom.grey[0],
        color: custom.grey[2],
        opacity: 0.7,
      },
    },
    [breakpoints.down(970)]: {
      root: {
        width: 'auto',
        padding: 20,
        paddingTop: 45,
        margin: '30px 20px',
      },
      footerCloseButton: {
        margin: '25px auto',
      },
    },
  })
);
