import { makeStyles, Theme } from '@material-ui/core';
import { MessageTypeEnum } from 'app/shared/enums';

export const useStyles = makeStyles(
  ({ palette, custom, shape, shadows }: Theme) => ({
    root: {
      left: 'auto',
      whiteSpace: 'pre-wrap',
    },
    snackbarContentRoot: {
      backgroundColor: palette.background.paper,
      color: palette.text.primary,
      lineHeight: 1.5,
      display: 'flex',
      padding: '20px 38px 20px 15px',
      borderRadius: shape.borderRadius,
      boxShadow: shadows[1],
    },
    snackbarContentMessage: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      position: 'relative',
      color: custom.grey[3],
      fontSize: 14,
      fontWeight: 500,
    },
    iconContainer: {
      display: 'flex',
      marginRight: 13,
      '&:before': {
        borderRadius: shape.borderRadius,
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        backgroundColor: palette.common.white,
      },
    },
    icon: {
      width: 34,
      height: 34,
    },
    leftBorder: {
      position: 'absolute',
      width: 7,
      height: '100%',
      left: 0,
      opacity: 0.5,
      borderTopLeftRadius: shape.borderRadius,
      borderBottomLeftRadius: shape.borderRadius,
    },
    action: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 22,
      color: custom.grey[6],
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      right: 10,
    },
    [MessageTypeEnum.Success]: {
      color: custom.status.success.main,
    },
    [MessageTypeEnum.Warning]: {
      color: palette.primary.main,
    },
    [MessageTypeEnum.Danger]: {
      color: custom.status.danger.contrastText,
    },
    [MessageTypeEnum.Success + 'borderLeft']: {
      backgroundColor: custom.status.success.main,
    },
    [MessageTypeEnum.Warning + 'borderLeft']: {
      backgroundColor: palette.primary.main,
    },
    [MessageTypeEnum.Danger + 'borderLeft']: {
      backgroundColor: custom.status.danger.contrastText,
    },
  })
);
