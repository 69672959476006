import { hotjar } from 'react-hotjar';

import { RouteEnum } from 'app/shared/enums';
import { agencyMode, isNewCookiesStyle } from 'app/services/env.service';

/*
Types for requestIdleCallback from:
https://github.com/microsoft/TypeScript/issues/21309#issuecomment-376338415
 */
type RequestIdleCallbackHandle = object;

type RequestIdleCallbackOptions = {
  timeout: number;
};

type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: () => number;
};

declare global {
  interface Window {
    requestIdleCallback: (
      callback: (deadline: RequestIdleCallbackDeadline) => void,
      opts?: RequestIdleCallbackOptions
    ) => RequestIdleCallbackHandle;
    cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void;
    Cookiebot: {
      consent: {
        preferences: boolean;
        marketing: boolean;
        necessary: boolean;
        statistics: boolean;
      };
    };
  }
}

const {
  REACT_APP_HOTJAR_HJID,
  REACT_APP_HOTJAR_HJSV,
  REACT_APP_TRAVEL_HOTJAR_HJID,
  REACT_APP_TRAVEL_HOTJAR_HJSV,
} = process.env;

export function initHotjar() {
  const { pathname } = window.location;

  const isTravel = pathname.includes(RouteEnum.TRAVEL);

  const hjid = isTravel ? REACT_APP_TRAVEL_HOTJAR_HJID : REACT_APP_HOTJAR_HJID;
  const hjsv = isTravel ? REACT_APP_TRAVEL_HOTJAR_HJSV : REACT_APP_HOTJAR_HJSV;

  if (
    !hjid ||
    !hjsv ||
    agencyMode ||
    (!window.Cookiebot.consent.preferences && isNewCookiesStyle)
  ) {
    return;
  }

  const executeScript = () => hotjar.initialize(+hjid, +hjsv);

  const initScriptWithDelay = () => {
    setTimeout(() => {
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback!(executeScript, { timeout: 1000 });
      } else {
        executeScript();
      }
    }, 1000);

    window.removeEventListener('scroll', initScriptWithDelay, false);
    document.removeEventListener('click', initScriptWithDelay, false);
  };

  window.addEventListener('scroll', initScriptWithDelay, false);
  document.addEventListener('click', initScriptWithDelay, false);
}
