declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export const TagManagerService = {
  addDataLayer: (dataLayer: Object) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(dataLayer);
  },
};
