export enum MessagesEnum {
  BlockIfDamaged = 'blockIfDamaged',
  CalculationInsurantEmailObfuscated = 'calculationInsurantEmailObfuscated',
  CarOwnerFamilyNameObfuscated = 'carOwnerFamilyNameObfuscated',
  CarOwnerHomeAddressStreetNameObfuscated = 'carOwnerHomeAddressStreetNameObfuscated',
  CarOwnerLastNameObfuscated = 'carOwnerLastNameObfuscated',
  CarOwnerLegalAddressStreetNameObfuscated = 'carOwnerLegalAddressStreetNameObfuscated',
  CarOwnerNameObfuscated = 'carOwnerNameObfuscated',
  CarOwnerPeselObfuscated = 'carOwnerPeselObfuscated',
  CarPhotoInspectionInfoBeesafe = 'carPhotoInspectionInfoBeesafe',
  CarRegistrationNumberError = 'carRegistrationNumberError',
  CarRegistrationNumberInvalid = 'carRegistrationNumberInvalid',
  CarRegistrationNumberRequired = 'carRegistrationNumberRequired',
  CarRegistrationNumberWarning = 'carRegistrationNumberWarning',
  CarVinObfuscated = 'carVinObfuscated',
  CheckoutCoowners2PeselAndBirthDateMismatch = 'checkoutCoowners2PeselAndBirthDateMismatch',
  CheckoutCoowners2PeselAndGenderMismatch = 'checkoutCoowners2PeselAndGenderMismatch',
  CheckoutCoowners2PeselAndLicenseYearMismatch = 'checkoutCoowners2PeselAndLicenseYearMismatch',
  CheckoutCoownersPeselAndBirthDateMismatch = 'checkoutCoownersPeselAndBirthDateMismatch',
  CheckoutCoownersPeselAndGenderMismatch = 'checkoutCoownersPeselAndGenderMismatch',
  CheckoutCoownersPeselAndLicenseYearMismatch = 'checkoutCoownersPeselAndLicenseYearMismatch',
  CheckoutInsurantPeselAndBirthDateMismatch = 'checkoutInsurantPeselAndBirthDateMismatch',
  CheckoutInsurantToYoung = 'checkoutInsurantToYoung',
  CheckoutOwnerPeselAndBirthDateMismatch = 'checkoutOwnerPeselAndBirthDateMismatch',
  CheckoutOwnerPeselAndGenderMismatch = 'checkoutOwnerPeselAndGenderMismatch',
  CheckoutPeselAndLicenseYearMismatch = 'checkoutPeselAndLicenseYearMismatch',
  CheckoutPromotionCodeForAcOnly = 'promotionCodeForAcOnly',
  CheckoutPromotionCodeForRenewalOnly = 'promotionCodeForRenewalOnly',
  CheckoutPromotionCodeIncorrect = 'promotionCodeIncorrect',
  CheckoutPromotionCodeOfferPriceTooLow = 'promotionCodeOfferPriceTooLow',
  CompleteDataFormCoowners1PeselAndGenderMismatch = 'completeDataFormCoowners1PeselAndGenderMismatch',
  CompleteDataFormCoowners1PeselAndLicenseYearMismatch = 'completeDataFormCoowners1PeselAndLicenseYearMismatch',
  CompleteDataFormCoowners2PeselAndGenderMismatch = 'completeDataFormCoowners2PeselAndGenderMismatch',
  CompleteDataFormCoowners2PeselAndLicenseYearMismatch = 'completeDataFormCoowners2PeselAndLicenseYearMismatch',
  CompleteDataFormOwnerPeselAndGenderMismatch = 'completeDataFormOwnerPeselAndGenderMismatch',
  CompleteDataFormOwnerPeselAndLicenseYearMismatch = 'completeDataFormOwnerPeselAndLicenseYearMismatch',
  ContactUsMessage = 'contactUsMessage',
  Coowners2LastNameObfuscated = 'coowners2LastNameObfuscated',
  Coowners2LegalAddressStreetNameObfuscated = 'coowners2LegalAddressStreetNameObfuscated',
  Coowners2NameObfuscated = 'coowners2NameObfuscated',
  Coowners2PassportObfuscated = 'coowners2PassportObfuscated',
  Coowners2PeselAndBirthDateMismatch = 'checkoutCoowners2PeselAndBirthDateMismatch',
  Coowners2PeselObfuscated = 'coowners2PeselObfuscated',
  CoownersLastNameObfuscated = 'coownersLastNameObfuscated',
  CoownersLegalAddressStreetNameObfuscated = 'coownersLegalAddressStreetNameObfuscated',
  CoownersNameObfuscated = 'coownersNameObfuscated',
  CoownersPassportObfuscated = 'coownersPassportObfuscated',
  CoownersPeselAndBirthDateMismatch = 'checkoutCoownersPeselAndBirthDateMismatch',
  CoownersPeselObfuscated = 'coownersPeselObfuscated',
  DifferentFirstRegistrationDateFromCepik = 'differentFirstRegistrationDateFromCepik',
  EnterYourEmailToProceed = 'enterYourEmailToProceed',
  FirstCoownerFamilyNameNotUnique = 'firstCoownerFamilyNameNotUnique',
  FirstCoownerFamilyNameObfuscated = 'coownersFamilyNameObfuscated',
  FirstCoownerLastNameNotUnique = 'firstCoownerLastNameNotUnique',
  FirstRegistrationDateCantBeBeforeProdYear = 'firstRegistrationDateCantBeBeforeProdYear',
  FirstRegistrationDateCantBeInFuture = 'firstRegistrationDateCantBeInFuture',
  FirstRegistrationDateCantBeToLate = 'firstRegistrationDateCantBeToLate',
  GetBetterOffersWithoutLeasing = 'getBetterOffersWithoutLeasing',
  InsuranceContactConsentRequiredIfPhoneNumberSet = 'insuranceContactConsentRequiredIfPhoneNumberSet',
  InsuranceEmailMarketingConsentRequiredIfEmailSet = 'insuranceEmailMarketingConsentRequiredIfEmailSet',
  InsuranceEmailRequired = 'insuranceEmailRequired',
  InsuranceMainConsentUnchecked = 'insuranceMainConsentUnchecked',
  InsuranceMarketingConsentRequiredIfPhoneNumberSet = 'insuranceMarketingConsentRequiredIfPhoneNumberSet',
  InsurancePastDateNotAllowed = 'insurancePastDateNotAllowed',
  InsurancePhoneNumberObfuscated = 'insurancePhoneNumberObfuscated',
  InsurancePhoneNumberRequired = 'insurancePhoneNumberRequired',
  InsuranceStartDateAfterCepikPolicyStartDate = 'insuranceStartDateAfterCepikPolicyStartDate',
  InsurantAdditionalEmailObfuscated = 'insurantAdditionalEmailObfuscated',
  InsurantAdditionalPhoneNumberObfuscated = 'insurantAdditionalPhoneNumberObfuscated',
  InsurantEmailObfuscated = 'insurantEmailObfuscated',
  InsurantHomeAddressStreetNameObfuscated = 'insurantHomeAddressStreetNameObfuscated',
  InsurantLastNameObfuscated = 'insurantLastNameObfuscated',
  InsurantNameObfuscated = 'insurantNameObfuscated',
  InsurantNipNumberObfuscated = 'insurantNipNumberObfuscated',
  InsurantPassportObfuscated = 'insurantPassportObfuscated',
  InsurantPeselObfuscated = 'insurantPeselObfuscated',
  InsurantPhoneNumberObfuscated = 'insurantPhoneNumberObfuscated',
  MatchingQuotationDataFound = 'matchingQuotationDataFound',
  MotoPhotoInspectionInfoBeesafe = 'motoPhotoInspectionInfoBeesafe',
  NoInsuranceForVehicleInLeasing = 'noInsuranceForVehicleInLeasing',
  NoModelAndVersionError = 'noModelAndVersionError',
  NoModelIeWarning = 'noModelIeWarning',
  NoVersionEtError = 'noVersionEtError',
  NoVersionEtWarning = 'noVersionEtWarning',
  NoVersionIeAndEtError = 'noVersionIeAndEtError',
  NoVersionIeWarning = 'noVersionIeWarning',
  OldCar = 'oldCar',
  OwnerFamilyNameNotUnique = 'ownerFamilyNameNotUnique',
  OwnerFamilyNameObfuscated = 'ownerFamilyNameObfuscated',
  OwnerLastNameNotUnique = 'ownerLastNameNotUnique',
  OwnerLastNameObfuscated = 'ownerLastNameObfuscated',
  OwnerNameObfuscated = 'ownerNameObfuscated',
  OwnerPassportObfuscated = 'ownerPassportObfuscated',
  OwnerPeselObfuscated = 'ownerPeselObfuscated',
  PasswordsDifference = 'passwordsDifference',
  PaymentPromotionCodeMessage = 'paymentPromotionCodeMessage',
  PaymentPromotionCodeMessageInternalTransfer = 'paymentPromotionCodeInternalTransferMessage',
  PaymentPromotionCodeWrongCode = 'paymentPromotionCodeWrongCode',
  PaymentPromotionVoucher = 'paymentPromotionVoucher',
  PersonalData = 'personalData',
  PersonalDataNotProvided = 'personalDataNotProvided',
  PersonalDataNotProvidedWithoutCarRegistrationNumber = 'personalDataNotProvidedWithoutCarRegistrationNumber',
  PersonalDataWithoutCarRegistrationNumber = 'personalDataWithoutCarRegistrationNumber',
  PeselAndBirthDateMismatch = 'peselAndBirthDateMismatch',
  QuotationCarVinObfuscated = 'quotationCarVinObfuscated',
  QuotationRegistrationNumberObfuscated = 'quotationRegistrationNumberObfuscated',
  RegistrationNumberNotKnown = 'registrationNumberNotKnown',
  RegistrationNumberObfuscated = 'registrationNumberObfuscated',
  RememberToPayForThePolicy = 'rememberToPayForThePolicy',
  SecondCoownerFamilyNameNotUnique = 'secondCoownerFamilyNameNotUnique',
  SecondCoownerFamilyNameObfuscated = 'coowners2FamilyNameObfuscated',
  SecondCoownerLastNameNotUnique = 'secondCoownerLastNameNotUnique',
  StartDateNotConfirmed = 'startDateNotConfirmed',
  TravelDestinationAbroad = 'travelDestinationAbroad',
  TravelDestinationError = 'travelDestinationError',
  TravelDestinationPoland = 'travelDestinationPoland',
  TravelEndDateError = 'travelEndDateError',
  TravelEndDateRequired = 'travelEndDateRequired',
  TravelGoalsError = 'travelGoalsError',
  TravelInsuranceEndDatePastNotAllowed = 'travelInsuranceEndDatePastNotAllowed',
  TravelInsuranceMaxRangeExceeded = 'travelInsuranceMaxRangeExceeded',
  TravelInsuranceStartDateDistantNotAllowed = 'travelInsuranceStartDateDistantNotAllowed',
  TravelInsuranceStartDatePastNotAllowed = 'travelInsuranceStartDatePastNotAllowed',
  TravelInsurantDataObfuscated = 'travelInsurantDataObfuscated',
  TravelInsurantEmailObfuscated = 'travelInsurantEmailObfuscated',
  TravelInsurantHomeAddressObfuscated = 'travelInsurantHomeAddressObfuscated',
  TravelOver99DestinationsError = 'travelOver99DestinationsError',
  TravelStartDateError = 'travelStartDateError',
  TravelStartDateRequired = 'travelStartDateRequired',
  TravelStartDateTooFar = 'travelStartDateTooFar',
  TravelTodayStartDateGracePeriodInfoAxa = 'travelTodayStartDateGracePeriodInfoAxa',
  TravelTodayStartDateGracePeriodInfoOther = 'travelTodayStartDateGracePeriodInfoOther',
  Traveler10DataObfuscated = 'traveler10DataObfuscated',
  Traveler1DataObfuscated = 'traveler1DataObfuscated',
  Traveler2DataObfuscated = 'traveler2DataObfuscated',
  Traveler3DataObfuscated = 'traveler3DataObfuscated',
  Traveler4DataObfuscated = 'traveler4DataObfuscated',
  Traveler5DataObfuscated = 'traveler5DataObfuscated',
  Traveler6DataObfuscated = 'traveler6DataObfuscated',
  Traveler7DataObfuscated = 'traveler7DataObfuscated',
  Traveler8DataObfuscated = 'traveler8DataObfuscated',
  Traveler9DataObfuscated = 'traveler9DataObfuscated',
  UsernameAlreadyInUse = 'usernameAlreadyInUse',
}
