import {
  ActiveScreenSetPayload,
  AvailabilitySetActionPayload,
  ClearHiddenDictionaryEntriesActionPayload,
  DictionariesSetActionPayload,
  DictionaryEntriesVisibilitySetActionPayload,
  DictionaryEntryVisibilitySetActionPayload,
  DictionarySetActionPayload,
  FormConfigSetActionPayload,
  FormErrorsClearActionPayload,
  IgnoreAnonymizedSetActionPayload,
  InitialFocusedDateSetActionPayload,
  MaxDateSetActionPayload,
  MessageSetActionPayload,
  MinDateSetActionPayload,
  OfferFormStructureSetActionPayload,
  PatchableSetActionPayload,
  QuestionErrorsClearActionPayload,
  QuestionMessageAddActionPayload,
  QuestionLabelSetActionPayload,
  ReadabilitySetActionPayload,
  RequirabilitySetActionPayload,
  SectionLabelSetActionPayload,
  SectionSubLabelSetActionPayload,
  SectionMessageSetActionPayload,
  SetHiddenDictionaryEntriesActionPayload,
  SetupValidatorForTypeActionPayload,
  ShouldAutofillSetPayload,
  ValidateFieldActionPayload,
  ValidateFormActionPayload,
  VisibilitySetActionPayload,
  QuestionClearableOnHidePayload,
  OverwrittenFormConfigActionPayload,
  DependsOnSetActionPayload,
} from './actionPayload.interfaces';
import { ActionType } from 'app/store/actionTypes';
import { FormsEnum } from 'app/shared/enums';
import { ActionsUnion, createAction } from 'app/store/helpers';

export const FormsConfigActions = {
  setFormConfigAction: (payload: FormConfigSetActionPayload) =>
    createAction(ActionType.SET_FORM_CONFIG, payload),
  setFormConfigClearAction: (formName: FormsEnum) =>
    createAction(ActionType.CLEAR_FORM_CONFIG, formName),
  setDictionaryAction: (payload: DictionarySetActionPayload) =>
    createAction(ActionType.SET_DICTIONARY, payload),
  setDictionariesAction: (payload: DictionariesSetActionPayload) =>
    createAction(ActionType.SET_DICTIONARIES, payload),
  setQuestionVisibilityAction: (payload: VisibilitySetActionPayload) =>
    createAction(ActionType.SET_QUESTION_VISIBILITY, payload),
  setQuestionIgnoreAnonymizedAction: (
    payload: IgnoreAnonymizedSetActionPayload
  ) => createAction(ActionType.SET_QUESTION_IGNORE_ANONYMIZED, payload),
  setQuestionPatchableAction: (payload: PatchableSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_PATCHABLE, payload),
  setQuestionLabelAction: (payload: QuestionLabelSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_LABEL, payload),
  setSectionVisibilityAction: (payload: VisibilitySetActionPayload) =>
    createAction(ActionType.SET_SECTION_VISIBILITY, payload),
  setSectionLabelAction: (payload: SectionLabelSetActionPayload) =>
    createAction(ActionType.SET_SECTION_LABEL, payload),
  setSectionSubLabelAction: (payload: SectionSubLabelSetActionPayload) =>
    createAction(ActionType.SET_SECTION_SUBLABEL, payload),
  setSectionMessageVisibilityAction: (payload: VisibilitySetActionPayload) =>
    createAction(ActionType.SET_SECTION_MESSAGE_VISIBILITY, payload),
  setMessageVisibilityAction: (payload: VisibilitySetActionPayload) =>
    createAction(ActionType.SET_MESSAGE_VISIBILITY, payload),
  setMinDateAction: (payload: MinDateSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_MIN_DATE, payload),
  setMaxDateAction: (payload: MaxDateSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_MAX_DATE, payload),
  setInitialFocusedDateAction: (payload: InitialFocusedDateSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_INITIAL_FOCUSED_DATE, payload),
  setDictionaryEntryVisibilityAction: (
    payload: DictionaryEntryVisibilitySetActionPayload
  ) => createAction(ActionType.SET_DICTIONARY_ENTRY_VISIBILITY, payload),
  setDictionaryEntriesVisibilityAction: (
    payload: DictionaryEntriesVisibilitySetActionPayload
  ) => createAction(ActionType.SET_DICTIONARY_ENTRIES_VISIBILITY, payload),
  clearHiddenDictionaryEntriesAction: (
    payload: ClearHiddenDictionaryEntriesActionPayload
  ) => createAction(ActionType.CLEAR_HIDDEN_DICTIONARY_ENTRIES, payload),
  setQuestionAvailabilityAction: (payload: AvailabilitySetActionPayload) =>
    createAction(ActionType.SET_QUESTION_AVAILABILITY, payload),
  setMessageAction: (payload: MessageSetActionPayload) =>
    createAction(ActionType.SET_MESSAGE, payload),
  setSectionMessageAction: (payload: SectionMessageSetActionPayload) =>
    createAction(ActionType.SET_SECTION_MESSAGE, payload),
  validateFormAction: (payload: ValidateFormActionPayload) =>
    createAction(ActionType.VALIDATE_FORM, payload),
  addQuestionMessage: (payload: QuestionMessageAddActionPayload) =>
    createAction(ActionType.ADD_QUESTION_MESSAGE, payload),
  clearQuestionErrorsAction: (payload: QuestionErrorsClearActionPayload) =>
    createAction(ActionType.CLEAR_QUESTION_ERRORS, payload),
  clearFormErrorsAction: (payload: FormErrorsClearActionPayload) =>
    createAction(ActionType.CLEAR_FORM_ERRORS, payload),
  setQuestionReadabilityAction: (payload: ReadabilitySetActionPayload) =>
    createAction(ActionType.SET_QUESTION_READABILITY, payload),
  setQuestionRequirabilityAction: (payload: RequirabilitySetActionPayload) =>
    createAction(ActionType.SET_QUESTION_REQUIRABILITY, payload),
  setActiveScreenAction: (payload: ActiveScreenSetPayload) =>
    createAction(ActionType.SET_FORM_ACTIVE_SCREEN, payload),
  setShouldAutofillAction: (payload: ShouldAutofillSetPayload) =>
    createAction(ActionType.SET_SHOULD_AUTO_FILL, payload),
  setOfferFormStructureAction: (payload: OfferFormStructureSetActionPayload) =>
    createAction(ActionType.SET_FORM_STRUCTURE, payload),
  setHiddenDictionariesEntries: (
    payload: SetHiddenDictionaryEntriesActionPayload
  ) => createAction(ActionType.SET_HIDDEN_DICTIONARIES_ENTRIES, payload),
  setupValidatorForType: (payload: SetupValidatorForTypeActionPayload) =>
    createAction(ActionType.SETUP_VALIDATOR_FOR_TYPE, payload),
  validateField: (payload: ValidateFieldActionPayload) =>
    createAction(ActionType.VALIDATE_FIELD, payload),
  setQuestionClearableOnHideAction: (payload: QuestionClearableOnHidePayload) =>
    createAction(ActionType.SET_QUESTION_CLEARABLE_ON_HIDE, payload),
  setOverwrittenFormConfigAction: (
    payload: OverwrittenFormConfigActionPayload
  ) => createAction(ActionType.SET_OVERWRITTEN_FORM_CONFIG, payload),
  setQuestionDependsOnAction: (payload: DependsOnSetActionPayload) =>
    createAction(ActionType.SET_QUESTION_DEPENDS_ON, payload),
  setPreviousProductionYear: (productionYear: number) =>
    createAction(ActionType.SET_PREVIOUS_PRODUCTION_YEAR, productionYear),
  setVehicleTypeChanged: (vehicleTypeChanged: boolean) =>
    createAction(ActionType.SET_VEHICLE_TYPE_CHANGED, vehicleTypeChanged),
};

export type FormsConfigActionsType = ActionsUnion<typeof FormsConfigActions>;
