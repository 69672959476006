export enum MoreFiltersTypeEnum {
  Amortization = 'amortizationType',
  AssistanceAfterFailure = 'afterFailure',
  ExcessType = 'excessType',
  FormOfRepair = 'repairType',
  SubstituteCar = 'substituteCar',
}

export enum FiltersExcessType {
  ExcessEqual500 = 'EQUAL_500',
  ExcessMore500 = 'MORE_THAN_500',
  NoExcess = 'NO_EXCESS',
}

export enum FilterAmortizationType {
  AmortizationWithoutDeduction = 'WITHOUT_DEDUCTION',
  AmortizationWithDeduction = 'WITH_DEDUCTION',
}

export enum FilterRepairType {
  NoCashASO = 'NO_CASH_ASO',
  NoCashService = 'NO_CASH_SERVICE',
  Cash = 'CASH',
}

export enum FiltersEnums {
  ExcessType = 'Udział własny',
  Amortization = 'Wartość części (amortyzacja)',
  AmortizationWithoutDeduction = 'Pełna',
  AmortizationWithDeduction = 'Obniżona',
  NoExcess = 'Bez udziału',
  ExcessEqual500 = '500 zł',
  ExcessMore500 = 'Powyżej 500 zł',
  FormOfRepair = 'Forma naprawy',
  FormOfRepairNoCashASO = 'Bezgotówkowa - ASO',
  FormOfRepairNoCashService = 'Bezgotówkowa - warsztat',
  FormOfRepairCash = 'Gotówkowa - kosztorys',
  AssistanceAfterFailure = 'Po awarii',
  ReplacementVehicle = 'Pojazd zastępczy',
  FormOfRepairTooltip = 'Wybierając gotówkową formę naprawy, odszkodowanie za&nbsp;szkodę dostaniesz na konto&nbsp;-&nbsp;wysokość odszkodowania zostanie wyliczona na&nbsp;podstawie kosztorysu. W formie bezgotówkowej, samochód odstawiasz do&nbsp;warsztatu lub&nbsp;ASO, a&nbsp;rozliczenie następuje między ubezpieczycielem a&nbsp;warsztatem samochodowym.',
  FormOfRepairTooltipMotor = 'Wybierając gotówkową formę naprawy, odszkodowanie za&nbsp;szkodę dostaniesz na konto&nbsp;-&nbsp;wysokość odszkodowania zostanie wyliczona na&nbsp;podstawie kosztorysu. W formie bezgotówkowej, pojazd odstawiasz do&nbsp;warsztatu lub&nbsp;ASO, a&nbsp;rozliczenie następuje między ubezpieczycielem a&nbsp;warsztatem.',
  ExcessTooltip = 'Jest to wartość szkody pokrywana z&nbsp;własnej kieszeni. Wybierając opcję “bez&nbsp;udziału” odszkodowanie zostanie wypłacone w&nbsp;całości. Pamiętaj, że&nbsp;ubezpieczenie z&nbsp;udziałem własnym jest tańsze.',
  AmortizationTooltip = 'Amortyzacja oznacza zużywanie się części. Wybierając opcję “Pełna” pomimo starzenia się auta, części zostaną wycenione na&nbsp;pełną wartość. W&nbsp;przypadku opcji “Obniżona”, odszkodowanie za&nbsp;części zostanie pomniejszone o&nbsp;procentową wartość wskazaną w&nbsp;szczegółach ofert.',
  AmortizationTooltipMotor = 'Amortyzacja oznacza zużywanie się części. Wybierając opcję “Pełna” pomimo starzenia się pojazdu, części zostaną wycenione na&nbsp;pełną wartość. W&nbsp;przypadku opcji “Obniżona”, odszkodowanie za&nbsp;części zostanie pomniejszone o&nbsp;procentową wartość wskazaną w&nbsp;szczegółach ofert.',
  MoreFilterOptionsDisable = 'Dla wybranego zakresu opcja nie jest możliwa do wyboru.',
  MoreFilterOptionsNoOffer = 'Dla wybranego zakresu nie mamy dostępnych ofert.',
  AcFilterNoOffers = 'Niestety ubezpieczyciele nie przedstawili oferty AC dla Twojego auta.',
  MoreFilters = 'Więcej filtrów',
  FilterOptionTitle = 'Opcje filtrowania',
  CompareOffers = 'Porównaj oferty',
  Sort = 'Sortowanie',
  FromCheapest = 'Od najtańszych',
  FromMostExpensive = 'Od najdroższych',
  Adjust = 'Dostosuj',
}
